import React, { useEffect, useState } from "react";
import "../../../sass/ProcessInfo/Processinfo.scss";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import Heading from "../../../components/Heading";
import { FormattedMessage, injectIntl } from "react-intl";
import ProcessCard from "../../../components/ProcessInfo/ProcessCard";
import { getALLProcess } from "../../../redux/actions/ProcessInfo/InfoActions"
import { PulseLoader } from "react-spinners";
import { Empty } from "antd";

const ProcessInfo = (props) => {
	const [Process, setProcess] = useState([])
	const [getLoading, setGetLoading] = useState(true)
	const { intl } = props

	useEffect(() => {
		document.title = intl.formatMessage({ id: "ProcessInfo" })
		getALLProcess()
		// eslint-disable-next-line
	}, [])

	const getALLProcess = () => {
		props.getALLProcess()
			.then(res => {
				if (res.status === "success") {
					setProcess(res.result)
					setGetLoading(false)
				}
				else {
					setProcess([])
					setGetLoading(false)
				}
			})
	}
	return (
		<div className="processinfo">
			<Heading text={<FormattedMessage id="Processinfo" />} />
			<Container>
				<Row style={{ justifyContent: "center" }} md={2} lg={3} sm={1} xs={1}>

					{getLoading ? <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}><PulseLoader color="#458ff6" size={12} /></div> :

						Process.map((pro, i) => {
							return (
								<Col style={{ marginTop: 20 }} key={i}>
									<ProcessCard pro={pro} />
								</Col>
							)
						})
					}
					{
						!Process.length && !getLoading && <Empty description={<FormattedMessage id="noData" />} />
					}

				</Row>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
	getALLProcess
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProcessInfo));
