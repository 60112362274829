import { toast } from "react-toastify";
import { socket } from "../../hooks/Socket";
import NotificationAlert from "../../components/NotificationAlert";

const initSocket = (user) => {
	if (localStorage.getItem("AyHc7zSW7n6rI")) {
		socket.on("connect", () => {

		});

		socket.emit('ping_user', {
			user_id: user._id
		})
		
		socket.on("new_notification", (data) => {
			if (
				data &&
				data.received_by &&
				Array.isArray(data.received_by) &&
				data.received_by.some((one) => one === user._id)
			) {
				toast(<NotificationAlert user={data.sender_info} msg={data.event_type} />);
			}
		});
		socket.on("error", (data) => {

		});
		socket.on("disconnect", () => { });
	}
};

export default initSocket;
