import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as Vec } from "../../assets/icons/IdeaInfo/Vector.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/IdeaInfo/ArrowDown.svg";
import { FormattedMessage, injectIntl } from "react-intl";
import {
	Menu,
	Dropdown,
	notification,
} from "antd";
import User from "../../services/user";
import {
	getRequests,
	deleteRequest,
} from "../../redux/actions/Manageteam/TeamActions";
import { Empty } from "antd";
import { socket } from "../../hooks/Socket";

const Requests = (props) => {
	//Loaders
	const [loadRequest, setloadRequest] = useState(true);
	//Data
	const [result, setResult] = useState([]);
	const { intl } = props;

	useEffect(() => {
		getInvites();
		// eslint-disable-next-line
	}, [props.refresh]);
	
	const getInvites = () => {
		props.getRequests(props.id).then((res) => {
			if (res && res.status === "success") {
				setResult(res.result.Invites);
				setloadRequest(true);
				props.setrefresh(false);
			} else if (res === "Not Found") setloadRequest(false);
		});
	};

	const deleteRequest = (userId) => {
		const data = {
			ideaId: props.id,
			userId: userId,
		};
		props.deleteRequest(data).then((res) => {
			if (res.status === "success") {
				let dta = [];
				dta = [...dta, userId];
				socket.emit("new_notification", {
					event_type: "sent_request",
					received_by: dta,
				});
				notification["success"]({
					message: intl.formatMessage({ id: "success" }),
					description: intl.formatMessage({ id: res.msg }),
				});
				getInvites();
			}
		});
	};
	const menu = (userId) => (
		<Menu>
			<Menu.Item key={userId} onClick={() => deleteRequest(userId)}>
				Cancel Request
			</Menu.Item>
		</Menu>
	);
	const menu2 = (userId) => (
		<Menu>
			<Menu.Item key="1" onClick={() => deleteRequest(userId)}>
				Delete
			</Menu.Item>
		</Menu>
	);
	return (
		<div className="Request">
			<div className="AllRequests">
				{loadRequest ? (
					<>
						{result.map((req, i) => {
							if (req.status !== "Accepted")
								return (
									<div className="user" key={i}>
										<div
											className="userImage"
											style={{
												backgroundImage: `url('${User.avatarLink({
													avatar: req.receiver.avatar,
													userId: req.receiver.userId,
												})}')`,
											}}
										></div>
										<div className="userInfos">
											<div className="username">
												{req.receiver.firstname} {req.receiver.lastname}
											</div>
											<div className="position">{req.position}</div>
											<div className="member">Member</div>
										</div>
										<div
											className={
												req.status === "Pending"
													? "status Pending"
													: "status Rejected"
											}
										>
											{req.status === "Pending" ? <FormattedMessage id="pending" /> : <FormattedMessage id="Rejected" />}
										</div>
										<div className="menu">
											<Dropdown
												overlay={
													req.status === "Pending"
														? menu(req.receiver.userId)
														: menu2(req.receiver.userId)
												}
											>
												<span onClick={(e) => e.preventDefault()} style={{cursor: 'pointer'}}>
													<Vec /> <ArrowDown />
												</span>
											</Dropdown>
										</div>
									</div>
								);
							else return ("")
						})}
					</>
				) : (
					<Empty description={<FormattedMessage id="noRequests" />} />
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	me: state.auth.login.user,
});

export default injectIntl(
	connect(mapStateToProps, { getRequests, deleteRequest })(Requests)
);
