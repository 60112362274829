import '@zoomus/websdk/dist/css/react-select.css'
import { ZoomMtg } from "@zoomus/websdk";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { getSignature } from "../redux/actions/Communications/CommActions"
import { connect } from "react-redux";
import { notification } from "antd";
import { injectIntl } from "react-intl";
import config from '../Helpers/config'


// setup your signautre endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
const apiKey = "9clhoHHvSsmVj4ePM6QP3g"
var meetingNumber = 0

var leaveUrl = `${config.zoomLink}/app` // the redirect url
var userName = ''
var userEmail = ''
var passWord = ''
// eslint-disable-next-line
var signature = ''

const ZoomMeet = (props) => {
	const { intl } = props
	const history = useHistory()

	// Loading zoom libararies before joining
	useEffect(() => {
		props.getSignature({ meetingNumber: props.meeting.met, role: 0 })
			.then(res => {
				if (res.status === "success") {
					meetingNumber = props.meeting.met
					signature = res.result
					passWord = props.meeting.pwd
					userName = props.meeting.username
					userEmail = props.meeting.useremail
					initiateMeeting(res.result)
				}
				else {
					history.goBack()
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: "error_1" }),
					})
				}
			})
		// eslint-disable-next-line
	}, [])
	useEffect(() => {
		showZoomDiv()
		ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.6/lib', '/av');
		ZoomMtg.preLoadWasm();
		ZoomMtg.prepareJssdk();

	}, [])

	const showZoomDiv = () => {
		document.getElementById('zmmtg-root').style.display = 'block'
	}

	const initiateMeeting = (signature) => {
		ZoomMtg.init({
			leaveUrl: leaveUrl,
			isSupportAV: true,
			success: (success) => {
				ZoomMtg.join({
					signature: signature,
					meetingNumber: meetingNumber,
					userName: userName,
					apiKey: apiKey,
					userEmail: userEmail,
					passWord: passWord,
					success: (success) => {
					},
					error: (error) => {
						// console.log(error)
					}
				})
			},
			error: (error) => {
				// console.log(error)
			}
		})
	}

	return (
		<div></div>
	);
}

const mapStateToProps = (state) => ({})

export default injectIntl(connect(mapStateToProps, { getSignature })(ZoomMeet))
