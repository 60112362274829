/*
 ** Path Section
 */

const PathMethods = {
	finishStep: (form, files, type, sessionId) => {
		return new Promise((resolve, reject) => {
			const data = {}
			if (type === "session" && !sessionId)
				reject("sessionerr")
			if (form.summaryOfOutcome && form.lessonsLearned)
				Object.assign(data, { outcome: form.summaryOfOutcome.toHTML(), lessons_learned: form.lessonsLearned.toHTML(), session: sessionId, document_links: files })
			else reject("error_2")
			resolve(data)
		})

	},
	getNextStage: (stages, currentStage) => {
		return new Promise((resolve, reject) => {
			stages.forEach((stage, i) => {
				if (stage._id === currentStage._id) {
					if ((stages.length - 1) === (i + 1))
						resolve({ newstage: stages[i + 1], nextbtn: "true" });
					else resolve({ newstage: stages[i + 1], nextbtn: "false" });
				}
			})
		})
	},
	checkModifedData: (oldData, newData) => {
		return new Promise((resolve, reject) => {
			const data = {}
			oldData.outcome !== newData.outcome && Object.assign(data, { outcome: newData.outcome })
			oldData.lessons_learned !== newData.lessons_learned && Object.assign(data, { lessons_learned: newData.lessons_learned })
			oldData.document_links !== newData.document_links && Object.assign(data, { document_links: newData.document_links })
			resolve(data)
		})
	}
};

export default PathMethods;
