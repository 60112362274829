import React, { useContext, useEffect, useState } from "react";
import Heading from "../../../components/Heading";
import countryCodes from "../../../json/countryCodes.json";
import countries from "../../../json/countries.json";
import cities from "../../../json/cities.json";
import langs from "../../../json/languages.json";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Container, Media, Row } from "reactstrap";
import "../../../sass/EditProfile.scss";
import {
	Form,
	Input,
	Select,
	Switch,
	Button,
	Tooltip,
	DatePicker,
	Alert,
	notification,
	Upload,
	Divider,
	Modal,
	Empty,
} from "antd";
import ImgCrop from "antd-img-crop";
import ReactCountryFlag from "react-country-flag";
import {
	Facebook,
	Globe,
	Info,
	Instagram,
	Key,
	Linkedin,
	Lock,
	Plus,
	Settings,
	Twitter,
	Unlock,
	X,
	Youtube,
} from "react-feather";
import { IntlContext } from "../../../context/internationalization";
import {
	editProfile,
	getUser,
	changePassword,
} from "../../../redux/actions/auth/loginActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import UserMethods from "../../../Helpers/UserHelpers";
import ProfileMethods from "../../../Helpers/Profiles";
import _ from "lodash";

import config from '../../../Helpers/config'

const userLink = config.userLink

const Edit = ({ user, editProfile, getUser, changePassword, intl }) => {
	const history = useHistory();
	const context = useContext(IntlContext);
	const [key, setKey] = useState(0);
	const [loading, setLoading] = useState(false);
	const [changed, setChanged] = useState(false);
	const [amra, setamra] = useState(false);
	const [stateCities, setStateCities] = useState([]);
	const [generalForm] = Form.useForm();
	const [question] = Form.useForm();
	const [changePasswordForm] = Form.useForm();
	const [fileList, setFileList] = useState([
		{
			uid: "-1",
			name: user.avatar,
			status: user.avatar,
			url: user.avatar ? UserMethods.getUserImage(user.userId, user.avatar) : UserMethods.getUserAvatar(),
		},
	]);
	const [educations, setEducations] = useState([]);
	const [experiences, setExperiences] = useState([]);
	const [expForm] = Form.useForm();
	const [eduForm] = Form.useForm();
	const [untilNow1, setUntilNow1] = useState(false);
	const [untilNow2, setUntilNow2] = useState(false);
	const [isVisible1, setIsVisible1] = useState(false);
	const [isVisible2, setIsVisible2] = useState(false);
	const questionsdata = {
		idea_holder: [
			<FormattedMessage id="ihQte_1" />,
			<FormattedMessage id="ihQte_2" />,
			<FormattedMessage id="ihQte_3" />,
			<FormattedMessage id="ihQte_4" />,
		],
		expert: [
			<FormattedMessage id="meciQte_1" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_4" />,
			<FormattedMessage id="meciQte_5" />,
			<FormattedMessage id="meciQte_6" />,
		],
		mentor: [
			<FormattedMessage id="meciQte_1" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_4" />,
			<FormattedMessage id="meciQte_5" />,
			<FormattedMessage id="meciQte_6" />,
		],
		coach: [
			<FormattedMessage id="meciQte_1" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_4" />,
			<FormattedMessage id="meciQte_5" />,
			<FormattedMessage id="meciQte_6" />,
		],
		coordinator: [
			"organization of coordinator: (government program, Incubators/accelerators, association, enterprise...)",
			"name of organization:",
		],
		investor: [
			"type of investor(business angel, individual, fond d'investissement...)",
			"geographic investment (local or international)",
			"select your interesting sectors for investment (a list of sectors...)",
		],
	};
	const openNotificationWithIcon = () => {
		notification["success"]({
			message: intl.formatMessage({ id: "success" }),
			description: intl.formatMessage({ id: "success_9" }),
			placement: "bottomLeft",
		});
	};

	useEffect(() => {
		document.title = intl.formatMessage({ id: "editprofile" })
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (user) {
			ProfileMethods.FillQsts(user.questions).then((res) => {
				generalForm.setFieldsValue({ questions: res });
				question.setFieldsValue(res);
			});
			setEducations(user.education);
			setExperiences(user.experiences);
		}
		// eslint-disable-next-line
	}, [user]);
	const layout = {
		layout: "vertical",
		size: "large",
	};

	const [formError, setFormError] = useState("");

	const onFinish1 = (values) => {
		const countryObj = countries.find((obj) => {
			if (!values.country) return false;
			const locale = context.state.locale;
			if (locale === "fr") return obj.label === values.country || obj.label_fr === values.country;
			return obj.label_ar === values.country;
		});

		const countryCities = cities.find((obj) => {
			if (!values.country || !values.city) return false;
			return obj.country === countryObj.label;
		});
		const cityObj = countryCities
			? countryCities.cities.find((obj) => {
				const locale = context.state.locale;
				if (locale === "en") return obj.label === values.city;
				else if (locale === "fr") return obj.label_fr === values.city;
				return obj.label_ar === values.city;
			})
			: undefined;

		const data = {
			phoneNumber: {
				number: values.countrycode + values.phone,
				privacy: values.phonePrivate
					? values.phonePrivate
						? "private"
						: "public"
					: user.phoneNumber.privacy,
			},
			gender: values.gender,
			address: {
				country: countryObj ? countryObj : {},
				city: cityObj ? cityObj : {},
				address: values.address ? values.address : "",
			},
			languages: values.languages,
			birthdate: {
				date: values.birthdate.format(),
				privacy: values.birthdatePrivate
					? values.birthdatePrivate
						? "private"
						: "public"
					: user.birthdate.privacy,
			},
			firstname: values.firstname,
			lastname: values.lastname,
			avatar: values.avatar !== user.avatar && values.avatar,
			biography: values.biography,
			account_type: user.account_type,
			socialMedia: {
				website: values.website,
				linkedin: values.linkedin,
				twitter: values.twitter,
				facebook: values.facebook,
				instagram: values.instagram,
				youtube: values.youtube,
			},
			questions: amra ? question.getFieldsValue() : [],
			experiences: experiences,
			education: educations,
		};
		setLoading(true);
		editProfile(data, user)
			.then((res) => {
				if (res.status === "success") {
					getUser()
						.then((res) => {
							history.push("/app/profile/me");
							setLoading(false);
						})
						.catch((err) => {
							setLoading(false);
							notification["error"]({
								message: intl.formatMessage({ id: "error_text" }),
								description: intl.formatMessage({ id: err }),
							});
						});
				} else {
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: res.msg }),
					});
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: err }),
				});
			});
	};

	const onFinish2 = (values) => {
		setLoading(true);
		setFormError("");
		changePassword(values)
			.then((res) => {
				setLoading(false);
				if (res) setFormError(res);
				else {
					changePasswordForm.resetFields();
					openNotificationWithIcon();
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	};
	const getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			var img = new Image();
			img.onload = function () {
				var buf = Buffer.from(reader.result, "base64");
				buf.length > 3000000
					? notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: "error_34" }),
					})
					: cb(img.src);
			};
			img.onerror = () => {
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: "error_34" }),
				});
			};
			img.src = reader.result;
		};
		reader.onerror = function (error) { };
	}

	const beforeUpload = async (file) => {
		await getBase64(file, res => {
			generalForm.setFieldsValue({
				avatar: res.substr(22),
			})
		})
		return false
	}
	const onChange = ({ fileList: newFileList }) => {
		setChanged(true);
		setFileList(newFileList);
	};

	const onPreview = async file => {
		let src = file.url;
		if (!src) {
			src = await new Promise(resolve => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const forms = [
		<Form
			{...layout}
			form={generalForm}
			name="general"
			onFinish={onFinish1}
			onValuesChange={() => setChanged(true)}
			initialValues={{
				...user,
				countrycode: user.phoneNumber.number.substr(
					0,
					user.phoneNumber.number.length - 9
				),
				phone: user.phoneNumber.number.substr(
					user.phoneNumber.number.length - 9,
					user.phoneNumber.number.length
				),
				birthdate: moment(user.birthdate.date),
				avatar: user.avatar,
				country: user.address.country
					? context.state.locale === "ar"
						? user.address.country.label_ar
						: context.state.locale === "fr"
							? user.address.country.label_fr
							: user.address.country.label
					: "",
				city: user.address.city
					? context.state.locale === "ar"
						? user.address.city.label_ar
						: context.state.locale === "fr"
							? user.address.city.label_fr
							: user.address.city.label
					: "",
				address: user.address.address,
				...user.socialMedia,
				...user.experiences,
			}}
		>
			<Container>
				<Media>
					<Media className="mt-25" body style={{ padding: "1%" }}>
						<div className="d-flex flex-sm-row flex-column justify-content-start px-0">
							<Form.Item name="avatar">
								<ImgCrop rotate>
									<Upload
										headers={{
											Authorization: `Bearer ${localStorage.getItem("AyHc7zSW7n6rI")}`,
										}}
										action={`${userLink}/api/v1/users/edit`}
										listType="picture-card"
										fileList={fileList}
										beforeUpload={beforeUpload}
										onChange={onChange}
										onPreview={onPreview}
									>
										{fileList.length < 1 && "+ Upload"}
									</Upload>
								</ImgCrop>
							</Form.Item>
						</div>
						<p className="text-muted mt-50">
							<small>Allowed JPG, GIF or PNG. Max size of 800kB</small>
						</p>
					</Media>
				</Media>
				<Row md={2} sm={1}>
					<Col>
						<Form.Item
							name="firstname"
							label={<FormattedMessage id="firstName" />}
							required
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
								() => ({
									validator(_, value) {
										if (!value || (
											/\b[a-zA-Z\u0621-\u064AÀ-ÿ][-,a-zA-Z\u0621-\u064AÀ-ÿ. ']{2,30}$/
										).test(value)) {
											return Promise.resolve();
										}
										return Promise.reject(
											<FormattedMessage id="error_27" />
										);
									},
								}),
							]}
						>
							<Input placeholder={intl.formatMessage({id: "firstName"})}/>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							name="lastname"
							label={<FormattedMessage id="lastName" />}
							required
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
								() => ({
									validator(_, value) {
										if (!value || (
											/\b[a-zA-Z\u0621-\u064AÀ-ÿ][-,a-zA-Z\u0621-\u064AÀ-ÿ. ']{2,30}$/
										).test(value)) {
											return Promise.resolve();
										}
										return Promise.reject(
											<FormattedMessage id="error_28" />
										);
									},
								}),
							]}
						>
							<Input placeholder={intl.formatMessage({id: "lastName"})} />
						</Form.Item>
					</Col>
				</Row>
				<Row md={2} sm={1}>
					<Col>
						<Container>
							<Row>
								<Form.Item
									label={<FormattedMessage id="phoneNumber" />}
									name="phone"
									required
									rules={[
										{
											required: true,
											message: <FormattedMessage id="EmptyError" />,
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												const phonenumber = getFieldValue("countrycode") + value;
												// eslint-disable-next-line
												if (!value || (/^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}/
												).test(phonenumber))
													return Promise.resolve();
												else
													return Promise.reject(
														<FormattedMessage id="invalidPhone" />
													);
											},
										}),
									]}
								>
									<Input
										placeholder="XXX-XXXXXX"
										addonBefore={
											<Form.Item name="countrycode" noStyle>
												<Select showSearch style={{ width: 100 }}>
													{countryCodes.map(({ dial_code, code }) => (
														<Select.Option value={dial_code} key={code}>
															<div
																className="dial-select"
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																}}
															>
																<div className="flag">
																	<ReactCountryFlag countryCode={code} svg />
																</div>
																<div className="code">{dial_code}</div>
															</div>
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										}
										style={{ width: "100%" }}
									/>
								</Form.Item>
							</Row>
						</Container>
						<Row>
							<Container>
								<div className="private">
									<span className="label">
										<FormattedMessage id="private" />:
									</span>
									<Form.Item name="phonePrivate" valuePropName="checked" noStyle>
										<Switch
											checkedChildren={<Lock size={15} />}
											unCheckedChildren={<Unlock size={15} />}
											defaultChecked={
												user.phoneNumber.privacy === "public" ? false : true
											}
										/>
									</Form.Item>
									<Tooltip
										className="icon"
										title={<FormattedMessage id="phonePrivacyInfo" />}
									>
										<Info size={15} color="grey" />
									</Tooltip>
								</div>
							</Container>
						</Row>
					</Col>
					<Col>
						<Container>
							<Row>
								<Form.Item
									name="birthdate"
									label={<FormattedMessage id="birthdate" />}
									rules={[
										{
											required: true,
											message: <FormattedMessage id="EmptyError" />,
										},
									]}
								>
									<DatePicker
										format="DD-MM-YYYY"
										placeholder="DD-MM-YYYY"
										disabledDate={(date) => {
											const now = new Date();
											const minDate = new Date(
												now.getFullYear() - 130,
												now.getMonth(),
												now.getDay()
											);
											const maxDate = new Date(
												now.getFullYear() - 13,
												now.getMonth(),
												now.getDay()
											);
											return date > maxDate || date < minDate;
										}}
										showToday={false}
									/>
								</Form.Item>
							</Row>
						</Container>
						<Row>
							<Container>
								<div className="private">
									<span className="label">
										<FormattedMessage id="private" />:
									</span>
									<Form.Item name="birthdatePrivate" valuePropName="checked" noStyle>
										<Switch
											checkedChildren={<Lock size={15} />}
											unCheckedChildren={<Unlock size={15} />}
											defaultChecked={
												user.birthdate.privacy === "public" ? false : true
											}
										/>
									</Form.Item>
									<Tooltip
										className="icon"
										title={<FormattedMessage id="birthdatePrivacyInfo" />}
									>
										<Info size={15} color="grey" />
									</Tooltip>
								</div>
							</Container>
						</Row>
					</Col>
				</Row>
				<Row md={2} sm={1}>
					<Col>
						<Form.Item
							label={<FormattedMessage id="gender" />}
							name="gender"
							required
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
							]}
						>
							<Select placeholder="Select a gender">
								<Select.Option value="male">Male</Select.Option>
								<Select.Option value="female">Female</Select.Option>
								<Select.Option value="other">Other</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							label={<FormattedMessage id="languages" />}
							name="languages"
						>
							<Select
								mode="tags"
								tagRender={(props) => {
									return (
										<div className="langTag">
											{props.value}
											<X
												color="white"
												className="close"
												onClick={props.onClose}
											/>
										</div>
									);
								}}
								showSearch
								placeholder="Select a language"
								name="languages"
							>
								{langs.map((i, k) => (
									<Select.Option key={k} value={i.nativeName}>
										{i.nativeName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row md={2} sm={1}>
					<Col>
						<Form.Item label={<FormattedMessage id="country" />} name="country">
							<Select
								placeholder={intl.formatMessage({id: "country"})}
								onChange={(value) => {
									const cntr = countries.find((obj) => (obj?.label_fr === value || obj?.label_ar === value))
									const arr = cities.find((obj) => obj.country === cntr?.label);
									setStateCities(arr ? arr.cities : []);
								}}
								showSearch
								allowClear
							>
								{countries.map((i) => (
									<Select.Option
										value={context.state.locale === "ar"
											? i.label_ar
											: context.state.locale === "fr"
												? i.label_fr
												: i.label}
										key={i.value}
									>
										{context.state.locale === "ar"
											? i?.label_ar
											: context.state.locale === "fr"
												? i?.label_fr
												: i?.label}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item label={<FormattedMessage id="city" />} name="city">
							<Select placeholder={intl.formatMessage({id: "city"})} showSearch allowClear>
								{stateCities.map((i) => (
									<Select.Option
										key={i.value}
										value={
											context.state.locale === "ar"
												? i.label_ar
												: context.state.locale === "fr"
													? i.label_fr
													: i.label
										}
									>
										{context.state.locale === "ar"
											? i.label_ar
											: context.state.locale === "fr"
												? i.label_fr
												: i.label}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row md={1} sm={1}>
					<Col>
						<Form.Item label={<FormattedMessage id="address" />} name="address">
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row md={1} sm={1}>
					<Col>
						<Form.Item
							label={<FormattedMessage id="biography" />}
							name="biography"
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
								() => ({
									validator(_, value) {
										if (value.length <= 2000) {
											return Promise.resolve();
										}
										return Promise.reject(
											"Your biography should be 2000 characters long at max"
										);
									},
								}),
							]}
						>
							<Input.TextArea rows={6} />
						</Form.Item>
					</Col>
				</Row>
				<Row md={2} sm={1}>
					<Col>
						<Form.Item
							name="website"
							label="Website:"
							rules={[
								() => ({
									validator(_, value) {
										if (!value) return Promise.resolve();
										if (value.length > 3000) {
											return Promise.reject("This link is too long");
										} else if (!(
											/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
										).test(value)) {
											return Promise.reject("Unvalid URL");
										} else {
											return Promise.resolve();
										}
									},
								}),
							]}
						>
							<Input
								prefix={<Globe color="#458ff6" />}
								placeholder="www.example.com"
							/>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							name="linkedin"
							label="Linkedin:"
							rules={[
								() => ({
									validator(_, value) {
										if (!value) return Promise.resolve();
										if (value.length > 3000) {
											return Promise.reject("This link is too long");
										} else if (!(
											/(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
										).test(value)) {
											return Promise.reject("Unvalid linkedin link");
										} else {
											return Promise.resolve();
										}
									},
								}),
							]}
						>
							<Input
								prefix={<Linkedin color="#458ff6" />}
								placeholder={intl.formatMessage({id: "linkedin"})}
							/>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							name="twitter"
							label="Twitter:"
							rules={[
								() => ({
									validator(_, value) {
										if (!value) return Promise.resolve();
										if (value.length > 3000) {
											return Promise.reject("This link is too long");
										} else if (!(
											/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
										).test(value)) {
											return Promise.reject("Unvalid twitter link");
										} else {
											return Promise.resolve();
										}
									},
								}),
							]}
						>
							<Input
								prefix={<Twitter color="#458ff6" />}
								placeholder={intl.formatMessage({id: "Twitter"})}
							/>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							name="facebook"
							label="Facebook:"
							rules={[
								() => ({
									validator(_, value) {
										if (!value) return Promise.resolve();
										if (value.length > 3000) {
											return Promise.reject("This link is too long");
										} else if (!(
											/(?:https?:)?\/\/(?:www\.)?(?:facebook|fb)\.com\/((?![A-z]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)[A-z0-9_\-.]+)\/?/
										).test(value)) {
											return Promise.reject("Unvalid facebook link");
										} else {
											return Promise.resolve();
										}
									},
								}),
							]}
						>
							<Input
								prefix={<Facebook color="#458ff6" />}
								placeholder={intl.formatMessage({id: "facebook"})}
							/>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							name="instagram"
							label="Instagram:"
							rules={[
								() => ({
									validator(_, value) {
										if (!value) return Promise.resolve();
										if (value.length > 3000) {
											return Promise.reject("This link is too long");
										} else if (!(
											/http(?:s)?:\/\/(?:www\.)?instagram\.com\/([a-zA-Z0-9_]+)/
										).test(value)) {
											return Promise.reject("Unvalid instagram link");
										} else {
											return Promise.resolve();
										}
									},
								}),
							]}
						>
							<Input
								prefix={<Instagram color="#458ff6" />}
								placeholder={intl.formatMessage({id: "instagram"})}
							/>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							name="youtube"
							label="Youtube:"
							rules={[
								() => ({
									validator(_, value) {
										if (!value) return Promise.resolve();
										if (value.length > 3000) {
											return Promise.reject("This link is too long");
										} else if (!(
											/http(?:s)?:\/\/(?:[A-z]+\.)?youtube.com\/(channel|c|user)\/([A-z0-9-_]+)\/?/
										).test(value)) {
											return Promise.reject("Unvalid youtube link");
										} else {
											return Promise.resolve();
										}
									},
								}),
							]}
						>
							<Input
								prefix={<Youtube color="#458ff6" />}
								placeholder={intl.formatMessage({id: "Youtube"})}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row md={2} sm={1}>
					<Col>
						<Form.Item
							label={<FormattedMessage id="Experiences" />}
							name="experiences"
						>
							<div className="experiences">
								{experiences.length > 0 ? (
									<Container>
										<Row className="experiences-header">
											<Col>Post title</Col>
											<Col>Organisation</Col>
											<Col md={1}></Col>
										</Row>
										<Divider style={{ margin: "5px 0" }} />
										{experiences.map((e, k) => (
											<Row className="experience" key={k}>
												<Col className="postTitle">{e.postTitle}</Col>
												<Col className="organisation">{e.organization}</Col>
												<Col md={1}>
													<X
														color="red"
														className="close"
														onClick={() => {
															setExperiences((exps) =>
																exps.filter((item, key) => key !== k)
															);
															setChanged(true);
														}}
													/>
												</Col>
											</Row>
										))}
									</Container>
								) : (
									<Empty
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description={<FormattedMessage id="noExperience" />}
									/>
								)}
							</div>
							<Button
								type="primary"
								block
								onClick={() => setIsVisible1(true)}
								disabled={experiences.length > 20}
							>
								<Plus />
								<FormattedMessage id="addexperiences" />
							</Button>
							<Modal
								cancelText={<FormattedMessage id="cancel" />}
								okText={<FormattedMessage id="ok" />}
								title="Add experience"
								visible={isVisible1}
								onCancel={() => setIsVisible1(false)}
								okButtonProps={{ style: { display: "none" } }}
								cancelButtonProps={{ style: { display: "none" } }}
							>
								<Form
									form={expForm}
									layout="vertical"
									size="large"
									onFinish={({ postTitle, organisation, duration }) => {
										setExperiences((exps) => [
											...exps,
											{
												postTitle,
												organization: organisation,
												orgImage: "org_avatar.jpg",
												startDate: new Date(duration[0]),
												endDate: untilNow1
													? "until_now"
													: new Date(duration[1]),
											},
										]);
										setChanged(true);
										setIsVisible1(false);
										expForm.resetFields();
									}}
									preserve={false}
								>
									<Form.Item
										label="Post title"
										name="postTitle"
										rules={[
											{
												required: true,
												message: <FormattedMessage id="EmptyError" />,
											},
											() => ({
												validator(_, value) {
													if (!value || value.length <= 100) {
														return Promise.resolve();
													}
													return Promise.reject(
														"This field should be 100 characters long at max"
													);
												},
											}),
										]}
									>
										<Input placeholder={intl.formatMessage({id: "PostTitle"})}/>
									</Form.Item>
									<Form.Item
										label="Organisation"
										name="organisation"
										rules={[
											{
												required: true,
												message: <FormattedMessage id="EmptyError" />,
											},
											() => ({
												validator(_, value) {
													if (!value || value.length <= 100) {
														return Promise.resolve();
													}
													return Promise.reject(
														"This field should be 100 characters long at max"
													);
												},
											}),
										]}
									>
										<Input placeholder={intl.formatMessage({id: "organization"})}/>
									</Form.Item>
									<Form.Item
										label="Duration"
										name="duration"
										rules={[
											{
												required: true,
												message: <FormattedMessage id="EmptyError" />,
											},
										]}
									>
										<DatePicker.RangePicker
											format="DD-MM-YYYY"
											disabled={[false, untilNow1]}
											disabledDate={(date) => date > new Date()}
										/>
									</Form.Item>
									<Form.Item>
										<span>Until now? </span>
										<Switch
											size="default"
											name="untilnow"
											onChange={(checked) => setUntilNow1(checked)}
										/>
									</Form.Item>
									<Button htmlType="submit" type="primary" block>
										{" "}
										Add{" "}
									</Button>
								</Form>
							</Modal>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							label={<FormattedMessage id="Educations" />}
							name="educactions"
						>
							<div className="experiences">
								{_.isArray(educations) && educations.length > 0 ? (
									<Container>
										<Row className="experiences-header">
											<Col>Field of Study</Col>
											<Col>School</Col>
											<Col md={1}></Col>
										</Row>
										<Divider style={{ margin: "5px 0" }} />
										{educations.map((e, k) => (
											<Row className="experience" key={k}>
												<Col className="postTitle">{e.fieldStudy}</Col>
												<Col className="organisation">{e.school}</Col>
												<Col md={1}>
													<X
														color="red"
														className="close"
														onClick={() => {
															setEducations((edus) =>
																edus.filter((item, key) => key !== k)
															);
															setChanged(true);
														}}
													/>
												</Col>
											</Row>
										))}
									</Container>
								) : (
									<Empty
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description={<FormattedMessage id="noEducation" />}
									/>
								)}
							</div>
							<Button
								type="primary"
								block
								onClick={() => setIsVisible2(true)}
								disabled={educations.length > 20}
							>
								<Plus />
								<FormattedMessage id="addEducations" />
							</Button>
							<Modal
								cancelText={<FormattedMessage id="cancel" />}
								okText={<FormattedMessage id="ok" />}
								title="Add education"
								visible={isVisible2}
								onCancel={() => setIsVisible2(false)}
								okButtonProps={{ style: { display: "none" } }}
								cancelButtonProps={{ style: { display: "none" } }}
							>
								<Form
									form={eduForm}
									layout="vertical"
									size="large"
									onFinish={({ fieldStudy, school, duration }) => {
										setEducations((edus) => [
											...edus,
											{
												fieldStudy,
												school,
												schoolImage: "school_avatar.jpg",
												startDate: new Date(duration[0]),
												endDate: untilNow2
													? "until_now"
													: new Date(duration[1]),
											},
										]);
										setChanged(true);
										setIsVisible2(false);
										eduForm.resetFields();
									}}
									preserve={false}
								>
									<Form.Item
										label="Field of study"
										name="fieldStudy"
										rules={[
											{
												required: true,
												message: <FormattedMessage id="EmptyError" />,
											},
											() => ({
												validator(_, value) {
													if (!value || value.length <= 100) {
														return Promise.resolve();
													}
													return Promise.reject(
														"This field should be 100 characters long at max"
													);
												},
											}),
										]}
									>
										<Input placeholder={intl.formatMessage({id: "fieldStudy"})}/>
									</Form.Item>
									<Form.Item
										label="School"
										name="school"
										rules={[
											{
												required: true,
												message: <FormattedMessage id="EmptyError" />,
											},
											() => ({
												validator(_, value) {
													if (!value || value.length <= 100) {
														return Promise.resolve();
													}
													return Promise.reject(
														"This field should be 100 characters long at max"
													);
												},
											}),
										]}
									>
										<Input placeholder={intl.formatMessage({id: "school"})} />
									</Form.Item>
									<Form.Item
										label="Duration"
										name="duration"
										rules={[
											{
												required: true,
												message: <FormattedMessage id="EmptyError" />,
											},
										]}
									>
										<DatePicker.RangePicker
											format="DD-MM-YYYY"
											disabled={[false, untilNow2]}
											disabledDate={(date) => date > new Date()}
										/>
									</Form.Item>
									<Form.Item>
										<span>Until now? </span>
										<Switch
											size="default"
											name="untilnow"
											onChange={(checked) => setUntilNow2(checked)}
										/>
									</Form.Item>
									<Button htmlType="submit" type="primary" block>
										{" "}
										Add{" "}
									</Button>
								</Form>
							</Modal>
						</Form.Item>
					</Col>
				</Row>

				<Row md={1} sm={1}>
					<Col>
						<Form.Item name="questions">
							<Form
								name="question"
								form={question}
								size="large"
								layout="vertical"
								onValuesChange={() => {
									setamra(true);
								}}
							>
								{questionsdata[user.account_type]
									? questionsdata[user.account_type].map((i, k) => (
										<Form.Item label={i} key={k} name={"qte" + k}>
											<Input.TextArea rows={6} />
										</Form.Item>
									))
									: ""}
							</Form>
						</Form.Item>
					</Col>
				</Row>
				<Button
					htmlType="submit"
					type="primary"
					disabled={!changed}
					loading={loading}
				>
					<FormattedMessage id="update" />
				</Button>
			</Container>
		</Form>,
		<Form
			{...layout}
			name="changePassword"
			form={changePasswordForm}
			onFinish={onFinish2}
			initialValues={{
				currPassword: "",
				newPassword: "",
				newPasswordConfirm: "",
			}}
		>
			<Form.Item
				name="currPassword"
				label={<FormattedMessage id="currPassword" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Input.Password />
			</Form.Item>
			<Form.Item
				name="newPassword"
				label={<FormattedMessage id="newPassword" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
					() => ({
						validator(_, value) {
							if (!value) return Promise.resolve();
							else if ((
								/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,100}$/
							).test(value)) return Promise.resolve();
							else
								return Promise.reject(
									<FormattedMessage id="passwordNotValid" />
								);
						},
					}),
				]}
			>
				<Input.Password />
			</Form.Item>
			<Form.Item
				name="newPasswordConfirm"
				label={<FormattedMessage id="newPasswordConfirm" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							const newPassword = getFieldValue("newPassword");
							if (!value) return Promise.resolve();
							else if (value === newPassword) return Promise.resolve();
							else
								return Promise.reject(
									<FormattedMessage id="passwordsNotIdentical" />
								);
						},
					}),
				]}
			>
				<Input.Password />
			</Form.Item>
			<Button htmlType="submit" type="primary" loading={loading}>
				<FormattedMessage id="update" />
			</Button>
		</Form>,
	];

	return (
		<div className="edit-profile-page">
			<Container>
				<Heading text={<FormattedMessage id="editprofile" />} />
				<Row>
					<Col md={3} style={{ marginBottom: 20 }}>
						<div className="edit-keys">
							<div
								className="edit-key"
								onClick={() => setKey(0)}
								style={{
									transition: "border .2s ease",
									borderLeft: key === 0 ? "4px solid #458ff6" : "none",
								}}
							>
								<Settings color={key === 0 ? "#458ff6" : "black"} />
								<span style={{ color: key === 0 ? "#458ff6" : "black" }}>
									<FormattedMessage id="general" />
								</span>
							</div>
							{/* <div
								className="edit-key"
								onClick={() => setKey(1)}
								style={{
									transition: "border .2s ease",
									borderLeft: key === 1 ? "4px solid #458ff6" : "none",
								}}
							>
								<Key color={key === 1 ? "#458ff6" : "black"} />
								<span style={{ color: key === 1 ? "#458ff6" : "black" }}>
									<FormattedMessage id="changePassword" />
								</span>
							</div> */}
						</div>
					</Col>
					<Col>
						<div className="edit-card">
							<Container>
								<div className="edit-error">
									{formError !== "" ? (
										<Alert
											message={<FormattedMessage id={formError} />}
											type="error"
											showIcon
										/>
									) : (
										""
									)}
								</div>
								{forms[key]}
							</Container>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	isAuth: state.auth.login.isAuth,
	user: state.auth.login.user,
});

const mapDispatchToProps = {
	editProfile,
	changePassword,
	getUser,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Edit));
