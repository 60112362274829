import Sessions from "../../../services/Sessions";

/*
 ** Session Section
 */

/*
 **  Create session (new slot)
 **  Ps: Create a one hour session slot
 */
export const createSession = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			const newData = {
				begin: data,
			};
			Sessions.createSession(newData)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Delete Session (delete slot)
 */
export const deleteSession = (sessionId) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.deleteSession(sessionId)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Show all session slots
 ** You can use Params to get specific slots
 */
export const getSlots = (Params) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.getSlots(Params)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.result.Sessions.length <= 0
						)
							resolve("Not Found");
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Show all session slots
 ** You can use Params to get specific slots
 */
export const getSession = (Params) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.getSession(Params)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.result.Sessions.length <= 0
						)
							resolve("Not Found");
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Show Current user session slots
 ** You can use Params to get specific slots
 */
export const getMySession = (Params) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.getMySession(Params)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.result.Sessions.length <= 0
						)
							resolve("Not Found");
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Show session slots associated with idea as an idea member
 ** You can use Params to get specific slots
 */
export const getSessionAsMember = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.getSessionAsMember(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.result.Sessions.length <= 0
						) resolve(res)
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Check finished Session
 */
export const CheckFinishedSession = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.CheckFinishedSession(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "success" && res.result.Sessions.length <= 0) console.log()
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Check finished Session
 */
export const CheckFinishedSessionAsOwner = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.CheckFinishedSessionAsOwner(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.result.Sessions.length <= 0
						) resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Show session slots associated with idea as an idea owner
 ** You can use Params to get specific slots
 */
export const getSessionAsOwner = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.getSessionAsOwner(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (
						err.response &&
						err.response.data &&
						err.response.status === 401
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Count all user session slots by date interval, status defaults to Available.
 ** You can use Params to get specific slots
 */
export const CountAllSession = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.CountAllSession(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Change session slot begin time
 */
export const updateSlotTime = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.updateSlotTime(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Reserve session slot as an idea holder.
 */
export const ReserveSlot = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.ReserveSlot(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Confirm a session slot by its owner. [mentor, expert, coach, instructor]
 */
export const ConfirmSlot = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.ConfirmSlot(id)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (
						err.response &&
						err.response.data &&
						err.response.status === 401
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Cancel a session slot by its owner. [mentor, expert, coach, instructor]
 */
export const CancelSlotOwner = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.CancelSlotOwner(id)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Cancel a session slot by its associated idea member. [idea_holder]
 */
export const CancelSlotMember = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.CancelSlotMember(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Finish a session.
 */
export const FinishSlot = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.FinishSlot(id)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Feedback a User in a finished Session, must provide all feedback details
 */
export const CreateSessionFeedback = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.CreateSessionFeedback(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Get all month Slots
 */
export const GetAllMonthSlots = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Sessions.GetAllMonthSlots(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
