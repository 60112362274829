import { useEffect } from 'react';
import '../css/terms.css'

const Terms = () => {

    return (
        <div id="termeOfService" class="flex flex-col flex-1 items-center justify-center p-16">
            <div class="max-w-812 text-center">
                <div class="c15 c17">
                    <p class="c13"><span class="c6">Conditions Générales d’Utilisation de  Leafunder</span></p>
                    <p class="c5 c13"><span class="c6"></span></p>
                    <p class="c8"><span class="c10 c7">&nbsp;Définitions et champ d’application:</span></p>
                    <p class="c8 c5"><span class="c1 c0"></span></p>
                    <p class="c8"><span class="c7 c0"> Leafunder</span><span class="c0">&nbsp;</span><span class="c16 c0 c15">est
                    une plateforme d'incubation en ligne qui permet aux porteurs d'idées de suivre un processus d'incubation
                    à distance avec des mentors et des experts. Dans le même temps, toute organisation peut gérer et
                    suivre les progrès de ses porteurs d'idées. Avec Leafunder, nous facilitons l'ensemble du processus,
                    en les aidant à se connecter directement aux bonnes ressources pour faire naître quelque chose
                    d'extraordinaire. Contrairement aux incubateurs classiques, avec Leafunder, les porteurs d'idées
                       peuvent développer leurs idées où qu'ils soient et à tout moment. </span></p><br />
                    <p class="c8"><span class="c0">Tout usage de l’application ou du service de</span><span
                        class="c7 c0">&nbsp; Leafunder</span><span class="c1 c0">&nbsp;est soumis à l’acceptation des
                    présentes Conditions Générales d’Utilisation.</span></p>
                    <p class="c8"><span class="c1 c0">En raison d’une simplification de synthèse:</span></p>
                    <p class="c8"><span class="c0">Les Utilisateurs de l’application (tous genres) porteront le
                    titre</span><span class="c7 c0"> l’Utilisateur</span><span class="c0">/</span><span
                            class="c7 c0">il</span><span class="c1 c0">&nbsp;.</span></p>
                    <p class="c8"><span class="c0">Les Clients de l’application (entreprises, universites, ...) porteront le
                    titre </span><span class="c7 c0">le client</span><span class="c1 c0">&nbsp;.</span></p>
                    <p class="c8 c5"><span class="c1 c0"></span></p>
                    <p class="c8"><span class="c7 c0 c21">&nbsp;</span><span class="c10 c7">Article 1. Condition d’accès au
                    service et inscription:</span></p>
                    <p class="c8 c5"><span class="c21 c7 c0 c23"></span></p>
                    <p class="c8 c12"><span class="c2 c0">a) Acceptation des Conditions Générales d’Utilisation (CGU)</span></p>
                    <p class="c8 c12 c5"><span class="c2 c0"></span></p>
                    <p class="c8"><span class="c0">L’utilisation de l’application ou du service de </span><span
                        class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;est subordonnée à l’acceptation des
                    présentes CGU.</span></p>
                    <p class="c8"><span class="c1 c0">Lors de la création de son profil, le futur Utilisateur, doit cliquer sur
                    la case: J’accepte les CGU. L’acceptation des CGU est entière et forme un tout indivisible.</span>
                    </p>
                    <p class="c8"><span class="c1 c0">L’Utilisateur ne peut choisir de voir appliquer une partie des CGU
                    seulement ni de formuler des réserves.</span></p>
                    <p class="c8"><span class="c1 c0">En acceptant les CGU, l’Utilisateur accepte notamment la Charte de bonne
                    conduite des Utilisateurs ainsi que l’article concernant le ‘ traitement des données personnelles
                    des Utilisateurs ‘. </span></p>
                    <p class="c8 c5"><span class="c1 c0"></span></p>
                    <p class="c8 c12"><span class="c2 c0">b) Modification des Conditions Générales d’Utilisation</span></p>
                    <p class="c8 c12 c5"><span class="c2 c0"></span></p>
                    <p class="c8"><span class="c7 c0"> Leafunder</span><span class="c0">&nbsp;</span><span class="c1 c0">se
                    réserve le droit de modifier, à tout moment et de manière unilatérale, les CGU, les fonctionnalités
                    présentes sur l’application ou les règles de fonctionnement du service.</span></p>
                    <p class="c8"><span class="c1 c0">Les modifications prendront effet immédiatement dès la mise en ligne des
                    CGU, l’Utilisateur sera notifié par un email qui contiendra le nouveau CGU.</span></p>
                    <p class="c8"><span class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;se réserve notamment le droit de
                    proposer ultérieurement de nouveaux services et d’intégrer la présence de partenaires.</span></p>
                    <p class="c8 c5"><span class="c1 c0"></span></p>
                    <p class="c8 c12"><span class="c2 c0">d) Connexion au service</span></p>
                    <p class="c8 c12 c5"><span class="c2 c0"></span></p>
                    <p class="c8"><span class="c1 c0">Les besoins nécessaires à l’accès au Service ainsi que tous les frais
                    afférents, y compris les frais éventuels de communication sont à la charge exclusive de
                    l’Utilisateur.</span></p>
                    <p class="c8 c5"><span class="c1 c0"></span></p>
                    <p class="c8 c12"><span class="c2 c0">e) Inscription</span></p>
                    <p class="c8 c12 c5"><span class="c2 c0"></span></p>
                    <p class="c8"><span class="c1 c0">Pour s’inscrire, le futur Utilisateur doit avoir lu et accepté les
                    présentes conditions générales d’utilisation et remplir les conditions suivantes :</span></p>
                    <p class="c3"><span class="c1 c0">• Être légalement majeur.</span></p>
                    <p class="c3"><span class="c1 c0">• S’assurer de la compatibilité du matériel utilisé dans le cadre de
                    l’accès au service.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas avoir déjà fait l’objet de sanctions ayant mené à la résiliation
                    de son profil.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas fournir des informations erronés.</span></p>
                    <p class="c3"><span class="c1 c0">• tous les obligations et les responsabilités mentionner dans l’Article
                    3.</span></p>
                    <p class="c8 c5"><span class="c1 c0"></span></p>
                    <p class="c8"><span class="c0">L’Utilisateur s’engage à ne pas créer ni utiliser un autre profil que celui
                    créé initialement, que ce soit sous sa propre identité ou celle de tiers sans avoir demandé et
                    obtenu l’autorisation de </span><span class="c7 c0"> Leafunder</span><span class="c1 c0">.</span></p>
                    <p class="c8"><span class="c0">Dans le cas contraire, </span><span class="c7 c0"> Leafunder</span><span
                        class="c1 c0">&nbsp;pourra suspendre immédiatement le compte de l’Utilisateur.</span></p>
                    <p class="c8 c5"><span class="c1 c0"></span></p>
                    <p class="c8"><span class="c10 c7">&nbsp;Article 2. Zone géographique couverte:</span></p>
                    <p class="c8 c5"><span class="c10 c7"></span></p>
                    <p class="c8"><span class="c0">L’application et le service de </span><span
                        class="c7 c0"> Leafunder</span><span class="c0 c1">&nbsp;s’adresse à l’ensemble des Utilisateurs
                        partout dans le monde, quelle que fonctionnalités peuvent être restreint pour des zones géographique
                    ou accessible sous des conditions précisées par l'entité qui organize l'événement.</span></p>
                    <p class="c8 c5"><span class="c1 c0"></span></p>
                    <p class="c8"><span class="c10 c7">&nbsp;Article 3. Obligations des Utilisateurs et responsabilités des
                    parties:</span></p>
                    <p class="c8 c5"><span class="c10 c7"></span></p>
                    <p class="c8 c12"><span class="c2 c0">a. Obligations s’appliquant aux Utilisateurs:</span></p>
                    <p class="c8 c12 c5"><span class="c2 c0"></span></p>
                    <p class="c8"><span class="c0">L’Utilisateur ne pourra pas utiliser l’application ou le service
                    de</span><span class="c7 c0">  Leafunder</span><span class="c1 c0">&nbsp;hors son contexte.</span>
                    </p>
                    <p class="c3"><span class="c1 c0">Durant l’utilisation des Services, l’Utilisateur s’engage a :</span></p>
                    <p class="c3"><span class="c1 c0">• Etre honnête et sincère dans ses informations et ses
                    déclarations.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas publier sur l’Application des contenus portant atteinte à la
                    pudeur et à la dignité humaine.</span></p>
                    <p class="c3"><span class="c1 c0">• Respecter le caractère privé des contenus et des conversations échangées
                    avec d’autres Utilisateurs au sein de l’Application et s’en interdire la diffusion ou la
                    divulgation.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas enfreindre les lois sur la propriété intellectuelle.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas enfreindre l‘ordre public.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas commettre de délit de diffamation et plus largement à ne pas
                    tenir de propos injurieux en particulier à caractère racial ou discriminatoire.</span></p>
                    <p class="c3"><span class="c1 c0">• Respecter les lois et règlements en vigueur.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas proférer d’incitation à la violence ou faire l’apologie de crimes
                    de guerre ou de crimes contre l’humanité.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas enfreindre les règles de bienséance et à demeurer correct
                    vis-à-vis des autres Utilisateurs.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne porter atteinte, d’aucune manière que ce soit, au déroulement normal
                    des Services ainsi qu’aux infrastructures de l’Application (tous les parts du service ).</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas démarcher d‘autres Utilisateurs dans le but de promouvoir des
                    services payants.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas divulguer à d’autres Utilisateurs ses informations de connexion,
                    en particulier son mot de passe d’accès à son profil, le compte de l’Utilisateur est totalement
                    personnel.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas faire de publicité ou d’incitation de quel qu’ordre que ce soit à
                    utiliser tout autre service, concurrent ou pas et à ne pas commettre de délit relevant de la
                    concurrence déloyale.</span></p>
                    <p class="c3"><span class="c1 c0">• Ne pas utiliser l’Application dans le but d’organiser des
                    manifestations.</span></p>
                    <p class="c3"><span class="c0">• L’Utilisateur s’engage à ne pas introduire de virus informatique ou tout
                    autre programme susceptible de détériorer, d’interrompre ou de porter atteinte à l’application
                </span><span class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;ou à tout ou partie de ses
                    fonctionnalités.</span></p>
                    <p class="c3"><span class="c0">• En s’inscrivant sur l’application </span><span
                        class="c7 c0"> Leafunder</span><span class="c1 c0">, l’Utilisateur s’engage à n’indiquer ou à ne
                        diffuser sous quelque forme que ce soit que des informations ou contenus conformes à la réalité et
                    le concernant personnellement.</span></p>
                    <p class="c3"><span class="c1 c0">• Mettre à jour, dans les plus brefs délais, en cas de changement de
                    situation en mettant à jour son profil accessible à partir de l’écran « Mon profil ».</span></p>
                    <p class="c3"><span class="c0">• En s’inscrivant et en publiant des informations, l’Utilisateur donne
                    l’autorisation à l’application </span><span class="c7 c0"> Leafunder</span><span
                            class="c1 c0">&nbsp;d’intégrer ces éléments aux différentes pages et rubriques de celle-ci et de les
                    communiquer aux autres utilisateurs ou clients.</span></p>
                    <p class="c3"><span class="c0">• Permettre à </span><span class="c7 c0"> Leafunder</span><span
                        class="c1 c0">&nbsp;d’utiliser mes données dans les applications tierces (Google Analytics ou
                    d’autre applications)</span></p>
                    <p class="c3"><span class="c0">• </span><span class="c0 c19">Permettre à </span><span
                        class="c19 c7 c0"> Leafunder </span><span class="c19 c0">de délivrer mes données personnelles,
                        informations postées et saisies lors de hackathons, discussions, challenges ... aux entreprises
                    (clients) et autres personnes autorisé par </span><span class="c19 c7 c0"> Leafunder</span><span
                            class="c14 c0">.</span></p>
                    <p class="c3"><span class="c0">• </span><span class="c19 c0">Permettre à </span><span
                        class="c19 c7 c0"> Leafunder </span><span class="c0 c14">de diffuser en publique les photos, videos …
                    des participants dans ses publicités.</span></p>
                    <p class="c3"><span class="c1 c0">• Savoir que mes idées et les documents seront protégés uniquement contre
                    les autres Utilisateurs et non pas traiter comme des propriétés intellectuelle.</span></p>
                    <p class="c3"><span class="c1 c0">• La soumission de ma participation (Cliquer sur le boutton Participer)
                    est un accord pour transférer mes données et mes documents aux clients du  Leafunder.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c12"><span class="c2 c0">b. Responsabilité des Parties</span></p>
                    <p class="c3 c12 c5"><span class="c2 c0"></span></p>
                    <p class="c3"><span class="c1 c0">Les Utilisateurs du service agissent sous leur seule et entière
                    responsabilité.</span></p>
                    <p class="c3"><span class="c0">A ce titre, le déroulement effectif, les propositions et les engagement
                    (Prix, …) sera une responsabilité totale du client qui a lancé l'événement dans la plateforme
                    (challenge, ....), sur quel que fondement que ce soit, le service proposé par </span><span
                            class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;étant un service d'intermédiation.</span>
                    </p>
                    <p class="c3"><span class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;ne pourra être tenue responsable
                    d’actions illégales ou passibles de sanctions pénales, faites par ses Utilisateurs et ses clients,
                    parmi lesquelles:</span></p>
                    <p class="c3"><span class="c0">Tout type de comportement illicite, en particulier le harcèlement. Dans le
                    cas d’une alerte du harcèlement,</span><span class="c7 c0">&nbsp; Leafunder</span><span
                            class="c0">&nbsp;est uniquement responsable de arrêter et fermer le compte et </span><span
                                class="c0">Donner la preuve de l'événement aux autorités.</span></p>
                    <p class="c3"><span class="c7 c0"> Leafunder </span><span class="c1 c0">ne peut pas être responsable des
                    actes de ses clients comme le mal utilisations des données donner aux clients (ex: diffusion des
                    données de l’Utilistateur ...).</span></p>
                    <p class="c3"><span class="c1 c0">Les donnees collecter ne seront pas diffuser en publique pour les autres
                    utilisateur.</span></p>
                    <p class="c3"><span class="c7 c0"> Leafunder</span><span class="c0">&nbsp;est seule responsable de la
                    vérification de l’identité de ses ses clients, le déroulement de ces rencontres réelles est organisé
                    indépendamment de </span><span class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;sous la seule
                        responsabilité des Utilisateurs. Dans ses relations avec les autres Utilisateurs, l’Utilisateur
                    s’engage également à respecter les obligations déjà définies (Article 3).</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c10 c7">&nbsp;Article 4. Droit a l’image</span></p>
                    <p class="c3 c5"><span class="c10 c7"></span></p>
                    <p class="c3"><span class="c0">Les Utilisateurs et les clients autorisent, à titre gratuit, </span><span
                        class="c7 c0"> Leafunder</span><span class="c1 c0">, ses partenaires ainsi que ses</span></p>
                    <p class="c3"><span class="c0">Sous-traitants et ses clients à fixer et prendre, reproduire, diffuser et
                    communiquer au public photographies et les vidéos des événements</span><span
                            class="c0">&nbsp;organisés</span><span class="c1 c0">&nbsp;sur lesquels apparaîtra l’Utilisateurs et
                    les représentants de ses clients.</span></p>
                    <p class="c3"><span class="c1 c0">Les Photographies et les Vidéos pourront être exploitées et utilisées
                    directement sous toute forme et tous supports connus et inconnus à ce jour, dans le monde entier,
                    sans limitation de durée, intégralement ou par extraits.</span></p>
                    <p class="c3"><span class="c0">Les Photographies et les Vidéos seront exploitées à des fins marketing afin
                    de promouvoir la plateforme et les services de </span><span class="c7 c0"> Leafunder</span><span
                            class="c1 c0">.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c10 c7">&nbsp;Article 5. Accès, fonctionnement de l’application et
                    responsabilité</span></p>
                    <p class="c3 c5"><span class="c10 c7"></span></p>
                    <p class="c3 c12"><span class="c2 c0">a. Acces</span></p>
                    <p class="c3 c12 c5"><span class="c2 c0"></span></p>
                    <p class="c3"><span class="c0">L’Application repose sur des technologies, des framework et des librairies
                    développées par des tiers. </span><span class="c7 c0"> Leafunder</span><span
                            class="c1 c0">&nbsp;s’engage à mettre en œuvre tous les moyens nécessaires pour assurer un accès
                    continu à l’application et du service 24H/24, et 7j/7 pour les Utilisateurs et les clients.</span>
                    </p>
                    <p class="c3"><span class="c0">Cependant, il peut arriver que l'accès au service soit interrompu dans le
                    cadre d'opérations de maintenance, de réparations d’urgence, ou par suite de circonstances
                    indépendantes de la volonté de </span><span class="c7 c0"> Leafunder</span><span
                            class="c1 c0">.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c11 c0">b. Fonctionnement de l’application</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c0">Les services </span><span class="c7 c0"> Leafunder</span><span
                        class="c0">&nbsp;sont spécifiés dans son statuts, les services développer ont des objectifs
                    spécifiques par les fondateurs de </span><span class="c7 c0"> Leafunder</span><span class="c0">,
                    toutes autre utilisation est interdit</span><span class="c1 c0">.</span></p>
                    <p class="c3"><span class="c0">Il est formellement interdit de planifier ou organiser une manifestation sur
                    la voie publique au moyen de la plateforme </span><span class="c7 c0"> Leafunder</span><span
                            class="c1 c0">.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c12"><span class="c2 c0">c. Responsabilité</span></p>
                    <p class="c3 c12 c5"><span class="c2 c0"></span></p>
                    <p class="c3"><span class="c7 c0"> Leafunder</span><span class="c0">&nbsp;</span><span class="c0">propose un
                    service par lequel encourage les Utilisateurs </span><span class="c16">à proposer des idées
                        innovantes à fort impact social, social et environnemental face à des vrais problèmes et
                    challenge</span><span class="c1 c0">, la plateforme cache tous les informations sensibles contre les
                        autres Utilisateurs et non pas contre les clients et leurs représentants, pour tous les réclamations
                        contre les harcèlements et d’autre actes la plateforme dispose d’une zone de contact direct avec le
                    support, et un email pour les réclamations.</span></p>
                    <p class="c3"><span class="c0">Les Utilisateurs assument entièrement les risques du dévoile de leurs idées
                    ou documents </span><span class="c0 c15"><a class="c22"
                            href="https://www.google.com/url?q=https://en.wiktionary.org/wiki/priv%25C3%25A9&amp;sa=D&amp;ust=1603323167523000&amp;usg=AOvVaw3knXspahNiJIQ1p7dcHPwz">privé</a></span><span
                                class="c0">s</span><span class="c1 c0">.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c10 c7">&nbsp;Article 5. Informations et contenu publiées par les Utilisateurs et
                    les Clients</span></p>
                    <p class="c3 c5"><span class="c10 c7"></span></p>
                    <p class="c3"><span class="c0">Les informations dans l’application relèvent de la responsabilité exclusive
                    de l’Utilisateur et du client. Aucune responsabilité de</span><span
                            class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;ne saurait découler de la violation par un
                    Utilisateur des Conditions Générales d’Utilisation (CGU).</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c7 c10">&nbsp;Article 6. Sanctions et résiliation</span></p>
                    <p class="c3 c5"><span class="c10 c7"></span></p>
                    <p class="c3 c12"><span class="c11 c0">a. Système de modération communautaire</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c0">Il peut arriver que des Utilisateurs ne respectent pas les présentes CGU, en
                    particulier concernant les règles de bonne conduite ou de respect de la propriété intellectuelle.
                </span><span class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;ne disposant pas des moyens
                        techniques et humains de contrôle du comportement des Utilisateurs et des clients (par leurs
                    représentants) sur l’Application, se basera sur les déclarations de ses Utilisateurs.</span></p>
                    <p class="c3"><span class="c0">Tout Utilisateur ou Client ayant fait l’objet d’une déclaration indiquant son
                    comportement illégal ou contraire aux dispositions des présentes CGU, pourra être exclu du service
                    de </span><span class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;et pouvant conduire conduire
                    à des poursuites judiciaires.</span></p>
                    <p class="c3"><span class="c1 c0">Toute déclaration infondée sera considérée comme une violation des
                    présentes CGU et est passible de sanctions (parmi lesquelles figure la suspension du Compte) et
                    pouvant conduire à des poursuites judiciaires.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c12"><span class="c2 c0">b. Suspension temporaire de compte</span></p>
                    <p class="c3 c5"><span class="c2 c0"></span></p>
                    <p class="c3"><span class="c0">Tout Utilisateur contrevenant aux présentes CGU peut voir son compte
                    temporairement suspendu et sans préavis, empêchant tout accès au Service. La durée de la suspension
                    est soumise au seul jugement de </span><span class="c7 c0"> Leafunder </span><span class="c1 c0">ou
                    les bénéficiaires du service.</span></p>
                    <p class="c3"><span class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;se réserve le droit de supprimer
                    le compte de l’Utilisateur sans préavis et sans que ce dernier ne puisse prétendre à une quelconque
                    indemnité ou remboursement de ses mises en relation encore inscrites à son compte.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c12"><span class="c0 c11">c. Résiliation du contrat du client</span></p>
                    <p class="c3 c12 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c1 c0">Le contrat entre  Leafunder et le client peut être résilié en cas de non
                    respect des différents articles des CGU par l’Utilisateur et du contrat signes.</span></p>
                    <p class="c3"><span class="c1 c0">Toute faute grave permet à  Leafunder de résilier le contrat de plein
                    droit, automatiquement et sans délai, avec aucun remboursement.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c10 c7">&nbsp;Article 7. Traitement des données personnelles des
                    Utilisateurs</span></p>
                    <p class="c3 c5"><span class="c10 c7"></span></p>
                    <p class="c3"><span class="c2 c0">a. Données collectées</span></p>
                    <p class="c3 c5"><span class="c2 c0"></span></p>
                    <p class="c3"><span class="c0">Soucieux du respect de la vie privée de ses Utilisateurs et de la protection
                    des informations qui lui sont fournies, </span><span class="c7 c0"> Leafunder</span><span
                            class="c1 c0">&nbsp;respecte la législation en vigueur en matière de protection de la vie privée
                            conformément aux dispositions de la loi n 09 08 relatif à la protection des données
                    personnelles.</span></p>
                    <p class="c3"><span class="c1 c0">Les données collectées sont les suivantes:</span></p>
                    <ul class="c20 lst-kix_ndakdmdmfs5c-0 start">
                        <li class="c4"><span class="c1 c0">Nom et Prénom</span></li>
                        <li class="c4"><span class="c1 c0">Sexe</span></li>
                        <li class="c4"><span class="c1 c0">Adresse email</span></li>
                        <li class="c4"><span class="c1 c0">Pays de residence</span></li>
                        <li class="c4"><span class="c1 c0">Date de naissance</span></li>
                        <li class="c4"><span class="c1 c0">Numéro de téléphone</span></li>
                    </ul>
                    <p class="c18"><span class="c1 c0">Autre informations peuvent être requis par les clients *</span></p>
                    <p class="c5 c18"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c1 c0">Qui permettent d’envoyer les notifications et le contact entre les clients
                    qui organisent les challenges, hackathons, débats …</span></p>
                    <p class="c3"><span class="c0">&nbsp;L’Utilisateur est informé que les données à caractère personnel qu’il
                    fournit sont traitées par un processus informatisé et conservées sur un ou plusieurs fichiers
                    informatiques. Ces données ont pour destinataires les administrateurs des différentes plateformes
                    de</span><span class="c0 c7"> Leafunder</span><span class="c1 c0">qui s’engagent à en préserver la
                    confidentialité et la sécurité.</span></p>
                    <p class="c3"><span class="c0">Quand l’Utilisateur soumet sa participation les données seront affichés via
                    un Dashboard et communiquées sous différentes formes au client (l’organisateur de l'événement)
                    &nbsp;et ses tiers de</span><span class="c7 c0"> Leafunder.</span><span class="c1 c0">&nbsp;</span>
                    </p>
                    <p class="c3"><span class="c0">L’Utilisateur reconnaît que l’application</span><span
                        class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;n’est pas responsable du contenu des
                    correspondances qu’il échange avec d’autres Utilisateurs.</span></p>
                    <p class="c3"><span class="c0">L’Utilisateur autorise</span><span class="c7 c0"> Leafunder</span><span
                        class="c1 c0">&nbsp;accéder aux informations enregistrées dans son profil.</span></p>
                    <p class="c3"><span class="c1 c0">L’Application conserve une trace des données suivantes :</span></p>
                    <ul class="c20 lst-kix_5d47rtb06sy-0 start">
                        <li class="c4"><span class="c1 c0">Informations données à l’inscription pour l’accès à
                        l’application.</span></li>
                    </ul>
                    <ul class="c20 lst-kix_pboflc8hokgw-0 start">
                        <li class="c4"><span class="c1 c0">L’historique et les informations liée aux anciennes participation de
                        l’Utilisateur.</span></li>
                    </ul>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c2 c0">b. Données transmises et reçues</span></p>
                    <p class="c3 c5"><span class="c2 c0"></span></p>
                    <p class="c3"><span class="c1 c0">L’Application demande et enregistre plusieurs données de ses Utilisateurs
                    afin de participer dans plusieurs challenges, hackathons … Ces données facilitent la participation
                    et prochainement permettra de donner aux Utilisateur des statistiques de leurs avancement.</span>
                    </p>
                    <p class="c3"><span class="c0">A aucun moment les idées et les documents d’un Utilisateur n’est divulguée
                    aux autres Utilisateurs, seuls</span><span class="c7 c0"> Leafunder</span><span
                            class="c1 c0">&nbsp;et les représentants des clients on le droit d’avoir des tel données.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c2 c0">c. Droit d'accès, de rectification et d'opposition</span></p>
                    <p class="c3 c5"><span class="c2 c0"></span></p>
                    <p class="c3"><span class="c1 c0">Conformément à l’article 34 de la n 09 08 relatif à la protection des
                    données personnelles, chaque Utilisateur dispose d’un droit d’accès, de modification, de
                    rectification et de suppression de ses données personnelles.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c11 c0">d. Sécurité des données personnelles</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                    <p class="c3"><span class="c7 c0"> Leafunder</span><span class="c1 c0">&nbsp;s'engage à prendre toute
                    précaution utile, au regard de la nature des données et des risques présentés par le traitement,
                    sauf en cas de tout imprévu hors la bonne volonté de l’application. Afin de préserver la sécurité
                    des données personnelles concernant les</span></p>
                    <p class="c3"><span class="c1 c0">Utilisateurs et, notamment, empêcher que les données personnelles ne
                    soient déformées ou endommagées.</span></p>
                    <p class="c3 c5"><span class="c2 c0"></span></p>
                    <p class="c3"><span class="c2 c0">e. Conservation des données</span></p>
                    <p class="c3 c5"><span class="c0 c2"></span></p>
                    <p class="c3"><span class="c1 c0">Les données enregistrées par l’Utilisateur ainsi que celles collectées
                    automatiquement sont conservées durant la période du temps nécessaire à la réalisation des finalités
                    visées ci-dessus.</span></p>
                    <p class="c3 c5"><span class="c1 c0"></span></p>
                </div>
            </div>
        </div>
    )
};

export default Terms;