import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../sass/Ideas/TopBar.scss";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";


const TopBar = ({ idea, ideaID }) => {
	const history = useHistory();
	return (
		<Container className="menuBar carta">
			<Row style={{ justifyContent: "center", height: "100%" }}>
				<Col md={3} style={{ margin: "5px 0" }}>
					<div
						className="MenuButton text-uppercase"
						onClick={() => history.push(`/app/incubator/${ideaID}`)}
					>
						<FormattedMessage id="goToIncubator" />
					</div>
				</Col>
				<Col md={3} style={{ margin: "5px 0" }}>
					<div
						className="MenuButton text-uppercase"
						onClick={() =>
							history.push({
								pathname: `/app/ideas/manageteam/${idea ? idea : ""}`,
								state: { idea_id: ideaID },
							})
						}
					>
						<FormattedMessage id="manageTeam" />
					</div>
				</Col>
				<Col md={3} style={{ margin: "5px 0" }}>
					<div
						className="MenuButton text-uppercase"
						onClick={() => history.push(`/app/ideas/edit/${idea}`)}
					>
						<FormattedMessage id="Editidea" />
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default TopBar;
