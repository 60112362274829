import React, { useContext, useState } from "react";
import "../../../sass/AddIdea.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import Heading from "../../../components/Heading";
import { Button, Form, Input, Select, Steps, Spin, notification } from "antd";
import { X, Youtube } from "react-feather";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import Uppy from "@uppy/core";
import { Dashboard, useUppy } from "@uppy/react";
import frLocale from "@uppy/locales/lib/fr_FR";
import enLocale from "@uppy/locales/lib/en_US";
import arLocale from "@uppy/locales/lib/ar_SA";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { IntlContext } from "../../../context/internationalization";
import categories from "../../../json/categories.json";
import { AddIdea } from "../../../redux/actions/Ideas/IdeasActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
const MySwal = withReactContent(Swal);

const { Step } = Steps;

const tagRender = (props) => (
	<div className="langTag">
		{props.value}
		<X color="white" className="close" onClick={props.onClose} />
	</div>
);

const tagRenderFormatted = (props) => (
	<div className="langTag">
		<FormattedMessage id={props.value} />
		<X color="white" className="close" onClick={props.onClose} />
	</div>
);

const targetCustomerList = [
	"potential",
	"loyal",
	"new",
	"discount",
	"former",
	"internal",
	"external",
	"intermediate",
];

const ideaPhaseList = [
	"generation",
	"selection",
	"implementation",
	"sustaining",
	"diffusion",
	"other",
];

const outcomeList = [
	"businessmodel",
	"design",
	"desktopsoftware",
	"engineeredproduct",
	"mobileapp",
	"processmethod",
	"webcontentmultimediaaudiovideo",
	"websiteservice",
	"other",
];

const skillsNeededList = [
	"communicationSkills",
	"leadership",
	"interpersonalSkills",
	"publicSpeaking",
	"conflictManagement",
	"decisionMaking",
	"timeManagement",
	"stressManagement",
	"customerServiceSkills",
	"creativeThinking",
	"networking",
	"flexibility",
	"negotiation",
	"mentoring",
	"activeListener",
	"accounting",
	"engineering",
	"banking",
	"healthcare",
	"projectManagement",
	"marketing",
	"foreignLanguageProficiency",
	"programingSkills",
	"MobileDevelopment",
	"database",
	"businessAnalytics",
	"dataScience",
	"dataMining",
	"networkSecurity",
	"uiUxDesign",
	"programmingLanguages",
];

export const Add = (props) => {
	const history = useHistory();
	const { intl } = props;
	const [current, setCurrent] = useState(0);
	const [borderColor, setBorderColor] = useState("rgb(213, 213, 213)");
	const [editorState, setEditorState] = useState(() =>
		BraftEditor.createEditorState(null)
	);
	const [Section1, setSection1] = useState([]);
	const [Section2, setSection2] = useState([]);
	const [loading, setLoading] = useState(false);
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const [form3] = Form.useForm();
	const context = useContext(IntlContext);
	useEffect(() => {
		document.title = intl.formatMessage({ id: "Addnewidea" })
		if (props.user.account_type !== "idea_holder") history.push("/404");
		// eslint-disable-next-line 
	}, [])
	const uppy = useUppy(() => {
		return new Uppy({
			restrictions: {
				maxNumberOfFiles: 5,
				minNumberOfFiles: 1,
				maxFileSize: 8388608,
				allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".gif"],
			},
			debug: false,
			locale:
				context.state.locale === "en"
					? enLocale
					: context.state.locale === "fr"
						? frLocale
						: arLocale,
		});
	});
	const layout = {
		layout: "vertical",
		size: "large",
	};

	const onFinish1 = ({ path, title, shortDescription, longDescription }) => {
		const data = {
			path,
			title,
			shortDescription,
			longDescription: longDescription ? longDescription.toHTML() : "",
		};

		setSection1(data);
		setCurrent((current) => current + 1);
	};

	const onFinish2 = (values) => {
		setSection2(values);
		setCurrent((current) => current + 1);
	};

	const onFinish3 = (values) => {
		const files = uppy.getFiles();
		const data = {
			Section1,
			Section2,
			values,
			files,
		};
		if (files) setLoading(true);
		props.AddIdea(data).then((res) => {
			setLoading(false);
			if (res.data && res.data.status === "success") {
				return MySwal.fire({
					title: "Idea Added",
					text: intl.formatMessage({ id: `${res.data.msg}` }),
					icon: "success",
					showClass: {
						popup: "swal2-noanimation",
						backdrop: "swal2-noanimation",
					},
					confirmButtonText: "Go to my Ideas",
					customClass: {
						confirmButton: "btn btn-primary",

						confirmButtonColor: "#458ff6",
					},
					buttonsStyling: false,
				}).then((result) => {
					if (result.isConfirmed) {
						history.push("/app/ideas/me");
					}
				});
			} else if (res.data && res.data.status === "failure") {
				setLoading(false);

				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: res.data.msg }),
				});
			} else {
				setLoading(false);
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: res }),
				});
			}
		});
	};

	const pathTitle = () => {
		return (<><FormattedMessage id="sel_process" />(<a href="/app/process" target="_blank" ><FormattedMessage id="ProcessInfo" /></a>)</>)
	}
	const forms = [
		<Form {...layout} form={form1} onFinish={onFinish1}>
			<Form.Item
				name="path"
				value={form1.path}
				label={pathTitle()}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Select placeholder={intl.formatMessage({ id: "selectProcess" })}>
					{props.Path && props.Path.length > 0
						? props.Path.map((p, i) => {
							return (
								<Select.Option value={p.process_id} key={i}>
									{localStorage.getItem("locale") === "fr"
										? p.name_fr
										: localStorage.getItem("locale") === "ar"
											? p.name_ar
											: p.name_en}
								</Select.Option>
							);
						})
						: ""}
				</Select>

			</Form.Item>
			<Form.Item
				name="title"
				value={form1.title}
				label={<FormattedMessage id="IdeaTitle" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Input placeholder={intl.formatMessage({ id: "error_58" })} />
			</Form.Item>
			<Form.Item
				name="shortDescription"
				value={form1.shortDescription}
				label={<FormattedMessage id="IdeaShortDisc" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Input placeholder={intl.formatMessage({ id: "error_59" })} />
			</Form.Item>
			<Form.Item
				name="longDescription"
				value={form1.longDescription}
				label={<FormattedMessage id="IdeaLongDisc" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
					() => ({
						validator(_, value) {
							const html = value ? value.toHTML() : "";
							if (html !== "" && html !== "<p></p>") {
								setBorderColor("rgb(213, 213, 213)");
								return Promise.resolve();
							}
							setBorderColor("red");
							return Promise.reject("");
						},
					}),
				]}
			>
				<BraftEditor
					placeholder={intl.formatMessage({ id: "error_60" })}
					value={editorState}
					language="en"
					controlBarClassName="editor-control-bar"
					className="editor"
					style={{ borderColor: borderColor }}
					excludeControls={["emoji", "media", "list-ul", "list-ol"]}
					onChange={(state) => setEditorState(state)}
				/>
			</Form.Item>
			<div className="first-navigation-buttons">
				<Button type="primary" htmlType="submit">
					<FormattedMessage id="next" />
				</Button>
			</div>
		</Form>,
		<Form {...layout} form={form2} onFinish={onFinish2}>
			<Form.Item
				name="category"
				value={form2.category}
				label={<FormattedMessage id="IdeaCategory" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Select
					placeholder={intl.formatMessage({ id: "error_63" })}
				>

					{categories.map((category, key) => (
						<Select.Option key={key} value={category.value}>
							{context.state.locale === "en"
								? category.label
								: context.state.locale === "fr"
									? category.label_fr
									: category.label_ar}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				name="tags"
				value={form2.tags}
				label={<FormattedMessage id="IdeaTags" />}
			// rules={[
			//   {
			//     required: true,
			//     message: <FormattedMessage id="EmptyError" />,
			//   },
			// ]}
			>
				<Select mode="tags" tagRender={tagRender} placeholder={intl.formatMessage({ id: "createTags" })} ></Select>
			</Form.Item>
			<Form.Item
				name="targetCustomer"
				value={form2.targetCustomer}
				label={<FormattedMessage id="IdeaTarget" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Select mode="tags" tagRender={tagRenderFormatted} placeholder={intl.formatMessage({ id: "error_66" })}>
					{targetCustomerList.map((item, key) => (
						<Select.Option key={key} value={item}>
							<FormattedMessage id={item} />
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				name="ideaPhase"
				value={form2.ideaPhase}
				label={<FormattedMessage id="Ideaphase" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Select placeholder={intl.formatMessage({ id: "choosePhase" })}>
					{ideaPhaseList.map((item, key) => (
						<Select.Option key={key} value={item}>
							<FormattedMessage id={item} />
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				name="outcome"
				value={form2.outcome}
				label={<FormattedMessage id="IdeaProgram" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Select placeholder={intl.formatMessage({ id: "selectOutcome" })}>
					{outcomeList.map((item, key) => (
						<Select.Option key={key} value={item}>
							<FormattedMessage id={item} />
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				name="skillsNeeded"
				value={form2.skillsNeeded}
				label={<FormattedMessage id="IdeaNeededskills" />}
			// rules={[
			//   {
			//     required: true,
			//     message: <FormattedMessage id="EmptyError" />,
			//   },
			// ]}
			>
				<Select placeholder={intl.formatMessage({ id: "choosePhase" })} mode="tags" tagRender={tagRenderFormatted}>
					{skillsNeededList.map((item, key) => (
						<Select.Option key={key} value={item}>
							<FormattedMessage id={item} />
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				name="solutions"
				value={form2.solutions}
				label={<FormattedMessage id="Ideasolution" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Select placeholder={intl.formatMessage({ id: "Solutionselect" })}>
					<Select.Option value="product">
						<FormattedMessage id="Product" />
					</Select.Option>
					<Select.Option value="service">
						<FormattedMessage id="Service" />
					</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item
				name="visibility"
				value={form2.visibility}
				label={<FormattedMessage id="Ideavisibility" />}
				rules={[
					{
						required: true,
						message: <FormattedMessage id="EmptyError" />,
					},
				]}
			>
				<Select placeholder={intl.formatMessage({ id: "Visibilityselect" })}>
					<Select.Option value="public">
						<FormattedMessage id="public" />
					</Select.Option>
					<Select.Option value="private">
						<FormattedMessage id="private" />
					</Select.Option>
				</Select>
			</Form.Item>
			<div className="navigation-buttons">
				<Button onClick={() => setCurrent(current - 1)}>
					{" "}
					<FormattedMessage id="prev" />
				</Button>
				<Button type="primary" htmlType="submit">
					<FormattedMessage id="next" />
				</Button>
			</div>
		</Form>,
		<Spin tip="Loading..." spinning={loading}>
			<Form {...layout} form={form3} onFinish={onFinish3}>
				<Form.Item
					name="youtubeVideo"
					value={form3.youtubeVideo}
					rules={[
						() => ({
							validator(_, value) {
								if (!value) return Promise.resolve();
								const ytRegex = new RegExp(
									/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=([^&\n]+)|youtu\.be\/([a-zA-Z\d]+))$/
								);
								if (value.length > 3000) {
									return Promise.reject(<FormattedMessage id="fieldTooLong" />);
								} else if (!ytRegex.test(value)) {
									return Promise.reject(
										<FormattedMessage id="error_61" />
									);
								} else {
									return Promise.resolve();
								}
							},
						}),
					]}
					label={<FormattedMessage id="youtubeVideo" />}
				>
					<Input
						prefix={<Youtube color="red" style={{ marginRight: 5 }} />}
						placeholder="https://www.youtube.com/watch?v=XXXXXXXXX"
					/>
				</Form.Item>
				<Form.Item label={<FormattedMessage id="IdeaImages" />}>
					<Dashboard note={intl.formatMessage({ id: "error_62" })} uppy={uppy} />
					<span style={{ color: "green" }}>{intl.formatMessage({ id: "error_62" })}</span>
				</Form.Item>
				<div className="navigation-buttons">
					<Button onClick={() => setCurrent(current - 1)}>
						<FormattedMessage id="prev" />
					</Button>
					<Button htmlType="submit" type="primary">
						<FormattedMessage id="done" />
					</Button>
				</div>
			</Form>
		</Spin>
	];

	return (
		<div className="add-idea-page">
			<Container>
				<Heading text={<FormattedMessage id="Addnewidea" />} />
				<div className="carta">
					<Container>
						<Steps current={current}>
							<Step title={<FormattedMessage id="ideaInfos" />} />
							<Step title={<FormattedMessage id="ideaClassification" />} />
							<Step title={<FormattedMessage id="ideaMedia" />} />
						</Steps>
					</Container>
				</div>
				<div className="carta">
					<Container>
						<Row style={{ justifyContent: "center" }}>
							<Col md={8}>{forms[current]}</Col>
						</Row>
					</Container>
				</div>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
	values: state.ideas.NewIdea.loading,
	Path: state.Path.Path.process_info,
});

export default injectIntl(connect(mapStateToProps, { AddIdea })(Add));
