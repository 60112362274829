import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
	Container,
	Row,
	Col,
	Alert,
	Pagination,
	PaginationItem,
	PaginationLink,

} from "reactstrap";
import {
	ChevronLeft,
	ChevronRight,
} from "react-feather";
import Heading from "../../../components/Heading";
import IdeaCard from "../../../components/Ideas/IdeaCard";
import { FormattedMessage, injectIntl } from "react-intl";
import "../../../sass/Ideas/AllIdeas.scss";
import { PulseLoader } from "react-spinners";
import { Form, Select, Empty, Input, Button } from "antd";
import {
	getAllIdeas,
	getAllIdeasWithFilters,
	searchIdeas
} from "../../../redux/actions/Ideas/IdeasActions";
import { useForm } from "antd/lib/form/Form";
import categories from "../../../json/categories.json";
import { IntlContext } from "../../../context/internationalization";
import IdeasMethods from "../../../Helpers/IdeaHelpers";
import "../../../css/noResult.css";

const AllIdeas = (props) => {
	const context = useContext(IntlContext);
	const layout = {
		layout: "vertical",
		size: "large",
	};
	const [ideas, setIdeas] = useState([]);
	const [result, setResult] = useState([]);
	const [load, setLoad] = useState(true);
	const [filter] = useForm();
	// eslint-disable-next-line 
	const [filterError, setfilterError] = useState("");
	// eslint-disable-next-line 
	const [filterSuccess, setfilterSuccess] = useState("");
	const [noResult, setNoResult] = useState(false);
	const [form] = Form.useForm();
	const { intl } = props
	useEffect(() => {
		document.title = intl.formatMessage({ id: "allIdeas" })
		getAllIdeas()
		// eslint-disable-next-line
	}, []);
	const getAllIdeas = () => {
		props.getAllIdeas({ filter: { page: 1 } }).then((res) => {
			if (res.status === "success") {
				setLoad(false);
				setResult(res.result);
				setIdeas(res.result.Ideas);
			} else if (res.status === "Not Found") {
				setLoad(false);
				setNoResult(true);
			}
		});
	}
	const form3Values = () => {
		IdeasMethods.getFilters(filter.getFieldsValue()).then((url) => {
			Object.assign(filter, { page: 1, url: url });
			props.getAllIdeas(filter).then((res) => {
				if (res.status === "success") {
					setLoad(false);
					setResult(res.result);
					setNoResult(false);
					setIdeas(res.result.Ideas);
				} else if (res.status === "Not Found") {
					setLoad(false);
					setNoResult(true);
				}
			});
		});
	};

	const getIdeasbyPage = (page) => {
		setLoad(true);
		Object.assign(filter, { page: page });
		props.getAllIdeas(filter).then((res) => {
			if (res.status === "success") {
				setLoad(false);
				setResult(res.result);
				setIdeas(res.result.Ideas);
			}
		});
	};
	const getNextPage = () => {
		setLoad(true);
		Object.assign(filter, { page: result.nextPage });
		props.getAllIdeas(filter).then((res) => {
			if (res.status === "success") {
				setLoad(false);
				setResult(res.result);
				setIdeas(res.result.Ideas);
			}
		});
	};
	const handlesearshClick = () => {

		const data = {
			search: form.getFieldValue("search"),
			page: 1
		}
		props.searchIdeas(data)
			.then(res => {
				if (res.status === "success") {
					setNoResult(false);
					setLoad(false);
					setResult(res.result);
					setIdeas(res.result.Ideas);
				}
				else {
					setNoResult(true);
					setLoad(false);
					setResult([]);
					setIdeas([]);
				}
			})

	}
	return (
		<div className="AllIdeas">
			<Heading text={<FormattedMessage id="allIdeas" />} />

			<Container>
				<Row>
					{/* HAD ROW DIAL CARDS + FILTER */}
					<Col lg={9} md={8} sm={12}>
						<Row style={{ marginBottom: 30 }}>
							{/* HAD ROW DIAL SEARCH */}
							<Col>
								<Form form={form} onFinish={handlesearshClick}>
									<Form.Item name="search">

										<div className="carta search">

											<Input
												size="large"
												value={form.search}
											// onChange={(e) => setName(e.target.value)}
											/>
											<Button type="primary" htmlType="submit" onClick={() => handlesearshClick()}>
												<FormattedMessage id="search" />
											</Button>
										</div>
									</Form.Item>
								</Form>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					{!load ? (
						<>
							<Col md={9} className="ideaGrid">
								{noResult ? (
									<div style={{ marginTop: "10%" }}>
										<Empty description={<FormattedMessage id="error_73" />} />
									</div>
								) : (
									<Container>
										<Row md={2} sm={1} xs={1}>
											{ideas.map((idea, i) => {
												return (
													<Col style={{ marginTop: "20px" }} key={i}>
														<IdeaCard idea={idea} user={props.user} />
													</Col>
												);
											})}
										</Row>
										<div className="pagination">
											<Pagination
												size="sm"
												aria-label="Page navigation example"
											>
												<PaginationItem>
													<PaginationLink
														href="#"
														first
														disabled={result.prevPage === null ? true : false}
														onClick={() => getIdeasbyPage(result.prevPage)}
													>
														<ChevronLeft size={15} /> Prev
													</PaginationLink>
												</PaginationItem>
												{[...Array(result.totalPages)].map((page, i) => {
													return (
														<PaginationItem
															key={i}
															active={result.page === i + 1 ? true : false}
														>
															<PaginationLink
																href="#"
																onClick={() => getIdeasbyPage(i + 1)}
															>
																{i + 1}
															</PaginationLink>
														</PaginationItem>
													);
												})}
												<PaginationLink
													href="#"
													last
													disabled={result.nextPage === null ? true : false}
													onClick={() => getNextPage()}
												>
													Next
													<ChevronRight size={15} />
												</PaginationLink>
											</Pagination>
										</div>
									</Container>
								)}
							</Col>
							<Col className="Filter">
								<Container>
									<Heading text="Filter" color="#009FFF" />
									<Form
										{...layout}
										form={filter}
										onValuesChange={form3Values}

									>
										<Form.Item
											name="category"
											label={<FormattedMessage id="IdeaCategory" />}
										>
											<Select allowClear>
												{categories.map((category, key) => (
													<Select.Option key={key} value={category.value}>
														{context.state.locale === "en"
															? category.label
															: context.state.locale === "fr"
																? category.label_fr
																: category.label_ar}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item name="path" label="The path of incubation">
											<Select allowClear>
												{props.Path && props.Path.length > 0
													? props.Path.map((p, i) => {
														return (
															<Select.Option value={p.id} key={i}>
																{localStorage.getItem("locale") === "fr"
																	? p.name_fr
																	: localStorage.getItem("locale") === "ar"
																		? p.name_ar
																		: p.name_en}
															</Select.Option>
														);
													})
													: ""}
											</Select>
										</Form.Item>
										<Form.Item
											name="solutions"
											label={<FormattedMessage id="Ideasolution" />}
										>
											<Select allowClear>
												<Select.Option value="product">
													<FormattedMessage id="Product" />
												</Select.Option>
												<Select.Option value="service">
													<FormattedMessage id="Service" />
												</Select.Option>
											</Select>
										</Form.Item>
										<Form.Item name="team_size" label="Team size">
											<Select allowClear>
												<Select.Option value="1">1</Select.Option>
												<Select.Option value="2">2</Select.Option>
											</Select>
										</Form.Item>
										{filterError ? (
											<Alert color="danger">
												<h6 className="alert-heading">Error</h6>
												<div className="alert-body">{filterError}</div>
											</Alert>
										) : filterSuccess ? (
											<Alert color="success">
												<h4 className="alert-heading">Success</h4>
												<div className="alert-body">
													{" "}
													<FormattedMessage id={filterSuccess} />
												</div>
											</Alert>
										) : (
											""
										)}
									</Form>
								</Container>
							</Col>
						</>
					) : (
						<div className="bolisi">
							<PulseLoader size={10} color="#458ff6" />
						</div>
					)}
				</Row>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
	Path: state.Path.Path.process_info,
});
export default injectIntl(connect(mapStateToProps, {
	getAllIdeas,
	getAllIdeasWithFilters,
	searchIdeas
})(AllIdeas));
