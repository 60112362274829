import QtsList from "./Questions";
import config from './config'

const dfAvt = 'https://1.bp.blogspot.com/-K31BYi2tFfs/YJquIz3UZuI/AAAAAAAAEDY/RfsM0KHi4cMsv_SCl61DBQ8bjf-oAgo0gCNcBGAsYHQ/s16000/user_avatar.jpg'

const UserMethods = {
	getQuestions: (data) => {
		return new Promise((resolve, reject) => {
			switch (data.account_type) {
				case "idea_holder":
					resolve(
						UserMethods.checkQTsbyType(
							data.questions,
							QtsList.ideaHolderQuestions
						)
					); break
				case "mentor":
					resolve(
						UserMethods.checkQTsbyType(data.questions, QtsList.meciQuestions)
					); break
				case "expert":
					resolve(
						UserMethods.checkQTsbyType(data.questions, QtsList.meciQuestions)
					); break
				case "coach":
					resolve(
						UserMethods.checkQTsbyType(data.questions, QtsList.meciQuestions)
					); break
				case "instructor":
					resolve(
						UserMethods.checkQTsbyType(data.questions, QtsList.meciQuestions)
					); break
				case "coordinator":
					resolve(
						UserMethods.checkQTsbyType(
							data.questions,
							QtsList.coordinatorQuestions
						)
					); break
				case "investor":
					resolve(
						UserMethods.checkQTsbyType(
							data.questions,
							QtsList.investorQuestions
						)
					); break
				default:
					resolve([]);
			}
		});
	},
	checkQTsbyType: (Qts, QtsTab) => {
		let Qstarray = [];
		Object.values(Qts).forEach((element, i) => {
			if (element !== undefined)
				Qstarray.push({
					qte: QtsTab[i],
					answer: element,
				});
		});
		return Qstarray;
	},
	getUserImage: (uuid, image) => {
		return image === 'user_avatar.jpg' ? dfAvt : `${config.serverLink}/api/userImgs/${uuid}/${image}`
	},
	getUserAvatar: () => {
		return `${config.serverLink}/api/userImgs/user_avatar.jpg`;
	},
	getTeamArray: (members) => {
		return new Promise((resolve, reject) => {
			let tab = [];
			members.forEach((member, i) => {
				tab = [
					...tab,
					{
						name: member.user.lastname + " " + member.user.firstname,
						position: member.position,
						img: member.user.avatar ? UserMethods.getUserImage(
							member.user.userId,
							member.user.avatar
						) : UserMethods.getUserAvatar(),
					},
				];
			});
			resolve(tab);
		});
	},
};

export default UserMethods;
