import React, { useContext } from "react";
import "../../../sass/Ideas/ideainfo.scss";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col, Container, Badge } from "reactstrap";
import {
	Hash,
	Settings,
	Target,
	ChevronLeft,
	ChevronRight,
} from "react-feather";
import TeamSwiper from "../../../components/TeamSwiper";
import { ReactComponent as Think } from "../../../assets/icons/IdeaInfo/think.svg";
import { ReactComponent as Start } from "../../../assets/icons/IdeaInfo/start.svg";
import { ReactComponent as Execute } from "../../../assets/icons/IdeaInfo/execute.svg";
import { ReactComponent as Launch } from "../../../assets/icons/IdeaInfo/launch.svg";
import { IntlContext } from "../../../context/internationalization";
import { getIdeaInfo } from "../../../redux/actions/Ideas/IdeasActions";
import { PulseLoader } from "react-spinners";
import Carousel from "../../../components/Carousel";
import { Divider } from "antd";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import TopBar from "../../../components/Ideas/TopBar";
import _ from "lodash";
import IdeasMethods from "../../../Helpers/IdeaHelpers";

let $tagColor = "#FFAE33",
	$targetCustomersColor = "#5E81F4",
	$oneValueColor = "#0CC3E7",
	$indeaNeedsColor = "#37B2FC",
	$categoriesColor = "#5E41F4";

const IdeaInfo = (props) => {
	const history = useHistory();
	const { intl } = props
	const [loadData, setLoadData] = useState(false);
	const context = useContext(IntlContext);
	const [ideaInfo, setIdeaInfo] = useState([]);
	const [imageindex, setImageindex] = useState(0);
	const [userIdea, setUserIdea] = useState(false);

	useEffect(() => {
		document.title = ideaInfo?.title ? ideaInfo?.title : intl.formatMessage({ id: "ideaInfos" });
		const id = props.match.params.id;
		props.getIdeaInfo(id).then((res) => {
			setLoadData(true);
			if (res.status === "success" && res.idea) {
				if (
					_.isArray(res.idea.members) &&
					res.idea.members.some((one) => one.user.userId === props.User.userId)
				)
					setUserIdea(true);
				res.idea.youtubeVideo
					? (res.idea.youtubeVideo = res.idea.youtubeVideo
						.split("v=")[1]
						.substring(0, 11))
					: (res.idea.youtubeVideo = "");
				setIdeaInfo(res.idea);
			} else if (res.status === "Not Found") history.push("/app/ideas");
		});
		// eslint-disable-next-line
	}, []);


	return (
		<div className="Ideainfo">
			<Container fluid>
				{loadData ? (
					<>
						{userIdea ? (
							<TopBar idea={ideaInfo.ideaId} ideaID={ideaInfo._id} />
						) : (
							""
						)}
						<div className="infosHeader">
							<Container>
								<Row>
									<Col md={6}>
										<div className="imagesdiv">
											{ideaInfo.images ? (
												<div
													className="image"
													style={{
														backgroundImage: `url('${IdeasMethods.getIdeaImage(ideaInfo.ideaId, ideaInfo.images[imageindex])}`,
													}}
												></div>
											) : (
												""
											)}
										</div>
									</Col>
									<Col>
										<div className="ideainfos">
											<div className="title">
												<h3>{ideaInfo.title}</h3>
											</div>
											<div className="desc">{ideaInfo.shortDescription}</div>
											{ideaInfo.images &&
												<Carousel
													items={ideaInfo.images}
													ideaInfo={ideaInfo}
													active={0}
													setActive={setImageindex}
												/>
											}
										</div>

									</Col>
								</Row>
							</Container>
						</div>

						<div className="ideabody">
							<div className="ideaState">
								<div className="state-info statetrue">
									<Start />
									<h5>Start</h5>
								</div>
								<div className="line-separater"></div>
								<div className="state-info">
									<Think />
									<h5>Think</h5>
								</div>
								<div className="line-separater"></div>
								<div className="state-info">
									<Execute />
									<h5>Execute</h5>
								</div>
								<div className="line-separater"></div>
								<div className="state-info">
									<Launch />
									<h5>Launch</h5>
								</div>
							</div>
							<Container>
								<Row>
									<Col md={8} sm={12} style={{ marginBottom: 20 }}>
										<div className="carta DescCard">
											<div className="d-flex justify-content-start align-items-center mb-1"></div>
											{ideaInfo.youtubeVideo ? (
												<iframe
													style={{ height: "360px" }}
													className="embed-responsive-item w-100 mb-1 youtube"
													frameBorder="0"
													src={`https://www.youtube-nocookie.com/embed/${ideaInfo.youtubeVideo}`}
													title="video"
													allow="accelerometer; encrypted-media; gyroscope;"
													allowFullScreen
												></iframe>
											) : (
												""
											)}
											<div
												className="desc"
												dangerouslySetInnerHTML={{
													__html: ideaInfo.longDescription,
												}}
											/>
											<Divider>Idea Members</Divider>
											<div className="teamSwiper">
												{ideaInfo.members &&
													<TeamSwiper members={ideaInfo.members} />
												}
												<div className="swiper-end">
													{context.state.direction === "ltr" ? (
														<ChevronLeft id="swiperLeft" color="#5A98F2" />
													) : (
														<ChevronRight id="swiperLeft" color="#5A98F2" />
													)}
													<div id="swiperPagination" />
													{context.state.direction === "ltr" ? (
														<ChevronRight id="swiperRight" color="#5A98F2" />
													) : (
														<ChevronLeft id="swiperRight" color="#5A98F2" />
													)}
												</div>
											</div>
										</div>
									</Col>
									<Col md={4} sm={12}>
										<div className="ideaTags">
											<div className="carta">
												<div className="carta-title">
													<Hash className="carta-icon" />
													<h3>
														<FormattedMessage id="IdeaCategory" />
													</h3>
												</div>
												<div className="carta-badges">
													{ideaInfo.category
														? ideaInfo.category.map((cat, key) => {
															return (
																<Badge
																	key={key}
																	className="badge"
																	style={{
																		backgroundColor: $categoriesColor,
																	}}
																	pill
																>
																	{cat.label}
																</Badge>
															);
														})
														: ""}
												</div>
											</div>
											<div className="carta">
												<div className="carta-title">
													<Hash className="carta-icon" />
													<h3>
														<FormattedMessage id="IdeaTags" />
													</h3>
												</div>
												<div className="carta-badges">
													{ideaInfo.tags
														? ideaInfo.tags.map((tag, key) => {
															return (
																<Badge
																	key={key}
																	className="badge"
																	style={{ backgroundColor: $tagColor }}
																	pill
																>
																	{tag}
																</Badge>
															);
														})
														: ""}
												</div>
											</div>
											<div className="carta">
												<div className="carta-title">
													<Target className="carta-icon" />
													<h3>
														<FormattedMessage id="IdeaTarget2" />
													</h3>
												</div>
												<div className="carta-badges">
													{ideaInfo.targetCustomer
														? ideaInfo.targetCustomer.map((target, key) => {
															return (
																<Badge
																	key={key}
																	className="badge"
																	style={{
																		backgroundColor: $targetCustomersColor,
																	}}
																	pill
																>
																	{target}
																</Badge>
															);
														})
														: ""}
												</div>
											</div>
											<div className="carta">
												<div className="carta-title">
													<Settings className="carta-icon" />
													<h3>
														<FormattedMessage id="IdeaNeededskills" />
													</h3>
												</div>
												<div className="carta-badges">
													{ideaInfo.skillsNeeded
														? ideaInfo.skillsNeeded.map((skill, key) => {
															return (
																<Badge
																	key={key}
																	className="badge"
																	style={{
																		backgroundColor: $indeaNeedsColor,
																	}}
																	pill
																>
																	{skill}
																</Badge>
															);
														})
														: ""}
												</div>
											</div>
											<div className="carta one-value">
												<Row
													style={{
														width: "100%",
														margin: "auto",
														display: "flex",
														alignItems: "center",
													}}
												>
													<Col
														md="12"
														className="px-0"
														style={{ width: "100%" }}
													>
														<div className="carta-title">
															<Settings className="carta-icon" />
															<h3>
																<FormattedMessage id="IdeaProgram2" />
															</h3>
														</div>
													</Col>
													<Col
														md="12"
														className="px-0"
														style={{ width: "100%" }}
													>
														<div className="carta-badges">
															<Badge
																className="badge"
																style={{ backgroundColor: $oneValueColor }}
																pill
															>
																{ideaInfo.outcome ? ideaInfo.outcome : ""}
															</Badge>
														</div>
													</Col>
												</Row>
											</div>
											<div className="carta one-value">
												<Row
													style={{
														width: "100%",
														margin: "auto",
														display: "flex",
														alignItems: "center",
													}}
												>
													<Col
														md="12"
														className="px-0"
														style={{ width: "100%" }}
													>
														<div className="carta-title">
															<Settings className="carta-icon" />
															<h3>
																<FormattedMessage id="Ideaphase2" />
															</h3>
														</div>
													</Col>
													<Col
														md="12"
														className="px-0"
														style={{ width: "100%" }}
													>
														<div className="carta-badges">
															<Badge
																className="badge"
																style={{ backgroundColor: $oneValueColor }}
																pill
															>
																{ideaInfo.phase ? ideaInfo.phase : ""}
															</Badge>
														</div>
													</Col>
												</Row>
											</div>
											<div className="carta one-value">
												<Row
													style={{
														width: "100%",
														margin: "auto",
														display: "flex",
														alignItems: "center",
													}}
												>
													<Col
														md="12"
														className="px-0"
														style={{ width: "100%" }}
													>
														<div className="carta-title">
															<Settings className="carta-icon" />
															<h3>
																<FormattedMessage id="Ideasolution2" />
															</h3>
														</div>
													</Col>
													<Col
														md="12"
														className="px-0"
														style={{ width: "100%" }}
													>
														<div className="carta-badges">
															<Badge
																className="badge"
																style={{ backgroundColor: $oneValueColor }}
																pill
															>
																{ideaInfo.solutions ? ideaInfo.solutions : ""}
															</Badge>
														</div>
													</Col>
												</Row>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					</>
				) : (
					<div className="bolisi">
						<PulseLoader size={10} color="#458ff6" />
					</div>
				)}
			</Container>
		</div >
	);
};

const mapStateToProps = (state) => ({
	User: state.auth.login.user,
});

export default injectIntl( connect(mapStateToProps, { getIdeaInfo })(IdeaInfo));
