// import '@zoomus/websdk/dist/css/bootstrap.css'
import "./sass/App.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Routerr from "./router/Router";
import { ConfigProvider } from "antd";
import { useContext } from "react";
import { IntlContext } from "./context/internationalization";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";


function App() {
	const { state } = useContext(IntlContext);
	useEffect(() => {
		AOS.init({
			duration: 700
		});

		document.getElementById('root').addEventListener('scroll', () => {
			AOS.refresh();
		});
	}, []);
	return (
		<ConfigProvider direction={state.direction}>
			<Router>
				<Route path="/" component={Routerr} />
			</Router>
		</ConfigProvider>
	);
}

export default App;
