import categories from "../json/categories.json";
import config from './config'

const IdeasMethods = {
	// fill Add Idea Object
	fillAddIdeaData: (values) => {
		return new Promise((resolve, reject) => {
			const data = {
				process_id: values.Section1.path,
				title: values.Section1.title,
				shortDescription: values.Section1.shortDescription,
				longDescription: values.Section1.longDescription,
				youtubeVideo: values.values.youtubeVideo
					? values.values.youtubeVideo
					: "",
				category: [],
				tags: values.Section2.tags ? values.Section2.tags : "",
				targetCustomer: values.Section2.targetCustomer,
				phase: values.Section2.ideaPhase,
				outcome: values.Section2.outcome,
				skillsNeeded: values.Section2.skillsNeeded
					? values.Section2.skillsNeeded
					: "",
				solutions: values.Section2.solutions,
				visibility: values.Section2.visibility,
			};
			categories.forEach((elmnt) => {
				if (values.Section2.category === elmnt.value) {
					data.category = [...data.category, elmnt];
					resolve(data);
				}
			});
		});
	},
	// convert Image to Base64
	getBase64: (file) => {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				var img = new Image();
				img.onload = function () {
					var buf = Buffer.from(reader.result, "base64");
					buf.length > 3000000 ? reject() : resolve(img.src);
				};
				img.onerror = () => { };
				img.src = reader.result;
			};
			reader.onerror = function (error) { };
		});
	},
	// get Array Images (base64)
	getImages: (files) => {
		return new Promise((resolve, reject) => {
			var tabImages = [];
			files.forEach((element) => {
				IdeasMethods.getBase64(element.data).then((res) => {
					tabImages = [...tabImages, res.split(",")[1]];
					if (tabImages.length === files.length) resolve(tabImages);
				});
			});
		});
	},
	// Check Form1 Fields is Chenged or not on Edit Idea
	ChekcFieldsForm1: (ideainfo, newdata) => {
		return new Promise((resolve, reject) => {
			const data = {};
			ideainfo.title !== newdata.title
				&& Object.assign(data, { title: newdata.title });
			ideainfo.shortDescription !== newdata.shortDescription
				&& Object.assign(data, { shortDescription: newdata.shortDescription });
			ideainfo.longDescription !== newdata.longDescription.toHTML()
				&& Object.assign(data, {
					longDescription: newdata.longDescription.toHTML(),
				});
			resolve(data);
		});
	},
	// Check Form2 Fields is Chenged or not on Edit Idea
	ChekcFieldsForm2: (ideainfo, newdata) => {
		return new Promise((resolve, reject) => {
			const data = {};
			if (ideainfo.youtubeVideo !== newdata.youtubeVideo)
				Object.assign(data, { youtubeVideo: newdata.youtubeVideo });
			else if (newdata.youtubeVideo === "")
				Object.assign(data, { youtubeVideo: "" });
			resolve(data);
		});
	},
	// Check Form3 Fields is Chenged or not on Edit Idea
	ChekcFieldsForm3: (ideainfo, newdata) => {
		return new Promise((resolve, reject) => {
			let data = {};
			const cat = categories.find((elm) => elm.value === newdata.category);
			var categ = [];
			ideainfo.tags !== newdata.tags
				&& Object.assign(data, { tags: newdata.tags });
			ideainfo.targetCustomer !== newdata.targetCustomer
				&& Object.assign(data, { targetCustomer: newdata.targetCustomer });
			ideainfo.skillsNeeded !== newdata.skillsNeeded
				&& Object.assign(data, { skillsNeeded: newdata.skillsNeeded });
			ideainfo.solutions !== newdata.solutions
				&& Object.assign(data, { solutions: newdata.solutions });
			ideainfo.visibility !== newdata.visibility
				&& Object.assign(data, { visibility: newdata.visibility });
			ideainfo.outcome !== newdata.outcome
				&& Object.assign(data, { outcome: newdata.outcome });
			ideainfo.category[0].value === cat.value
				&& (categ = [
					...categ,
					categories.find((elm) => elm.value === newdata.category),
				]);
			categ[0]
				&& Object.assign(data, { category: categ })

			ideainfo.phase !== newdata.ideaPhase
				&& Object.assign(data, { phase: newdata.ideaPhase });

			resolve(data);
		});
	},
	// all Ideas Filters
	getFilters: (filters) => {
		return new Promise((resolve, reject) => {
			let url = "";
			filters.category && (url = `&category=${filters.category}`)
			filters.path && (url = url + `&path=${filters.path}`)
			filters.solutions
				&& (url = url + `&solutions=${filters.solutions}`)
			filters.team_size
				&& (url = url + `&team_size=${filters.team_size}`)
			resolve(url);
		});
	},
	getIdeaImage: (uuid, image) => {
		return `${config.serverLink}/api/ideasImgs/${uuid}/${image}`;
	},
	getIdeaAvatar: () => {
		return `${config.serverLink}/api/ideasImgs/idea_avatar.jpg`;
	},
	CreateData: (idea, images) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			Object.keys(idea).forEach((key) => {
				if (typeof idea[key] === "object")
					formData.append(key, JSON.stringify(idea[key]));
				else formData.append(key, idea[key]);
			});
			images.forEach((img) => formData.append("images", img.data));
			resolve(formData);
		});
	},
};

export default IdeasMethods;
