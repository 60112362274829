import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PulseLoader } from "react-spinners";
import { Col, Container, Row } from "reactstrap";
import userService from "../../../services/user";
import "../../../sass/Sessions/Book.scss";
import {
	Calendar,
	Empty,
	Form,
	Modal,
	Select,
	Alert,
	notification,
} from "antd";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";
import {
	ReserveSlot,
	getSession,
	GetAllMonthSlots,
} from "../../../redux/actions/Sessions/SessionsActions";
import { getMyIdeas } from "../../../redux/actions/Ideas/IdeasActions";
import SessionsMethods from "../../../Helpers/SessionsHelpers";
import { useForm } from "antd/lib/form/Form";
import { socket } from "../../../hooks/Socket";
import classNames from "classnames";

export const Book = (props) => {
	const { intl } = props;
	const { id } = useParams();
	const history = useHistory();
	const [mentor, setMentor] = useState({});
	const [modalVisible, setModalVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [selectedDate, setSelectedDate] = useState(moment());
	const [slots, setSlots] = useState([])
	const [slotSelected, setslotSelected] = useState([]);
	const [myIdeas, setmyIdeas] = useState([]);
	const [resultat, setresultat] = useState({});
	// eslint-disable-next-line 
	const [idea, setIdea] = useForm();
	const [errAlertmsg, seterrAlertmsg] = useState("");

	useEffect(() => {
		userService
			.getOneUser({ id })
			.then((res) => {
				if (res.status === "success") {
					if (res.results.account_type === "idea_holder") history.push("/404");
					setMentor(res.results);
					setLoading(false);
				}
			})
			.catch((err) => {
				history.push("/404");
			});
	}, [id, history]);

	useEffect(() => {
		if (mentor._id) {
			getSlots(moment());
			getIdeas();
			getSlotsbyRange();
		}
		// eslint-disable-next-line 
	}, [mentor]);
	useEffect(() => {
		if (mentor._id) {
			getSlotsbyRange();
		}
		// eslint-disable-next-line 
	}, [mentor, selectedDate]);
	// get My Ideas
	const getIdeas = () => {
		props.getMyIdeas().then((res) => {
			if (res.status === "success") setmyIdeas(res.result.Ideas);
			else
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: res.msg }),
				});
		});
	};

	// get Mentor Available Slots
	const getSlots = (date) => {
		SessionsMethods.getSlotsByDate(date).then((res) => {
			Object.assign(res, { owner: mentor._id, limit: 24 });
			props.getSession(res).then((result) => {
				if (result.status === "success") {
					setSlots(result.result.Sessions);
				} else {
					setSlots([]);
				}
			});
		});
	};
	// get Mentor Available Slots by Range
	const getSlotsbyRange = () => {
		const begin = moment(selectedDate).format("YYYY-MM-01");
		const data = {
			begin,
			end: moment(begin).add(31, "days"),
		};
		SessionsMethods.getSlotsByRange(data).then((res) => {
			Object.assign(res, { owner: mentor._id });
			props.GetAllMonthSlots(res).then((result) => {
				if (result.status === "success") {
					setresultat(result.result);
				} else setresultat([]);
			});
		});
	};
	const ReserveSlot = () => {
		const data = {
			id: slotSelected.sessionId,
			ideaId: idea.getFieldValue("ideaId"),
		};
		props.ReserveSlot(data).then((res) => {
			if (res.status === "success") {
				notification["success"]({
					message: intl.formatMessage({ id: "success" }),
					// description: intl.formatMessage({ id: res.msg }),
				});
				setModalVisible(false);
				let dta = [];
				dta = [...dta, mentor._id];
				socket.emit("new_notification", {
					event_type: "book_session",
					received_by: dta,
					sessionId: slotSelected.sessionId,
					ideaId: idea.getFieldValue("ideaId"),
					idea: "",
				});
			} else seterrAlertmsg(res.en);
		});
	};
	return (
		<div className="book-page">
			<Container>
				{loading ? (
					<PulseLoader color="#458ff6" size={8} />
				) : (
					<Row style={{ justifyContent: "center" }}>
						<Col md={10}>
							<div className="carta title">
								<span><FormattedMessage id="booksession" /></span>
								<span className="name">{`${mentor.firstname} ${mentor.lastname}`}</span>
							</div>
							<div className="carta">
								<Container>
									<Row>
										<Col md={6} style={{ paddingTop: 50 }}>
											<div className="calendar-wrapper">
												<div
													className="picture"
													style={{
														backgroundImage: `url('${userService.avatarLink({
															avatar: mentor.avatar,
															userId: mentor.userId,
														})}')`,
													}}
												></div>
												<div className="header">
													<FormattedMessage id="timetomeet" /> <span>{mentor.firstname}</span>
												</div>
												<Calendar
													fullscreen={false}
													value={selectedDate}
													onSelect={(date) => {
														setSelectedDate(date);
														getSlots(date);
													}}
													// onPanelChange={(date, mode) =>
													// }
													dateFullCellRender={(date) => {
														return (
															<div
																className={classNames("cell", {
																	"cell-selected":
																		date.diff(selectedDate) === 0,
																	"cell-stroke":
																		resultat[date.format("YYYY-MM-DD")] > 0,
																})}
															>
																{date.date()}
															</div>
														);
													}}
												/>
											</div>
										</Col>

										<Col md={6} style={{ paddingTop: 30 }}>
											<div className="booker-wrapper">
												<div className="header"><FormattedMessage id="bookmeeting" /></div>
												<div className="slots-wrapper">
													{slots.length ? (
														slots.map((slot, i) => {
															return (
																<div
																	key={i}
																	className="slot"
																	style={
																		slot.session_status === "Waiting"
																			? { background: "#e25555" }
																			: {}
																	}
																	onClick={() => {
																		seterrAlertmsg("");
																		idea.setFieldsValue({ ideaId: "" });
																		setslotSelected(slot);
																		setModalVisible(true);
																	}}
																>
																	{moment(slot.begin_at).format("hh:mm A")}
																</div>
															);
														})
													) : (
														<Empty
															description={<FormattedMessage id="noSlot" />}
														/>
													)}
												</div>
											</div>
										</Col>
									</Row>
								</Container>
							</div>
						</Col>
					</Row>
				)}
			</Container>
			<Modal
				cancelText={<FormattedMessage id="cancel" />}
				title={<FormattedMessage id="session" />}
				visible={modalVisible}
				onCancel={() => setModalVisible(false)}
				okText={<FormattedMessage id="confirm" />}
				onOk={() => ReserveSlot()}
			>
				{errAlertmsg ? <Alert message={errAlertmsg} type="error" /> : ""}
				<div
					className="modal-lead"
					style={errAlertmsg ? { marginTop: "20px" } : {}}
				>
					Your session is booked from{" "}
					{moment(slotSelected.begin_at).format("hh:mm A")} to{" "}
					{moment(slotSelected.end_at).format("hh:mm A")}
				</div>
				<div className="select-idea">
					<Form size="large" layout="vertical" form={idea}>
						<Form.Item
							name="ideaId"
							label={<FormattedMessage id="selectYourIdea" />}
						>
							<Select placeholder="Select idea" allowClear>
								{myIdeas.length
									? myIdeas.map((idea, i) => {
										return (
											<Select.Option key={i} value={idea.ideaId}>
												{idea.title}
											</Select.Option>
										);
									})
									: ""}
							</Select>
						</Form.Item>
					</Form>
				</div>
				<div className="instructions">
					<FormattedMessage id="bookDesc" />
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = {
	ReserveSlot,
	getSession,
	getMyIdeas,
	GetAllMonthSlots,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Book));
