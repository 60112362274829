import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Main } from "../../../assets/icons/MainApp/Layout/main.svg";
import { ReactComponent as Ideas } from "../../../assets/icons/MainApp/Layout/ideas.svg";
import { ReactComponent as ExploreProfile } from "../../../assets/icons/MainApp/Layout/exploreProfile.svg";
import { ReactComponent as Sessions } from "../../../assets/icons/MainApp/Layout/sessions.svg";
import "../../../sass/MainAppLayout.scss";
import { ReactComponent as EcosystemDataset } from "../../../assets/icons/MainApp/Layout/ecosystemDataset.svg";
import { Layout, Menu, } from "antd";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { FormattedMessage } from "react-intl";
import SubMenu from "antd/lib/menu/SubMenu";
const { Sider } = Layout;

const MentorsLayout = (props) => {
	const [selectedKey, setSelectedKey] = useState(["main"]);
	const location = useLocation();
	const history = useHistory();
	const bp = useBreakpoint();
	const { pathname } = location;
	useEffect(() => {
		const arr = [
			"ideas",
			"incubator",
			"explore",
			"sessions",
			"help",
			"opportunities",
			"training",
			"programs",
			"startups",
			"events",
			"workspaces",
			"ecosystem",
			"resources",
			"blogs",
			"other",
			"invites",
			"notifications",
			"all",
			"idea_holder",
			"mentor",
		];
		const x = pathname.split("/");

		let key = "404";
		if (x[1] === "app") {
			key = x[2] ? x[2] : "";
		}

		if (arr.indexOf(key) !== -1) setSelectedKey([key]);
		else if (key === "") setSelectedKey(["main"]);
		else setSelectedKey([]);
	}, [pathname]);
	return (
		<Sider
			breakpoint="md"
			collapsedWidth={bp === "sm" || bp === "md" ? 0 : 80}
			collapsible
			collapsed={props.collapsed}
			onCollapse={() => props.setCollapsed(!props.collapsed)}
			theme="light"
			trigger={null}
			style={{
				overflow: "auto",
				height: "100vh",
				position: "fixed",
				left: 0,
				zIndex: 9999,
			}}
			className="sider"
		>
			<Menu mode="inline" className="sider-menu" selectedKeys={selectedKey}>
				<Menu.Item
					key="main"
					icon={<Main />}
					onClick={() => history.push("/app")}
				>
					<FormattedMessage id="Main" />
				</Menu.Item>
				<Menu.Item
					key="ideas"
					icon={<Ideas />}
					onClick={() => history.push("/app/ideas")}
				>
					<FormattedMessage id="Ideas" />
				</Menu.Item>
				<SubMenu
					key="profile"
					icon={<ExploreProfile />}
					title={
						<span className="ideas-text" style={{
							opacity: props.collapsed ? 0 : 1,
							transition: "opacity .2s ease",
						}}>
							<FormattedMessage id="exploreProfile" />
						</span>
					}
				>
					<Menu.Item key="all" onClick={() => history.push("/app/explore/all")}>
						<FormattedMessage id="allprofiles" />
					</Menu.Item>
					<Menu.Item
						key="idea_holder"
						onClick={() => history.push("/app/explore/idea_holder")}
					>
						<FormattedMessage id="ideaHolder" />
					</Menu.Item>
					<Menu.Item
						key="instructor"
						onClick={() => history.push("/app/explore/instructor")}
					>
						<FormattedMessage id="instructor" />
					</Menu.Item>
				</SubMenu>

				<Menu.Item
					key="sessions"
					icon={<Sessions />}
					onClick={() => history.push("/app/sessions")}
				>
					<FormattedMessage id="sessions" />
				</Menu.Item>
				<Menu.Item
					key="process"
					icon={<EcosystemDataset />}
					onClick={() => history.push("/app/process")}
				>
					<FormattedMessage id="ProcessInfo" />
				</Menu.Item>
			</Menu>
		</Sider>
	);
};

export default MentorsLayout;
