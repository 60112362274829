import Api from "./Api";

const Sessions = {
	/*
	 **  Create session (new slot)
	 **  Ps: Create a one hour session slot
	 */
	createSession(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post("/sessions/", data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Delete Session (delete slot)
	 */
	deleteSession(sessionId) {
		return new Promise((resolve, reject) => {
			Api()
				.delete(`/sessions/${sessionId}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Show all session slots
	 ** You can use Params to get specific slots
	 */
	getSession(params) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/sessions/slots`, { params: params })
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Show all slots
	 ** You can use Params to get specific slots
	 */
	getSlots(params) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`sessions/slots/count/from/${params.begin}/to/${params.end}`, {
					params: params,
				})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Show Current user session slots
	 ** You can use Params to get specific slots
	 */
	getMySession(params) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/sessions/me?limit=10${params}&sort=begin`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Show session slots associated with idea as an idea member
	 ** You can use Params to get specific slots
	 */
	getSessionAsMember(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(
					data.params
						? `/sessions/member/${data.ideaId}/?sort=begin&limit=10${data.params}`
						: `/sessions/member/${data.ideaId}/?sort=begin&limit=10`
				)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Check finished Session
	 */
	CheckFinishedSession(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(
					`/sessions/member/${data.ideaId}/?sort=begin&limit=100&status=Confirmed`
				)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Check finished Session as Owner
	 */
	CheckFinishedSessionAsOwner(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/sessions/me?status=Confirmed&sort=begin&limit=100`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Show session slots associated with idea as an idea owner
	 ** You can use Params to get specific slots
	 */

	getSessionAsOwner(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`sessions/me/${data.ideaId}/?${data.params}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Count all user session slots by date interval, status defaults to Available.
	 ** You can use Params to get specific slots
	 */
	CountAllSession(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/sessions/slots/count/from/${data.begin}/to/${data.end}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Change session slot begin time
	 */
	updateSlotTime(data) {
		return new Promise((resolve, reject) => {
			Api()
				.patch(`/sessions/${data.id}/time`, data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Reserve session slot as an idea holder.
	 */
	ReserveSlot(data) {
		return new Promise((resolve, reject) => {
			Api()
				.patch(`/sessions/${data.id}/${data.ideaId}/reserve`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Confirm a session slot by its owner. [mentor, expert, coach, instructor]
	 */
	ConfirmSlot(id) {
		return new Promise((resolve, reject) => {
			Api()
				.patch(`/sessions/${id}/confirm`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Cancel a session slot by its owner. [mentor, expert, coach, instructor]
	 */
	CancelSlotOwner(id) {
		return new Promise((resolve, reject) => {
			Api()
				.patch(`/sessions/${id}/cancel`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Cancel a session slot by its associated idea member. [idea_holder]
	 */
	CancelSlotMember(data) {
		return new Promise((resolve, reject) => {
			Api()
				.patch(`/sessions/${data.id}/${data.ideaId}/cancel`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Finish a session.
	 */
	FinishSlot(id) {
		return new Promise((resolve, reject) => {
			Api()
				.patch(`/sessions/${id}/finish`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Sessions Feed-back
	 */
	CreateSessionFeedback(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/feedbacks/sessions/${data.sessionId}/${data.userId}`, data.body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 ** Get all month Slots
	 */
	GetAllMonthSlots(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/sessions/slots/count/from/${data.begin}/to/${data.end}`, {
					params: data,
				})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
};

export default Sessions;
