import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import "../../../sass/ProcessInfo/Processinfo.scss";
import { ReactComponent as Test } from "./Test.svg";
import { getProccesById } from "../../../redux/actions/ProcessInfo/InfoActions"
import { useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import ProcessMethods from "../../../Helpers/ProcessInfoHelpers"

const ProcessInfoPage = (props) => {
	const params = useParams()
	const [infos, setInfos] = useState([])
	const [infoLoading, setInfoLoading] = useState(true)
	const { intl } = props
	useEffect(() => {
		document.title = intl.formatMessage({ id: "EventInfo" })
		getProcessById()
		// eslint-disable-next-line
	}, [])

	const getProcessById = () => {
		props.getProccesById(params.id)
			.then(res => {
				if (res.status === "success") {
					setInfos(res.result)
					setInfoLoading(false)
				}
			})
	}

	const getDescrp = (desc) => {
		return `${desc.substring(0, 150)}...`
	}

	return (

		infoLoading ? <PulseLoader color="#458ff6" size={8} /> :

			<div className="process_info">
				<div className="process_header">
					<div className="dwara">
						<h1>{localStorage.getItem("locale") === "ar" ? infos.name_ar : localStorage.getItem("locale") === "fr" ? infos.name_fr : infos.name_en}</h1>
					</div>
					<Test />
				</div>
				<Container>
					<div className="process_content">
						<Row>
							<Col md={4}>
								<div className="process_image" style={{ backgroundImage: `url(${ProcessMethods.getProcessImage(infos.process_id, infos.image)})` }}></div>
							</Col>
							<Col md={8}>
								<div className="process_infoII">
									<p>
										{
											localStorage.getItem("locale") === "ar" ? infos.description_ar : localStorage.getItem("locale") === "fr" ? infos.description_fr : infos.description_en
										}
									</p>
								</div>
							</Col>
						</Row>
					</div>
					<div className="stages_info">
						<Row>
							{
								infos.stages && infos.stages.map((stage, i) => {
									return (
										<Col md={4} key={i}>
											<div className="cardi">
												<div className="face face1">
													<div className="content">
														{/* <img src="https://github.com/Jhonierpc/WebDevelopment/blob/master/CSS%20Card%20Hover%20Effects/img/design_128.png?raw=true" /> */}
														<h3>{localStorage.getItem("locale") === "ar" ? stage.name_ar : localStorage.getItem("locale") === "fr" ? stage.name_fr : stage.name_en}</h3>
													</div>
												</div>
												<div className="face face2">
													<div className="content">
														<p>
															{
																localStorage.getItem("locale") === "ar" ? getDescrp(stage.description_ar) : localStorage.getItem("locale") === "fr" ? getDescrp(stage.description_fr) : getDescrp(stage.description_en)
															}
														</p>
													</div>
												</div>
											</div>
										</Col>

									)
								})
							}
						</Row>
					</div>
				</Container>
			</div>

	);
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
	getProccesById
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProcessInfoPage));
