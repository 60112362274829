import Avatar from "antd/lib/avatar/avatar";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import {
	CheckSquare,
	ChevronLeft,
	ChevronRight,
	File,
	Image,
	Plus,
	Send,
	Smile,
	ArrowDown,
	Video
} from "react-feather";
import {
	Alert,
	DatePicker,
	Empty,
	Form,
	message as msg,
	Modal,
	Popover,
	Select,
	Button,
	Spin
} from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Input, notification, Upload } from "antd";
import { IntlContext } from "../../context/internationalization";
import UserService from "../../services/user";
import Message from "./Message";
import { PulseLoader } from "react-spinners";
import moment from "moment";
import IdeasMethods from "../../Helpers/IdeaHelpers";
import UserMethods from "../../Helpers/UserHelpers";
import {
	CreateMessage,
	getMessages,
	CreateUserConversation,
	UploadFileToConversation,
	createTask,
	sendTask,
	getMessagesWithPage
} from "../../redux/actions/Communications/CommActions";
import _ from "lodash";
import { socket } from "../../hooks/Socket";
import InfiniteScroll from "react-infinite-scroll-component";
import Picker from "emoji-picker-react";
import { Link, useHistory } from "react-router-dom";
import config from '../../Helpers/config'

const socketLink = config.socketLink
const MAX_FILE_SIZE = 1024 * 1024 * 16;
const MAX_IMAGE_SIZE = 1024 * 1024 * 5;

export const Convo = (props) => {
	const { intl } = props;
	const { state } = useContext(IntlContext);
	const [isInsertOpen, setIsInsertOpen] = useState(false);
	const [loadMessage, setloadMessage] = useState(false);
	const [message, setMessage] = useState("");
	const [Chat, setChat] = useState([]);
	const [ChatResult, setChatResult] = useState([]);
	const [taskModalVisible, setTaskModalVisible] = useState(false);
	const [hasMore, sethasMore] = useState(false);
	const [taskError, settaskError] = useState(false);
	const [Scroll, setScroll] = useState(false);
	const [waiting, setwaiting] = useState(false);
	const [form] = Form.useForm();
	const history = useHistory();
	// const [borderColor, setBorderColor] = useState("rgb(213, 213, 213)");
	const [isZoomVisible, setIsZoomVisible] = useState(false)
	const [task, setTask] = useState({
		user: "",
		title: "",
		deadline: moment().add(1, "d"),
		desc: "",
	});
	useEffect(() => {
		socket.on("new_message", (data) => {
			if (props.recipient._id === data.conversation) {
				setChat([data, ...Chat]);
			}
		});
		return () => { socket.off("new_message"); };
		// eslint-disable-next-line
	}, [Chat]);
	useEffect(() => {
		if (!props.newDM) {
			props.ReadAllMessages();
			getMessages();
		}
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (props.SearchResult) {
			setChat(props.SearchResult);
		}
	}, [props.SearchResult]);

	const getMessages = () => {
		const data = {
			_id: props?.recipient?._id,
		};
		props.getMessages(data).then((res) => {
			if (res.status === "success") {
				if (_.isObject(res.result) && _.isArray(res.result.Messages)) {
					setChat(res.result.Messages);
					if (res.result.hasNextPage) sethasMore(true)
					else sethasMore(false)
					setChatResult(res.result);
					setloadMessage(true);
				}
			} else {
				setChatResult([]);
				setloadMessage(true);
			}
		});
	};
	const msgsnextPage = () => {
		const data = {
			_id: props.recipient._id,
			page: ChatResult.nextPage
		}
		if (ChatResult.nextPage)
			props.getMessagesWithPage(data)
				.then(res => {
					if (res.status === "success") {
						if (_.isObject(res.result) && _.isArray(res.result.Messages)) {
							setChat([...Chat, ...res.result.Messages]);
							if (res.result.hasNextPage) sethasMore(true)
							else sethasMore(false)
							setChatResult(res.result);
							setloadMessage(true);
						}
					} else {
						setloadMessage(true);
						history.push("/500");
					}
				})
	};
	const sendMessage = () => {
		if (props.newDM) {
			props.CreateUserConversation(props.recipient._id).then((res) => {
				if (res.status === "success") {

					const data = {
						body: message.trim(),
						_id: res.result.upserted[0]._id ? res.result.upserted[0]._id : "",
					};
					props.CreateMessage(data).then((re) => {
						setMessage("");
						if (re.status === "success") {
							const data = {
								_id: res.result.upserted[0]._id,
							};
							props.getMessages(data).then((res) => {
								if (res.status === "success") {
									if (
										_.isObject(res.result) &&
										_.isArray(res.result.Messages)
									) {
										setChat(res.result.Messages);
										setloadMessage(true);
										props.setnewRecipient()
									}

								}
								else {
									setChat([]);
									setloadMessage(true);
								}
							});
							// props.getMyDMsConversations();
						}
					});
				}
			});
		} else {
			const data = {
				body: message.trim(),
				_id: props.recipient._id,
			};
			props.CreateMessage(data).then((res) => {
				setMessage("");
				if (res.status === "success") {
					getMessages();
					// props.chat_type === "teams"
					// 	? props.getMyTeamsConversations()
					// 	: props.chat_type === "dm"
					// 		? props.getMyDMsConversations()
					// 		:
					// 		props.chat_type === "sessions"
					// 			? props.getMySessionsConversations()
					// 			: console.log();
				}
			});
		}
	};
	const uploadImageMessage = (file) => {
		setwaiting(true)
		if (file.size <= MAX_IMAGE_SIZE) {
			return true;
		} else {
			notification["error"]({
				message: props.intl.formatMessage({ id: "bigImageTitle" }),
				description: props.intl.formatMessage({ id: "bigImageMessage" }),
			});
			return false;
		}
	};
	const uploadFileMessage = (file) => {
		setwaiting(true)
		if (file.size <= MAX_FILE_SIZE) {
			return true;
		} else {
			notification["error"]({
				message: props.intl.formatMessage({ id: "bigFileTitle" }),
				description: props.intl.formatMessage({ id: "bigFileMessage" }),
			});
			return false;
		}
	};

	const handleChangeFile = (info) => {
		if (info.file.status === "done") {
			getMessages()
			setwaiting(false)
			msg.success("file uploaded");
		}
		else if (info.file.status === "error") {
			notification["error"]({
				message: props.intl.formatMessage({ id: "error_text" }),
				description: props.intl.formatMessage({ id: "error_121" }),
			});
			setwaiting(false)
		}
	};
	const handleChangeImage = (info) => {
		if (info.file.status === "done") {
			getMessages()
			setwaiting(false)
			msg.success("Image uploaded");
		}
		else if (info.file.status === "error") {
			notification["error"]({
				message: props.intl.formatMessage({ id: "error_text" }),
				description: props.intl.formatMessage({ id: "error_121" }),
			});
			setwaiting(false)
		}
	};


	const onEmojiClick = (event, emojiObject) => {
		if (emojiObject) {
			const nemsg = message + emojiObject.emoji;
			setMessage(nemsg);
		}
	};

	const createTask = () => {
		const data = {
			_id: props.recipient._id,
			user: task.user,
			body: task,
		};
		data.body.deadline = moment(data.body.deadline).format("MM-DD-YYYY");
		if (!data.user || !data.body.description || !data.body.title)
			settaskError(true);
		else {
			props
				.createTask(data)
				.then((res) => {
					if (res.status === "success") {
						sendTask({ task: res.result._id, _id: res.result.conversation });
						setTask({
							...task,
							user: "",
							title: "",
							deadline: moment().add(1, "d"),
							description: "",
						});
						setTaskModalVisible(false);
						notification["success"]({
							message: intl.formatMessage({ id: "success" }),
							description: intl.formatMessage({ id: "taskCreated" }),
						});
					} else if (res.status === "failure") {
						notification["error"]({
							message: props.intl.formatMessage({ id: "error_text" }),
							description: props.intl.formatMessage({ id: res.msg }),
						});
					}
				})
				.catch((err) => {
					notification["error"]({
						message: props.intl.formatMessage({ id: "error_text" }),
						description: props.intl.formatMessage({ id: 'error_1' }),
					});
					history.push("/500");
				});
		}
	};

	const sendTask = (data) => {
		props.sendTask(data).then((res) => {
			if (res.status === "success") {
				getMessages()
				msg.success("Task added")
			}
			else
				msg.error(<FormattedMessage id="error_1" />)
		});
	};

	const backtoEnd = () => {
		setScroll(!Scroll)
		var myDiv = document.getElementById("sessions");
		myDiv.scrollTop = myDiv.scrollHeight;

	}
	return (
		<Spin spinning={waiting} tip="Loading...">
			<div className="carta conversation">
				<div className="recipient-header">
					<div className="profile">
						{props.md ? (
							<div
								onClick={() => {
									props.setRecipient(null);
								}}
								className="convo-back"
							>
								{state.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
							</div>
						) : (
							""
						)}
						{props.type === "teams" ? (
							<Avatar
								size="large"
								src={
									props.recipient.idea &&
										props.recipient.idea.ideaId &&
										props.recipient.idea.images[0]
										? IdeasMethods.getIdeaImage(
											props.recipient.idea.ideaId,
											props.recipient.idea.images[0]
										)
										: IdeasMethods.getIdeaAvatar()
								}
								onClick={() => {
									props.md && props.setShowProfile(true);
								}}
								className={classNames({
									"ml-10": state.direction === "rtl",
									"mr-10": state.direction === "ltr",
								})}
								style={{
									marginRight: state.direction === "ltr" ? 10 : 0,
									marginLeft: state.direction === "rtl" ? 10 : 0,
								}}
							>
								T
							</Avatar>
						) : props.type === "sessions" ? (
							<Avatar
								size="large"
								src={
									props.recipient.session_owner &&
										props.recipient.session_owner.userId &&
										props.recipient.session_owner.avatar
										? UserMethods.getUserImage(
											props.recipient.session_owner.userId,
											props.recipient.session_owner.avatar
										)
										: UserMethods.getUserAvatar()
								}
								onClick={() => {
									props.md && props.setShowProfile(true);
								}}
								className={classNames({
									"ml-10": state.direction === "rtl",
									"mr-10": state.direction === "ltr",
								})}
								style={{
									marginRight: state.direction === "ltr" ? 10 : 0,
									marginLeft: state.direction === "rtl" ? 10 : 0,
								}}
							>
								S
							</Avatar>
						) : (
							<Avatar
								size="large"
								onClick={() => {
									props.md && props.setShowProfile(true);
								}}
								className={classNames({
									"ml-10": state.direction === "rtl",
									"mr-10": state.direction === "ltr",
								})}
								src={UserService.avatarLink({
									avatar: props.userdm?.avatar,
									userId: props.userdm?.userId

								})}
								style={{
									marginRight: state.direction === "ltr" ? 10 : 0,
									marginLeft: state.direction === "rtl" ? 10 : 0,
								}}
							/>
						)}
						<div
							className="fullname-status"
							onClick={() => {
								props.md && props.setShowProfile(true);
							}}
						>
							<div className="fullname">
								{props.type === "teams" ? (
									<>
										<FormattedMessage id="teamOf" />{" "}
										<span>
											{props.recipient.idea ? props.recipient.idea.title : ""}
										</span>
									</>
								) : props.type === "sessions" ? (
									props.recipient?.session_owner &&
										props.recipient.session_owner?.firstname ? (
										`${props.recipient.session_owner.firstname} ${props.recipient.session_owner.lastname}`
									) : (
										""
									)
								) : props.userdm ? (
									`${props.userdm.firstname} ${props.userdm.lastname}`
								) : (
									""
								)}
							</div>
							{props.user.account_type !== "idea_holder" && <div
								className={classNames("state", {
									// This is for defining the status of recipient (Online? Offline?);
									online: true,
									"last-online": false,
								})}
								onClick={() => {
									history.push(`/app/incubatorstate/${props.recipient.idea._id}`)
								}}
							>
								<FormattedMessage id="goToIncubator" />
							</div>
							}

						</div>
					</div>
					{/* <div className="buttons">
					<div className="botona">
						<MoreVertical size={13} />
					</div>
				</div> */}
				</div>
				{props.recipient?.meeting_info && <div className="fixed--zoom">
					<div className="zoom-link" onClick={() => { setIsZoomVisible(true) }}>
						<Video style={{
							margin: "0 5px"
						}} />
						<FormattedMessage id="gotoMeeting" />
					</div>

				</div>}
				<Modal
					title={<FormattedMessage id="startMeeting" />}
					visible={isZoomVisible}
					centered
					onCancel={() => setIsZoomVisible(false)}
					footer={[
						<Link style={{ margin: "71px", marginLeft: "-20px" }} to={{
							pathname: `/app/zoommeeting?joinMeeting=true&met=${props.recipient?.meeting_info?.meeting_id}&pwd=${props.recipient?.meeting_info?.password}&username=${props.user.firstname + " " + props.user.lastname}&useremail=${props.user.email}`,

						}} target="_blank">

							<Button type="primary"
							><FormattedMessage id="useLeafSDK" /></Button></Link>,
						<Button type="primary" onClick={() => {
							window.open(props.recipient?.meeting_info.join_url, '_blank')
						}}><FormattedMessage id="goZoom" /></Button>]}
				>
					<FormattedMessage id="startMeetingDes" />
				</Modal>

				<div style={{ overflow: "auto", height: "100%", display: "flex", flexDirection: "column-reverse" }} id="sessions">

					{loadMessage ? (
						Chat.length ? (
							<InfiniteScroll
								style={{ display: 'flex', flexDirection: 'column-reverse', alignSelf: "center" }}
								dataLength={Chat.length}
								next={msgsnextPage}
								loader={<PulseLoader color="#458ff6" size={8} />}
								pullDownToRefreshContent={<h1>Pull Down</h1>}
								scrollableTarget="sessions"
								scrollThreshold={1}
								hasMore={hasMore}
								inverse={true}
								onScroll={() => setScroll(true)}
							>

								{Scroll ?
									<div className="conv-input">
										<div className="send-button potona" style={{ position: "absolute", marginTop: "-30px", zIndex: "5" }} onClick={() => {
											backtoEnd()
										}}>
											<ArrowDown /></div> </div>
									: ""}
								{Chat.map((msg, i) => {
									return (

										<Message
											key={i}
											sent_at={msg.sent_at}
											text={msg.body}
											sent={msg.sent_by._id === props.user._id}
											recipient={props.recipient}
											sentby={msg.sent_by}
											type={msg.type}
											meeting={msg.type === "zoom" ? msg.meeting : ""}
											file={
												msg.type === "file" && msg.file_path
													? {
														name: msg.file_original_name
															? msg.file_original_name
															: "",
														link: msg.file_path,
														ext: msg.file_path.split(".").pop(),
														size: msg.file_size ? msg.file_size : 0,
													}
													: {}
											}
											image={msg.type === "image" ? msg.file_path : ""}
											task={
												msg.type === "task" && msg.task
													? {
														_id: msg.task._id,
														title: msg.task.title,
														desc: msg.task.description,
														deadline: msg.task.deadline,
														user: msg.task.assigned_user,
														status: msg.task.status,
													}
													: {}
											}
										/>

									);
								})}

							</InfiniteScroll>

						) : (
							<div style={{ display: "flex !important", flexDirection: "column-reverse !important", justifyContent: "center !important", alignItems: "center !important" }}>
								<Empty
									style={{ marginTop: "50px" }}
									description={<FormattedMessage id="noMessage" />}
								/>
							</div>
						)
					) : (
						<div className="msg-loader">
							<PulseLoader size={12} color="#458ff6" />
						</div>
					)}
				</div>

				<div className="conv-input">
					<div
						className="insert-button potona"
						onClick={() => setIsInsertOpen(!isInsertOpen)}
					>
						{props.chat_type === "teams" ? (
							<div
								className={classNames("task-button", "potona", "pos-absolute", {
									"task-button-open": isInsertOpen,
									"task-button-closed": !isInsertOpen,
								})}
								onClick={() => {
									setTaskModalVisible(true);
								}}
							>
								<CheckSquare />
							</div>
						) : (
							""
						)}

						<Upload
							action={`${socketLink}/wbs/v1/messages/upload/image/to/${props?.recipient?._id}`}
							beforeUpload={uploadImageMessage}
							onChange={handleChangeImage}
							showUploadList={false}
							headers={{
								Authorization: `Bearer ${localStorage.getItem("AyHc7zSW7n6rI")}`,
							}}
							name="image"
						>
							<div
								className={classNames("image-button", "potona", "pos-absolute", {
									"image-button-open": isInsertOpen,
									"image-button-closed": !isInsertOpen,
								})}
							>
								<Image />
							</div>
						</Upload>
						<Upload
							action={`${socketLink}/wbs/v1/messages/upload/file/to/${props?.recipient?._id}`}
							beforeUpload={uploadFileMessage}
							onChange={handleChangeFile}
							showUploadList={false}
							headers={{
								Authorization: `Bearer ${localStorage.getItem("AyHc7zSW7n6rI")}`,
							}}
							name="file"
						>
							<div
								className={classNames("file-button", "potona", "pos-absolute", {
									"file-button-open": isInsertOpen,
									"file-button-closed": !isInsertOpen,
								})}
							>
								<File />
							</div>
						</Upload>
						<Plus />
					</div>
					<div className="message-input">
						<Form
							style={{ height: "100%" }}
							onFinish={() => {
								if (message.trim().length > 0) sendMessage();
								setChat((chat) => [
									...chat,
									{
										body: message.trim(),
										sent_by: {
											_id: props.user._id,
										},
									},
								]);
							}}
						>
							<Input
								value={message}
								placeholder="Type a message here"
								onChange={(e) => {
									e.target.value.length < 1000 && setMessage(e.target.value);
								}}
							/>
						</Form>
					</div>

					<Popover
						content={
							<div>
								<Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} />
							</div>
						}
					>
						<div className="emoji-button potona">
							<Smile />
						</div>
					</Popover>
					<div className="send-button potona">
						<Send
							type="submit"
							onClick={() => {
								if (message.trim().length > 0) sendMessage();
								setChat((chat) => [
									...chat,
									{
										body: message.trim(),
										sent_by: {
											_id: props.user._id,
										},
									},
								]);
							}}
						/>
					</div>
				</div>
				<Modal
					title={<FormattedMessage id="manageTask" />}
					visible={taskModalVisible}
					okText={<FormattedMessage id="assign" />}
					cancelText={<FormattedMessage id="cancelBtn" />}
					onCancel={() => setTaskModalVisible(false)}
					onOk={() => {
						createTask();
						// setTaskModalVisible(false);
						setTask({
							...task,
							deadline: moment().add(1, "d"),
						});
					}}
				>
					<Form layout="vertical" size="large" form={form}>
						<Form.Item
							value={form.user}
							name="user"
							label={<FormattedMessage id="assignedTo" />}
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
							]}
						>
							<FormattedMessage id="addMembers">
								{(msg) => (
									<Select
										showSearch
										allowClear
										style={{ width: "100%" }}
										placeholder="Select a person"
										onChange={(val) =>
											setTask((task) => ({ ...task, user: val }))
										}
									>
										{props.recipient?.idea_members.map((member, i) => {
											return (
												<Select.Option key={i} value={member._id}>
													{`${member.firstname} ${member.lastname}`}
												</Select.Option>
											);
										})}
									</Select>
								)}
							</FormattedMessage>
						</Form.Item>

						<Form.Item
							label={<FormattedMessage id="title" />}
							name="title"
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
							]}
						>
							<Input
								placeholder="Title"
								value={task.title}
								onChange={(e) => {
									setTask((task) => ({ ...task, title: e.target.value }));
								}}
							/>
						</Form.Item>
						<Form.Item
							label={<FormattedMessage id="description" />}
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
							]}
						>
							<Input.TextArea
								value={task.description}
								onChange={(e) => {
									setTask((task) => ({ ...task, description: e.target.value }));
								}}
								autoSize={{ minRows: 3, maxRows: 5 }}
							></Input.TextArea>
						</Form.Item>
						<Form.Item
							label={<FormattedMessage id="deadline" />}
							style={{
								textAlign: "center",
							}}
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
							]}
						>
							<DatePicker
								value={task.deadline}
								onChange={(v) => {
									setTask((task) => ({ ...task, deadline: v }));
								}}
								style={{
									width: "100%",
									borderRadius: 10,
								}}
							/>
						</Form.Item>
					</Form>
					{taskError ? (
						<Alert
							message={<FormattedMessage id="allfeildsError" />}
							type="error"
						/>
					) : (
						""
					)}
				</Modal>
			</div>
		</Spin>
	);

};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = {
	CreateMessage,
	getMessages,
	CreateUserConversation,
	UploadFileToConversation,
	createTask,
	sendTask,
	getMessagesWithPage
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Convo));
