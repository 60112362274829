export const NewIdea = (state = { loading: false, MyIdeas: null }, action) => {
	switch (action.type) {
		case "ADD_LOADING": {
			return { ...state, loading: action.loading };
		}
		case "MY_IDEAS": {
			return { ...state, MyIdeas: action.MyIdeas };
		}
		default: {
			return state;
		}
	}
};
