import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MainAppLayout from "../../../components/layouts/MainApp/Layout";
import Profile from "../Profile";
import EditProfile from "../Profile/Edit.js";
import AddIdea from "../Idea/Add.js";
import EditIdea from "../Idea/EditIdea";
import MyIdeas from "../Idea/MyIdeas";
import AllIdeas from "../Idea/AllIdeas";
import IdeaInfo from "../Idea/IdeaInfo";
import ManageTeam from "../ManageTeam/ManageTeam";
import Profiles from "../Profile/Profiles";
import Sessions from "../Sessions";
import BookSession from "../Sessions/Book";
import CreateSession from "../Sessions/Create";
import Incubator from "../Incubator";
import IdeaIncubation from "../Incubator/IdeasIncubation";
import ChatApp from "../ChatApp";
import Invites from "../ManageTeam/Invites";
import Notifications from "../Notifications";
import ProcessInfo from "../ProcessInfo";
import ProcessInfoPage from "../ProcessInfo/ProcessInfo"
import Home from "../home";
import Redzoom from "../../../Zoom/Redzoom";
import ZoomMeetHome from "../ZoomMeet";
import MentorIdeaIncubation from "../Incubator/MentorIndex"


import initSocket from "../../../services/Socket/index";

const Index = ({ user }) => {
	useEffect(() => {
		if (user && user?._id) {
			initSocket(user);
		}
		// eslint-disable-next-line
	}, []);
	return (
		<MainAppLayout user={user}>
			<Switch>
				<Route exact path="/app">
					<Home />
				</Route>
				{/* Profile routes */}
				<Route exact path="/app/profile/edit">
					<EditProfile />
				</Route>
				<Route exact path="/app/profile/:id">
					<Profile />
				</Route>
				{/* Idea routes */}
				<Route exact path="/app/ideas/new">
					<AddIdea />
				</Route>
				<Route exact path="/app/process" component={ProcessInfo} />
				<Route exact path="/app/process/:id" component={ProcessInfoPage} />
				<Route exact path="/app/ideas/edit/:id" component={EditIdea} />
				<Route exact path="/app/ideas/me" component={MyIdeas} />
				<Route exact path="/app/ideas" component={AllIdeas} />
				<Route exact path="/app/ideas/manageteam/:id" component={ManageTeam} />
				<Route path="/app/ideas/:id" component={IdeaInfo} />
				<Route exact path="/app/explore/:type">
					<Profiles />
				</Route>
				<Route exact path="/app/sessions">
					<Sessions />
				</Route>
				<Route exact path="/app/sessions/book/:id">
					<BookSession />
				</Route>
				<Route exact path="/app/sessions/create">
					<CreateSession />
				</Route>
				<Route exact path="/app/incubator">
					<IdeaIncubation />
				</Route>
				<Route exact path="/app/incubatorstate/:ideaid">
					<MentorIdeaIncubation />
				</Route>
				<Route exact path="/app/incubator/:ideaid">
					<Incubator />
				</Route>
				<Route exact path="/app/chat/:chat_type?/:chat_id?">
					<ChatApp />
				</Route>
				<Route exact path="/app/invites">
					<Invites />
				</Route>
				<Route exact path="/app/notifications">
					<Notifications />
				</Route>
				<Route exact path="/app/redzoom">
					<Redzoom />
				</Route>
				<Route exact path="/app/zoommeeting" >
					<ZoomMeetHome />
				</Route>
				<Redirect to="/404" />
			</Switch>
		</MainAppLayout>
	);
};

export default Index;
