import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Convs, RecipientProfile } from "../../../components/ChatApp";
import Convo from "../../../components/ChatApp/Convo";
import { getMessages } from "../../../redux/actions/Communications/CommActions";
export const MdApp = (props) => {
	const [showProfile, setShowProfile] = useState(false);
	return (
		<div>
			<Row>
				<Col>
					{props.recipient === null ? (
						<Convs
							loadingConversation={props.loadingConversation}
							setUserdmprops={props.setUserdmprops}
							chenageValue={props.chenageValue}
							data={props.Conversations}
							setRecipient={props.setRecipient}
							type={props.currPage}
						/>
					) : showProfile ? (
						<RecipientProfile
							getImageConvs={props.getImageConvs}
							getDocsConvs={props.getDocsConvs}
							SearchInMessages={props.SearchInMessages}
							userdm={props.userdm}
							recipient={props.recipient}
							type={props.currPage}
							md
							setShowProfile={setShowProfile}
						/>
					) : (
						<Convo
							chat_type={props.chat_type}
							SearchResult={props.SearchResult}
							userdm={props.userdm}
							ReadAllMessages={props.ReadAllMessages}
							newDM={props.newDM}
							getMySessionsConversations={props.getMySessionsConversations}
							getMyTeamsConversations={props.getMyTeamsConversations}
							getMyDMsConversations={props.getMyDMsConversations}
							user={props.user}
							recipient={props.recipient}
							type={props.currPage}
							setShowProfile={setShowProfile}
							md
							setRecipient={props.setRecipient}
						/>
					)}
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = { getMessages };

export default connect(mapStateToProps, mapDispatchToProps)(MdApp);
