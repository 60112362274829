import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import "../../../sass/ChatApp/index.scss";
import { Info } from "react-feather";
import { FormattedMessage, injectIntl } from "react-intl";
import { Convs, Convo, RecipientProfile } from "../../../components/ChatApp";
import classnames from "classnames";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { MdApp } from "./MdApp";
import { useHistory, useParams, useLocation } from "react-router";
import _ from "lodash";
import {
	getMyTeamsConversations,
	getMyDMsConversations,
	getMySessionsConversations,
	ReadAllMessages,
	getUnreadConvsCount,
	getMessages,
	SearchInMessages,
	getImageConvs,
	getDocsConvs,
	getTaskConvs
} from "../../../redux/actions/Communications/CommActions";
import { socket } from "../../../hooks/Socket";
import CommeMethods from "../../../Helpers/CommeHelpers";

export const ChatApp = (props) => {
	const { intl } = props;
	const params = useParams();
	const history = useHistory();
	const bp = useBreakpoint();
	const location = useLocation();
	// This state for changing between DMs, Team Rooms and Session Rooms;
	const [currPage, setCurrPage] = useState(params.chat_type);
	// This state for selecting the recipient.. Later it can be modified to include
	// a type of the recipient (User, Team, Session? ..);
	const [recipient, setRecipient] = useState(null);
	const [newDM, setnewDM] = useState(false);
	const [Conversations, setConversations] = useState([]);
	const [SearchResult, setSearchResult] = useState(null);
	const [loadingConversation, setloadingConversation] = useState(false);
	const [load, setload] = useState(false);
	const { chat_type } = params;
	const [userdm, setUserdmprops] = useState(null);

	useEffect(() => {
		document.title = intl.formatMessage({ id: "messages" })
		// eslint-disable-next-line 
	}, [])
	// This useEffect is used to test if the chat type provided in the route
	// is correct. 404 is redirected if not.
	useEffect(() => {
		if (!chat_type) history.push("/app/chat/dm");
		else if (
			chat_type !== "sessions" &&
			chat_type !== "teams" &&
			chat_type !== "dm"
		)
			history.push("/404");
		else setCurrPage(chat_type);
		// eslint-disable-next-line
	}, [params, history]);

	useEffect(() => {
		history.push(`/app/chat/${currPage}`);
		// eslint-disable-next-line
	}, [currPage]);

	useEffect(() => {
		if (recipient !== null) history.push(`/app/chat/${currPage}`);
		// eslint-disable-next-line
	}, [recipient]);

	useEffect(() => {
		if (!location.state) {
			setRecipient(null);
			setload(false);
		}
		if (chat_type === "dm") getMyDMsConversations()
		else if (chat_type === "teams") getMyTeamsConversations()
		else if (chat_type === "sessions") getMySessionsConversations()
		// eslint-disable-next-line
	}, [chat_type]);

	useEffect(() => {
		if (location?.state && location?.state?.user) {
			props.getMyDMsConversations().then((res) => {
				const newconv = res.result.Conversations.filter(cnv => cnv.dm_members.some(user => user._id === location.state.user._id))
				if (newconv[0]) {
					setUserdmprops(location.state.user);
					setRecipient(newconv[0]);
					setload(true);
					setnewDM(false);
				}
				else {
					setUserdmprops(location.state.user);
					setRecipient(location.state.user);
					setload(true);
					setnewDM(true);
				}
			});
		} else if (location?.state && location?.state?.idea_id) {
			props.getMyTeamsConversations().then((res) => {
				res.result.Conversations.forEach((one) => {
					if (one.idea._id === location.state.idea_id) {
						setRecipient(one);
						setload(true);
					}
				});
			});

		} else if (location?.state && location?.state?.session_id) {
			props.getMySessionsConversations().then((res) => {
				res.result.Conversations.forEach((one) => {
					if (one.session._id === location.state.session_id) {
						setRecipient(one);
						setload(true);
					}
				});
			});
		}
		// eslint-disable-next-line
	}, [Conversations, location, chat_type, props]);

	const getMyTeamsConversations = () => {
		setConversations([]);
		setUserdmprops(null)
		props.getMyTeamsConversations().then(async (res) => {
			if (res.status === "success") {
				if (_.isObject(res.result) && _.isArray(res.result.Conversations)) {
					setConversations(res.result.Conversations);
					setloadingConversation(true);
				}
			}
		});
	};
	const getMyDMsConversations = () => {
		setConversations([]);
		props.getMyDMsConversations().then((res) => {
			if (res.status === "success") {
				if (_.isObject(res.result) && _.isArray(res.result.Conversations)) {
					setConversations(res.result.Conversations);
					setloadingConversation(true);
				}
			}
		});
	};
	const getMySessionsConversations = () => {
		setConversations([]);
		setUserdmprops(null)
		props.getMySessionsConversations().then((res) => {
			if (res.status === "success") {
				if (_.isObject(res.result) && _.isArray(res.result.Conversations)) {
					setConversations(res.result.Conversations);
					setloadingConversation(true);
				}
			}
		});
	};
	useEffect(() => {
		socket.on("message_notification", (data) => {
			if (data.conv_type === "direct" && chat_type === "dm")
				getMyDMsConversations()
			else if (data.conv_type === "team" && chat_type === "teams")
				getMyTeamsConversations()
			else if (data.conv_type === "session" && chat_type === "sessions")
				getMySessionsConversations()
		});
		return () => { socket.off("message_notification"); };
		// eslint-disable-next-line
	}, []);
	const chenageValue = async (cnv) => {
		setUserdmprops(null)
		setnewDM(false);
		setRecipient(null);
		setload(false);
		CommeMethods.selectConvs(cnv).then((res) => {
			setRecipient(res);
			setload(true);
		});
	};

	const ReadAllMessages = () => {
		props.ReadAllMessages(recipient._id).then((res) => {
			recipient.type === "team"
				? getMyTeamsConversations()
				: recipient.type === "direct"
					? getMyDMsConversations()
					: getMySessionsConversations();
		});
	};

	const SearchInMessages = (value) => {
		props
			.SearchInMessages({ _id: recipient._id, search: value })
			.then((res) => {
				if (res.status === "success")
					setSearchResult(res.result.Messages);
			});
	};
	const getDocsConvs = () => {
		return new Promise((resolve, reject) => {
			props.getDocsConvs({ _id: recipient._id, type: "file" }).then((res) => {
				if (res.status === "success") resolve(res);
			}).catch(err => reject(err))
		})
	};
	const getImageConvs = () => {
		return new Promise((resolve, reject) => {
			props.getImageConvs({ _id: recipient._id, type: "image" }).then((res) => {
				if (res.status === "success") resolve(res);
			}).catch(err => reject(err))
		})
	};
	const getTaskConvs = () => {
		return new Promise((resolve, reject) => {
			props.getTaskConvs({ id: recipient._id, type: "task" }).then((res) => {
				if (res.status === "success") resolve(res);
			}).catch(err => reject(err))
		})
	};
	const setnewRecipient = () => {
		setRecipient(Conversations[0])
		setnewDM(false)
	}
	return (
		<div className="chat-app">
			<Container>
				<div className="carta mb-20">
					<Row>
						<Col md={2} sm={12} style={{ margin: "10px 0" }}>
						</Col>
						<Col md={3} sm={12} style={{ margin: "10px 0" }}>
							<div
								className={classnames("nav-button", {
									"nav-button-selected": currPage === "dm",
								})}
								onClick={() => {
									setCurrPage("dm");
								}}
							>
								<FormattedMessage id="directMessage" />
							</div>
						</Col>
						< Col md={3} sm={12} style={{ margin: "10px 0" }}>
							{
								props.user.account_type === "idea_holder" && <div
									className={classnames("nav-button", {
										"nav-button-selected": currPage === "teams",
									})}
									onClick={() => {
										setCurrPage("teams");
									}}
								>
									<FormattedMessage id="teamRooms" />
								</div>
							}
						</Col>
						<Col md={3} sm={12} style={{ margin: "10px 0" }}>
							<div
								className={classnames("nav-button", {
									"nav-button-selected": currPage === "sessions",
								})}
								onClick={() => {
									setCurrPage("sessions");
								}}
							>
								<FormattedMessage id="sessionRooms" />
							</div>
						</Col>
					</Row>
				</div>
				{bp.lg ? (
					<Row>
						<Col md={3}>
							<Convs
								loadingConversation={loadingConversation}
								setUserdmprops={setUserdmprops}
								chenageValue={chenageValue}
								data={Conversations}
								setRecipient={setRecipient}
								type={currPage}
							/>
						</Col>
						<Col md={recipient === null ? 9 : 6}>
							<div
								className={classnames("carta", "conversation", {
									"no-recipient": recipient === null,
								})}
							>
								{load ? (
									<>
										<Convo
											chat_type={chat_type}
											SearchResult={SearchResult}
											userdm={userdm}
											ReadAllMessages={ReadAllMessages}
											newDM={newDM}
											getMySessionsConversations={getMySessionsConversations}
											getMyTeamsConversations={getMyTeamsConversations}
											getMyDMsConversations={getMyDMsConversations}
											user={props.user}
											recipient={recipient}
											type={currPage}
											setnewRecipient={setnewRecipient}
										/>
									</>
								) : (
									<>
										<div className="icon">
											<Info size={30} />
										</div>
										<div className="title">
											<FormattedMessage id="noRecipientSelected" />
										</div>
										<div className="text">
											<FormattedMessage id="selectRecipientToStartConversation" />
										</div>
									</>
								)}
							</div>
						</Col>
						{recipient === null ? (
							""
						) : (
							<Col md={3}>
								<RecipientProfile
									getImageConvs={getImageConvs}
									getTaskConvs={getTaskConvs}
									getDocsConvs={getDocsConvs}
									SearchInMessages={SearchInMessages}
									userdm={userdm}
									recipient={recipient}
									type={currPage}

								/>
							</Col>
						)}
					</Row>
				) : (
					<MdApp
						loadingConversation={loadingConversation}
						setUserdmprops={setUserdmprops}
						chenageValue={chenageValue}
						getImageConvs={getImageConvs}
						Conversations={Conversations}
						getDocsConvs={getDocsConvs}
						userdm={userdm}
						chat_type={chat_type}
						SearchResult={SearchResult}
						SearchInMessages={SearchInMessages}
						ReadAllMessages={ReadAllMessages}
						getMySessionsConversations={getMySessionsConversations}
						getMyTeamsConversations={getMyTeamsConversations}
						getMyDMsConversations={getMyDMsConversations}
						currPage={currPage}
						recipient={recipient}
						md
						setRecipient={setRecipient}
					/>

				)}
			</Container>
		</div >
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = {
	getMyTeamsConversations,
	getMyDMsConversations,
	getMySessionsConversations,
	ReadAllMessages,
	getUnreadConvsCount,
	SearchInMessages,
	getImageConvs,
	getDocsConvs,
	getMessages,
	getTaskConvs
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChatApp));
