import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import "../../../sass/Profile/Profile.scss";
import cover from "../../../assets/App/profile-cover.jpg";
import { IntlContext } from "../../../context/internationalization";
import {
	Book,
	Briefcase,
	Facebook,
	Gift,
	Globe,
	Info,
	Instagram,
	Linkedin,
	Mail,
	MapPin,
	Phone,
	Twitter,
	Youtube,
	ChevronLeft,
	ChevronRight,
	Circle,
} from "react-feather";
import { Alert, Button, Divider, Empty, Input, notification, Result } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import User from "../../../services/user";
import { PulseLoader } from "react-spinners";
import SwiperCore, { Navigation, Pagination, Autoplay, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import IdeaCard from "../../../components/Ideas/IdeaCard";
import { getAllIdeasWithUserId } from "../../../redux/actions/Ideas/IdeasActions";
import { reportUser } from "../../../redux/actions/auth/loginActions"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import UserMethods from "../../../Helpers/UserHelpers"
import Modal from "antd/lib/modal/Modal";
import { socket } from "../../../hooks/Socket";

SwiperCore.use([Navigation, Pagination, Autoplay, A11y]);

const Profile = ({ user, getAllIdeasWithUserId, reportUser, intl }) => {
	const bp = useBreakpoint();
	const { id } = useParams();
	const history = useHistory();
	const context = useContext(IntlContext);
	const { locale } = context.state;

	const [online, setOnline] = useState(false);
	const [loadingUser, setLoadingUser] = useState(true);
	const [userProfile, setUserProfile] = useState({});
	const [guest, setGuest] = useState(false);
	const [Ideas, setIdeas] = useState([]);
	const [loadData, setLoadData] = useState(false);
	const [reporVisible, setrReportVisible] = useState(false)
	const [reportUserMessage, setreportUserMessage] = useState("")
	const [ErrReport, setErrReport] = useState("")


	useEffect(() => {
		document.title = userProfile?.lastname?.toUpperCase() + " " + userProfile?.firstname?.toUpperCase()
		// eslint-disable-next-line
	}, [userProfile])
	useEffect(() => {
		if (id === "me" ) {
			setGuest(false);
			setUserProfile(user);
			setLoadingUser(false);
			getAllIdeasWithUserId(user._id).then((re) => {
				setLoadData(true);
				setLoadingUser(false);
				if (re.status === "success") {
					setIdeas(re.result.Ideas);
				} else setIdeas([]);
			});
		} else {
			User.getOneUser({ id })
				.then((res) => {
					setUserProfile(res.results);
					setGuest(res.results.userId !== user.userId);
					socket.emit('ping_user', {
						user_id: res.results._id
					})
					getAllIdeasWithUserId(res.results._id).then((re) => {
						setLoadData(true);
						setLoadingUser(false);
						if (re.status === "success") {
							setIdeas(re.result.Ideas);
						} else setIdeas([]);
					});
				})
				.catch((err) => {
					history.push("/404");
				});
		}
		// eslint-disable-next-line 
	}, [user, id]);

	useEffect(() => {
		const interval = setInterval(() => {
			socket.emit('ping_user', {
				user_id: userProfile?._id
			})
		}, 3000);
		return () => clearInterval(interval);
	}, [userProfile]);

	useEffect(() => {
		socket.on("pong_user", data => {
			(data?.isConnected)
				? setOnline(true) : setOnline(false)
		})
		return () => { socket.off("pong_user"); };
	}, [])

	const sendReport = () => {
		const data = {
			userId: userProfile.userId,
			comment: reportUserMessage,
		};
		if (reportUserMessage)
			reportUser(data).then((res) => {
				if (res.status === "success") {
					setreportUserMessage("");
					setrReportVisible(false);
					notification["success"]({
						message: intl.formatMessage({ id: "success" }),
						description: intl.formatMessage({ id: "taskCreated" }),
					});
				}
			});
		else {
			setErrReport(<FormattedMessage id="reportErr" />);
		}
	};
	return loadingUser ? (
		<div className="loadingUser">
			<PulseLoader color="#458ff6" />
		</div>
	) : (
		<div className="profile-page">
			<Container fluid>
				<div
					className="profile-cover"
					style={{ backgroundImage: `url('${cover}')` }}
				>
					<div className="cover-infos">
						{userProfile.socialMedia &&
							userProfile.socialMedia.linkedin !== "" ? (
							<div className="cover-info">
								<a href={userProfile.socialMedia.linkedin} target="_blank" rel="noreferrer">
									<Linkedin strokeWidth={1} />
								</a>
							</div>
						) : (
							""
						)}
						{userProfile.socialMedia &&
							userProfile.socialMedia.twitter &&
							userProfile.socialMedia.twitter !== "" ? (
							<div className="cover-info">
								<a href={userProfile.socialMedia.twitter} target="_blank" rel="noreferrer">
									<Twitter strokeWidth={1} />
								</a>
							</div>
						) : (
							""
						)}
						{userProfile.socialMedia &&
							userProfile.socialMedia.youtube &&
							userProfile.socialMedia.youtube !== "" ? (
							<div className="cover-info">
								<a href={userProfile.socialMedia.youtube} target="_blank" rel="noreferrer">
									<Youtube strokeWidth={1} />
								</a>
							</div>
						) : (
							""
						)}
						{userProfile.socialMedia &&
							userProfile.socialMedia.website &&
							userProfile.socialMedia.website !== "" ? (
							<div className="cover-info">
								<a href={userProfile.socialMedia.website} target="_blank" rel="noreferrer">
									<Globe strokeWidth={1} />
								</a>
							</div>
						) : (
							""
						)}
						{userProfile.socialMedia &&
							userProfile.socialMedia.facebook &&
							userProfile.socialMedia.facebook !== "" ? (
							<div className="cover-info">
								<a href={userProfile.socialMedia.facebook} target="_blank" rel="noreferrer">
									<Facebook strokeWidth={1} />
								</a>
							</div>
						) : (
							""
						)}
						{userProfile.socialMedia &&
							userProfile.socialMedia.instagram &&
							userProfile.socialMedia.instagram !== "" ? (
							<div className="cover-info">
								<a href={userProfile.socialMedia.instagram} target="_blank" rel="noreferrer">
									<Instagram strokeWidth={1} />
								</a>
							</div>
						) : (
							""
						)}
						{guest ? (
							userProfile.account_type !== "idea_holder" ? (
								<Button
									type="primary"
									style={{
										backgroundColor: "#FFC700",
									}}
									onClick={() => {
										history.push(`/app/sessions/book/${userProfile.userId}`);
									}}
								>
									<FormattedMessage id="bookNow" />
								</Button>
							) : (
								""
							)
						) : (
							<Button
								type="primary"
								onClick={() => {
									history.push("/app/profile/edit");
								}}
							>
								<FormattedMessage id="editprofile" />
							</Button>
						)}
					</div>
				</div>
				<Row style={{ marginTop: 30 }}>
					<Col
						md={4}
						sm={12}
						style={{
							marginBottom: 30,
						}}
					>
						<div className="main-infos section">
							<div
								className="profile-picture"
								style={{
									backgroundImage: `url('${userProfile.avatar !== "user_avatar.jpg" ? UserMethods.getUserImage(userProfile.userId, userProfile.avatar) : UserMethods.getUserAvatar()}')`,
								}}
							></div>
							{guest ? (
								<div className="type-tag">
									<FormattedMessage id={userProfile.account_type} />
								</div>
							) : (
								""
							)}
							<div className="online-status">
								<div
									className="online-dot"
									style={{
										backgroundColor: online ? "#00e001" : "rgb(179, 179, 179)",
									}}
								></div>
								<span className="online-text">
									{online ? "Online" : "Offline"}
								</span>
							</div>
							<div className="full-name">
								<span>{userProfile.firstname}</span>
								<span> </span>
								<span>{userProfile.lastname}</span>
							</div>
							{userProfile.address.country && userProfile.address.city ? (
								<div className="location">
									<MapPin size={12} />
									<span>
										{locale === "ar"
											? userProfile.address.city.label_ar
											: locale === "fr"
												? userProfile.address.city.label_fr
												: userProfile.address.city.label}
									</span>
									<span> - </span>
									<span>
										{locale === "ar"
											? userProfile.address.country.label_ar
											: locale === "fr"
												? userProfile.address.country.label_fr
												: userProfile.address.country.label}
									</span>
								</div>
							) : (
								""
							)}
							{guest ? (
								<div className="message-nursery">
									<Button
										type="primary"
										style={{ margin: "0 5px" }}
										onClick={() =>
											history.push({
												pathname: "/app/chat/dm",
												state: { user: userProfile },
											})
										}
									>
										{" "}
										<FormattedMessage id="sendMessage" />
									</Button>
									<Button type="primary" danger style={{ margin: "0 5px" }} onClick={() => setrReportVisible(true)}>
										<FormattedMessage id="report" />
									</Button>
								</div>
							) : (
								""
							)}
							<div className="infos-grid">
								{userProfile?.birthdate?.privacy === "piblic" &&
									<><Divider style={{ margin: "5px 0" }} />
										<div className="infos-grid-item">
											<div className="infos-grid-item-key">
												<Gift size={15} />
												<span> Age </span>
											</div>
											<div className="infos-grid-item-value">
												{Math.abs(
													new Date(
														Date.now() -
														new Date(userProfile.birthdate.date).getTime()
													).getUTCFullYear() - 1970
												)}
											</div>
										</div></>}
								{userProfile.phoneNumber ? (
									<>
										<Divider style={{ margin: "5px 0" }} />
										<div className="infos-grid-item">
											<div className="infos-grid-item-key">
												<Phone size={15} />
												<span> Phone number </span>
											</div>
											<div className="infos-grid-item-value">
												{userProfile.phoneNumber.number}
											</div>
										</div>
									</>
								) : (
									""
								)}
								<Divider style={{ margin: "5px 0" }} />
								<div className="infos-grid-item">
									<div className="infos-grid-item-key">
										<Info size={15} />
										<span> Gender </span>
									</div>
									<div className="infos-grid-item-value">
										{userProfile.gender}
									</div>
								</div>
								<Divider style={{ margin: "5px 0" }} />
								<div className="infos-grid-item">
									<div className="infos-grid-item-key">
										<Mail size={15} />
										<span> Email </span>
									</div>
									<div className="infos-grid-item-value">
										{userProfile.email}
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md={8} sm={12}>
						<Row style={{ marginTop: 0, marginBottom: 30 }}>
							<Col>
								<div className="biography section">
									<div className="biography-heading">Biography:</div>
									<div className="biography-content">
										{userProfile.biography}
									</div>
								</div>
							</Col>
						</Row>
						<Row style={{ marginBottom: 0 }}>
							<Col md={7} style={{ marginBottom: 30 }}>
								<div className="section">
									{userProfile.questions.length ? (
										userProfile.questions.map((qte, i) => {
											return (
												<div className="questions" key={i}>
													<div className="question">
														<Circle
															color="#458ff6"
															style={{ margin: "0 5px" }}
														/>
														{qte.qte ? (
															<FormattedMessage id={qte.qte} />
														) : (
															<FormattedMessage id={qte.qte} />
														)}
													</div>
													<div className="answer">
														{qte.answer ? (
															qte.answer
														) : (
															<Empty
																description={<FormattedMessage id="noAnswer" />}
															/>
														)}
													</div>
												</div>
											);
										})
									) : (
										<Empty />
									)}
									{userProfile.account_type === "idea_holder" ? (
										<>
											<Divider style={{ margin: "10px 0" }} />
											<div className="ideas">
												<div className="ideas-heading">
													<FormattedMessage id="Ideas" />
												</div>
												<Container>
													{!loadData ? (
														<PulseLoader color="#458ff6" size={8} />
													) : (
														<Swiper
															// spaceBetween={100}
															slidesPerView={1}
															slidesPerGroup={1}
															// loop
															navigation={{
																nextEl: "#swiperRight",
																prevEl: "#swiperLeft",
																disabledClass: "navigation-disabled",
															}}
															pagination={{
																clickable: true,
																el: "#swiperPagination",
																bulletClass: "bullet",
																bulletActiveClass: "bullet-active",
															}}
															autoplay={{
																delay: 3000,
																disableOnInteraction: false,
															}}
															centeredSlides={true}
															style={{ padding: "20px 0" }}
														>
															{Ideas.length ? (
																Ideas.map((idea, i) => {
																	return (
																		<SwiperSlide key={i}>
																			<IdeaCard
																				idea={idea}
																				style={{
																					width: bp.xl ? "80%" : "90%",
																				}}
																			/>
																		</SwiperSlide>
																	);
																})
															) : (
																<Empty
																	description={<FormattedMessage id="noIdea" />}
																/>
															)}
														</Swiper>
													)}
													<div className="swiper-end">
														<ChevronLeft id="swiperLeft" color="#5A98F2" />
														<div id="swiperPagination"></div>
														<ChevronRight id="swiperRight" color="#5A98F2" />
													</div>
												</Container>
											</div>
										</>
									) : (
										""
									)}
								</div>
							</Col>
							<Col
								style={{
									marginBottom: 30,
								}}
							>
								<div className="section experience-education">
									<div className="exp-edu-heading">
										<Briefcase color="#458ff6" />
										<span>Experiences:</span>
									</div>
									<div className="exps">
										{userProfile.experiences.length === 0 ? (
											<div className="no-request">
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description={<FormattedMessage id="noExperience" />}
												/>
											</div>
										) : (
											userProfile.experiences.map((item, key) => (
												<div className="exp" key={key}>
													<div className="org-img"></div>
													<div className="org-infos">
														<div className="post">{item.postTitle}</div>
														<div className="duration">
															{item.endDate === "until_now"
																? `In post from ${new Date(
																	item.startDate
																).getFullYear()}`
																: `from ${new Date(
																	item.startDate
																).getFullYear()} to ${new Date(
																	item.endDate
																).getFullYear()}`}
														</div>
														<div className="org">at {item.organization}</div>
													</div>
												</div>
											))
										)}
									</div>
									<div className="exp-edu-heading">
										<Book color="#458ff6" />
										<span>Education:</span>
									</div>
									<div className="exps">
										{userProfile.education.length === 0 ? (
											<div className="no-request">
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description={<FormattedMessage id="noEducation" />}
												/>
											</div>
										) : (
											userProfile.education.map((item, key) => (
												<div className="exp" key={key}>
													<div className="std-img"></div>
													<div className="org-infos">
														<div className="post">{item.fieldStudy}</div>
														<div className="duration">
															{item.endDate === "until_now"
																? `from ${new Date(
																	item.startDate
																).getFullYear()}`
																: `from ${new Date(
																	item.startDate
																).getFullYear()} to ${new Date(
																	item.endDate
																).getFullYear()}`}
														</div>
														<div className="org">at {item.school}</div>
													</div>
												</div>
											))
										)}
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				<Modal
					cancelText={<FormattedMessage id="cancel" />}
					visible={reporVisible}
					onCancel={() => setrReportVisible(false)}
					onOk={() => {
						sendReport()
					}}
					okText={<FormattedMessage id="send" />}
					width={700}
				>
					<Result className="vector-400" status="404" title={<FormattedMessage id="reportmsg" />} />
					<div>
						<Input.TextArea
							className="form-control"
							value={reportUserMessage}
							onChange={(e) => setreportUserMessage(e.target.value)}
						/>
					</div>
					{ErrReport ? (
						<Alert
							style={{ marginTop: "50px" }}
							type="error"
							description={ErrReport}
							closable
							onClose={() => setErrReport("")}
						/>
					) : (
						""
					)}
				</Modal>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		isAuth: state.auth.login.isAuth,
		user: state.auth.login.user,
	};
};

export default injectIntl(connect(mapStateToProps, { getAllIdeasWithUserId, reportUser })(Profile))
