import api from "./Api";

const Auth = {
  loginUser(form) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/login", form)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  registerUser(form) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users", form)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response)
            reject(err.response.data);
          else reject('error_1')
        });
    });
  },
  resendEmail(email) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/resend/verify", { email })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response)
            reject(err.response.data);
          else reject('error_1')
        });
    });
  },
  verifyEmail(token) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/verify", { token })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response)
            reject(err.response.data);
          else reject('error_1')
        });
    });
  },
  facebookRegister(userID, accessToken) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/fbregister", { userID, accessToken })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response)
            reject(err);
          else reject('error_1')
        });
    });
  },
  facebookLogin(userID, accessToken) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/fblogin", { userID, accessToken })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response)
            reject(err);
          else reject('error_1')
        });
    });
  },
  googleRegister(idToken) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/googleregister", { idToken })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response)
            reject(err);
          else reject('error_1')
        });
    });
  },
  googleLogin(idToken) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/googlelogin", { idToken })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response)
            reject(err);
          else reject('error_1')
        });
    });
  },
  linkedin(code, state) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/linkedin", { linkedin: { code, state } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response)
            reject(err);
          else reject('error_1')
        });
    });
  },
  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/forgot", { email })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response)
            reject(err);
          else reject('error_1')
        });
    });
  },
  resetPassword(token, password, password2) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/reset", { token, password, password2 })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response)
            reject(err);
          else reject('error_1')
        });
    });
  },
};

export default Auth;
