import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import "../../../sass/ManageTeam/ManageTeam.scss";
import TopBar from "../../../components/Ideas/TopBar";
import Heading from "../../../components/Heading";
import Requests from "../../../components/ManageTeam/Requests";
import Teams from "../../../components/ManageTeam/Teams";
import { FormattedMessage, injectIntl } from "react-intl";
import AutoComplete from "../../../components/autocomplete";
import classnames from "classnames";
import {
	getRequests,
	getUsers,
	sendRequest,
	getUsersbyName,
} from "../../../redux/actions/Manageteam/TeamActions";
import TeamsMethode from "../../../Helpers/ManageTeam";
import { Plus, X } from "react-feather";
import { Button, Input, Modal, notification } from "antd";
import { Spin } from "antd";
import { socket } from "../../../hooks/Socket";
import {
	useHistory,
	useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";

const ManageTeam = (props) => {
	const { intl } = props;
	const ideaId = props.match.params.id;
	const [idea_id, setIdea_is] = useState("");
	const [suggestions, setSugg] = useState([]);
	// eslint-disable-next-line
	const [Results, setResults] = useState([]);
	const [formModal, setFormModal] = useState(false);
	const [Position, setPosition] = useState("");
	const [userId, setUserId] = useState("");
	const [ObjectuserId, setObjectUserId] = useState("");
	const [refresh, setrefresh] = useState(false);
	const [spinReq, setspinReq] = useState(true);
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		///
		if (location?.state) setIdea_is(location.state.idea_id);
		if (
			ideaId.length < 36 ||
			_.isEmpty(location.state) ||
			!location.state.idea_id ||
			!props.match.params.id
		) {
			history.push("/app");
			notification["error"]({
				message: intl.formatMessage({ id: "error_text" }),
				description: intl.formatMessage({ id: "error_1" }),
			});
		}
		// eslint-disable-next-line
	}, [location]);
	useEffect(() => {
		document.title = intl.formatMessage({ id: "manageTeam" });
		props.getUsers().then((res) => {
			if (res && res.status === "success") {
				setResults(res.results.Users);
				TeamsMethode.getUsersArray(res.results).then((data) => {
					setSugg(data);
				});
			}
		});
		// eslint-disable-next-line
	}, []);
	const getUsersbyName = (name) => {
		props.getUsersbyName(name).then((res) => {
			if (res && res.status === "success") {
				setResults(res.results.Users);
				TeamsMethode.getUsersArray(res.results).then((data) => {
					setSugg(data);
				});
			}
		});
	};
	const sendRequest = () => {
		if (userId && Position) {
			const data = {
				ideaId: props.match.params.id,
				userId: userId,
				position: Position,
			};
			setspinReq(false);
			props.sendRequest(data)
				.then((res) => {
					if (res && res.status === "success") {
						notification["success"]({
							message: intl.formatMessage({ id: "success" }),
							description: intl.formatMessage({ id: res.msg }),
						});
						setPosition("");
						setFormModal(!formModal);
						setrefresh(true);
						setspinReq(true);
						let dta = [];
						dta = [...dta, ObjectuserId];
						socket.emit("new_notification", {
							event_type: "sent_request",
							received_by: dta,
						});
					} else {
						notification["error"]({
							message: intl.formatMessage({ id: "error_text" }),
							description: intl.formatMessage({ id: res.msg }),
						});
						setPosition("");
						setspinReq(true);
					}
				})
				.catch((err) => {
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: 'error_1' }),
					});
					setPosition("");
					setspinReq(true);
				});
		}
	};
	return (
		<div className="ManageTeam">
			<Container>
				<TopBar idea={ideaId ? ideaId : ""} ideaID={idea_id ? idea_id : ""} />
				<Row style={{ marginTop: 50 }}>
					<Col md={8} style={{ marginBottom: 30, height: "100%" }}>
						<div className="carta">
							<Heading
								color="#458ff6"
								text={<FormattedMessage id="addMembers" />}
							/>
							<FormattedMessage id="addMembers">
								{(msg) => (
									<AutoComplete
										suggestions={suggestions}
										className="form-control"
										filterKey="name"
										onChange={(e) => getUsersbyName(e.target.value)}
										customRender={(
											suggestion,
											i,
											filteredData,
											activeSuggestion,
											onSuggestionItemClick,
											onSuggestionItemHover
										) => {
											if (props.user._id !== suggestion._id) return (
												<li
													className={classnames("suggestion-item", {
														active:
															filteredData.indexOf(suggestion) ===
															activeSuggestion,
													})}
													key={i}
													onMouseEnter={() =>
														onSuggestionItemHover(
															filteredData.indexOf(suggestion)
														)
													}
												>
													<div className="user-info">
														<div
															style={{
																backgroundImage: `url('${suggestion.img}')`,
															}}
															alt={suggestion.name}
															className="user-image"
														></div>
														<span className="user-name">{suggestion.name}</span>
													</div>
													<Plus
														className={classnames("send-invite")}
														onClick={() => {
															setUserId(suggestion.userId);
															setObjectUserId(suggestion._id);

															setFormModal(!formModal);
														}}
													/>
												</li>
											)
											else return ''
										}}
									/>
								)}
							</FormattedMessage>
						</div>
						<div className="carta" style={{ marginTop: 30 }}>
							<Heading
								color="#458ff6"
								text={<FormattedMessage id="rolesAndPositions" />}
							/>
							<Teams id={ideaId} idea_id={idea_id ? idea_id : ""} />
						</div>
					</Col>
					<Col md={4} style={{ marginBottom: 30 }}>
						<div className="carta">
							<Heading
								color="#458ff6"
								text={<FormattedMessage id="editRequests" />}
							/>
							<Requests id={ideaId} refresh={refresh} setrefresh={setrefresh} />
						</div>
					</Col>
				</Row>
			</Container>
			<Modal
				cancelText={<FormattedMessage id="cancel" />}
				okText={<FormattedMessage id="ok" />}
				title={<FormattedMessage id="enterMemberPosition" />}
				visible={formModal}
				onCancel={() => setFormModal(false)}
				closeIcon={<X color="#FF0000" />}
				centered
				footer={
					spinReq ?
						<Button type="primary" onClick={() => sendRequest()}>
							<FormattedMessage id="sendRequest" />
						</Button> :
						<Button disabled>
							<Spin />
						</Button>
				}
				onOk={() => {
					setFormModal(!formModal);
				}}
			>
				<FormattedMessage id="enterMemberPosition">
					{(msg) => (
						<Input
							size="large"
							placeholder={msg}
							value={Position}
							onChange={(e) => setPosition(e.target.value)}
						/>
					)}
				</FormattedMessage>
			</Modal>
		</div >
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

export default injectIntl(
	connect(mapStateToProps, {
		getRequests,
		getUsers,
		sendRequest,
		getUsersbyName,
	})(ManageTeam)
);
