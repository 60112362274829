import axios from "axios";
import config from "../../Helpers/config";

const Api = () => {
  let data = {
    baseURL: `${config.socketLink}/wbs/v1`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const token = localStorage.getItem("AyHc7zSW7n6rI");
  if (token) data.headers["Authorization"] = "Bearer " + token;

  return axios.create(data);
};

export default Api;
