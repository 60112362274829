import Api from "./Api"

const ProcessInfo = {
    getALLProcess() {
        return new Promise((resolve, reject) => {
            Api().get("/processes/search")
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        })
    },
    getProcessById(id) {
        return new Promise((resolve, reject) => {
            Api().get(`/processes/by/${id}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        })
    }
}

export default ProcessInfo