import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CheckCircle, Edit2, User, XCircle } from "react-feather";
import { Button, notification } from "antd";
import {
	CancelSlotMember,
	CreateSessionFeedback,
	CancelSlotOwner,
	ConfirmSlot,
} from "../../redux/actions/Sessions/SessionsActions";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { socket } from "../../hooks/Socket";
import NotifyMethods from "../../Helpers/Notifications";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

import { CreateSessionConversation } from "../../redux/actions/Communications/CommActions";

const MySwal = withReactContent(Swal);

const SessionStatus = (props) => {
	const bp = useBreakpoint();
	const { intl } = props;
	const { status, setModalVisible, session, setSelectedSession } = props;
	const [feedbacked, setFeedbacked] = useState(false);
	useEffect(() => {
		props.user.account_type === "idea_holder"
			? session.isTeamFeedbacked
				? setFeedbacked(true)
				: setFeedbacked(false)
			: session.isMentorFeedbacked
				? setFeedbacked(true)
				: setFeedbacked(false);
		// eslint-disable-next-line
	}, [session]);
	// Cancel Slot of Session as an Idea Holder
	const CancelSlot = () => {
		const data = {
			id: session.sessionId,
			ideaId: session.session_idea.ideaId,
		};
		MySwal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				props.CancelSlotMember(data).then((res) => {
					if (res.status === "success") {
						let dta = [];
						// eslint-disable-next-line
						dta = [...dta, res.result.session_owner];
						socket.emit("new_notification", {
							event_type: "cancel_session_team",
							received_by: res.result.session_owner,
							sessionId: res.result.sessionId,
						});
						MySwal.fire(
							"Deleted!",
							"Session Destroyed with success.",
							"success"
						);
						props.getSessionAsMember();
					} else if (res.en)
						notification["error"]({
							message: intl.formatMessage({ id: "error_text" }),
							description: intl.formatMessage({ id: res.en }),
						});
				});
			}
		});
	};
	// Cancel Slot of Session as an Owner
	const CancelSlotOwner = () => {
		MySwal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				props.CancelSlotOwner(session.sessionId).then((res) => {
					if (res.status === "success") {
						MySwal.fire(
							"Deleted!",
							"Session Destroyed with success.",
							"success"
						);
						NotifyMethods.getUsersIds(session.session_idea.members).then(
							(data) => {
								socket.emit("new_notification", {
									event_type: "cancel_session_mentor",
									received_by: data,
									sessionId: res.result.sessionId,
								});
							}
						);

						props.getMySession();
					} else if (res.en)
						notification["error"]({
							message: intl.formatMessage({ id: "error_text" }),
							description: intl.formatMessage({ id: res.en }),
						});
				});
			}
		});
	};

	// Confirm Slot of Session by Owner
	const ConfirmSlot = () => {
		props.ConfirmSlot(session.sessionId).then((res) => {
			if (res.status === "success") {
				props.CreateSessionConversation(session._id).then((res) => {
				});
				MySwal.fire(
					"Confirmed !",
					"Session Confirmed with success.",
					"success"
				);
				NotifyMethods.getUsersIds(session.session_idea.members).then((data) => {
					socket.emit("new_notification", {
						event_type: "confirm_session_mentor",
						received_by: data,
						sessionId: res.result.sessionId,
					});
				});

				props.getSessionAsMember();
				props.getMySession();
			} else if (res.en) {
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: res.en }),
				});
			}
		});
	};
	return (
		<div className="session-item status">
			<div className="heading">
				<FormattedMessage id="status" />
			</div>
			{status === "Waiting" ? (
				<>
					<div className="status-status">
						<FormattedMessage id="Waiting" />
					</div>
					{props.user.account_type !== "idea_holder" ? (
						<div
							className="accept"
							style={{
								flexWrap: bp.md && !bp.lg ? "wrap-reverse" : "nowrap",
								justifyContent: bp.md && !bp.lg ? "center" : "space-between",
								width: bp.xs ? "60%" : "100%",
							}}
						>
							<div className="accept-button cancel">
								<XCircle
									color="#FF0000"
									size={20}
									strokeWidth={0.5}
									onClick={() => CancelSlotOwner()}
								/>
								<span>
									<FormattedMessage id="cancel" />
								</span>
							</div>
							<div className="accept-button">
								<CheckCircle
									color="#28a745"
									size={20}
									strokeWidth={0.5}
									onClick={() => ConfirmSlot()}
								/>
								<span>
									<FormattedMessage id="accept" />
								</span>
							</div>
						</div>
					) : (
						<div className="cancel">
							<XCircle
								color="#FF0000"
								size={20}
								strokeWidth={0.5}
								onClick={() => {
									CancelSlot();
								}}
							/>
							<span>
								<FormattedMessage id="cancel" />
							</span>
						</div>
					)}
				</>
			) : status === "Confirmed" ? (
				<div className="session-item status">
					<div className="status-status">
						<FormattedMessage id="Confirmed" />
					</div>
					<div className="confirmed-text">
						Lorem ipsum dolor sit amet sit amet
					</div>
				</div>
			) : status === "Confirmed" ? (
				<div className="session-item status">
					<div className="status-status">
						<FormattedMessage id="Confirmed" />
					</div>
					<div className="confirmed-text">
						Lorem ipsum dolor sit amet sit amet
					</div>
				</div>
			) : status === "Canceled" ? (
				<div className="session-item status">
					<div className="status-status">
						<FormattedMessage id="Canceled" />
					</div>
					<div className="confirmed-text">
						Lorem ipsum dolor sit amet sit amet
					</div>
				</div>
			) : status === "Finished" ? (
				<>
					<div className="status-status">
						<FormattedMessage id="finished" />
					</div>
					{User.account_type === "idea_holder" && <div className="feedback">
						<Button
							shape="round"
							type="primary"
							disabled={feedbacked}
							className="feedback-btn"
							icon={
								<Edit2
									size={10}
									style={{
										margin: "0 5px",
									}}
								/>
							}
							onClick={() => {
								setModalVisible(true);
								setSelectedSession(session);
							}}
						>
							<FormattedMessage id="feedback" />
						</Button>
						<span>
							<FormattedMessage id="addFeedback" />
						</span>
					</div>}
				</>
			) : (
				""
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	User: state.auth.login.user,
});

const mapDispatchToProps = {
	CancelSlotMember,
	CreateSessionFeedback,
	CancelSlotOwner,
	ConfirmSlot,
	CreateSessionConversation,
};

export default injectIntl(
	connect(mapStateToProps, mapDispatchToProps)(SessionStatus)
);
