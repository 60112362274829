import React, { useEffect, useState } from "react";
import "../../sass/Auth.scss";
import logo from "../../assets/logo.png";
import { ReactComponent as Shape } from "../../assets/Auth/shape.svg";
import { NavLink } from "react-router-dom";
import Heading from "../../components/Heading";
import { Container, Row, Col, Button } from "reactstrap";
import QueryString from "query-string";
import microsoftimg from "../../assets/microsoft-logo.png"
import { AlertCircle, Facebook, Instagram, Linkedin, Twitter, Youtube } from "react-feather";
import {
	loginWithEmailOrUsername,
	facebookLogin,
	googleLogin,
	linkedin,
} from "../../redux/actions/auth/loginActions";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import LanguageDropdown from "../../components/Languages";

const Login = (props) => {
	const { intl } = props
	const [errorSaml, setErrorSaml] = useState("")

	useEffect(() => {
		const params = QueryString.parse("error")
		if (params) setErrorSaml(params)
		document.title = intl.formatMessage({ id: "Login" })
		// eslint-disable-next-line 
	}, [])

	return (
		<div className="auth-page">
			<div className="logo-container">
				<NavLink to="/">
					<img src={logo} alt="" className="leafunder-logo" style={{ height: "60px", marginTop: "5px" }} />
				</NavLink>
			</div>
			<div className="form-container">
				<Container>
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col md="6">
							<div className="form-card">
								<LanguageDropdown />
								<Shape className="shape" />
								<Heading text={<FormattedMessage id="LoginTitle" />} />
								{/* <div className="form-errors">
									<div className="form-error">
										<AlertCircle className="form-error-icon" /> {errorSaml}
									</div>
								</div> */}
								<form action="https://leafunder.ofppt.ma/api/login-idp/callback" method="post">
									<Button
										className="button google button-primary-stroke button-submit"
										type="submit"
										id="signinButton"
									>
										<img src={microsoftimg} alt="microsoft-logo" />
										<FormattedMessage id="LoginBtn" />
									</Button>
								</form>
							</div>
							<div className="footer_">
								<p><FormattedMessage id="socials" /></p>
								<div className="socials">
									<a href="https://www.facebook.com/ofppt.page.officielle" rel="noreferrer" target="__blank">
										<Facebook className="social-icon" />
									</a>
									<a href="https://www.youtube.com/c/OFPPTchaineofficielle" rel="noreferrer" target="__blank">
										<Youtube className="social-icon" />
									</a>
									<a href="https://www.linkedin.com/company/ofpptpageofficielle" rel="noreferrer" target="__blank">
										<Linkedin className="social-icon" />
									</a>
									<a href="https://www.instagram.com/ofppt.officiel/" rel="noreferrer" target="__blank">
										<Instagram className="social-icon" />
									</a>
									<a href="https://twitter.com/OFPPT_Officiel" rel="noreferrer" target="__blank">
										<Twitter className="social-icon" />
									</a>
								</div>
							</div>
						</Col>
					</Row>
					{/* <div className="copyright">©Leafunder 2020. All rights reserved</div> */}
				</Container>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	values: state.auth.login,
});

export default injectIntl(connect(mapStateToProps, {
	loginWithEmailOrUsername,
	facebookLogin,
	googleLogin,
	linkedin,
})(Login));

