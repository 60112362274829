import { Button, notification, Select, Table, Form } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Container } from "reactstrap";
import Heading from "../../../components/Heading";
import "../../../sass/ManageTeam/Invites.scss";
import {
	getMyRequests,
	acceptRequests,
	rejectRequests,
} from "../../../redux/actions/Manageteam/TeamActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import UserMethods from "../../../Helpers/UserHelpers";
import { socket } from "../../../hooks/Socket";
import {
	CreateTeamConversation,
} from "../../../redux/actions/Communications/CommActions";


const Invites = (props) => {
	const { intl } = props;
	const history = useHistory();
	const timezone = moment.tz.guess();
	const [data, setData] = useState([]);
	const [loaddata, setloaddata] = useState(false);
	// eslint-disable-next-line
	const [Action, setAction] = useState("");
	// eslint-disable-next-line
	const [ideaId, setIdeaId] = useState("");
	// eslint-disable-next-line
	const [sender, setsender] = useState("");
	const [form] = Form.useForm();

	useEffect(() => {
		document.title = intl.formatMessage({ id: "invites" });
		setData([]);
		getMyRequests();
		// eslint-disable-next-line
	}, []);
	const getMyRequests = () => {
		props.getMyRequests().then((res) => {
			if (res.status === "success") {
				res.result.Invites
					&& res.result.Invites.map((invite, i) => {
						setData([]);
						if (invite.status === "Pending") {
							setData([
								...data,
								{
									key: "1",
									username: invite.sender,
									role: "Member",
									idea: invite.idea_info,
									action: { idea: invite.idea_info, sender: invite.sender },
									position: invite.position,
									date: invite.invited_date,
								},
							]);
						}
						setloaddata(true);
						return ''
					})
				
			} else {
				setData([]);
				setloaddata(true);
			}
		});
	};

	useEffect(() => { }, [Action]);

	const change = async (action) => {
		setIdeaId(action.idea.ideaId);
		setsender(action.sender._id);
		if (
			form.getFieldValue("Action") === "confirm" &&
			action.idea.ideaId &&
			action.sender._id
		) {
			props.acceptRequests(action.idea.ideaId).then((res) => {
				if (res.status === "success") {
					getMyRequests();
					props.CreateTeamConversation(action.idea._id);
					notification["success"]({
						message: intl.formatMessage({ id: "success" }),
						// description: intl.formatMessage({ id: res.status }),
					});
					let dta = [];
					dta = [...dta, action.sender._id];
					socket.emit("new_notification", {
						event_type: "confirm_request",
						received_by: dta,
					});
					getMyRequests();
				} else
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: res.msg }),
					});
			});
		}
		if (
			form.getFieldValue("Action") === "cancel" &&
			action.idea.ideaId &&
			action.sender._id
		) {
			props.rejectRequests(action.idea.ideaId).then((res) => {
				if (res.status === "success") {
					getMyRequests();
					notification["success"]({
						message: intl.formatMessage({ id: "success" }),
						// description: intl.formatMessage({ id: res.status }),
					});
					let dta = [];
					dta = [...dta, action.sender._id];
					socket.emit("new_notification", {
						event_type: "cancel_request",
						received_by: dta,
					});
					getMyRequests();
				} else
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: res.msg }),
					});
			});
		}
	};
	const columns = [
		{
			title: <FormattedMessage id="Username" />,
			dataIndex: "username",
			key: "username",
			render: (text) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<div style={{ padding: "10px" }}>
							<Avatar
								src={text.avatar ? UserMethods.getUserImage(text.userId, text.avatar) : UserMethods.getUserAvatar()}
							/>
						</div>
						<div style={{ padding: "10px" }}>{text.username}</div>
					</div>
					<div style={{ padding: "10px" }}>
						<Button onClick={() => history.push(`/app/profile/${text.userId}`)}>
							<FormattedMessage id="seeProfile" />
						</Button>
					</div>
				</div>
			),
			width: 250,
		},
		{
			title: <FormattedMessage id="Idea" />,
			dataIndex: "idea",
			key: "idea",
			render: (text) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<div style={{ padding: "10px" }}>{text.title}</div>
					</div>
					<div style={{ padding: "10px" }}>
						{text.shortDescription.length >= 10
							? text.shortDescription.substr(0.1)
							: text.shortDescription}
					</div>
					<div style={{ padding: "10px" }}>
						<Button onClick={() => history.push(`/app/ideas/${text.ideaId}`)}>
							<FormattedMessage id="seeIdea" />
						</Button>
					</div>
				</div>
			),
			width: 250,
		},
		{
			title: <FormattedMessage id="Role" />,
			dataIndex: "role",
			key: "role",
			ellipsis: true,
			width: 100,
		},
		{
			title: <FormattedMessage id="Position" />,
			dataIndex: "position",
			key: "position",
			ellipsis: true,
			width: 150,
		},
		{
			title: <FormattedMessage id="Date" />,
			dataIndex: "date",
			key: "date",
			ellipsis: true,
			render: (text) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					{" "}
					<p>{moment(text).clone().tz(timezone).format("LL")}</p>
					<p>{moment(text).clone().tz(timezone).format("HH:MM A")}</p>
				</div>
			),
		},
		{
			title: <FormattedMessage id="Actions" />,
			dataIndex: "action",
			key: "action",
			fixed: "right",
			width: 150,
			render: (action) => (
				<Form
					form={form}
					onValuesChange={() => {
						change(action);
					}}
				>
					<Form.Item name="Action">
						<Select
							placeholder={<FormattedMessage id="pending" />}
							style={{ width: "100%" }}
							allowClear
						>
							<Select.Option value="confirm">
								<FormattedMessage id="confirm" />
							</Select.Option>
							<Select.Option value="cancel">
								<FormattedMessage id="cancel" />
							</Select.Option>
						</Select>
					</Form.Item>
				</Form>
			),
		},
	];

	return (
		<div className="invites">
			<Container>
				<Heading text={<FormattedMessage id="invites" />} />
				{/* <Empty /> */}
				{loaddata ? (
					<Table
						scroll={{ x: 500 }}
						bordered
						style={{ borderRadius: "5px" }}
						columns={columns}
						dataSource={data}
					/>
				) : (
					""
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
	getMyRequests,
	acceptRequests,
	rejectRequests,
	CreateTeamConversation,

};
export default injectIntl(
	connect(mapStateToProps, mapDispatchToProps)(Invites)
);
