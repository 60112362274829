import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Heading from "../../../components/Heading";
import { FormattedMessage } from "react-intl";
import IdeaCard from "../../../components/Ideas/IdeaCard";
import { getMyIdeas } from "../../../redux/actions/Ideas/IdeasActions";
import { connect } from "react-redux";
import "../../../sass/Ideas/myIdeas.scss";
import { PulseLoader } from "react-spinners";
import { Empty } from "antd";
import { injectIntl } from "react-intl";

const MyIdeas = (props) => {
	const { intl } = props;
	const [ideas, setIdeas] = useState([]);
	// eslint-disable-next-line
	const [result, setResult] = useState([]);
	const [noResult, setNoResult] = useState(false);
	const [load, setLoad] = useState(true);
	useEffect(() => {
		document.title = intl.formatMessage({ id: "MyIdeas" });
		props.getMyIdeas().then((res) => {
			setLoad(false);
			if (res.status === "success") {
				setResult(res.result);
				setIdeas(res.result.Ideas);
			}
			else {
				setNoResult(true)
			}
		});
		// eslint-disable-next-line
	}, []);
	return (
		<div className="myIdeas">
			<Heading text={<FormattedMessage id="MyIdeas" />} />
			<Container>
				{!load ? (
					<Row style={{ justifyContent: "center" }} md={2} lg={3} sm={1} xs={1}>
						{ideas.length > 0 ? (
							ideas.map((idea, i) => {
								return (
									<Col style={{ marginTop: 20 }} key={i}>
										<IdeaCard idea={idea} user={props.user} />
									</Col>
								);
							})
						) : noResult ? (
							<Empty description={<FormattedMessage id="error_73" />} />
						) : (
							<Empty description={<FormattedMessage id="error_73" />} />
						)}
					</Row>
				) : (
					<div className="bolisi">
						<PulseLoader size={10} color="#458ff6" />
					</div>
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

export default injectIntl(connect(mapStateToProps, { getMyIdeas })(MyIdeas));
