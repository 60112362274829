import api from "./Api";
import config from "../Helpers/config";

const User = {
  get() {
    return new Promise((resolve, reject) => {
      api()
        .get("/users/me")
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  getOneUser({ id }) {
    return new Promise((resolve, reject) => {
      api()
        .get(`/users/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  completeProfile(data) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/infos", data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  editProfile(data) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/edit", data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  changePassword(data) {
    return new Promise((resolve, reject) => {
      api()
        .post("/users/edit/password", data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  avatarLink({ avatar, userId }) {
    let sublink = "user_avatar.jpg";
    if (!avatar || !userId) return `${config.serverLink}/api/userImgs/${sublink}`;
    else if (avatar !== sublink) sublink = `${userId}/${avatar}`;
    return `${config.serverLink}/api/userImgs/${sublink}`;
  },
  reportUser(data) {
    return new Promise((resolve, reject) => {
      api().post(`/reports/user/${data.userId}`, { comment: data.comment })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
    })
  }
};

export default User;
