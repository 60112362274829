import { Alert, Col, Empty, Input, Row } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import classNames from "classnames";
import React, { useContext, useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, Search, Video, Clock } from "react-feather";
import { FormattedMessage } from "react-intl";
import { Container } from "reactstrap";
import { IntlContext } from "../../context/internationalization";
import IdeasMethods from "../../Helpers/IdeaHelpers";
import UserMethods from "../../Helpers/UserHelpers";
import UserService from "../../services/user";
import "../../sass/ChatApp/Message.scss";
import { Download } from "react-feather";
import Modal from "antd/lib/modal/Modal";
import { Tooltip, Button } from "antd";
import moment from "moment-timezone";
import { CreateZoomMeeting, sendZoomMeeting } from "../../redux/actions/Communications/CommActions"
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import config from '../../Helpers/config'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const socketLink = config.socketLink
const zoomLink = config.zoomLink
const REACT_APP_ZOOM_API_KEY = "UzYGzAwrRYuEg2PoEd_yJg"
const REACT_APP_ZOOM_REDIRECT_URL = `${zoomLink}/app/redzoom`

const MySwal = withReactContent(Swal);
const FileMessage = (props) => {
	const bp = useBreakpoint();

	return (
		<a
			href={`${socketLink}/${props.file?.link}`}
			target="_blank"
			rel="noreferrer"
			download
		>
			<div className="file-message-wrapper" >
				{props.text !== "" ? (
					<div className="text-with-file">{props.text}</div>
				) : (
					""
				)}
				<div
					className="file-message"
					style={{
						flexWrap: bp.xs ? "wrap" : "nowrap",
						textAlign: bp.xs ? "center" : "unset",
					}}
				>
					<div
						className={classNames("file-type", {
							"file-type-pdf": props.file?.ext?.toLowerCase() === "pdf",
							"file-type-doc":
								(props.file && props.file?.ext?.toLowerCase() === "doc") ||
								(props.file && props.file?.ext?.toLowerCase() === "docx"),
							"file-type-ppt":
								(props.file && props.file?.ext?.toLowerCase() === "ppt") ||
								(props.file && props.file?.ext?.toLowerCase() === "pptx"),
							"file-type-xls":
								(props.file && props.file?.ext?.toLowerCase() === "xls") ||
								(props.file && props.file?.ext?.toLowerCase() === "xlsx"),
							"file-type-txt": props.file && props.file?.ext?.toLowerCase() === "txt",
						})}
					>
						{props.file?.ext}
					</div>
					<div
						className="file-name-size"
						style={{
							marginTop: bp.xs ? 10 : 0,
						}}
					>
						<div className="name">
							{props.file?.name?.length > 25
								? props.file?.name?.substr(0, 25) + "..."
								: props.file?.name}
						</div>
						<div className="size">
							{props.file?.size < 1000
								? props.file?.size + "b"
								: props.file?.size < 1000000
									? Math.round(props.file?.size / 1000) + "Kb"
									: Math.round(props.file?.size / 1000000) + "Mb"}
						</div>
					</div>
					<div className="download-btn">
						<a
							href={`${socketLink}/${props.file?.link}`}
							target="_blank"
							rel="noreferrer"
							download
						>
							<Download size={15} />
						</a>
					</div>
				</div>
			</div>
		</a>
	);
};

const ImageMessage = (props) => {
	const bp = useBreakpoint();
	return (
		<div className="image-message-wrapper">
			{props.text !== "" ? (
				<div className="text-with-image">{props.text}</div>
			) : (
				""
			)}
			<div
				className="image-message"
				style={{
					backgroundImage: `url('${socketLink}/${props.image}')`,
					width: bp.xl ? 200 : bp.lg ? 123 : 100,
					height: bp.xl ? 200 : bp.lg ? 123 : 100,
				}}
			></div>
		</div>
	);
};
const TaskMessage = (props) => {
	const bp = useBreakpoint();
	const { task } = props;


	return (
		<div className="task-message-wrapper">
			{props.text !== "" ? (
				<div className="text-with-task">{props.text ? props.text : ""}</div>
			) : (
				""
			)}
			<div className="task-message">
				{/* <div className="task-checkbox">
					<Checkbox checked={task.status === "finished"} />
				</div> */}
				<div
					className={classNames("task-infos", {
						"task-infos-pending": task.status === "pending",
						"task-infos-finished": task.status === "finished",
					})}
				>
					<div className="task-title">{task.title ? task.title : ""}</div>
					<div className="task-desc">{task.description ? task.description : ""}</div>
					<div
						className="task-footer"
						style={{
							flexWrap: bp.xs ? "wrap" : "nowrap",
							justifyContent: bp.xs ? "center" : "space-between",
						}}
					>
						<Row>
							<Col>
								<div className="task-user">
									<Avatar
										src={UserService.avatarLink({
											avatar: task.assigned_user.avatar,
											userId: task.assigned_user.userId,
										})}
										size="small"
									/>
									<div>{`${task.assigned_user.firstname} ${task.assigned_user.lastname}`}</div>
								</div>
							</Col>

							<Col>
								<div className="task-deadline">
									<Clock size={15} style={{ margin: "0 3px" }} />{" "}
									<FormattedMessage id="deadline" />{" "}
									<Tooltip title={task.deadline ? moment(task.deadline).format("YYYY-MM-DD") : ""}>
										{task.deadline ? moment(task.deadline).format("YYYY-MM-DD") : ""}
									</Tooltip>
								</div>

							</Col>

						</Row>
					</div>
				</div>
			</div>
		</div>
	);
};

const RecipientProfile = (props) => {
	const { state } = useContext(IntlContext);
	const [showSearch, setshowSearch] = useState(false);
	const [SearchInput, setSearchInput] = useState("");
	const [files, setFiles] = useState([])
	const [isFiles, setisFiles] = useState(false)
	const [images, setimages] = useState([])
	const [tasks, setTasks] = useState([])
	const [isTasks, setisTasks] = useState(false)
	const [isImages, setisImages] = useState(false)
	const history = useHistory();
	const [pdpPreview, setPdpPreview] = useState({
		previewVisible: false,
		previewImage: "",
		previewTitle: "",
	});
	const [createModalVisible, setCreateModalVisible] = useState(false)
	const [zoomLoadings, setZoomLoadings] = useState(false)
	const [zoomERR, setzoomERR] = useState("")
	const timezone = moment.tz.guess();

	const handlePostMessage = (e) => {
		if (e.data.type === "response") {
			const resp = e.data.response;
			if (resp.code) {
				const create = {
					topic: "Meeting at " + moment()
						.clone()
						.tz(timezone)
						.format("DD-MM-YY HH:MM A"),
					code: resp.code,
				}
				props.CreateZoomMeeting(create)
					.then(res => {
						if (res.status === "success") {
							setZoomLoadings(false)

							const data = {
								meeting: res.result?._id,
								convId: props.recipient._id
							}
							props.sendZoomMeeting(data)
								.then(result => {
									setCreateModalVisible(false)
									MySwal.fire(
										"Created!",
										"Your Zoom meeting created with success.",
										"success"
									)

								})
								.catch((err) => { history.push("/500") });
						}
						else if (res.status === "failure") {
							setZoomLoadings(false)
							setzoomERR(res.msg)
						}
					})
					.catch((err) => { history.push("/500") });
			}
		}
	}
	useEffect(() => {
		window.addEventListener("message", handlePostMessage);
		// eslint-disable-next-line
	}, [])

	const getsearchedValues = (value) => {
		setSearchInput(value);
		props.SearchInMessages(value);
	};
	const getImageConvs = () => {
		props.getImageConvs()
			.then(res => {
				if (res.status === "success")
					setimages(res.result.Messages)
			})
			.catch((err) => { history.push("/500") });
	};
	const getDocsConvs = () => {
		props.getDocsConvs()
			.then(res => {
				if (res.status === "success")
					setFiles(res.result.Messages)
			})
			.catch((err) => { history.push("/500") });
	};
	const getTaskConvs = () => {
		props.getTaskConvs()
			.then(res => {
				if (res.status === "success")
					setTasks(res.result.Tasks)
			})
			.catch((err) => { history.push("/500") });
	}
	const openZoomWindow = () => {
		setZoomLoadings(true)
		const oauthUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${REACT_APP_ZOOM_API_KEY}&redirect_uri=${REACT_APP_ZOOM_REDIRECT_URL}`
		const width = 580,
			height = 630,
			left = window.screen.width / 2 - width / 2,
			top = window.screen.height / 2 - height / 2;

		window.open(
			oauthUrl,
			"Zoom",
			"menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
			width +
			", height=" +
			height +
			", top=" +
			top +
			", left=" +
			left
		);
	}
	return (
		<div className="carta recipient-profile">
			{props.md ? (
				<div
					className="recipient-profile__go-back"
					onClick={() => {
						props.setShowProfile(false);
					}}
				>
					{state.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
				</div>
			) : (
				""
			)}
			{props.type === "teams" ? (
				<Avatar
					className="avatar"
					src={
						props.recipient.idea &&
							props.recipient.idea.ideaId &&
							props.recipient.idea.images[0]
							? IdeasMethods.getIdeaImage(
								props.recipient.idea.ideaId,
								props.recipient.idea.images[0]
							)
							: IdeasMethods.getIdeaAvatar()
					}
				>
					{IdeasMethods.getIdeaAvatar()}
				</Avatar>
			) : props.type === "sessions" ? (
				<Avatar
					className="avatar"
					src={
						props.recipient.session_owner &&
							props.recipient.session_owner.userId &&
							props.recipient.session_owner.avatar
							? UserMethods.getUserImage(
								props.recipient.session_owner.userId,
								props.recipient.session_owner.avatar
							)
							: UserMethods.getUserAvatar()
					}
				>
					{UserMethods.getUserAvatar()}
				</Avatar>
			) : (
				<div
					className="avatar"
					style={{
						backgroundImage: `url('${UserService.avatarLink({
							avatar: props.userdm
								? props.userdm?.avatar
								: props.recipient.avatar,
							userId: props.userdm
								? props.userdm?.userId
								: props.recipient.userId,
						})}')`,
					}}
				></div>
			)}
			<div className="name-position">
				<div className="name" style={{ textTransform: "capitalize" }}>
					{props.type === "teams" ? (
						<>
							<FormattedMessage id="teamOf" />{" "}
							<span>
								{props.recipient.idea ? props.recipient.idea.title : ""}
							</span>
						</>
					) : props.type === "sessions" ? (
						props.recipient?.session_owner &&
							props.recipient.session_owner?.firstname ? (
							`${props.recipient.session_owner.firstname} ${props.recipient.session_owner.lastname}`
						) : (
							""
						)
					) : props.userdm ? (
						`${props.userdm?.firstname} ${props.userdm?.lastname}`
					) : (
						`${props.recipient?.firstname} ${props.recipient?.lastname}`
					)}
				</div>
				<div className="position">
					{" "}
					{props.type === "sessions" ? "mentor" : ""}
				</div>
			</div>
			<div className="search-zoom">
				<div className="buttona" onClick={() => setshowSearch(!showSearch)}>
					<div className="icon">
						<Search />
					</div>
					<div className="text">
						<FormattedMessage id="searchConversation" />
					</div>
				</div>
				<div className="buttona" onClick={() => setCreateModalVisible(true)}>
					<div className="icon">
						<Video />
					</div>
					<div className="text">
						<FormattedMessage id="zoomMeeting" />
					</div>
				</div>
				<Modal
					title={<FormattedMessage id="createZoom" />}
					visible={createModalVisible}
					okText={<FormattedMessage id="create" />}
					cancelText={<FormattedMessage id="cancel" />}
					onCancel={() => setCreateModalVisible(false)}
					onOk={openZoomWindow}
					footer={[
						<Button key="back" onClick={() => {
							setCreateModalVisible(false)
							setZoomLoadings(false)
						}}>
							<FormattedMessage id="cancel" />
						</Button>,
						<Button key="submit" type="primary" loading={zoomLoadings} onClick={() => openZoomWindow()}>
							<FormattedMessage id="create" />
						</Button>,
					]}
				>
					{zoomERR && <Alert type="error" message={<FormattedMessage id={zoomERR} />} closable />}
					<div style={{ marginTop: "10px" }}>
						<FormattedMessage id="createZoomDesc" />
					</div>
				</Modal>

			</div>
			{showSearch ? (
				<div className="search_input">
					<Input
						value={SearchInput}
						placeholder="Search in messages"
						onChange={(e) => getsearchedValues(e.target.value)}
					/>
				</div>
			) : (
				""
			)}
			<div className="docs-links">
				{props.type === "teams" ? <div className="ptn" onClick={() => {
					setisTasks(!isTasks)
					setisImages(false)
					setisFiles(false)
					getTaskConvs()
				}}>
					<FormattedMessage id="task" />
				</div> : ""}
				<div className="ptn" onClick={() => {
					setisTasks(false)
					setisImages(false)
					setisFiles(!isFiles)
					getDocsConvs()
				}}>
					<FormattedMessage id="docs" />
				</div>
				<div className="ptn" onClick={() => {
					setisTasks(false)
					setisFiles(false)
					setisImages(!isImages)
					getImageConvs()
				}}>
					<FormattedMessage id="images" />
				</div>

			</div>
			{isFiles ? files?.length > 0 ? <div className="message-images" style={{ height: "160px", overflow: "auto", flexDirection: "column", marginTop: "10px" }}>
				{files && files.map((file, i) => {
					return (
						<Row key={i} >
							<FileMessage file={
								file.file_path
									? {
										name: file.file_original_name
											? file.file_original_name
											: "",
										link: file.file_path,
										ext: file.file_path.split(".").pop(),
										size: file.file_size ? file.file_size : 0,
									}
									: {}
							} />

						</Row>
					)
				})}
			</div> : <Empty description={<FormattedMessage id="noFiles" />} /> : ""}
			{isImages ? images?.length > 0 ? <div className="message-images" style={{ height: "300px", overflow: "auto", flexDirection: "column", marginTop: "10px", marginLeft: "-12px" }}>
				<Container style={{ marginTop: "10px" }}>
					{images.map((img, i) => {
						return (
							<Row xs="2" key={i} style={{ cursor: "pointer" }} onClick={() => {
								setPdpPreview((x) => ({ ...x, previewVisible: true, previewImage: img.file_path }))
							}}>
								<ImageMessage image={img.file_path} />
							</Row>
						)
					})}
					<Modal
						visible={pdpPreview.previewVisible}
						title={pdpPreview.previewTitle}
						footer={null}
						cancelText={<FormattedMessage id="cancel" />}
						okText={<FormattedMessage id="ok" />}
						onCancel={() => {
							setPdpPreview((x) => ({
								...x,
								previewVisible: false,
							}));
						}}
					>
						<img
							alt="example"
							style={{ width: "100%" }}
							src={`${socketLink}/${pdpPreview.previewImage}`}
						/>
					</Modal>
				</Container>
			</div> : <Empty description={<FormattedMessage id="noImages" />} /> : ""}
			{
				isTasks ? tasks?.length > 0 ? <div className="message-images" style={{ height: "300px", overflow: "auto", flexDirection: "column", marginTop: "10px", marginLeft: "-12px" }}>
					<Container style={{ marginTop: "10px" }}>
						{tasks.map((tsk, i) => {
							return (
								<Row xs="2" key={i} style={{ cursor: "pointer" }}>
									<TaskMessage task={tsk} />
								</Row>
							)
						})}
					</Container>
				</div>
					: <Empty description={<FormattedMessage id="noTasks" />} /> : ""}



		</div>
	);
};

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { CreateZoomMeeting, sendZoomMeeting })(RecipientProfile);
