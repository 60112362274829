import React, { useContext } from "react";
import { connect } from "react-redux";
import "../../sass/Profile/ProfileCard.scss";
import { ReactComponent as Shape } from "../../assets/App/Profiles/ProfileCardSVG.svg";
import { Heart, MapPin, ArrowRight } from "react-feather";
import { IntlContext } from "../../context/internationalization";
import { FormattedMessage } from "react-intl";
import User from "../../services/user";
import { SendOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

export const ProfileCard = (props) => {
	const context = useContext(IntlContext);
	const history = useHistory();
	const { locale } = context.state;
	const user = props.user;
	return (
		<div className="profile-card">
			<div
				className="profile-card-filler"
				style={{
					backgroundColor:
						user.account_type === "idea_holder" ? "#436EDE" : "#8F36FF",
				}}
			></div>
			<Shape
				className="profile-card-filler-shape"
				style={{
					fill: user.account_type === "idea_holder" ? "#436EDE" : "#8F36FF",
				}}
			/>

			<div className="user-name-follow">
				<span className="name">{`${user.firstname} ${user.lastname}`}</span>
				<Heart className="follow" size={20} style={{ cursor: "pointer" }} />
			</div>
			<div className="user-location">
				<MapPin size={12} />
				<span>
					{locale === "ar"
						? user.address.city.label_ar
						: locale === "fr"
							? user.address.city.label_fr
							: user.address.city.label}
				</span>
				<span> - </span>
				<span>
					{locale === "ar"
						? user.address.country.label_ar
						: locale === "fr"
							? user.address.country.label_fr
							: user.address.country.label}
				</span>
			</div>
			<div className="user-avatar-send">
				<div className="avatar-side">
					<div
						className="avatar"
						style={{
							backgroundImage: `url('${User.avatarLink({
								avatar: user.avatar,
								userId: user.userId,
							})}')`,
						}}
					></div>
					<div
						className="account-type"
						style={{
							backgroundColor:
								user.account_type === "idea_holder" ? "#436EDE" : "#8F36FF",
						}}
					>
						<FormattedMessage id={user.account_type} />
					</div>
				</div>
				<div className="send-side">
					<div
						className="send"
						style={{
							borderColor:
								user.account_type === "idea_holder" ? "#436EDE" : "#8F36FF",
							cursor: "pointer",
						}}
					>
						<SendOutlined
							onClick={() =>
								history.push({
									pathname: "/app/chat/dm",
									state: { user: user },
								})
							}
							color={
								user.account_type === "idea_holder" ? "#436EDE" : "#8F36FF"
							}
						/>
					</div>
				</div>
			</div>
			<div className="user-bio">{user.biography}</div>
			<div className="user-bio-gradient"></div>
			<div
				className="see-more"
				style={{
					color: user.account_type === "idea_holder" ? "#436EDE" : "#8F36FF",
					cursor: "pointer",
				}}
				onClick={() => history.push(`/app/profile/${user.userId}`)}
			>
				<FormattedMessage id="seeMore" />{" "}
				<ArrowRight
					size={13}
					color={user.account_type === "idea_holder" ? "#436EDE" : "#8F36FF"}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	// user: state.auth.login.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
