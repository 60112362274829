import React, { useEffect } from "react";
import "../../sass/home.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { getUser } from "../../redux/actions/auth/loginActions";
import { ReactComponent as MainSVG } from "../../assets/About/MainSVG.svg";
// import "../../sass/About.scss";
const Home = (props) => {
	const history = useHistory()
	const { intl } = props
	const { getUser } = props
	useEffect(() => {
		document.title = intl.formatMessage({ id: "main" })
	}, [intl])
	useEffect(() => {
		getUser()
			.then(({ user }) => {
				if (!user.infos_verified) history.push("/app/completeProfile");
				else if (!user.verified_by_staff && user.account_type !== "idea_holder") history.push("/app/notVerified");
			})
			.catch((err) => {
			})
	}, [getUser, history]);

	return (
		<div className="home">
			<section className="section__main">
				<Container>
					<h1 className="section__main--title heading-1">
						<FormattedMessage id="welcomeLeafunder" />
					</h1>
					<h3 className="section__main--slogan heading-3">
						<FormattedMessage id="WelcomtoLeafunderSub" />
					</h3>
					<MainSVG className="section__main--main-svg" />
				</Container>
			</section>
			<section className="section__about-us">
				<Container>
					<p className="lead">
						<FormattedMessage id="homeDesc" />
					</p>
					{props.user.account_type === "idea_holder" ? <Button className="button button-primary-fill btn" onClick={() => history.push("/app/ideas/new")}>
						<FormattedMessage id="Addnewidea" />
					</Button> : <Button type="primary" className="button button-primary-fill btn" onClick={() => history.push("/app/sessions/create")}>
						<FormattedMessage id="Createslots" />
					</Button>}
				</Container>
			</section>

		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user
});
export default injectIntl(connect(mapStateToProps, { getUser })(Home));
