import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import QueryString from "query-string";
import { useHistory } from "react-router-dom";

function Redzoom() {
    const { search } = useLocation()
    let history = useHistory();

    useEffect(() => {
        if (window.opener) {
            const params = QueryString.parse(search)
            window.opener.postMessage({ type: "response", response: params }, "*");
            window.close();
        } else history.push('/404')
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            Hello
        </div>
    )
}

export default Redzoom
