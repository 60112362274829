import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Clock, Download, MoreHorizontal, Video } from "react-feather";
import { ReactComponent as Sent } from "../../assets/App/ChatApp/Sent.svg";
import { ReactComponent as Delivered } from "../../assets/App/ChatApp/Delivered.svg";
import { ReactComponent as Seen } from "../../assets/App/ChatApp/Seen.svg";
import UserService from "../../services/user";
import "../../sass/ChatApp/Message.scss";
import Avatar from "antd/lib/avatar/avatar";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Button, Checkbox, notification, Tooltip } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { connect } from "react-redux";
import Linkify from 'react-linkify';
import Modal from "antd/lib/modal/Modal";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { finishTask } from "../../redux/actions/Communications/CommActions"
import config from '../../Helpers/config'

const socketLink = config.socketLink

const ZoomMessage = (props) => {
	return <div className="zoom-message">
		<div className="zoom-topic">
			{props.meeting.topic}
		</div>
		<div className="zoom-link" onClick={() => props.setIsZoomVisible(true)}>
			<Video style={{
				margin: "0 5px"
			}} />
			<FormattedMessage id="startMeeting" />
		</div>
		<div className="zoom-user">
			<div className="avatar" style={{
				backgroundImage: `url('${UserService.avatarLink({
					avatar: props.sentby.avatar,
					userId: props.sentby.userId
				})}')`
			}} ></div>
			<div className="name">
				{`${props.sentby.firstname} ${props.sentby.lastname}`}
			</div>
		</div>
	</div>
}

const ImageMessage = (props) => {
	const bp = useBreakpoint();
	const [pdpPreview, setPdpPreview] = useState({
		previewVisible: false,
		previewImage: "",
		previewTitle: "",
	});
	return (
		<div className="image-message-wrapper">
			{props.text !== "" ? (
				<div className="text-with-image">{props.text}</div>
			) : (
				""
			)}
			<div
				className="image-message"
				style={{
					cursor: "pointer",
					backgroundImage: `url('${socketLink}/${props.image}')`,
					width: bp.xl ? 300 : bp.lg ? 200 : 100,
					height: bp.xl ? 300 : bp.lg ? 200 : 100,
				}}
				onClick={() => {
					setPdpPreview((x) => ({ ...x, previewVisible: true, previewImage: props.image }))
				}}
			></div>
			<Modal
				visible={pdpPreview.previewVisible}
				title={pdpPreview.previewTitle}
				footer={null}
				cancelText={<FormattedMessage id="cancel" />}
				okText={<FormattedMessage id="ok" />}
				onCancel={() => {
					setPdpPreview((x) => ({
						...x,
						previewVisible: false,
					}));
				}}
			>
				<img
					alt="example"
					style={{ width: "100%" }}
					src={`${socketLink}/${pdpPreview.previewImage}`}
				/>
			</Modal>
		</div>
	);
};

const FileMessage = (props) => {
	const bp = useBreakpoint();

	// const saveFile = (name, url) => {
	// 	fileSaver.saveAs(url, name);
	// };
	return (
		<div className="file-message-wrapper">
			{props.text !== "" ? (
				<div className="text-with-file">{props.text}</div>
			) : (
				""
			)}
			<div
				className="file-message"
				// onClick={() => {
				//   window.open(props.file.link, "_blank");
				// }}
				style={{
					flexWrap: bp.xs ? "wrap" : "nowrap",
					textAlign: bp.xs ? "center" : "unset",
				}}
			>
				<div
					className={classNames("file-type", {
						"file-type-pdf": props.file?.ext?.toLowerCase() === "pdf",
						"file-type-doc":
							(props.file && props.file?.ext?.toLowerCase() === "doc") ||
							(props.file && props.file?.ext?.toLowerCase() === "docx"),
						"file-type-ppt":
							(props.file && props.file?.ext?.toLowerCase() === "ppt") ||
							(props.file && props.file?.ext?.toLowerCase() === "pptx"),
						"file-type-xls":
							(props.file && props.file?.ext?.toLowerCase() === "xls") ||
							(props.file && props.file?.ext?.toLowerCase() === "xlsx"),
						"file-type-txt": props.file && props.file?.ext?.toLowerCase() === "txt",
					})}
				>
					{props.file.ext}
				</div>
				<div
					className="file-name-size"
					style={{
						marginTop: bp.xs ? 10 : 0,
					}}
				>
					<div className="name">
						{props.file?.name?.length > 25
							? props.file?.name?.substr(0, 25) + "..."
							: props.file?.name}
					</div>
					<div className="size">
						{props.file?.size < 1000
							? props.file?.size + "b"
							: props.file?.size < 1000000
								? Math.round(props.file?.size / 1000) + "Kb"
								: Math.round(props.file?.size / 1000000) + "Mb"}
					</div>
				</div>
				<div className="download-btn">
					<a
						href={`${socketLink}/${props.file?.link}`}
						target="_blank"
						rel="noreferrer"
						download
					>
						<Download size={15} />
					</a>
				</div>
			</div>
		</div>
	);
};

const TaskMessage = (props) => {
	const bp = useBreakpoint();
	const { task } = props;
	const [taskChecked, setChecked] = useState(false)
	const { intl } = props

	const finishTask = (e) => {
		e.preventDefault()
		const data = {
			task: task._id,
			_id: props.recipient._id
		}
		if (props.user._id === task.user._id) {
			props.finishTask(data)
			task.status = "finished"
			setChecked(true)
		}
		else
			notification["error"]({
				message: intl.formatMessage({ id: "error_text" }),
				description: intl.formatMessage({ id: "taskError" }),
			});
	}

	return (
		<div className="task-message-wrapper">
			{props.text !== "" ? (
				<div className="text-with-task">{props.text ? props.text : ""}</div>
			) : (
				""
			)}
			<div className="task-message">
				<div className="task-checkbox">
					<Checkbox disabled={task.status === "finished"} checked={task.status === "finished" ? true : taskChecked} onChange={finishTask} />
				</div>
				<div
					className={classNames("task-infos", {
						"task-infos-pending": task.status === "pending",
						"task-infos-finished": task.status === "finished",
					})}
				>
					<div className="task-title">{task.title ? task.title : ""}</div>
					<div className="task-desc">{task.desc ? task.desc : ""}</div>
					<div
						className="task-footer"
						style={{
							flexWrap: bp.xs ? "wrap" : "nowrap",
							justifyContent: bp.xs ? "center" : "space-between",
						}}
					>
						<div className="task-user">
							<Avatar
								src={UserService.avatarLink({
									avatar: task.user.avatar,
									userId: task.user.userId,
								})}
								size="small"
							/>
							<div>{`${task.user.firstname} ${task.user.lastname}`}</div>
						</div>
						<div className="task-deadline">
							<Clock size={15} style={{ margin: "0 3px" }} />{" "}
							<FormattedMessage id="deadline" />{" "}
							<Tooltip title={task.deadline ? moment(task.deadline).format("YYYY-MM-DD") : ""}>
								{task.deadline ? moment(task.deadline).format("YYYY-MM-DD") : ""}
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Message = (props) => {
	const [isZoomVisible, setIsZoomVisible] = useState(false)
	const { intl } = props
	const history = useHistory()

	const finishTask = (data) => {
		props.finishTask(data)
			.then(res => {
				if (res.status === "success") {
					notification["success"]({
						message: intl.formatMessage({ id: "success" }),
						description: intl.formatMessage({ id: "taskFinished" }),
					});
				}
				else notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: "taskError" }),
				});
			})
			.catch((err) => { history.push("/500") });
	}
	useEffect(() => { }, []);
	const renderMessage = (type) => {
		switch (type) {
			case "file":
				return <FileMessage  {...props} />;
			case "task":
				return <TaskMessage {...props} finishTask={finishTask} />;
			case "image":
				return <ImageMessage {...props} />;
			case "zoom":
				return <ZoomMessage {...props} setIsZoomVisible={setIsZoomVisible} />;
			default:
				return props.text;
		}
	};
	return (
		<div
			className={classNames("message-container", {
				"message-container-sent": props.sent,
			})}
		>
			{props.sent ? (
				<Tooltip placement="top" title={moment(props.sent_at).format("HH:MM A")}>
					<div className="message-menu">
						<MoreHorizontal size={15} />
					</div>
				</Tooltip>
			) : (
				<Avatar
					size="small"
					src={UserService.avatarLink({
						avatar: props.sentby.avatar,
						userId: props.sentby.userId,
					})}
					style={{ margin: "0 5px" }}
				/>
			)}
			<Linkify>
				<div
					className={classNames("message", {
						"message-sent": props.sent,
						"message-received": !props.sent,
					})}
				>
					{renderMessage(props.type)}
				</div>
			</Linkify>
			{props.sent ? (
				<div className="sent-indicators">
					{props.seen ? <Seen /> : props.delivered ? <Delivered /> : <Sent />}
				</div>
			) : (
				<Tooltip placement="top" title={moment(props.sent_at).format("HH:MM A")}>
					<div className="message-menu">
						<MoreHorizontal size={15} />
					</div>
				</Tooltip>
			)}
			<Modal
				title={<FormattedMessage id="startMeeting" />}
				visible={isZoomVisible}
				centered
				cancelText={<FormattedMessage id="cancel" />}
				okText={<FormattedMessage id="ok" />}
				onCancel={() => setIsZoomVisible(false)}
				footer={[
					<Link style={{ margin: "71px", marginLeft: "-20px" }} to={{
						pathname: `/app/zoommeeting?joinMeeting=true&met=${props.meeting.meeting_id}&pwd=${props.meeting.password}&username=${props.user.firstname + " " + props.user.lastname}&useremail=${props.user.email}`,

					}} target="_blank">

						<Button type="primary"
						><FormattedMessage id="useLeafSDK" /></Button></Link>,
					<Button type="primary" onClick={() => {
						window.open(props.meeting.join_url, '_blank')
					}}><FormattedMessage id="goZoom" /></Button>]}
			>
				<FormattedMessage id="startMeetingDes" />
			</Modal>
		</div>
	);
};

Message.defaultProps = {
	text: "",
	sent: false,
	seen: false,
	delivered: false,
	recipient: {
		avatar: "",
		userId: "",
	},
	type: "text",
	file: {
		link: "",
		ext: "",
		size: 0,
	},
	task: {
		title: "",
		desc: "",
		date: "",
		status: "pending",
	},
	image: "",
	meeting: ""
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = { finishTask };

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Message))

// {props.sent ? (
//   <>
//     <div className="message-menu">
//       <MoreHorizontal size={15} />
//     </div>
//     <div className="message message-sent">{props.text}</div>
//     <div className="sent-indicators">
//       {props.seen ? <Seen /> : props.delivered ? <Delivered /> : <Sent />}
//       {/* <Seen /> */}
//     </div>
//   </>
// ) : (
//   <>
//     <Avatar
//       size="small"
//       src={UserService.avatarLink({
//         avatar: props.recipient.avatar,
//         userId: props.recipient.userId,
//       })}
//       style={{ margin: "0 5px" }}
//     />
//     <div className="message message-received">{props.text}</div>
//     <div className="message-menu">
//       <MoreHorizontal size={15} />
//     </div>
//   </>
// )}
