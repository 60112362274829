import { useEffect, useState } from "react";
import CompleteProfile from "./CompleteProfile";
import NotVerified from "./NotVerified";
import MainApp from "./MainApp";
import { connect } from "react-redux";
import { getUser } from "../../redux/actions/auth/loginActions";
import { getAvailableProcess } from "../../redux/actions/Path/PathActions";
import { PulseLoader } from "react-spinners";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";


const App = ({ user, isAuth, getUser, getAvailableProcess }) => {
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		getUser()
			.then(({ user }) => {
				if (!user.infos_verified) history.push("/app/completeProfile");
				else if (!user.verified_by_staff && user.account_type !== "idea_holder") history.push("/app/notVerified");
				setLoading(false);
			})
			.catch((err) => {
			});
	}, [getUser, history]);

	useEffect(() => {
		getAvailableProcess()
	}, [getAvailableProcess]);

	return loading ? (
		<div className="loading-screen">
			<img src={logo} className="loading-screen-logo" alt="" style={{ width: "500px" }} />
			<PulseLoader color="#458ff6" />
		</div>
	) : (
		<Switch>
			<Route exact path="/app/completeProfile">
				<CompleteProfile user={user} />
			</Route>
			<Route exact path="/app/notVerified">
				<NotVerified user={user} />
			</Route>
			<Route path="/app">
				<MainApp user={user} />
			</Route>
			<Redirect to="/404" />
		</Switch>
	);
};

const mapStateToProps = (state) => {
	return {
		isAuth: state.auth.login.isAuth,
		user: state.auth.login.user,
	};
};

export default connect(mapStateToProps, {
	getUser,
	getAvailableProcess,
})(App);
