import { Divider, Popover } from "antd";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Container, Row } from "reactstrap";
import logo from "../../assets/logo.png";
import Heading from "../../components/Heading";
import Logout from "../../components/Logout";
import Languages from "../../components/Languages";
import "../../sass/notVerified.scss";
import { ChevronDown, LogOut } from "react-feather";
import Avatar from "antd/lib/avatar/avatar";
import User from "../../services/user";
import { IntlContext } from "../../context/internationalization";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const NotVerified = ({ user }) => {
	const history = useHistory();
	const breakpoint = useBreakpoint();
	const context = useContext(IntlContext);
	const [isLoading, setIsloading] = useState(true)

	useEffect(() => {
		if (user.verified_by_staff !== false) history.push("/404");
		return setIsloading(false)
		// eslint-disable-next-line
	}, [user.verified_by_staff])

	if (isLoading) return null

	return (
		<div className="notVerified-page h-100">
			<div className="nv-header-container">
				<Container>
					<div className="nv-header">
						<img className="nv-header-brand" src={logo} style={{ width: "100px" }} alt="" />
						<div className="nv-header-navs">
							<Languages className="nv-header-nav" />
							<Popover
								trigger="click"
								className="nv-header-nav"
								placement={
									context.state.direction === "ltr"
										? "bottomRight"
										: "bottomLeft"
								}
								content={
									<Container className="popover-content">
										<Col style={{ margin: "auto", textAlign: "center" }}>
											<Avatar src={User.avatarLink(user)} size="large" />
											<div style={{ margin: "10px 0" }}>
												{`${user.firstname} ${user.lastname}`}
											</div>
										</Col>
										<Divider style={{ margin: "10px 0" }} />
										<div className="popover-logout">
											<Logout>
												<LogOut />
												<span>
													{" "}
													<FormattedMessage id="logout" />{" "}
												</span>
											</Logout>
										</div>
									</Container>
								}
							>
								<div className="nv-user-dropdown">
									<Avatar size="large" src={User.avatarLink(user)} />
									{breakpoint !== "sm" && breakpoint !== "xs" ? (
										<span className="nv-user-dropdown-name">{`${user.firstname}`}</span>
									) : (
										""
									)}
									<ChevronDown size={15} />
								</div>
							</Popover>
						</div>
					</div>
				</Container>
			</div>
			<Container className="notVerif-box">
				<Row style={{ justifyContent: "center", alignItems: "center" }}>
					<Col md={8}>
						<div className="nv-card">
							<Heading
								text={<FormattedMessage id="waitingVerification" />}
								color="#109CF1"
							/>
							<div className="lead nv-lead">
								<FormattedMessage id="accountNotActive" />
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default NotVerified;
