import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Container } from "reactstrap";
import "../../../sass/Incubator/index.scss";
import { BulbOutlined, EditOutlined, UnlockOutlined, CheckCircleOutlined, UserAddOutlined, ScheduleOutlined } from "@ant-design/icons";
import { Avatar, notification, Timeline } from "antd";
import UserService from "../../../services/user";
import { Calendar, ChevronRight, Clock } from "react-feather";
import { Stage, Steps } from "../../../components/Incubator/MentorPage";
import { getMyIdeas } from "../../../redux/actions/Ideas/IdeasActions";
import { getPath, getStageinfo, EditStep, getIncubationTimeline } from "../../../redux/actions/Path/PathActions";
import { ReactComponent as MainSVG } from "../../../assets/About/MainSVG.svg";
import _ from "lodash";
import moment from "moment";
import Modal from "antd/lib/modal/Modal";
import IdeasMethods from "../../../Helpers/IdeaHelpers";
import UserMethods from "../../../Helpers/UserHelpers";

export const SessDesc = (props) => {
	const { line } = props
	return (
		<span className="Desc"><FormattedMessage id="mentoringsessionDesc" /><Link to={`/app/profile/${line?.session_owner?.userId}`}>{" " + line?.session_owner?.firstname.toUpperCase() + " " + line?.session_owner?.lastname.toUpperCase()}</Link></span>
	)
}
export const UserDesc = (props) => {
	const { line } = props
	return (
		<span className="Desc"> <FormattedMessage id="newMemberadded" />  <Link to={`/app/profile/${line.user.userId}`}>{" " + line?.user?.firstname.toUpperCase() + " " + line?.user?.lastname.toUpperCase()} </Link> <FormattedMessage id="newMemberaddedDesc" /></span>
	)
}
export const StepDesc = (props) => {
	const { line } = props
	return (
		<span className="Desc"><FormattedMessage id="stepdesc" /> (<FormattedMessage id="step" /> {line?.step_info?.step_number} :
			{ " " + localStorage.getItem("locale") === "ar"
				? line?.step_info?.name_ar
				: localStorage.getItem("locale") === "fr"
					? line?.step_info?.name_fr
					: line?.step_info?.name_en})
			{line.type === "step_opened" ? <FormattedMessage id="opendedby" /> : <FormattedMessage id="Finishedby" />} <Link to={`/app/profile/${line?.[line.type === "step_opened" ? "opened_by" : "finished_by"]?.userId}`}>{" " + line?.[line.type === "step_opened" ? "opened_by" : "finished_by"]?.firstname.toUpperCase() + " " + line?.[line.type === "step_opened" ? "opened_by" : "finished_by"]?.lastname.toUpperCase()} </Link></span>
	)
}


const Incubation = (props) => {
	const { intl } = props;
	const timezone = moment.tz.guess();
	const history = useHistory();
	const { ideaid } = useParams();
	const { user } = props;
	const [Paths, setPaths] = useState([]);
	const [selectedStage, setselectedStage] = useState([]);
	const [loadData, setloadData] = useState(false);
	const [steps, setsteps] = useState([]);
	const [loadSteps, setloadSteps] = useState(false);
	const [nextbutton, setNextbutton] = useState(true);
	const [timelineModal, settimelineModal] = useState(false);
	const [TimelineData, setTimelineData] = useState([])

	useEffect(() => {
		document.title = intl.formatMessage({ id: "incubator" });
		if (user.account_type === "idea_holder") history.push("/404");
		getPathByIdea();
		getIncubationTimeline()
		// eslint-disable-next-line
	}, []);

	//get Path for selected Idea
	const getPathByIdea = () => {
		props.getPath(ideaid).then((res) => {
			if (res.status === "success" && res.result !== null) {
				setPaths(res.result);
				setloadData(true);
			} else {
				history.push("/app");
			}
		});
	};

	useEffect(() => {
		selectedStage &&
			getsSteps();
		// eslint-disable-next-line
	}, [selectedStage]);
	const getsSteps = () => {
		const data = {
			ideaId: ideaid,
			stageId: selectedStage._id,
		};
		setloadSteps(false);
		props.getStageinfo(data).then((res) => {
			if (res.status === "success") {
				setsteps(res.result);
				setloadSteps(true);
			} else {
				setsteps([]);
			}
		});
	};

	const getIncubationTimeline = () => {
		props.getIncubationTimeline(ideaid)
			.then(res => {
				if (res.status === "success")
					setTimelineData(res.timeline)
				else
					setTimelineData([])

			})
			.catch(err => console.log(err))
	}
	const getTimlineType = (type) => {
		switch (type) {
			case "member_added":
				return "newMember"
			case "step_opened":
				return "stepopened"
			case "step_finished":
				return "stepfinished"
			case "session_finished":
				return "mentoringsession"
			default:
				break;
		}
	}
	const getTimlineIcon = (type) => {
		switch (type) {
			case "member_added":
				return <UserAddOutlined />
			case "step_opened":
				return <UnlockOutlined />
			case "step_finished":
				return <CheckCircleOutlined />
			case "session_finished":
				return <ScheduleOutlined />
			default:
				break;
		}
	}
	return (
		<div className="incubator-page">
			<section className="section__main">
				<Container>
					<h1 className="section__main--title heading-1">
						<FormattedMessage id="Incubatorspace" />
					</h1>
					<MainSVG className="section__main--main-svg" />
				</Container>
			</section>
			<Container>
				{loadData ? (
					<>
						<div className="carta">
							<Row>
								<Col md={3} style={{ marginBottom: 20 }}>
									<div className="idea-selector">
										<BulbOutlined style={{ margin: "0 5px" }} size={30} />
										{Paths.idea_info.title}
									</div>
								</Col>
								<Col md={3} style={{ marginBottom: 20 }}>
									<div className="idea-selector">
										<Avatar
											src={UserService.avatarLink({
												avatar: Paths.idea_info.user.avatar,
												userId: Paths.idea_info.user.userId,
											})}
											style={{
												margin: "0 5px",
											}}
										/>
										<div className="team-leader">
											<div className="name">
												{`${Paths.idea_info.user.firstname} ${Paths.idea_info.user.lastname}`}
											</div>
											<div
												className="all-members"
												onClick={() =>
													history.push(`/app/profile/${Paths.idea_info.user.userId}`)
												}
											>
												<FormattedMessage id="seeProfile" />
												<ChevronRight size={12} />
											</div>
										</div>
									</div>
								</Col>
								<Col md={3} style={{ marginBottom: 20, cursor: "pointer" }} onClick={() => settimelineModal(true)}>
									<div className="idea-selector" >
										<Clock
											color="#999999"
											style={{
												margin: "0 10px",
											}}
											size={30}
										/>
										<div className="team-leader">
											<div className="name">
												<FormattedMessage id="timeLine" />
											</div>
										</div>
									</div>
								</Col>
								<Col md={{ size: 2, offset: 1 }} style={{ marginBottom: 20 }}>
									<div className="idea-selector idea-selector-start">
										<Calendar size={12} style={{ margin: "0 5px" }} />
										{moment(Paths.idea_info.created_date)
											.clone()
											.tz(timezone)
											.fromNow()}
									</div>
								</Col>
							</Row>
							<div className="heading">
								<p>
									<FormattedMessage id="stagesAndSteps" />
								</p>
								<hr />
							</div>
							<Row md={4} sm={2} xs={1} style={{ justifyContent: "center" }}>
								{_.isArray(Paths.stages)
									? Paths.stages.map((stage, key) => (
										<Col
											key={stage._id}
											style={{
												justifyContent: "center",
												alignItems: "center",
												marginBottom: 20,
											}}
										>
											<Stage
												key={stage._id}
												setselectedStage={setselectedStage}
												stages={stage}
												size={120}
											/>
										</Col>
									))
									: notification["error"]({
										message: intl.formatMessage({ id: "error_text" }),
										description: intl.formatMessage({ id: "error_1" }),
									})}
							</Row>
							<Row>
								{loadSteps ? (
									<Steps
										steps={{
											getsSteps: getsSteps,
											process_info: Paths.process_info,
											idea: Paths.idea_info,
											allSteps: steps,
											stage: selectedStage,
											total: selectedStage.total_steps,
											finished: selectedStage.steps_finished,
											stages: Paths.stages,
											path_id: Paths.path_id,
											setselectedStage: setselectedStage,
											nextbutton: nextbutton,
											setNextbutton: setNextbutton,
										}}
									/>
								) : (
									""
								)}
							</Row>
						</div>
						<Modal
							cancelText={<FormattedMessage id="cancel" />}
							okText={<FormattedMessage id="ok" />}
							title={<FormattedMessage id="timeLine" />}
							visible={timelineModal}
							autoFocusButton={true}
							centered
							width={1000}
							className="step-modal"
							onCancel={() => settimelineModal(false)}
							onOk={() => settimelineModal(false)}
						>
							<div className="timeline">

								<Timeline mode="left">
									<Timeline.Item dot={<EditOutlined />} label={moment(Paths.idea_info.created_date).format("YYYY-MM-DD HH:MM:SS")}>
										<div className="card ideacreated">
											<h6><FormattedMessage id="ideacreated" /></h6>
											<span className="action">	<FormattedMessage id="newIdeacreated" />  <Link to={`/app/ideas/${Paths.idea_info.ideaId}`}>{` (${Paths.idea_info.title}) `} </Link>  <FormattedMessage id=
												"cretaedby" />  <Link to={`/app/profile/${Paths.idea_info.user.userId}`} >{` ${Paths.idea_info.user.firstname.toUpperCase() + " " + Paths.idea_info.user.lastname.toUpperCase()} `}</Link></span>
											<div className="createdby">

												<p
													className="Ellipse_224"
													style={Paths.idea_info.user ? {
														backgroundImage: `url('${IdeasMethods.getIdeaImage(Paths.idea_info.ideaId, Paths.idea_info.images[0])}')`,
													} : {}}
												></p>
												<div className="user-infos">
													<div className="name">
														{Paths.idea_info.title}
													</div>
												</div>
											</div>
										</div>
									</Timeline.Item>
									{TimelineData.length && TimelineData.map((line, index) => {
										return (index > 0 &&
											<Timeline.Item key={index} dot={getTimlineIcon(line.type)} label={moment(line.time_date).format("YYYY-MM-DD HH:MM:SS")}><div className="card ideacreated">
												<h6><FormattedMessage id={getTimlineType(line.type)} /></h6>

												{line.type === "member_added" && <UserDesc intl={intl} line={line} history={history} />}
												{(line.type === "step_opened" || line.type === "step_finished") && <StepDesc intl={intl} line={line} history={history} />}
												{(line.type === "session_finished") && <SessDesc intl={intl} line={line} history={history} />}
												{line.type === "member_added" && <div className="createdby">
													<p
														className="Ellipse_224"
														style={line?.user ? {
															backgroundImage: `url('${UserMethods.getUserImage(line.user.userId, line.user.avatar)}')`,
														} : {}}
													></p>
													<div className="user-infos">
														<div className="name">
															{line?.user?.firstname.toUpperCase() + " " + line?.user?.lastname.toUpperCase()}
														</div>
														<div className="position">{line?.position}</div>
													</div>
												</div>}
												{line.type === "session_finished" && <div className="createdby">
													<p
														className="Ellipse_224"
														style={line?.session_owner ? {
															backgroundImage: `url('${UserMethods.getUserImage(line.session_owner.userId, line.session_owner.avatar)}')`,
														} : {}}
													></p>
													<div className="user-infos">
														<div className="name">
															{line?.session_owner?.firstname.toUpperCase() + " " + line?.session_owner?.lastname.toUpperCase()}
														</div>
														<div className="position">{line?.position}</div>
													</div>
												</div>}

											</div></Timeline.Item>
										)
									})}
								</Timeline>
							</div>
						</Modal>

					</>
				) : (
					""
				)}
			</Container>
		</div >
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = { getPath, getMyIdeas, getStageinfo, EditStep, getIncubationTimeline };

export default injectIntl(
	connect(mapStateToProps, mapDispatchToProps)(Incubation)
);
