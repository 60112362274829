import React, { useEffect } from "react";
import { Button } from 'reactstrap'
import { Link, NavLink } from 'react-router-dom'
import errorImg from '../assets/images/pages/error.svg'
import logo from "../assets/logo.png";
import "../sass/404.scss"
import { FormattedMessage } from "react-intl";

const NotFound = () => {
	useEffect(() => {
		document.title = "404"
		// eslint-disable-next-line 
	}, [])
	return (
		<div className='misc-wrapper'>
			<NavLink to="/">
				<img src={logo} alt="" className="leafunder-logo" style={{ height: "60px", marginTop: "5px" }} />
			</NavLink>
			<div className='misc-inner p-2 p-sm-3'>
				<div className='w-100 text-center'>

					<h2 className='mb-1'><FormattedMessage id="NotFound" /> </h2>
					<p className='mb-2'><FormattedMessage id="NotFoundDesc" /></p>

					<img className='img-fluid' src={errorImg} alt='Not authorized page' />
				</div>
			</div>
			<Button tag={Link} to='/app' color='primary' className='btn-sm-block mb-2'>
				Back to home
			</Button>
		</div>
	)
}
export default NotFound;
