import moment from "moment";
import _ from "lodash";
/*
 ** Profiles Helpers
 */

const ProfileMethods = {
	getDate: (dt) => {
		return new Promise((resolve, reject) => {
			const newdate = new Date(dt);
			if (newdate) resolve(moment(newdate).format("YYYY-MM-DD"));
			else reject("error");
		});
	},
	getDateRange: (Range) => {
		return new Promise((resolve, reject) => {
			let from = new Date(Range[0]);
			from = moment(from).format("YYYY-MM-DD");
			let to = new Date(Range[1]);
			to = moment(to).format("YYYY-MM-DD");
			if (from && to) resolve(`&from=${from}&to=${to}`);
			else reject("error");
		});
	},
	FilterUser: (filters) => {
		return new Promise((resolve, reject) => {
			let url = "";
			filters.gender && (url = `&gender=${filters.gender}`)
			filters.accountType
				&& (url += `&account=${filters.accountType}`)
			filters.availability && filters.availability.length
				? ProfileMethods.getDateRange(filters.availability)
					.then((res) => {
						res && (url += res)
						resolve(url);
					})
					.catch((err) => reject("error_1"))
				: resolve(url);
		});
	},
	FillQsts: (qsts) => {
		return new Promise((resolve, reject) => {
			let data = {};
			_.isObject(qsts) &&
				qsts.forEach((qst, i) => {
					data[`qte${i}`] = qst.answer;
				});
			resolve(data);
		});
	},
};

export default ProfileMethods;
