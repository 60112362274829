import React, { useContext, useEffect, useState } from "react";
import {
	Check,
	Download,
	ExternalLink,
	File,
	HelpCircle,
	Layout,
	Lock,
	PlayCircle,
	PlusCircle,
	Unlock,
} from "react-feather";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row } from "reactstrap";
import {
	Button,
	Form,
	Grid,
	Input,
	Modal,
	Radio,
	Result,
	Alert,
	Select,
	Empty,
	notification,
} from "antd";
import classNames from "classnames";
import { ReactComponent as StepsLeft } from "../../assets/App/Incubator/stepsLeft.svg";
import { ReactComponent as StepsRight } from "../../assets/App/Incubator/stepsRight.svg";
import { ReactComponent as StepsDashedLine } from "../../assets/App/Incubator/stepsDashedLine.svg";
import { ReactComponent as StepsDashedLineMD } from "../../assets/App/Incubator/stepsDashedLineMD.svg";
import BraftEditor from "braft-editor";
import "../../sass/Incubator/StageStep.scss";
import { connect } from "react-redux";
import {
	getStepinfo,
	openStep,
	finishStep,
	helpMeImStack,
	EditStep
} from "../../redux/actions/Path/PathActions";
import { getSessionAsMember } from "../../redux/actions/Sessions/SessionsActions";
import { useForm } from "antd/lib/form/Form";
import PathMethods from "../../Helpers/PathHelpers";
import moment from "moment";
import _ from "lodash";
import { DeleteOutlined } from "@ant-design/icons";
import { IntlContext } from "../../context/internationalization";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const mapPropsToState = (state) => ({});

const { useBreakpoint } = Grid;
//stage_info
export const Stage = (props) => {
	const { stages, setselectedStage } = props;
	const [cercles, setCercles] = useState([]);
	const rayon = props.size / 2 - 5;
	const perimeter = 2 * rayon * Math.PI;
	const center = props.size / 2;
	const percent = Math.max(stages.steps_finished, 0) / stages.total_steps;

	useEffect(() => {
		const items = [];
		let finished = stages.steps_finished > 0 ? stages.steps_finished + 1 : 0;
		for (let i = 0; i < stages.total_steps; i++) {
			items.push(
				<div
					key={i + 1}
					className="stage-cercle"
					style={{
						top:
							center +
							rayon *
							Math.sin(
								((2 * Math.PI) / stages.total_steps) * i - Math.PI / 2
							),
						left:
							center +
							rayon *
							Math.cos(
								((2 * Math.PI) / stages.total_steps) * i - Math.PI / 2
							),
						backgroundColor: finished > 0 ? "#458ff6" : "white",
						border: finished > 0 ? "none" : "2px solid #dfdfdf",
					}}
				></div>
			);
			finished--;
		}
		setCercles(items);
		// eslint-disable-next-line
	}, [center, stages.total_steps, rayon]);

	return (
		<div
			className="incubator-stage"
			style={{
				width: props.size + 20,
				height: props.size + 20,
				cursor: "pointer",
			}}
			onClick={() => {
				setselectedStage(stages);
			}}
		>
			<div
				className="stage-name"
				style={{
					width: props.size + 20,
					// height: props.size + 20,
					borderRadius: props.size,
					overflow: "hidden",
					fontSize: props.size / 8,
				}}
			>
				{localStorage.getItem("locale") === "ar"
					? stages.stage_info.name_ar
					: localStorage.getItem("locale") === "fr"
						? stages.stage_info.name_fr
						: stages.stage_info.name_en}
			</div>
			<svg width={props.size + 20} height={props.size + 20}>
				<circle
					cx="50%"
					cy="50%"
					r={rayon}
					fill="none"
					stroke="#dfdfdf"
					strokeWidth="3px"
				></circle>
				<circle
					cx="50%"
					cy="50%"
					r={rayon}
					fill="none"
					stroke="#458ff6"
					strokeWidth="5px"
					strokeDasharray={`${percent * perimeter}px ${perimeter - percent * perimeter
						}px`}
					strokeDashoffset={perimeter / 4}
				></circle>
			</svg>
			{cercles}
		</div>

	);
};

export const Steps = injectIntl(
	connect(mapPropsToState, {
		getStepinfo,
		openStep,
		finishStep,
		helpMeImStack,
		getSessionAsMember,
		EditStep
	})((props) => {
		const timezone = moment.tz.guess();
		const history = useHistory()
		const { steps, intl } = props;
		const [stepItems, setStepItems] = useState([]);
		const [isModalVisible, setIsModalVisible] = useState(false);
		const [isModalIIVisible, setIsModalIIVisible] = useState(false);
		const [isStackVisible, setIsStackVisible] = useState(false);
		const [EditContent, setEditContent] = useState(false);
		const [loadcontent, setloadcontent] = useState(false);
		const [finishError, setfinishError] = useState("");
		const [modalItemsIndex, setModalItemsIndex] = useState(0);
		const [helpMeMessage, sethelpMeMessage] = useState("");
		const [ErrHelpme, setErrHelpme] = useState("");
		const [selectedStep, setselectedStep] = useState([]);
		const [result, setresult] = useState([]);
		const [Sessions, setSessions] = useState([]);
		const [SelectedSession, setSelectedSession] = useState("");
		const [sessionselected, setsessionselected] = useState("");
		const context = useContext(IntlContext);

		const [form] = useForm();

		const [editorState, setEditorState] = useState(() =>
			BraftEditor.createEditorState(null)
		);
		const [borderColor, setBorderColor] = useState("#999999");
		const [files, setFiles] = useState([]);
		const [newfile, setNewfile] = useState("");
		// const bp = useBreakpoint();
		const bps = useBreakpoint();

		// get Finished Sessions
		useEffect(() => {
			const data = {
				ideaId: steps.idea.ideaId,
				params: "&status=Finished",
			};
			props.getSessionAsMember(data).then((res) => {
				if (res.status === "success") setSessions(res.result.Sessions);
			});
			// eslint-disable-next-line
		}, []);
		useEffect(() => {
			if (selectedStep.step_id) getStepinfo();
			// eslint-disable-next-line
		}, [selectedStep]);
		const getStepinfo = () => {
			const data = {
				ideaId: steps.idea._id,
				stepId: selectedStep.step_id,
			};
			props.getStepinfo(data).then((res) => {
				setloadcontent(false);
				setresult([]);
				if (res.status === "success") {
					setresult(res.result);

					form.setFieldsValue({
						summaryOfOutcome: BraftEditor.createEditorState(res.result.outcome),
						lessonsLearned: BraftEditor.createEditorState(
							res.result.lessons_learned
						),
					});
					setFiles(res.result.document_links);
					_.isObject(res.result.session)
						? setsessionselected(
							res.result.session.session_owner.firstname +
							" " +
							res.result.session.session_owner.lastname +
							"/" +
							moment(res.result.session.begin_at)
								.clone()
								.tz(timezone)
								.format("DD-MM-YY") +
							" at " +
							moment(res.result.session.begin_at)
								.clone()
								.tz(timezone)
								.format("hh:mm") +
							"-" +
							moment(res.result.session.end_at)
								.clone()
								.tz(timezone)
								.format("hh:mm A")
						)
						: setsessionselected("");
					setloadcontent(true);
				} else setresult([]);
			});
		};
		useEffect(() => {
			const { md, xs } = bps;
			const items = [];
			let { total, allSteps } = steps;
			const mod = md ? 3 : xs ? 1 : 2;
			allSteps.forEach((element, i) => {
				i < total && items.push(
					<StepCercle
						key={element._id}
						setselectedStep={setselectedStep}
						i={i}
						step={element}
						result={result}
						getStepinfo={getStepinfo}
						setIsModalVisible={setIsModalVisible}
						setIsModalIIVisible={setIsModalIIVisible}
					/>
				);
			});

			//GENERATE LINES.
			for (let i = 0; i < total - (total % mod); i++) {
				items[i] = (
					<Step key={i + 1} i={i} mod={mod} xs={xs} context={context}>
						{items[i]}
					</Step>
				);
			}
			let rest = total % mod;
			let multiplier = (total - (total % mod)) % (mod * 2) === 0 ? 1 : 2;
			// ----------------- REST ----------------------
			for (let i = total - (total % mod); i < total; i++) {
				items[i] = (
					<Step key={i + 1} i={i - total + multiplier * (total % mod)} mod={total % mod} xs={xs} context={context}>
						{items[i]}
					</Step>
				);
			}

			//----------------------------------------------
			// Push the dashed line
			items.push(
				<Col key={Math.random()} md={12 / mod} sm={12 / mod} xs={12} className="flex justify-center mb-50 coll pos-relative">
					{
						md ?
							<StepsDashedLine
								className={classNames("steps-dashed-line", "pos-absolute", {
									"steps-dashed-line-reversed": (rest === 0 && total % (mod * 2) !== 0) || (rest !== 0 && multiplier === 1),
								})}
							/> :
							<StepsDashedLineMD
								className={classNames("steps-dashed-line", "steps-dashed-line-md", "pos-absolute", {
									"steps-dashed-line-md-reversed": (rest === 0 && total % (mod * 2) !== 0) || (rest !== 0 && multiplier === 1),
								})}
							/>
					}
				</Col>
			);
			total++;
			setStepItems(items);
			// eslint-disable-next-line
		}, [bps, steps.finished, steps.total]);

		const openStep = () => {
			const data = {
				pathId: steps.path_id,
				stepId: selectedStep.step_id,
				stageId: steps.stage._id,
			};
			props.openStep(data).then((res) => {
				if (res.status === "success") steps.getsSteps();
				else if (res.status === "failure")
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: res.msg }),
					});
			});
		};
		const finishStep = () => {
			PathMethods.finishStep(
				form.getFieldsValue(),
				files,
				result.step.type,
				SelectedSession
			)
				.then((res) => {
					const data = {
						pathId: steps.path_id,
						stepId: selectedStep.step_id,
						stageId: steps.stage._id,
						body: res,
					};

					props.finishStep(data).then((res) => {
						if (res.status === "success") {
							steps.getPathByIdea()
							steps.getsSteps();
						} else setfinishError(res.msg);
					});
				})
				.catch((err) => setfinishError(<FormattedMessage id={err} />));
		};
		const getFiles = () => {
			if (newfile) {
				setFiles([...files, newfile]);
				setNewfile("");
			}
		};

		const helpMe = () => {
			const data = {
				reason:
					"(" +
					steps.process_info.name_en +
					")-(" +
					steps.stage.stage_info.name_en +
					")-(" +
					result.step.name_en +
					")",
				message: helpMeMessage,
			};
			if (helpMeMessage)
				helpMeImStack(data).then((res) => {
					if (res.status === "success") {
						sethelpMeMessage("");
						setIsStackVisible(false);
					}
				});
			else {
				setErrHelpme(<FormattedMessage id="helpmeErr" />);
			}
		};
		useEffect(() => {
			if (steps.stages[steps.stages.length - 1] !== steps.stage)
				steps.setNextbutton(true);
			else steps.setNextbutton(false);
			// eslint-disable-next-line
		}, [steps.stage]);
		const getNextStage = () => {
			if (steps.stages[steps.stages.length - 1] !== steps.stage)
				PathMethods.getNextStage(steps.stages, steps.stage).then((res) => {
					if (res.nextbtn === "true") {
						steps.setNextbutton(false);
					}
					steps.setselectedStage(res.newstage);
				});
			else steps.setNextbutton(false);
		};
		const deleteDoc = (key) => {
			setFiles(
				files.filter(function (item) {
					return item !== key;
				})
			);
		};

		const updateDetails = () => {
			PathMethods.finishStep(
				form.getFieldsValue(),
				files,
				result.step.type,
				SelectedSession
			).then(res => {
				PathMethods.checkModifedData(result, res)
					.then(newData => {
						const data = {
							pathId: steps.path_id,
							stepId: selectedStep.step_id,
							stageId: steps.stage._id,
							body: newData,
						}
						props.EditStep(data)
							.then(res => {
								if (res.status === "success") {
									setEditContent(false);
									setresult({ ...result, status: "finished" })
								}
							})
							.catch((err) => { history.push("/500") });
					})
					.catch((err) => { history.push("/500") });
			})
				.catch((err) => { history.push("/500") });
		}
		const modalItems = [
			<div className="step-description">
				<div className="title">
					<FormattedMessage id="stepDescription" />
				</div>
				<div
					className="desc"
					dangerouslySetInnerHTML={
						loadcontent
							? localStorage.getItem("locale") === "ar"
								? { __html: result.step.description_ar }
								: localStorage.getItem("locale") === "fr"
									? { __html: result.step.description_fr }
									: { __html: result.step.description_en }
							: ""
					}
				>
					{ }
				</div>
				<div className="buttons">
					{result.status === "opened" && <Button
						type="primary"
						danger
						shape="round"
						size="large"
						disabled={result.status === "finished" ? true : false}
						icon={
							<HelpCircle
								style={{
									margin: "0 5px",
								}}
							/>
						}
						onClick={() => setIsStackVisible(true)}
					>
						<FormattedMessage id="helpMeIamStuck" />
					</Button>}
					{result.status === "finished" && <Button
						type="primary"
						shape="round"
						size="large"
						icon={
							<HelpCircle
								style={{
									margin: "0 5px",
								}}
							/>
						}
						onClick={() => {
							setEditContent(true);
							setresult({ ...result, status: "opened" })
						}}
					>
						<FormattedMessage id="EditDetails" />
					</Button>}
					{loadcontent && result.step.type === "session" ? (
						<Select
							defaultValue={sessionselected}
							disabled={result.status === "finished" ? true : false}
							style={{ width: "50%" }}
							placeholder={<FormattedMessage id="selectSessions" />}
							onChange={(val) => setSelectedSession(val)}
							allowClear
						>
							{Sessions.length &&
								Sessions.map((sess, i) => {
									return (
										<Select.Option key={i}>
											{sess.session_owner.firstname +
												" " +
												sess.session_owner.lastname}{" "}
											/{" "}
											{moment(sess.begin_at)
												.clone()
												.tz(timezone)
												.format("DD-MM-YY") +
												" at " +
												moment(sess.begin_at)
													.clone()
													.tz(timezone)
													.format("hh:mm") +
												"-" +
												moment(sess.end_at)
													.clone()
													.tz(timezone)
													.format("hh:mm A")}
										</Select.Option>
									);
								})}
						</Select>
					) : (
						""
					)}
					<div>
						<Col>
							<Row>
								{!EditContent && <Button
									type="primary"
									shape="round"
									size="large"
									style={{
										backgroundColor: "#79D168",
										borderColor: "white",
									}}
									disabled={result.status === "finished" ? true : false}
									onClick={() => finishStep()}
									icon={
										<Check
											style={{
												margin: "0 5px",
											}}
										/>
									}
								>
									{result.status === "finished" ? (
										<FormattedMessage id="finished" />
									) : (
										<FormattedMessage id="markComplete" />
									)}
								</Button>}
								{EditContent && <Button
									type="primary"
									shape="round"
									size="large"
									disabled={result.status === "finished" ? true : false}
									onClick={() => updateDetails()}
									icon={
										<Check
											style={{
												margin: "0 5px",
											}}
										/>
									}
								>

									<FormattedMessage id="updateDetails" />
								</Button>}
							</Row>
							<Row style={{ marginTop: "10px" }}>
								{loadcontent && result.status === "finished" ? (
									<>
										{" "}
										<FormattedMessage id="finishedby" />
										{result.finished_by.firstname +
											" " +
											result.finished_by.lastname}{" "}
									</>
								) : (
									""
								)}
							</Row>
						</Col>
					</div>
				</div>

				{finishError ? (
					<Alert
						description={finishError}
						type="error"
						closable
						onClose={() => setfinishError("")}
					/>
				) : (
					""
				)}
				<hr />
				<div className="step-completion">
					<div className="title">
						<FormattedMessage id="stepCompletionDetails" />
					</div>
					<Form layout="vertical" size="large" form={form}>
						<Form.Item
							name="summaryOfOutcome"
							value={form.summaryOfOutcome}
							label={<FormattedMessage id="summaryOfOutcome" />}
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
								() => ({
									validator(_, value) {
										const html = value ? value.toHTML() : "";
										if (html !== "" && html !== "<p></p>") {
											setBorderColor("rgb(213, 213, 213)");
											return Promise.resolve();
										}
										setBorderColor("red");
										return Promise.reject("");
									},
								}),
							]}
						// style={{ height: "400px" }}
						>
							<BraftEditor
								value={editorState}
								language="en"
								readOnly={result.status === "finished" ? true : false}
								controlBarClassName="editor-control-bar"
								contentClassName="editorHeight"
								className="editor"
								style={{
									borderColor: borderColor,
									borderRadius: 20,
									border: "1px solid",
								}}
								// contentStyle={{height: "500px"}}
								excludeControls={["emoji", "media", "list-ul", "list-ol"]}
								onChange={(state) => setEditorState(state)}
							/>
						</Form.Item>

						<Form.Item
							name="lessonsLearned"
							value={form.lessons_learned}
							label={<FormattedMessage id="lessonsLearned" />}
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
								() => ({
									validator(_, value) {
										const html = value ? value.toHTML() : "";
										if (html !== "" && html !== "<p></p>") {
											setBorderColor("rgb(213, 213, 213)");
											return Promise.resolve();
										}
										setBorderColor("red");
										return Promise.reject("");
									},
								}),
							]}
						>
							<BraftEditor
								value={editorState}
								language="en"
								readOnly={result.status === "finished" ? true : false}
								controlBarClassName="editor-control-bar"
								contentClassName="editorHeight"
								className="editor"
								style={{
									borderColor: borderColor,
									borderRadius: 20,
									border: "1px solid",
								}}
								excludeControls={["emoji", "media", "list-ul", "list-ol"]}
								onChange={(state) => setEditorState(state)}
							/>
						</Form.Item>

						<div className="completed-documents">
							<FormattedMessage id="completedDocuments" />
						</div>
						{_.isArray(files) && files.length ? (
							files.map((item, key) => (
								<div className="file-entry" key={key}>
									<div className="icon">
										<File />
									</div>
									<a href={item} className="name" target="_blank" rel="noreferrer">
										{item}
									</a>
									{result.status === "finished" ? (
										""
									) : (
										<div
											style={{ cursor: "pointer" }}
											onClick={() => deleteDoc(item)}
										>
											<DeleteOutlined />
										</div>
									)}
								</div>
							))
						) : (
							<Empty description={<FormattedMessage id="noFiles" />} />
						)}
						{result.status !== "finished" ? (
							<>
								<hr />
								<Row>
									<Col md={6} sm={12} className="mb-20">
										<Input
											value={newfile}
											placeholder="Document link"
											onChange={(e) => setNewfile(e.target.value)}
										/>
									</Col>
									<Col className="mb-20 text-center">
										<Button
											type="primary"
											className="add-button"
											shape="round"
											onClick={() => getFiles()}
										>
											<FormattedMessage id="add" />
											<PlusCircle style={{ margin: "0 10px" }} />
										</Button>
									</Col>
								</Row>
							</>
						) : (
							""
						)}
					</Form>
				</div>
			</div>,
			<div className="learning-materials">
				{loadcontent && _.isArray(result.step.material_resources)
					? result.step.material_resources.map((material, i) => {
						return (
							<div key={i} className="material" style={{ marginTop: "10px" }}>
								<div className="icon">
									<PlayCircle />
								</div>
								<div className="title">{material.name}</div>
								<div className="botona">
									<a href={material.link} target="_blank" rel="noreferrer">
										<Button danger type="primary" shape="round">
											<FormattedMessage id="goToResource" />
											<ExternalLink
												style={{
													margin: "0 5px",
												}}
											/>
										</Button>
									</a>
								</div>
							</div>
						);
					})
					: ""}
			</div>,
			<div className="template">
				{loadcontent && _.isArray(result.step.templates)
					? result.step.templates.map((template, i) => {
						return (
							<div key={i} className="material" style={{ marginTop: "10px" }}>
								<div className="icon">
									<Layout />
								</div>
								<div className="title">{template.name}</div>
								<div className="botona">
									<a href={template.link} target="_blank" rel="noreferrer">
										<Button danger type="primary" shape="round">
											<FormattedMessage id="downloadTemplate" />
											<Download
												style={{
													margin: "0 5px",
												}}
											/>
										</Button>
									</a>
								</div>
							</div>
						);
					})
					: ""}
			</div>,
		];

		return (
			<div className="incubator-steps flex flex-col justify-center items-center">
				<Row noGutters className="w-100 justify-center">
					{stepItems}
				</Row>
				{steps.nextbutton ? (
					<Button type="primary" onClick={() => getNextStage()}>
						<FormattedMessage id="continue" />
					</Button>
				) : (
					""
				)}
				{loadcontent ? (
					<Modal
						cancelText={<FormattedMessage id="cancel" />}
						okText={<FormattedMessage id="ok" />}
						title={
							localStorage.getItem("locale") === "ar"
								? result.step.name_ar
								: localStorage.getItem("locale") === "fr"
									? result.step.name_fr
									: result.step.name_en
						}
						visible={isModalVisible}
						onCancel={() => {
							// setresult({ ...result, status: "finished" })
							setEditContent(false)
							setIsModalVisible(false)
						}}
						onOk={() => {
							setIsModalVisible(false);
						}}
						width={1000}
						className="step-modal"
					>
						<Radio.Group
							style={{
								textAlign: "center",
								width: "100%",
							}}
							defaultValue={0}
							value={modalItemsIndex}
							onChange={(e) => {
								setModalItemsIndex(e.target.value);
							}}
							options={[
								{ label: <FormattedMessage id="description" />, value: 0 },
								{
									label: <FormattedMessage id="learningMaterials" />,
									value: 1,
								},
								{ label: <FormattedMessage id="template" />, value: 2 },
							]}
							optionType="button"
							buttonStyle="solid"
						/>
						{modalItems[modalItemsIndex]}
					</Modal>
				) : (
					""
				)}

				<Modal
					cancelText={<FormattedMessage id="cancel" />}
					visible={isModalIIVisible}
					onCancel={() => setIsModalIIVisible(false)}
					onOk={() => {
						openStep();
						setIsModalIIVisible(false);
					}}
					okText={<FormattedMessage id="openStep" />}
					width={700}
					className="open-modal"
				>
					<Result className="pt-1" status="403" title={<FormattedMessage id="lockedtext" />} />
				</Modal>
				<Modal
					cancelText={<FormattedMessage id="cancel" />}
					visible={isStackVisible}
					onCancel={() => setIsStackVisible(false)}
					onOk={() => {
						helpMe();
					}}
					okText={<FormattedMessage id="send" />}
					width={700}
				>
					<Result status="404" title={<FormattedMessage id="helpme" />} />
					<div>
						<Input.TextArea
							className="form-control"
							value={helpMeMessage}
							onChange={(e) => sethelpMeMessage(e.target.value)}
						/>
					</div>
					{ErrHelpme ? (
						<Alert
							style={{ marginTop: "50px" }}
							type="error"
							description={ErrHelpme}
							closable
							onClose={() => setErrHelpme("")}
						/>
					) : (
						""
					)}
				</Modal>
			</div>
		);
	})
);

const StepCercle = ({
	className,
	i,
	step,
	setIsModalVisible,
	setselectedStep,
	setIsModalIIVisible,
}) => (
	<div
		className={classNames("step", className, {
			"step-finished": step.status === "finished",
			"step-unlocked": step.status === "opened",
		})}
		onClick={() => {
			setselectedStep(step);
			step.status === "locked"
				? setIsModalIIVisible(true)
				: setIsModalVisible(true);
		}}
		style={{ cursor: "pointer" }}
	>
		<div className="step-title">
			{" "}
			<FormattedMessage id="step" /> <span>{i}</span>
		</div>
		<div className="step-cercle flex justify-center items-center flex-col">
			<div className="number">{i}</div>
			{step.status === "finished" ? (
				<Check />
			) : step.status === "opened" ? (
				<Unlock />
			) : (
				<Lock />
			)}
		</div>
	</div>
);

const Step = ({ i, mod, children, xs, context }) => (
	<Col
		md={12 / mod}
		sm={12 / mod}
		xs={12}
		className={classNames("flex", "justify-center", "mb-50", "coll", {
			"coll-xs": xs,
		})}
	>
		<Row noGutters className="w-100">
			<Col
				style={{
					height: "100%",
				}}
			>
				{xs ? (
					""
				) : (
					mod === 1 ? (
						<>
							{
								i === 1 ? (
									context.state.direction === "ltr" ? <StepsLeft className="steps-left pos-absolute" /> : <StepsRight className="steps-right pos-absolute" />
								) : (""
								)
							}
							{
								context.state.direction === "ltr" ? <div className="steps-line pos-absolute steps-line-mod-1-left"></div> : <div className="steps-line pos-absolute steps-line-mod-1-right"></div>
							}
						</>
					) : (
						(i + 1) % mod === 1 ? (
							(i + 1) % (mod * 2) === mod + 1 ? (

								context.state.direction === "rtl" ? <StepsRight className="steps-right pos-absolute" /> : <StepsLeft className="steps-left pos-absolute" />
							) : (
								""
							)
						) : (
							<div className="steps-line pos-absolute">
							</div>
						)
					)
				)}
			</Col>
			<Col
				className="flex justify-center items-center pos-relative"
				style={{
					height: "100%",
				}}
			>
				{xs ? (
					<div className="steps-line steps-line-vertical pos-absolute"></div>
				) : (
					<div className="steps-line pos-absolute"></div>
				)}
				{children}
			</Col>
			<Col
				style={{
					height: "100%",
				}}
			>
				{xs ? (
					""
				) : mod === 1 ? (
					<>
						{
							i === 0 ? (
								context.state.direction === "ltr" ? <StepsRight className="steps-right pos-absolute" /> :
									<StepsLeft className="steps-left pos-absolute" />

							) : (
								""
							)
						}
						{
							context.state.direction === "ltr" ? <div className="steps-line pos-absolute steps-line-mod-1-right"></div> : <div className="steps-line pos-absolute steps-line-mod-1-left"></div>
						}
					</>

				) : (
					(i + 1) % mod === 0 ? (
						(i + 1) % (mod * 2) === 0 ? (
							""
						) : (
							context.state.direction === "rtl" ? <StepsLeft className="steps-left pos-absolute" /> : <StepsRight className="steps-right pos-absolute" />
						)
					) : (
						<div className="steps-line pos-absolute"></div>
					)
				)}
			</Col>
		</Row>
	</Col>
);
