import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Col, Row } from "reactstrap"
import "../../sass/HelpMenu/HelpMenu.scss"
import { PlusOutlined, NodeIndexOutlined, UngroupOutlined, UsergroupAddOutlined, ContainerOutlined, UserOutlined, BulbOutlined, SolutionOutlined } from '@ant-design/icons';



const HelpMenu = (props) => {
	const history = useHistory()
	return (
		<div className="help_menu">
			{props.user.account_type === "idea_holder" ?

				<Row className="content">
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/ideas/new")}>
						<p><FormattedMessage id="New_Idea" /></p>
						<PlusOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/incubator")}>
						<p><FormattedMessage id="incubator" /></p>
						<NodeIndexOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/sessions")}>
						<p><FormattedMessage id="sessions" /></p>
						<UngroupOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/ideas")}>
						<p><FormattedMessage id="Ideas" /></p>
						<BulbOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/ideas/me")}>
						<p><FormattedMessage id="MyIdeas" /></p>
						<SolutionOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/invites")}>
						<p><FormattedMessage id="invites" /></p>
						<UsergroupAddOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/explore/all")}>
						<p><FormattedMessage id="profiles" /></p>
						<UserOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/process")}>
						<p><FormattedMessage id="ProcessInfo" /></p>
						<ContainerOutlined style={{ fontSize: "30px" }} />
					</Col>
				</Row >
				:
				<Row className="content">
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/sessions/create")}>
						<p><FormattedMessage id="Createslots" /></p>
						<PlusOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/incubator")}>
						<p><FormattedMessage id="incubator" /></p>
						<NodeIndexOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/sessions")}>
						<p><FormattedMessage id="sessions" /></p>
						<UngroupOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/ideas")}>
						<p><FormattedMessage id="Ideas" /></p>
						<BulbOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/explore/all")}>
						<p><FormattedMessage id="profiles" /></p>
						<UserOutlined style={{ fontSize: "30px" }} />
					</Col>
					<Col md={3} sm={2} xs={1} className="item-menu" onClick={() => history.push("/app/process")}>
						<p><FormattedMessage id="ProcessInfo" /></p>
						<ContainerOutlined style={{ fontSize: "30px" }} />
					</Col>
				</Row>
			}
		</div >

	)

}

const mapStateToProps = (state) => ({
	user: state.auth.login.user
})
const mapDispatchToProps = {}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HelpMenu))