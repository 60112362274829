import Api from "./Api";

const ManageTeam = {
  /*
   ** Get requests sended
   */
  getRequests(IdeaId) {
    return new Promise((resolve, reject) => {
      Api()
        .get(`/teams/${IdeaId}/invites`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  /*
   ** Get My requests
   */
  getMyRequests() {
    return new Promise((resolve, reject) => {
      Api()
        .get(`/teams/invites/me`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  /*
   ** acceptRequests requests
   */
  acceptRequests(ideaId) {
    return new Promise((resolve, reject) => {
      Api()
        .post(`/teams/${ideaId}/accept`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  /*
   ** reject requests
   */
  rejectRequests(ideaId) {
    return new Promise((resolve, reject) => {
      Api()
        .post(`/teams/${ideaId}/reject`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  /*
   ** Get Team Members
   */
  getTeamMembers(ideaID) {
    return new Promise((resolve, reject) => {
      Api()
        .get(`/teams/me/${ideaID}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  /*
   ** Get Users for search
   */
  getUsers(url) {
    return new Promise((resolve, reject) => {
      Api()
        .get(url ? `/users/?sort=begin&limit=6${url}` : "/users/?sort=begin&limit=6")
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  /*
   ** Get Users with name
   */
  sendRequest(data) {
    return new Promise((resolve, reject) => {
      Api()
        .post(`/teams/${data.ideaId}/request`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  /*
   ** Send request to user
   */
  getUsersbyName(name) {
    return new Promise((resolve, reject) => {
      Api()
        .get(`/users/?account=idea_holder&limit=5&search_query=${name}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  /*
   ** Delete Or cancel Request sended
   */
  deleteRequest(data) {
    return new Promise((resolve, reject) => {
      Api()
        .delete(`/teams/${data.ideaId}/request`, { data: { userId: data.userId } })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  /*
   ** Edit Team Member Position
   */
  editPosition(data) {
    return new Promise((resolve, reject) => {
      Api()
        .patch(
          `/teams/${data.ideaId}/position/${data.userId}`,
          data
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  /*
   ** Edit Team Member Role
   */
  editRole(data) {
    return new Promise((resolve, reject) => {
      Api()
        .patch(
          `/teams/${data.ideaId}/admin/${data.userId}`
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  /*
   ** Delete Team Member from Idea.
   */
  deleteMember(data) {
    return new Promise((resolve, reject) => {
      Api()
        .delete(
          `/teams/${data.ideaId}/${data.userId}`
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};
export default ManageTeam;
