import { Dropdown, Empty, Menu, Popover } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Check, ExternalLink, MoreHorizontal, XCircle } from "react-feather";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { IntlContext } from "../../context/internationalization";
import "../../sass/Notifications/NotifPopover.scss";
import classNames from "classnames";
import {
	getNotifications,
	ReadAllNotifications,
	ReadNotificationsById,
} from "../../redux/actions/Notifications/Notifications";
import _ from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserMethods from "../../Helpers/UserHelpers";
import NotifyMethods from "../../Helpers/Notifications";
import InfiniteScroll from "react-infinite-scroll-component";
import { PulseLoader } from "react-spinners";
import moment from "moment-timezone";
import { socket } from "../../hooks/Socket";

export const NotifEntry = (props) => {
	const { state } = useContext(IntlContext);
	const [showMenu, setShowMenu] = useState(false);
	const history = useHistory();
	const timezone = moment.tz.guess();
	return (
		<div
			style={{ cursor: "pointer" }}
			className={classNames("notif-entry", {
				"cursor-pointer": props.link !== "",
			})}
			onClick={() => {
				if (props.link !== "") {
					history.push(props.link);
					if (props.setVisible) props.setVisible(false);
				}
				props.readNotif(props.notif.notification_id);
			}}
			onMouseEnter={() => {
				setShowMenu(true);
			}}
			onMouseLeave={() => {
				setShowMenu(false);
			}}
		>
			{props.imgSrc === "" ? (
				""
			) : (
				<div
					className="image"
					style={{
						backgroundImage: `url('${props.imgSrc}')`,
					}}
				></div>
			)}
			<div className="notif">
				<div className="notif-title">{props.title}</div>
				<div
					className={classNames("notif-content", {
						"notif-content-unread": props.unread,
					})}
				>
					{props.content}
				</div>
				<div style={{ fontSize: "10px" }}>
					{moment(props.notif.created_at).clone().tz(timezone).fromNow()}
				</div>
			</div>

			{props.unread && <div className="unread-circle"></div>}
			{props.withMenu && (
				<div
					className={classNames("notif-menu", {
						"notif-menu-show": showMenu,
						"notif-menu-hide": !showMenu,
					})}
				>
					<Dropdown
						trigger={["click"]}
						placement={state.direction === "ltr" ? "bottomRight" : "bottomLeft"}
						overlay={
							<Menu>
								<Menu.Item>
									<Check size={15} style={{ margin: "0 10px" }} />{" "}
									<FormattedMessage id="markAsRead" />
								</Menu.Item>
								<Menu.Item>
									<XCircle size={15} style={{ margin: "0 10px" }} />{" "}
									<FormattedMessage id="removeNotification" />
								</Menu.Item>
							</Menu>
						}
					>
						<MoreHorizontal />
					</Dropdown>
				</div>
			)}
		</div>
	);
};

const NotifPopover = (props) => {
	const history = useHistory();
	const { state } = useContext(IntlContext);
	const [Notifs, setNotifs] = useState([]);
	const [results, setResults] = useState([]);
	const [visible, setVisible] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (!props.parentVisible && visible) setVisible(false);
		// eslint-disable-next-line
	}, [props.parentVisible]);

	useEffect(() => {
		props.CleanNotificationCount();
		getNotifications();
		// eslint-disable-next-line
	}, [props]);
	useEffect(() => {
		socket.on("new_notification", (data) => {
			if (
				data &&
				data.received_by &&
				Array.isArray(data.received_by) &&
				data.received_by.some((one) => one === props.user._id)
			) {
				getNotifications();
			}
		});
		return () => { socket.off("new_notification"); };
		// eslint-disable-next-line
	}, []);
	const getNotifications = () => {
		props.getNotifications(1).then((res) => {
			if (res && res.status === "success") {
				if (res.result.hasNextPage) {
					setHasMore(true);
					setPage(res.result.nextPage);
				}
				setNotifs(res.result.Notifications);
				setResults(res.result);
			}
		});
	};
	const getNotificationsbyPage = () => {
		if (results.hasNextPage) {
			props.getNotifications(page).then((res) => {
				if (res && res.status === "success") {
					setNotifs((Notifs) => [...Notifs, ...res.result.Notifications]);
					setResults(res.result);
				}
			});
		} else setHasMore(false);
	};
	// Read Just One notification
	const ReadNotif = (id) => {
		props.ReadNotificationsById(id).then((res) => {
			if (res && res.status === "success" && res.updated === 1) {
				getNotifications();
			}
		});
	};
	const ReadAllNotif = () => {
		props.ReadAllNotifications().then((res) => {
			if (res && res.status === "success") {
				getNotifications();
			}
		});
	};
	return (
		<div className="notif-popover">
			<div className="header flex items-center">
				<div className="title">
					<FormattedMessage id="notifications" />
				</div>
				<Popover
					trigger="click"
					visible={visible}
					content={
						<div className="notifs-more">
							<div
								className="potona"
								onClick={() => {
									ReadAllNotif();
									setVisible(!visible);
								}}
							>
								<Check size={15} style={{ margin: "0 10px" }} />
								<FormattedMessage id="markAllAsRead" />
							</div>
							<div
								className="potona"
								onClick={() => {
									history.push("/app/notifications");
									props.setParentVisible(false);
								}}
							>
								<ExternalLink size={15} style={{ margin: "0 10px" }} />
								<FormattedMessage id="seeAll" />
							</div>
						</div>
					}
					placement={state.direction === "ltr" ? "bottomRight" : "bottomLeft"}
				>
					<MoreHorizontal
						className="cursor-pointer"
						onClick={() => setVisible(!visible)}
					/>
				</Popover>
			</div>
			<div className="notifs-wrapper" id="notifsWrapper2">
				<InfiniteScroll
					dataLength={Notifs.length}
					next={getNotificationsbyPage}
					loader={<PulseLoader color="#458ff6" size={8} />}
					scrollableTarget="notifsWrapper2"
					scrollThreshold={1}
					hasMore={hasMore}
				>
					{_.isArray(Notifs) && Notifs.length ? (
						Notifs.map((notif, i) => {
							return (
								<NotifEntry
									key={i}
									readNotif={ReadNotif}
									setVisible={props.setParentVisible}
									notif={notif}
									title={
										notif.sent_by.firstname
											? notif.sent_by.firstname.toUpperCase() +
											" " +
											notif.sent_by.lastname.toUpperCase()
											: ""
									}
									unread={
										notif.read_by.some((one) => one === props.user._id)
											? false
											: true
									}
									content={
										<div>
											<FormattedMessage id={notif.type} />
										</div>
									}
									link={NotifyMethods.getLinkbyType(notif)}
									imgSrc={notif.sent_by.avatar ? UserMethods.getUserImage(
										notif.sent_by.userId,
										notif.sent_by.avatar
									) : UserMethods.getUserAvatar()}
								/>
							);
						})
					) : (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={<FormattedMessage id="noNotifications" />}
						/>
					)}
				</InfiniteScroll>
			</div>
		</div>
	);
};

NotifEntry.defaultProps = {
	link: "",
	imgSrc: "",
	title: "",
	content: "",
	unread: false,
	withMenu: false,
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

export default connect(mapStateToProps, {
	getNotifications,
	ReadAllNotifications,
	ReadNotificationsById,
})(NotifPopover);
