import { combineReducers } from "redux";
import auth from "./auth";
import ideas from "./IdeaHolderReducers"
import Path from "./Path"

export default combineReducers({
  auth,
  ideas,
  Path
});
