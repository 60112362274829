import { Empty } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { Edit2 } from "react-feather";
import { PulseLoader } from "react-spinners";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { socket } from "../../hooks/Socket";
import UserService from "../../services/user";
import Conversation from "./Conversation";

const Convs = (props) => {
	const history = useHistory()
	return (
		<div className="carta convs">
			<div className="convs-profile mb-20">
				<div className="flex justify-center items-center">
					{props.me.avatar ? <Avatar
						style={{
							margin: "0 10px",
						}}
						src={UserService.avatarLink({
							avatar: props.me.avatar,
							userId: props.me.userId,
						})}
						size="large"
					/> : ''}

					<div className="name-post">
						<div className="name">{`${props.me.firstname} ${props.me.lastname}`}</div>
					</div>
				</div>
				<Edit2 size={15} style={{ margin: "0 10px", cursor: "pointer" }} onClick={() => history.push("/app/profile/edit")} />
			</div>
			{props.loadingConversation ? (
				<div className="convs-convs">
					{props.data && props.data.length ? (
						props.data.map((conv, i) => {
							return (
								<Conversation
									key={i}
									conv={conv}
									type={props.type}
									onClick={() => {
										props.setRecipient(null);
										if (props.type === "teams") {
											props.chenageValue(conv);
											socket.emit("join_conversation", { conv_id: conv._id });
										} else if (props.type === "dm") {
											props.chenageValue(conv);
											conv.dm_members.forEach((user, i) => {
												if (user._id !== props.me._id) {
													props.setUserdmprops(user);
												}
											});
											socket.emit("join_conversation", { conv_id: conv._id });
										} else if (props.type === "sessions") {
											props.chenageValue(conv);
											socket.emit("join_conversation", { conv_id: conv._id });
										}

									}}
								/>
							);
						})
					) : (
						<Empty description="No Convs" />
					)}
				</div>
			) : (
				<div className="convs-convs-loader">	<PulseLoader color="#458ff6" size={8} /> </div>
			)}
		</div>
	);
};

Convs.defaultProps = {
	type: "dm",
};

const mapStateToProps = (state) => ({
	me: state.auth.login.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Convs);
