import Path from "../../../services/Path";

/*
 **  Path Section
 */

/*
 **  Show current user chosen paths for their ideas
 */

export const getPath = (ideaId) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Path.getPath(ideaId)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 **  Show Stages info
 */

export const getStageinfo = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Path.getStageinfo(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 **  Show Step info
 */

export const getStepinfo = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Path.getStepinfo(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** document links accepts [array of url strings]
 */

export const finishStep = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Path.finishStep(data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};

/*
 **  Opens a Path Step in a given Stage, previous steps or stages must be completed
 */

export const openStep = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Path.openStep(data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};

/*
 **  List Available Processes
 */

export const getAvailableProcess = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Path.getAvailableProcess()
				.then((res) => {
					if (res.status === "success")
						dispatch({
							type: "GET_PROCESS",
							process_info: res.processes,
						});
				})
				.catch((err) => {
					if (err.response && (err.response.status === 404 || err.response.status === 401)) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Show A Stage Info
 */

export const getStageById = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Path.getStageById()
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Show A Step Info
 */

export const getStepById = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {


			Path.getStepById(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};

/*
**	Help me i'm stack 
*/
export const helpMeImStack = (data) => {
	return new Promise((resolve, reject) => {
		Path.helpMeImStack(data)
			.then(res => {
				if (res.status === "success") resolve(res)
			})
			.catch((err) => {
				if (err.response.status === 404 || err.response.status === 401) {
					const res = err.response.data;
					if (res.status && res.status === "failure") resolve(res);
					else reject(err);
				} else reject(err);
			});
	})
}

export const EditStep = (data) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			Path.EditStep(data)
				.then(res => {
					if (res.status === "success") resolve(res)
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		})
	}
}

export const getIncubationTimeline = (idea) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			Path.getIncubationTimeline(idea)
				.then(res => {
					if (res.status === "success") resolve(res)
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		})
	}
}