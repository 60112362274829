import React, { useState } from "react";
import "../../sass/ManageTeam/EditPosition.scss";
import { Select, Input, notification } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { X } from "react-feather";
import {
	editPosition,
	editRole,
} from "../../redux/actions/Manageteam/TeamActions";
import { connect } from "react-redux";
import { socket } from "../../hooks/Socket";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { Option } = Select;

const EditPosition = (props) => {
	const { intl } = props;
	const [position, setPosition] = useState("");
	const [positionerr, setPositionerr] = useState("");
	const history = useHistory()


	const editPose = () => {
		if (position) {
			const data = {
				ideaId: props.idea,
				userId: props.data.user.userId,
				position,
			};
			props.editPosition(data).then((res) => {
				if (res.status === "success") {
					notification["success"]({
						message: intl.formatMessage({ id: "success" }),
						// description: intl.formatMessage({ id: res.msg }),
					});

					props.getTeamMembers();
					props.onClose();
					let dta = [];
					dta = [...dta, props.data.user._id];
					socket.emit("new_notification", {
						event_type: "edit_position",
						received_by: dta,
						ideaId: props.idea,
						idea: props.idea_id,
					});
				} else if (res.status === "failure")
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: res.msg }),
					});
			});
		} else setPositionerr(<FormattedMessage id="EmptyError" />);
	};

	const editRole = () => {
		const data = {
			ideaId: props.idea,
			userId: props.data.user.userId,
			position,
		};
		props.editRole(data).then((res) => {
			if (res.status === "success") {
				props.getTeamMembers();
				props.onClose();
				notification["success"]({
					message: intl.formatMessage({ id: "success" }),
					// description: intl.formatMessage({ id: res.msg }),
				});
				let dta = [];
				dta = [...dta, props.data.user._id];
				socket.emit("new_notification", {
					event_type: "edit_role",
					received_by: dta,
					ideaId: props.idea,
					idea: props.idea_id,
				});
			} else if (res.status === "failure")
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: res.msg }),
				});
		}).catch((err) => { history.push("/500") });
	};

	return (
		<>
			<X color="#FF0000" className="x" onClick={props.onClose} />
			<h6>
				{props.edit === "role"
					? "add new role"
					: props.edit === "pose"
						? "add new position"
						: ""}
			</h6>
			{props.edit === "role" ? (
				<Select className="select tt">
					<Option value="admin">
						<FormattedMessage id="admin" />
					</Option>
				</Select>
			) : props.edit === "pose" ? (
				<FormattedMessage id="enterMemberPosition">
					{(msg) => (
						<>
							<Input
								// size="large"
								className="autocomplete-search form-control"
								style={positionerr ? { borderColor: "red" } : {}}
								placeholder={msg}
								value={position}
								onChange={(e) => setPosition(e.target.value)}
							/>
							<span style={{ color: "red" }}>{positionerr}</span>
						</>
					)}
				</FormattedMessage>
			) : (
				""
			)}
			{props.edit === "role" ? (
				<div className="saveButton" onClick={() => editRole()}>
					Save
				</div>
			) : props.edit === "pose" ? (
				<div className="saveButton" onClick={() => editPose()}>
					Save
				</div>
			) : (
				""
			)}
		</>
	);
};
const mapStateToProps = (state) => ({});
export default injectIntl(
	connect(mapStateToProps, { editPosition, editRole })(EditPosition)
);
