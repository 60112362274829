import React, { Component } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { TransitionGroup } from "react-transition-group";
import IdeasMethods from "../Helpers/IdeaHelpers";
import "../sass/Carousel.scss";
import "../sass/Ideas/ideainfo.scss";

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      level: this.props.level,
      image: this.props.image,
      ideaInfo: this.props.ideaInfo,
    };
  }

  render() {
    const className = "item image level" + this.props.level;
    return (
      <div
        className={className}
        style={{
          backgroundImage: `url('${IdeasMethods.getIdeaImage(this.state.ideaInfo, this.state.image)}')`,
        }}
      ></div>
    );
  }
}

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
      ideaInfo: this.props.ideaInfo,
      active: this.props.active,
      setActive: this.props.setActive,
      direction: "",
    };
    this.rightClick = this.moveRight.bind(this);
    this.leftClick = this.moveLeft.bind(this);
  }

  generateItems() {
    var generatedItems = [];
    var level;
    for (var i = this.state.active - 2; i < this.state.active + 3; i++) {
      var index = i;
      if (i < 0) {
        index = this.state.items.length + i;
      } else if (i >= this.state.items.length) {
        index = i % this.state.items.length;
      }
      level = this.state.active - i;

      if (index < 0) index = 0
      generatedItems.push(
        <Item
          key={i}
          image={this.state.items[index]}
          ideaInfo={this.state.ideaInfo.ideaId}
          level={level}
        />
      );
    }
    return generatedItems;
  }

  moveLeft() {
    var newActive = this.state.active;
    newActive--;
    this.state.setActive(
      newActive < 0 ? this.state.items.length - 1 : newActive
    );
    this.setState({
      active: newActive < 0 ? this.state.items.length - 1 : newActive,
      direction: "left",
    });
  }

  moveRight() {
    var newActive = this.state.active;
    this.state.setActive((newActive + 1) % this.state.items.length);
    this.setState({
      active: (newActive + 1) % this.state.items.length,
      direction: "right",
    });
  }

  render() {
    return (
      <div id="carousel" className="noselect">
        <ChevronLeft
          className="arrow arrow-left"
          onClick={this.leftClick}
          color="#228291"
        />
        <TransitionGroup transitionname={this.state.direction}>
          {this.generateItems()}
        </TransitionGroup>
        <ChevronRight
          className="arrow arrow-right"
          onClick={this.rightClick}
          color="#228291"
        />
      </div>
    );
  }
}
