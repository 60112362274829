import React from "react";
import "../css/Heading.css";

export default function Heading({ text, color, ...rest }) {
  return (
    <div className="heading-comp">
      <h5
        className={"heading-2 " + (rest.className ? rest.className : "")}
        style={{ color: color === "primary" ? "#458ff6" : color }}
      >
        {text}
      </h5>
      <hr
        style={{
          backgroundColor: color === "primary" ? "#458ff6" : color,
          borderColor: color === "primary" ? "#458ff6" : color,
        }}
      />
    </div>
  );
}
