import moment from "moment";
/*
 ** Sessions Helpers
 */
const SeccionsMethods = {
	MergeDateAndTime: (selectedDate, addedSlot) => {
		return new Promise((resolve, reject) => {
			const date = moment(selectedDate).format("YYYY-MM-DD");
			const time = moment(addedSlot).format("HH:mm");
			const newSlot = moment(date + "  " + time, "YYYY-MM-DD HH:mm");
			if (newSlot) resolve(newSlot);
			else reject("err");
		});
	},
	getSlotsByDate: (date) => {
		return new Promise((resolve, reject) => {
			const newDate = moment(date).format("YYYY-MM-DD");
			const params = {
				begin: newDate,
				end: newDate,
			};
			if (params) resolve(params);
			else reject("err");
		});
	},
	getSlotsByRange: (data) => {
		return new Promise((resolve, reject) => {
			const params = {
				begin: moment(data.begin).format("YYYY-MM-DD"),
				end: moment(data.end).format("YYYY-MM-DD"),
			};
			if (params) resolve(params);
			else reject("err");
		});
	},
	getSliderValue: (val) => {
		return new Promise((resolve, reject) => {
			val === 0
				? resolve(0)
				: val === 20
					? resolve(1)
					: val === 40
						? resolve(2)
						: val === 60
							? resolve(3)
							: val === 80
								? resolve(4)
								: val === 100
									? resolve(5)
									: resolve(0);
		});
	},
	FiltersSessions: (Sessions) => {
		return new Promise((resolve, reject) => {
			let Sess = [];
			Sessions.forEach((se, i) => {
				if (se.session_status !== "Available") Sess = [...Sess, se];
			});
			Sess.length ? resolve(Sess) : reject("err");
		});
	},
	getDate: (dt) => {
		return new Promise((resolve, reject) => {
			const newdate = new Date(dt);
			if (newdate) resolve(moment(newdate).format("YYYY-MM-DD"));
			else reject("error");
		});
	},
	getDateRange: (Range) => {
		return new Promise((resolve, reject) => {
			let from = new Date(Range[0]);
			from = moment(from).format("YYYY-MM-DD");
			let to = new Date(Range[1]);
			to = moment(to).format("YYYY-MM-DD");
			if (from && to) resolve(`&begin=${from}&end=${to}`);
			else reject("error");
		});
	},
	FilterSessionType: (filters) => {
		return new Promise((resolve, reject) => {
			let url = "";
			filters.Status && (url = `&status=${filters.Status}`)
			filters.availability && filters.availability.length
				? SeccionsMethods.getDateRange(filters.availability)
					.then((res) => {
						res && (url += res)
						resolve(url);
					})
					.catch((err) => reject("error_1"))
				: resolve(url);
		});
	},
	CheckFinishedSession: (result) => {
		return new Promise((resolve, reject) => {
			const now = moment();
			let end = "";
			result.forEach(res => {
				end = moment(res.end_at);
				if (now.diff(end, "hours") >= 1)
					resolve({ state: true, SessionID: res.sessionId });
				else resolve({ state: false });
			})
		});
	},
};

export default SeccionsMethods;
