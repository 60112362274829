import React, { useContext, useEffect, useState } from "react";
import {
	Check,
	Download,
	ExternalLink,
	File,
	Layout,
	Lock,
	PlayCircle,
	Unlock,
} from "react-feather";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row } from "reactstrap";
import {
	Button,
	Form,
	Grid,
	Modal,
	Radio,
	Empty,
} from "antd";
import classNames from "classnames";
import { ReactComponent as StepsLeft } from "../../assets/App/Incubator/stepsLeft.svg";
import { ReactComponent as StepsRight } from "../../assets/App/Incubator/stepsRight.svg";
import { ReactComponent as StepsDashedLine } from "../../assets/App/Incubator/stepsDashedLine.svg";
import { ReactComponent as StepsDashedLineMD } from "../../assets/App/Incubator/stepsDashedLineMD.svg";
import BraftEditor from "braft-editor";
import "../../sass/Incubator/StageStep.scss";
import { connect } from "react-redux";
import {
	getStepinfo,
	openStep,
	finishStep,
	helpMeImStack,
	EditStep
} from "../../redux/actions/Path/PathActions";
import { getSessionAsMember } from "../../redux/actions/Sessions/SessionsActions";
import { useForm } from "antd/lib/form/Form";
import PathMethods from "../../Helpers/PathHelpers";
import _ from "lodash";
import { IntlContext } from "../../context/internationalization";


const mapPropsToState = (state) => ({});

const { useBreakpoint } = Grid;
//stage_info
export const Stage = (props) => {
	const { stages, setselectedStage } = props;
	const [cercles, setCercles] = useState([]);
	const rayon = props.size / 2 - 5;
	const perimeter = 2 * rayon * Math.PI;
	const center = props.size / 2;
	const percent = Math.max(stages.steps_finished, 0) / stages.total_steps;

	useEffect(() => {
		const items = [];
		let finished = stages.steps_finished + 1;
		for (let i = 0; i < stages.total_steps; i++) {
			items.push(
				<div
					key={i + 1}
					className="stage-cercle"
					style={{
						top:
							center +
							rayon *
							Math.sin(
								((2 * Math.PI) / stages.total_steps) * i - Math.PI / 2
							),
						left:
							center +
							rayon *
							Math.cos(
								((2 * Math.PI) / stages.total_steps) * i - Math.PI / 2
							),
						backgroundColor: finished > 0 ? "#458ff6" : "white",
						border: finished > 0 ? "none" : "2px solid #dfdfdf",
					}}
				></div>
			);
			finished--;
		}
		setCercles(items);
		// eslint-disable-next-line
	}, [center, stages.total_steps, rayon]);

	return (
		<div
			className="incubator-stage"
			style={{
				width: props.size + 20,
				height: props.size + 20,
				cursor: "pointer",
			}}
			onClick={() => {
				setselectedStage(stages);
			}}
		>
			<div
				className="stage-name"
				style={{
					width: props.size + 20,
					// height: props.size + 20,
					borderRadius: props.size,
					overflow: "hidden",
					fontSize: props.size / 8,
				}}
			>
				{localStorage.getItem("locale") === "ar"
					? stages.stage_info.name_ar
					: localStorage.getItem("locale") === "fr"
						? stages.stage_info.name_fr
						: stages.stage_info.name_en}
			</div>
			<svg width={props.size + 20} height={props.size + 20}>
				<circle
					cx="50%"
					cy="50%"
					r={rayon}
					fill="none"
					stroke="#dfdfdf"
					strokeWidth="3px"
				></circle>
				<circle
					cx="50%"
					cy="50%"
					r={rayon}
					fill="none"
					stroke="#458ff6"
					strokeWidth="5px"
					strokeDasharray={`${percent * perimeter}px ${perimeter - percent * perimeter
						}px`}
					strokeDashoffset={perimeter / 4}
				></circle>
			</svg>
			{cercles}
		</div>

	);
};

export const Steps = injectIntl(
	connect(mapPropsToState, {
		getStepinfo,
		openStep,
		finishStep,
		helpMeImStack,
		getSessionAsMember,
		EditStep
	})((props) => {
		const { steps } = props;
		const [stepItems, setStepItems] = useState([]);
		const [isModalVisible, setIsModalVisible] = useState(false);
		const [loadcontent, setloadcontent] = useState(false);
		const [modalItemsIndex, setModalItemsIndex] = useState(0);
		const [selectedStep, setselectedStep] = useState([]);
		const [result, setresult] = useState([]);
		const context = useContext(IntlContext);

		const [form] = useForm();

		const [editorState, setEditorState] = useState(() =>
			BraftEditor.createEditorState(null)
		);
		const [borderColor, setBorderColor] = useState("#999999");
		const [files, setFiles] = useState([]);
		// const bp = useBreakpoint();
		const bps = useBreakpoint();

		useEffect(() => {
			if (selectedStep.step_id) getStepinfo();
			// eslint-disable-next-line
		}, [selectedStep]);
		const getStepinfo = () => {
			const data = {
				ideaId: steps.idea._id,
				stepId: selectedStep.step_id,
			};
			props.getStepinfo(data).then((res) => {
				setloadcontent(false);
				setresult([]);
				if (res.status === "success") {
					setresult(res.result);

					form.setFieldsValue({
						summaryOfOutcome: BraftEditor.createEditorState(res.result.outcome),
						lessonsLearned: BraftEditor.createEditorState(
							res.result.lessons_learned
						),
					});
					setFiles(res.result.document_links);
					setloadcontent(true);
				} else setresult([]);
			});
		};
		useEffect(() => {
			const { md, xs } = bps;
			const items = [];
			let { total, allSteps } = steps;
			const mod = md ? 3 : xs ? 1 : 2;
			allSteps.forEach((element, i) => {
				i < total && items.push(
					<StepCercle
						key={element._id}
						setselectedStep={setselectedStep}
						i={i}
						step={element}
						result={result}
						getStepinfo={getStepinfo}
						setIsModalVisible={setIsModalVisible}

					/>
				);
			});

			//GENERATE LINES.
			for (let i = 0; i < total - (total % mod); i++) {
				items[i] = (
					<Step key={i + 1} i={i} mod={mod} xs={xs} context={context}>
						{items[i]}
					</Step>
				);
			}
			let rest = total % mod;
			let multiplier = (total - (total % mod)) % (mod * 2) === 0 ? 1 : 2;
			// ----------------- REST ----------------------
			for (let i = total - (total % mod); i < total; i++) {
				items[i] = (
					<Step key={i + 1} i={i - total + multiplier * (total % mod)} mod={total % mod} xs={xs} context={context}>
						{items[i]}
					</Step>
				);
			}

			//----------------------------------------------
			// Push the dashed line
			items.push(
				<Col key={Math.random()} md={12 / mod} sm={12 / mod} xs={12} className="flex justify-center mb-50 coll pos-relative">
					{
						md ?
							<StepsDashedLine
								className={classNames("steps-dashed-line", "pos-absolute", {
									"steps-dashed-line-reversed": (rest === 0 && total % (mod * 2) !== 0) || (rest !== 0 && multiplier === 1),
								})}
							/> :
							<StepsDashedLineMD
								className={classNames("steps-dashed-line", "steps-dashed-line-md", "pos-absolute", {
									"steps-dashed-line-md-reversed": (rest === 0 && total % (mod * 2) !== 0) || (rest !== 0 && multiplier === 1),
								})}
							/>
					}
				</Col>
			);
			total++;
			setStepItems(items);
			// eslint-disable-next-line
		}, [bps, steps.finished, steps.total]);

		useEffect(() => {
			if (steps.stages[steps.stages.length - 1] !== steps.stage)
				steps.setNextbutton(true);
			else steps.setNextbutton(false);
			// eslint-disable-next-line
		}, [steps.stage]);
		const getNextStage = () => {
			if (steps.stages[steps.stages.length - 1] !== steps.stage)
				PathMethods.getNextStage(steps.stages, steps.stage).then((res) => {
					if (res.nextbtn === "true") {
						steps.setNextbutton(false);
					}
					steps.setselectedStage(res.newstage);
				});
			else steps.setNextbutton(false);
		};
		const modalItems = [
			<div className="step-description">
				<div className="title">
					<FormattedMessage id="stepDescription" />
				</div>
				<div
					className="desc"
					dangerouslySetInnerHTML={
						loadcontent
							? localStorage.getItem("locale") === "ar"
								? { __html: result.step.description_ar }
								: localStorage.getItem("locale") === "fr"
									? { __html: result.step.description_fr }
									: { __html: result.step.description_en }
							: ""
					}
				>
					{ }
				</div>
				{result.status === "finished" && <div className="step-completion">
					<div className="title">
						<FormattedMessage id="stepCompletionDetails" />
					</div>
					<Form layout="vertical" size="large" form={form}>
						<Form.Item
							name="summaryOfOutcome"
							value={form.summaryOfOutcome}
							label={<FormattedMessage id="summaryOfOutcome" />}
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
								() => ({
									validator(_, value) {
										const html = value ? value.toHTML() : "";
										if (html !== "" && html !== "<p></p>") {
											setBorderColor("rgb(213, 213, 213)");
											return Promise.resolve();
										}
										setBorderColor("red");
										return Promise.reject("");
									},
								}),
							]}

						// style={{ height: "400px" }}
						>
							<BraftEditor
								value={editorState}
								language="en"
								readOnly={result.status === "finished" ? true : false}
								controlBarClassName="editor-control-bar"
								contentClassName="editorHeight"
								className="editor"
								style={{
									borderColor: borderColor,
									borderRadius: 20,
									border: "1px solid",
								}}
								// contentStyle={{height: "500px"}}
								excludeControls={["emoji", "media", "list-ul", "list-ol"]}
								onChange={(state) => setEditorState(state)}
							/>
						</Form.Item>

						<Form.Item
							name="lessonsLearned"
							value={form.lessons_learned}
							label={<FormattedMessage id="lessonsLearned" />}
							rules={[
								{
									required: true,
									message: <FormattedMessage id="EmptyError" />,
								},
								() => ({
									validator(_, value) {
										const html = value ? value.toHTML() : "";
										if (html !== "" && html !== "<p></p>") {
											setBorderColor("rgb(213, 213, 213)");
											return Promise.resolve();
										}
										setBorderColor("red");
										return Promise.reject("");
									},
								}),
							]}
						>
							<BraftEditor
								value={editorState}
								language="en"
								readOnly={result.status === "finished" ? true : false}
								controlBarClassName="editor-control-bar"
								contentClassName="editorHeight"
								className="editor"
								style={{
									borderColor: borderColor,
									borderRadius: 20,
									border: "1px solid",
								}}
								excludeControls={["emoji", "media", "list-ul", "list-ol"]}
								onChange={(state) => setEditorState(state)}
							/>
						</Form.Item>

						<div className="completed-documents">
							<FormattedMessage id="completedDocuments" />
						</div>
						{_.isArray(files) && files.length ? (
							files.map((item, key) => (
								<div className="file-entry" key={key}>
									<div className="icon">
										<File />
									</div>
									<a href={item} className="name" target="_blank" rel="noreferrer">
										{item}
									</a>
								</div>
							))
						) : (
							<Empty description={<FormattedMessage id="noFiles" />} />
						)}
					</Form>

				</div>}
			</div>,
			<div className="learning-materials">
				{loadcontent && _.isArray(result.step.material_resources)
					? result.step.material_resources.map((material, i) => {
						return (
							<div key={i} className="material" style={{ marginTop: "10px" }}>
								<div className="icon">
									<PlayCircle />
								</div>
								<div className="title">{material.name}</div>
								<div className="botona">
									<a href={material.link} target="_blank" rel="noreferrer">
										<Button danger type="primary" shape="round">
											<FormattedMessage id="goToResource" />
											<ExternalLink
												style={{
													margin: "0 5px",
												}}
											/>
										</Button>
									</a>
								</div>
							</div>
						);
					})
					: ""}
			</div>,
			<div className="template">
				{loadcontent && _.isArray(result.step.templates)
					? result.step.templates.map((template, i) => {
						return (
							<div key={i} className="material" style={{ marginTop: "10px" }}>
								<div className="icon">
									<Layout />
								</div>
								<div className="title">{template.name}</div>
								<div className="botona">
									<a href={template.link} target="_blank" rel="noreferrer">
										<Button danger type="primary" shape="round">
											<FormattedMessage id="downloadTemplate" />
											<Download
												style={{
													margin: "0 5px",
												}}
											/>
										</Button>
									</a>
								</div>
							</div>
						);
					})
					: ""}
			</div>,
		];

		return (
			<div className="incubator-steps flex flex-col justify-center items-center">
				<Row noGutters className="w-100 justify-center">
					{stepItems}
				</Row>
				{steps.nextbutton && (
					<Button type="primary" onClick={() => getNextStage()}>
						<FormattedMessage id="continue" />
					</Button>
				)}
				{loadcontent && (
					<Modal
						cancelText={<FormattedMessage id="cancel" />}
						okText={<FormattedMessage id="ok" />}
						title={
							localStorage.getItem("locale") === "ar"
								? result.step.name_ar
								: localStorage.getItem("locale") === "fr"
									? result.step.name_fr
									: result.step.name_en
						}
						visible={isModalVisible}
						onCancel={() => {
							setIsModalVisible(false)
							setselectedStep([])
						}}
						onOk={() => {
							setIsModalVisible(false);
						}}
						width={1000}
						className="step-modal"
					>
						<Radio.Group
							style={{
								textAlign: "center",
								width: "100%",
							}}
							defaultValue={0}
							value={modalItemsIndex}
							onChange={(e) => {
								setModalItemsIndex(e.target.value);
							}}
							options={[
								{ label: <FormattedMessage id="description" />, value: 0 },
								{
									label: <FormattedMessage id="learningMaterials" />,
									value: 1,
								},
								{ label: <FormattedMessage id="template" />, value: 2 },
							]}
							optionType="button"
							buttonStyle="solid"
						/>
						{modalItems[modalItemsIndex]}
					</Modal>
				)}
			</div>
		);
	})
);

const StepCercle = ({
	className,
	i,
	step,
	setIsModalVisible,
	setselectedStep,
	setIsModalIIVisible,
}) => (
	<div
		className={classNames("step", className, {
			"step-finished": step.status === "finished",
			"step-unlocked": step.status === "opened",
		})}
		onClick={() => {
			setselectedStep(step);
			setIsModalVisible(true);
		}}
		style={{ cursor: "pointer" }}
	>
		<div className="step-title">
			{" "}
			<FormattedMessage id="step" /> <span>{i + 1}</span>
		</div>
		<div className="step-cercle flex justify-center items-center flex-col">
			<div className="number">{i + 1}</div>
			{step.status === "finished" ? (
				<Check />
			) : step.status === "opened" ? (
				<Unlock />
			) : (
				<Lock />
			)}
		</div>
	</div>
);

const Step = ({ i, mod, children, xs, context }) => (
	<Col
		md={12 / mod}
		sm={12 / mod}
		xs={12}
		className={classNames("flex", "justify-center", "mb-50", "coll", {
			"coll-xs": xs,
		})}
	>
		<Row noGutters className="w-100">
			<Col
				style={{
					height: "100%",
				}}
			>
				{xs ? (
					""
				) : (
					mod === 1 ? (
						<>
							{
								i === 1 ? (
									context.state.direction === "ltr" ? <StepsLeft className="steps-left pos-absolute" /> : <StepsRight className="steps-right pos-absolute" />
								) : (""
								)
							}
							{
								context.state.direction === "ltr" ? <div className="steps-line pos-absolute steps-line-mod-1-left"></div> : <div className="steps-line pos-absolute steps-line-mod-1-right"></div>
							}
						</>
					) : (
						(i + 1) % mod === 1 ? (
							(i + 1) % (mod * 2) === mod + 1 ? (

								context.state.direction === "rtl" ? <StepsRight className="steps-right pos-absolute" /> : <StepsLeft className="steps-left pos-absolute" />
							) : (
								""
							)
						) : (
							<div className="steps-line pos-absolute">
							</div>
						)
					)
				)}
			</Col>
			<Col
				className="flex justify-center items-center pos-relative"
				style={{
					height: "100%",
				}}
			>
				{xs ? (
					<div className="steps-line steps-line-vertical pos-absolute"></div>
				) : (
					<div className="steps-line pos-absolute"></div>
				)}
				{children}
			</Col>
			<Col
				style={{
					height: "100%",
				}}
			>
				{xs ? (
					""
				) : mod === 1 ? (
					<>
						{
							i === 0 ? (
								context.state.direction === "ltr" ? <StepsRight className="steps-right pos-absolute" /> :
									<StepsLeft className="steps-left pos-absolute" />

							) : (
								""
							)
						}
						{
							context.state.direction === "ltr" ? <div className="steps-line pos-absolute steps-line-mod-1-right"></div> : <div className="steps-line pos-absolute steps-line-mod-1-left"></div>
						}
					</>

				) : (
					(i + 1) % mod === 0 ? (
						(i + 1) % (mod * 2) === 0 ? (
							""
						) : (
							context.state.direction === "rtl" ? <StepsLeft className="steps-left pos-absolute" /> : <StepsRight className="steps-right pos-absolute" />
						)
					) : (
						<div className="steps-line pos-absolute"></div>
					)
				)}
			</Col>
		</Row>
	</Col>
);
