import Api from "./ApiSocket";

const Communications = {
	CreateUserConversation(user_id) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/conversations/direct/${user_id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	CreateTeamConversation(idea_id) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/conversations/team/${idea_id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	CreateSessionConversation(session_id) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/conversations/session/${session_id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getMySessionsConversations() {
		return new Promise((resolve, reject) => {
			Api()
				.get("/conversations/me/session?limit=20&sort=begin")
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getMyDMsConversations() {
		return new Promise((resolve, reject) => {
			Api()
				.get("/conversations/me/direct?limit=20&sort=begin")
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getMyTeamsConversations() {
		return new Promise((resolve, reject) => {
			Api()
				.get("/conversations/me/team?limit=20&sort=begin")
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getMyTeamsConversationsByIdea(idea) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/conversations/me/team?idea=${idea}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	CreateMessage(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/messages/send/to/${data._id}`, { body: data.body })
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getMessages(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/messages/by/${data._id}?sort=begin&limit=15`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getMessagesWithPage(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/messages/by/${data._id}?page=${data.page}&sort=begin&limit=15`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	ReadAllMessages(id) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/messages/read/${id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getUnreadConvsCount() {
		return new Promise((resolve, reject) => {
			Api()
				.get("/messages/count/unread")
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	UploadFileToConversation(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/messages/upload/file/to/${data._id}`, { file: data.file })
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	UploadImageToConversation(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/messages/upload/image/to/${data._id}`, { image: data.image })
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	ShowMessagesLinkedToAConversation(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/messages/by/${data.conversation}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	SearchInMessages(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(
					`/messages/by/${data._id}?sort=begin&limit=50&search_query=${data.search}`
				)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getImageConvs(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/messages/by/${data._id}?sort=begin&limit=50&type=${data.type}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getDocsConvs(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/messages/by/${data._id}?sort=begin&limit=50&type=${data.type}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	createTask(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/tasks/to/${data.user}/in/${data._id}`, data.body)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	sendTask(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/messages/assign/${data.task}/in/${data._id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	finishTask(data) {
		return new Promise((resolve, reject) => {
			Api()
				.patch(`/tasks/${data.task}/${data._id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getTaskConvs(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/tasks/?conversation=${data.id}&sort=begin`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	CreateZoomMeeting(data) {
		return new Promise((resolve, reject) => {
			Api().post(`/meetings`, data)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => reject(err));
		})
	},
	sendZoomMeeting(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/messages/assign/${data.meeting}/to/${data.convId}`, data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	getSignature(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get("/meetings/getSignature", { params: data })
				.then(res => {
					resolve(res.data)
				}).catch(err => reject(err))
		})
	},
	
	
};

export default Communications;
