import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import Heading from "../../../components/Heading";
import "../../../sass/Sessions/index.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment-timezone";
import {
	Avatar,
	Input,
	Slider,
	Empty,
	Form,
	Select,
	Alert,
	Spin,
	DatePicker,
	notification,
} from "antd";
import { ArrowRightCircle, ChevronLeft, ChevronRight } from "react-feather";
import Modal from "antd/lib/modal/Modal";
import {
	getSessionAsMember,
	getMySession,
	CreateSessionFeedback,
	CancelSlotMember,
	CheckFinishedSession,
	CheckFinishedSessionAsOwner,
	FinishSlot,
} from "../../../redux/actions/Sessions/SessionsActions";
import { getMyIdeas } from "../../../redux/actions/Ideas/IdeasActions";
import { useForm } from "antd/lib/form/Form";
import IdeaMethods from "../../../Helpers/IdeaHelpers";
import UserMethods from "../../../Helpers/UserHelpers";
import SessionsMethods from "../../../Helpers/SessionsHelpers";
import SessionStatus from "../../../components/sessions/SessionsStatus";
import InfiniteScroll from "react-infinite-scroll-component";
import NotifyMethods from "../../../Helpers/Notifications";
import _ from "lodash";
import { socket } from "../../../hooks/Socket";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { IntlContext } from "../../../context/internationalization";
import { useHistory } from "react-router";
import { PulseLoader } from "react-spinners";

export const Index = (props) => {
	const bp = useBreakpoint();
	const { state } = useContext(IntlContext);
	const history = useHistory();
	const { intl } = props;
	const timezone = moment.tz.guess();
	const [modalVisible, setModalVisible] = useState(false);
	const [loadData, setloadData] = useState(false);
	const [hasMore, sethasMore] = useState(false);
	const [Sessions, setSessions] = useState([]);
	const [SelectedSession, setSelectedSession] = useState([]);
	const [idea] = useForm();
	const [Comment, setComment] = useState(1);
	const [page, setpage] = useState(1);
	const [myIdeas, setmyIdeas] = useState([]);
	const [Rating, setRating] = useState({
		punctual: 0,
		helpful: 0,
		convincing: 0,
	});
	const [Filters] = useForm();
	const [SelectedFilter, setSelectedFilter] = useState("");
	const [FeddError, setFeedError] = useState({
		feedState: false,
		feedErrmsg: "",
	});
	const [ConfirmCanceling, setConfirmCanceling] = useState(false);

	useEffect(() => {
		setComment("");
		setRating({ ...Rating, punctual: 0, helpful: 0, convincing: 0 });
		setFeedError({ ...FeddError, feedState: false, feedErrmsg: "" });
		// eslint-disable-next-line
	}, [modalVisible]);

	useEffect(() => {
		document.title = intl.formatMessage({ id: "sessions" })
		if (props.user.account_type === "idea_holder") getIdeas();
		if (props.user.account_type !== "idea_holder") {
			CheckFinishedSessionAsOwner();
			getMySession();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		socket.on("new_notification", (data) => {
			if (
				data &&
				data.received_by &&
				Array.isArray(data.received_by) &&
				data.received_by.some((one) => one === props.user._id)
			) {
				getMySession();
			}
		});
		return () => { socket.off("new_notification"); };
		// eslint-disable-next-line
	}, []);
	const getIdeas = () => {
		props.getMyIdeas().then((res) => {
			if (res.status === "success") setmyIdeas(res.result.Ideas);
		});
	};
	//get session with Idea as a member or idea holder
	const getSessionAsMember = (param) => {
		const Data = {
			ideaId: idea.getFieldValue("ideaId"),
			params: param ? `&page=${page}${param}` : `&page=1&status=Waiting`,
		};
		if (idea.getFieldValue("ideaId")) {
			setloadData(true);
			props.getSessionAsMember(Data).then((res) => {
				if (res.status === "success") {
					sethasMore(res.result.hasNextPage);
					res.result.hasNextPage ? setpage(res.result.nextPage) : setpage(1);
					setloadData(false);
					setSessions(res.result.Sessions);
				} else if (res === "Not Found") {
					setloadData(false);
					setSessions([]);
				}
			});
		} else {
			setSessions([]);
		}
	};
	// check finished Sessions
	const CheckFinishedSession = () => {
		const Data = {
			ideaId: idea.getFieldValue("ideaId"),
			params: "&status=Confirmed",
		};
		if (idea.getFieldValue("ideaId")) {
			props.CheckFinishedSession(Data).then((res) => {
				if (res.status === "success") {
					SessionsMethods.CheckFinishedSession(res.result.Sessions).then(
						(result) => {
							if (result.state === true)
								props.FinishSlot(result.SessionID).then((fin) => {
									if (fin.status === "success")
										notification["success"]({
											message: intl.formatMessage({ id: "success" }),
											description: intl.formatMessage({
												id: "finishedSessions",
											}),
										});
								});
						}
					);
				}
			});
		}
	};
	// check finished Sessions as Owner
	const CheckFinishedSessionAsOwner = () => {
		const params = "&status=Confirmed";
		props.CheckFinishedSessionAsOwner(params).then((res) => {
			if (res.status === "success") {
				SessionsMethods.CheckFinishedSession(res.result.Sessions).then(
					(result) => {
						if (result.state === true)
							props.FinishSlot(result.SessionID).then((fin) => {
								if (fin.status === "success")
									notification["success"]({
										message: intl.formatMessage({ id: "success" }),
										description: intl.formatMessage({ id: "finishedSessions" }),
									});
							});
					}
				);
			}
		});
	};
	//get Session as mentor
	const getMySession = (param) => {
		const params = param ? `&page=${page}${param}` : `&page=1&status=Waiting`;

		setloadData(true);
		props.getMySession(params).then((res) => {
			if (res.status === "success") {
				sethasMore(res.result.hasNextPage);
				res.result.hasNextPage ? setpage(res.result.nextPage) : setpage(1);
				SessionsMethods.FiltersSessions(res.result.Sessions).then((re) => {
					setloadData(false);
					re === "err" ? setSessions([]) : setSessions(re);
				});
			} else {
				setloadData(false);
				setSessions([]);
			}
		});
	};
	// create FeedBack
	const CreateSessionFeedback = (sessionId) => {
		const data = {
			sessionId: SelectedSession.sessionId,
			userId:
				props.user.account_type === "idea_holder"
					? SelectedSession.session_owner.userId
					: SelectedSession.session_idea.user.userId,
			body: {
				comment: Comment,
				feedback_details: [
					{
						question_code: "punctual",
						rating: Rating.punctual,
					},
					{
						question_code: "helpful",
						rating: Rating.helpful,
					},
					{
						question_code: "convincing",
						rating: Rating.convincing,
					},
				],
			},
		};
		props.CreateSessionFeedback(data).then((res) => {
			if (res.status === "success") {
				setModalVisible(false);
				notification["success"]({
					message: intl.formatMessage({ id: "success" }),
				});
				NotifyMethods.getUsersIds(SelectedSession.session_idea.members).then(
					(res) => {
						let dta = [];
						dta = [...dta, SelectedSession.session_owner._id];
						socket.emit("new_notification", {
							event_type:
								props.user.account_type === "idea_holder"
									? "team_feedback_mentor"
									: "mentor_feedback_team",
							received_by:
								props.user.account_type === "idea_holder" ? dta : res,
							sessionId: SelectedSession.sessionId,
						});
					}
				);

				props.user.account_type !== "idea_holder"
					? getMySession("&status=Finished")
					: getSessionAsMember("&status=Finished");
			} else {
				setFeedError({ ...FeddError, feedState: true, feedErrmsg: res.en });
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: res.en }),
				});
			}
		});
	};

	/*
	 **	get Profiles by filter searsh
	 */
	const getFilters = () => {
		SessionsMethods.FilterSessionType(Filters.getFieldsValue()).then((res) => {
			if (res === "") setpage(1);
			setSelectedFilter(res);
			props.user.account_type === "idea_holder"
				? getSessionAsMember(res)
				: getMySession(res);
		});
	};

	const testnextPage = () => {
		if (props.user.account_type === "idea_holder") {
			const Data = {
				ideaId: idea.getFieldValue("ideaId"),
				params: `&page=${page}${SelectedFilter}`,
			};
			if (idea.getFieldValue("ideaId")) {
				props.getSessionAsMember(Data).then((res) => {
					if (res.status === "success") {
						sethasMore(res.result.hasNextPage);
						res.result.hasNextPage ? setpage(res.result.nextPage) : setpage(1);
						setSessions((Sessions) => [...Sessions, ...res.result.Sessions]);
					} else if (res === "Not Found") {
						setSessions(Sessions);
					}
				});
			} else {
				setloadData(false);
				setSessions([]);
			}
		} else
			props.getMySession(`&page=${page}${SelectedFilter}`).then((res) => {
				if (res.status === "success") {
					res.result.hasNextPage
						? sethasMore(res.result.hasNextPage)
						: sethasMore(false);
					res.result.hasNextPage ? setpage(res.result.nextPage) : setpage(1);
					SessionsMethods.FiltersSessions(res.result.Sessions).then((re) => {
						setloadData(false);
						re === "err"
							? setSessions(Sessions)
							: setSessions((Sessions) => [...Sessions, ...re]);
					});
				} else {
					setloadData(false);
					setSessions([]);
				}
			});
	};
	return (
		<div className="sessions-page">
			<Container>
				<Heading text={<FormattedMessage id="sessions" />} />
				{/* 

        */}
				{props.user.account_type === "idea_holder" ? (
					<Form
						size="large"
						layout="vertical"
						form={idea}
						onValuesChange={() => {
							CheckFinishedSession();
							getSessionAsMember("");
						}}
					>
						<Form.Item
							name="ideaId"
							label={<FormattedMessage id="selectYourIdea" />}
						>
							<Select placeholder="Select idea" allowClear>
								{myIdeas.length
									? myIdeas.map((idea, i) => {
										return (
											<Select.Option key={i} value={idea.ideaId}>
												{idea.title}
											</Select.Option>
										);
									})
									: ""}
							</Select>
						</Form.Item>
					</Form>
				) : (
					""
				)}
				<Row>
					<Col lg={9} md={8} sm={12} id="bola">
						<div
							className="bola"
							style={
								(idea.getFieldValue("ideaId") &&
									Sessions.length > 0 &&
									props.user.account_type === "idea_holder") ||
									(props.user.account_type !== "idea_holder" &&
										Sessions.length > 0)
									? { marginLeft: "1%" }
									: { marginLeft: "35%", marginTop: "30%" }
							}
							id="sessions"
						>
							{_.isArray(Sessions) && Sessions.length ? (
								loadData ? (
									<div className="example" >
										<Spin />
									</div>
								) : (
									<InfiniteScroll
										dataLength={Sessions.length}
										next={testnextPage}
										loader={<PulseLoader color="#458ff6" size={8} />}
										scrollableTarget="sessions"
										scrollThreshold={1}
										hasMore={hasMore}
									>
										{Sessions.map((sess, i) => {
											return (
												<div key={sess._id}>
													{sess.session_idea ? (
														<div

															className={
																sess.session_status === "Waiting"
																	? "carta session-card session-card-waiting"
																	: sess.session_status === "Confirmed"
																		? "carta session-card session-card-confirmed"
																		: sess.session_status === "Canceled"
																			? "carta session-card session-card-canceled"
																			: "carta session-card session-card-finished"
															}
														>
															<Row className="session-card-title"	>

																SESSION OF THE{" "}
																{moment(sess.begin_at)
																	.clone()
																	.tz(timezone)
																	.format("DD-MM-YYYY")}{" "}
																from{" "}
																{moment(sess.begin_at)
																	.clone()
																	.tz(timezone)
																	.format("hh:mm A")}{" "}
																to{" "}
																{moment(sess.end_at)
																	.clone()
																	.tz(timezone)
																	.format("hh:mm A")}
															</Row>
															<Row>
																{/* <Container> */}
																<Col
																	md={5}
																	sm={12}
																	style={{
																		marginBottom: 20,
																	}}
																>
																	<div className="session-item about-idea">
																		<div className="heading">
																			<FormattedMessage id="aboutIdea" />
																		</div>
																		<div className="about-idea-content">
																			<Container>
																				<Row noGutters>
																					<Col
																						md={12}
																						lg={4}
																						style={{
																							marginBottom: 10,
																						}}
																					>
																						<div
																							className="idea-image"
																							style={{
																								background: `url(${sess.session_idea.images[0] ? IdeaMethods.getIdeaImage(
																									sess.session_idea.ideaId,
																									sess.session_idea.images[0]
																								) : IdeaMethods.getIdeaAvatar()} )`,
																								backgroundSize: "cover",
																								objectFit: "cover",
																								//   backgroundImage: ...
																							}}
																						></div>
																					</Col>
																					<Col>
																						<div className="idea-title-desc">
																							<div className="title">
																								{sess.session_idea.title}
																							</div>
																							<div className="desc">
																								{sess.session_idea
																									.shortDescription.length > 100
																									? sess.session_idea.shortDescription.substr(
																										0,
																										100
																									) + "..."
																									: sess.session_idea
																										.shortDescription}
																							</div>
																							<div
																								className="see-more"
																								onClick={() => {
																									history.push(
																										`/app/ideas/${sess.session_idea.ideaId}`
																									);
																								}}
																							>
																								<FormattedMessage id="seeMore" />
																								{state.direction === "ltr" ? (
																									<ChevronRight size={10} />
																								) : (
																									<ChevronLeft size={10} />
																								)}
																							</div>
																						</div>
																					</Col>
																				</Row>
																			</Container>
																		</div>
																	</div>
																</Col>
																<Col
																	md={2}
																	sm={12}
																	style={{
																		marginBottom: 20,
																	}}
																>
																	<div className="session-item idea-holder">
																		<div className="heading">
																			<FormattedMessage id="ideaHolder" />
																		</div>
																		<div
																			className="team-leader"
																			style={{
																				flexWrap:
																					bp.md && !bp.lg ? "wrap" : "nowrap",
																			}}
																		>
																			<Avatar
																				size="large"
																				src={sess.session_idea.user.avatar ? UserMethods.getUserImage(
																					sess.session_idea.user.userId,
																					sess.session_idea.user.avatar
																				) : UserMethods.getUserAvatar()}
																			/>
																			<div className="infos">
																				<div className="name">
																					{sess.session_idea.user.firstname +
																						" " +
																						sess.session_idea.user.lastname}
																				</div>
																				<div className="post">Team Leader</div>
																			</div>
																		</div>

																		<div className="team-members">
																			{sess.session_idea.members.length > 1 ? (
																				<>
																					<div className="title">
																						<FormattedMessage id="teamMembers" />
																					</div>
																					<Avatar.Group>
																						{sess.session_idea.members.map(
																							(member, i) => {
																								if (member.admin !== true)
																									return (
																										<Avatar
																											key={member.user.userId}
																											src={member.user.avatar ? UserMethods.getUserImage(
																												member.user.userId,
																												member.user.avatar
																											) : UserMethods.getUserAvatar()}
																										/>
																									);
																								return ''
																							}
																						)}
																					</Avatar.Group>
																				</>
																			) : (
																				""
																			)}
																		</div>
																	</div>
																</Col>
																<Col
																	md={2}
																	sm={12}
																	style={{
																		marginBottom: 20,
																	}}
																>
																	<div className="session-item mentor">
																		<div className="heading">
																			<FormattedMessage id="mentor" />
																		</div>
																		<div className="mentor-avatar">
																			<Avatar
																				size="large"
																				src={sess.session_owner.avatar ? UserMethods.getUserImage(
																					sess.session_owner.userId,
																					sess.session_owner.avatar
																				) : UserMethods.getUserAvatar()}
																			/>
																		</div>
																		<div className="mentor-infos">
																			<div className="name">
																				{sess.session_owner.firstname +
																					" " +
																					sess.session_owner.lastname}
																			</div>
																			{/* <div className="exp">
															Lorem ipsum dolor sit amet consectetur adipisicing
															elit.
														</div> */}
																		</div>
																	</div>
																</Col>
																<Col
																	md={2}
																	sm={12}
																	style={{
																		marginBottom: 20,
																	}}
																>
																	<SessionStatus
																		getMySession={getMySession}
																		getSessionAsMember={getSessionAsMember}
																		session={sess}
																		user={props.user}
																		status={sess.session_status}
																		setModalVisible={setModalVisible}
																		setSelectedSession={setSelectedSession}
																		setConfirmCanceling={setConfirmCanceling}
																	/>
																</Col>
																<Col
																	md={1}
																	sm={12}
																	style={{
																		marginBottom: 20,
																	}}
																>
																	<div className="session-item session-room">
																		<div className="heading">
																			<FormattedMessage id="sessionRoom" />
																		</div>
																		<div className="go-to-room" onClick={() =>
																			(sess.session_status === "Confirmed" || sess.session_status === "Finished") && history.push({
																				pathname: "/app/chat/sessions",
																				state: { session_id: sess._id },
																			})}
																		>
																			<ArrowRightCircle
																				size={20}
																				strokeWidth={0.5}
																			/>
																			<span>
																				<FormattedMessage id="GoToRoom" />
																			</span>
																		</div>
																	</div>
																</Col>
																{/* </Container> */}
															</Row>
														</div>
													) : (
														""
													)}
												</div>
											);
										})}
									</InfiniteScroll>
								)
							) : (
								<Empty />
							)}
						</div>
					</Col>
					<Col>
						{idea.getFieldValue("ideaId") &&
							props.user.account_type === "idea_holder" ? (
							<div className="carta filter">
								<Heading
									text={<FormattedMessage id="filter" />}
									color="#458ff6"
								/>
								<Form
									form={Filters}
									layout="vertical"
									size="large"
									onValuesChange={getFilters}
								>
									<Form.Item
										name="Status"
										label={<FormattedMessage id="status" />}
									>
										<Select
											value="Waiting"
											placeholder="Status"
											size="large"
											allowClear
										>
											<Select.Option value="Waiting">
												<FormattedMessage id="Waiting" />
											</Select.Option>
											<Select.Option value="Confirmed">
												<FormattedMessage id="Confirmed" />
											</Select.Option>
											<Select.Option value="Canceled">
												<FormattedMessage id="Canceled" />
											</Select.Option>
											<Select.Option value="Finished">
												<FormattedMessage id="Finished" />
											</Select.Option>
										</Select>
									</Form.Item>

									<Form.Item
										name="availability"
										label={<FormattedMessage id="availability" />}
									>
										<DatePicker.RangePicker format="DD-MM-YYYY" allowClear />
									</Form.Item>
								</Form>
								<Alert
									message={<FormattedMessage id="info" />}
									description={<FormattedMessage id="warningSessions" />}
									type="info"
									closable
								/>
							</div>
						) : props.user.account_type !== "idea_holder" ? (
							<div className="carta">
								<Heading
									text={<FormattedMessage id="filter" />}
									color="#458ff6"
								/>
								<Form
									layout="vertical"
									size="large"
									form={Filters}
									onValuesChange={getFilters}
									initialValues={{
										Status: "Waiting"
									}}
								>
									<Form.Item
										name="Status"
										label={<FormattedMessage id="status" />}
									>
										<Select
											// defaultValue="Waiting"
											placeholder="Status"
											size="large"
											allowClear
										>
											<Select.Option value="Waiting">
												<FormattedMessage id="Waiting" />
											</Select.Option>
											<Select.Option value="Confirmed">
												<FormattedMessage id="Confirmed" />
											</Select.Option>
											<Select.Option value="Canceled">
												<FormattedMessage id="Canceled" />
											</Select.Option>
											<Select.Option value="Finished">
												<FormattedMessage id="Finished" />
											</Select.Option>
										</Select>
									</Form.Item>

									<Form.Item
										name="availability"
										label={<FormattedMessage id="availability" />}
									>
										<DatePicker.RangePicker format="DD-MM-YYYY" allowClear />
									</Form.Item>
								</Form>
								<Alert
									message={<FormattedMessage id="info" />}
									description={<FormattedMessage id="warningSessions" />}
									type="info"
									closable
								/>
							</div>
						) : (
							""
						)}
					</Col>
				</Row>
			</Container>

			{props.user.account_type === "idea_holder" && <Modal
				title={<FormattedMessage id="feedback" />}
				visible={modalVisible}
				onCancel={() => {
					setModalVisible(false);
					setComment("");
					setRating({ ...Rating, punctual: 0, helpful: 0, convincing: 0 });
				}}
				okText={<FormattedMessage id="confirm" />}
				cancelText={<FormattedMessage id="cancelBtn" />}
				onOk={() => CreateSessionFeedback()}
			>
				<div className="feedback-question">
					<FormattedMessage id="mentorPunctal" />
					<Slider
						defaultValue={Rating.punctual}
						marks={{
							0: "0",
							20: "1",
							40: "2",
							60: "3",
							80: "4",
							100: "5",
						}}
						step={20}
						onChange={(val) => {
							SessionsMethods.getSliderValue(val).then((re) => {
								setRating({ ...Rating, punctual: re });
							});
						}}
					/>
				</div>
				<div className="feedback-question">
					<FormattedMessage id="mentorHelped" />
					<Slider
						defaultValue={Rating.helpful}
						marks={{
							0: "0",
							20: "1",
							40: "2",
							60: "3",
							80: "4",
							100: "5",
						}}
						step={20}
						onChange={(val) => {
							SessionsMethods.getSliderValue(val).then((re) => {
								setRating({ ...Rating, helpful: re });
							});
						}}
					/>
				</div>
				<div className="feedback-question">
					<FormattedMessage id="mentorConviced" />
					<Slider
						defaultValue={Rating.convincing}
						marks={{
							0: "0",
							20: "1",
							40: "2",
							60: "3",
							80: "4",
							100: "5",
						}}
						step={20}
						onChange={(val) => {
							SessionsMethods.getSliderValue(val).then((re) => {
								setRating({ ...Rating, convincing: re });
							});
						}}
					/>
				</div>
				<div className="feedback-question">
					<FormattedMessage id="generalOpinion" />
					<Input.TextArea
						value={Comment}
						onChange={(e) => setComment(e.target.value)}
						autoSize={{ minRows: 4, maxRows: 8 }}
					></Input.TextArea>
				</div>
				<div className="feedback-thankyou">
					<FormattedMessage id="thankYouForFeedback" />
				</div>
				<div style={{ marginTop: "20px" }}>
					{FeddError.feedState ? (
						<Alert message={FeddError.feedErrmsg} type="error" />
					) : (
						""
					)}
				</div>
			</Modal>
			}
			<Modal
				cancelText={<FormattedMessage id="cancel" />}
				title={<FormattedMessage id="confirm " />}
				visible={ConfirmCanceling}
				onCancel={() => {
					setConfirmCanceling(false);
				}}
				okText={<FormattedMessage id="confirm" />}
			// onOk={() => CancelSlot()}
			>
				Test
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = {
	getSessionAsMember,
	getMyIdeas,
	getMySession,
	CreateSessionFeedback,
	CancelSlotMember,
	CheckFinishedSession,
	CheckFinishedSessionAsOwner,
	FinishSlot,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Index));
