import UserHelpers from './UserHelpers'

const TeamsMethode = {
	//Get Users by name
	getUsersArray: (result) => {
		return new Promise((resolve, reject) => {
			let data = [];

			result.Users.forEach((user) => {
				data.push({
					name: user.firstname + " " + user.lastname,
					img: `${UserHelpers.getUserImage(user.userId, user.avatar)}`,
					userId: user.userId,
					_id: user.id,
				});
			});
			data.length > 0 ? resolve(data) : reject("err");
		});
	},

};

export default TeamsMethode;
