import Notifications from "../../../services/Socket/Notifications";

export const getNotifications = (page) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Notifications.getNotifications(page)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => resolve(err));
		});
	};
};
export const getNotificationsBypage = (page) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Notifications.getNotificationsBypage(page)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => resolve(err));
		});
	};
};

export const ReadAllNotifications = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Notifications.ReadAllNotifications()
				.then((res) => {
					if (res && res.status === "success") resolve(res);
				})
				.catch((err) => resolve(err));
		});
	};
};

export const ReadNotificationsById = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Notifications.ReadNotificationsById(id)
				.then((res) => {
					if (res && res.status === "success") resolve(res);
				})
				.catch((err) => resolve(err));
		});
	};
};
