const initialState = {
  user: null,
  isAuth: localStorage.getItem("AyHc7zSW7n6rI") ? true : false,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER": {
      return { ...state, isAuth: true };
    }
    case "GET_USER": {
      return { ...state, user: action.user };
    }
    case "LOGOUT_USER": {
      localStorage.removeItem("AyHc7zSW7n6rI");
      return { ...state, isAuth: false };
    }
    default: {
      return state;
    }
  }
};
