import Api from "./ApiSocket";

// Notifactions Section

const Notifications = {
	getNotifications(page) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/notifications?limit=10&sort=begin&page=${page}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	ReadAllNotifications() {
		return new Promise((resolve, reject) => {
			Api()
				.post("/notifications/read")
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	ReadNotificationsById(id) {
		return new Promise((resolve, reject) => {
			Api()
				.post(`/notifications/read/${id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
};

export default Notifications;
