import { useEffect, useState } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay, A11y } from "swiper";
import { Container } from "reactstrap";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "../css/TeamSwiper.css";

//import SVGs
import { ReactComponent as Facebook } from "../assets/icons/facebook2.svg";
import { ReactComponent as Instagram } from "../assets/icons/instagram2.svg";
import { ReactComponent as Linkedin } from "../assets/icons/linkedin2.svg";
import { ReactComponent as Twitter } from "../assets/icons/twitter2.svg";
import { ReactComponent as Youtube } from "../assets/icons/youtube2.svg";
import UserMethods from "../Helpers/UserHelpers";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay, A11y]);

const TheySaid = (props) => {
  const [persons, setPersons] = useState([]);
  useEffect(() => {
    UserMethods.getTeamArray(props.members).then((res) => {
      setPersons(res);
    });
  }, [props]);
  return (
    <Container>
      <Swiper
        breakpoints={{
          // when window width is >= 320
          220: {
            spaceBetween: 100,
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          800: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 1,
          },
        }}
        spaceBetween={100}
        slidesPerView={1}
        slidesPerGroup={1}
        loop={persons.length > 3}
        navigation={{
          nextEl: "#swiperRight",
          prevEl: "#swiperLeft",
          disabledClass: "navigation-disabled",
        }}
        pagination={{
          clickable: true,
          el: "#swiperPagination",
          bulletClass: "bullet",
          bulletActiveClass: "bullet-active",
        }}
        style={{ padding: "20px" }}
      >
        {persons.map((person, id) => (
          <SwiperSlide key={id}>
            <div className="person-card">
              <div
                className="person-pdp"
                style={{
                  backgroundImage: `url('${person.img}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div className="person-name">{person.name.toUpperCase()}</div>
              <div className="person-position">{person.position}</div>
              <div className="person-desc">{person.desc}</div>
              <div className="person-socials">
                {person.fb ? (
                  <a
                    className="person-social"
                    href={person.fb}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Facebook />
                  </a>
                ) : (
                  ""
                )}
                {person.ig ? (
                  <a
                    className="person-social"
                    href={person.ig}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Instagram />
                  </a>
                ) : (
                  ""
                )}
                {person.in ? (
                  <a
                    className="person-social"
                    href={person.in}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Linkedin />
                  </a>
                ) : (
                  ""
                )}
                {person.twt ? (
                  <a
                    className="person-social"
                    href={person.twt}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Twitter />
                  </a>
                ) : (
                  ""
                )}
                {person.yt ? (
                  <a
                    className="person-social"
                    href={person.yt}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Youtube />
                  </a>
                ) : (
                  ""
                )}
              </div>
              <div className="message button-primary-stroke" style={{ cursor: "pointer" }}
              >Message</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default TheySaid;