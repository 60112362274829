const NotifyMethods = {
	getUsersIds: (members) => {
		return new Promise((resolve, reject) => {
			let users = [];
			members.forEach((member) => {
				users.push(member.user._id);
			});
			resolve(users);
		});
	},
	getLinkbyType: (notif) => {
		switch (notif.type) {
			case "confirm_session_mentor": {
				return "/app/sessions";
			}
			case "cancel_session_mentor": {
				return "/app/sessions";
			}
			case "cancel_session_team": {
				return "/app/sessions";
			}
			case "book_session": {
				return "/app/sessions";
			}
			case "team_feedback_mentor": {
				return "/app/sessions";
			}
			case "mentor_feedback_team": {
				return "/app/sessions";
			}
			case "sent_request": {
				return "/app/invites";
			}
			case "confirm_request": {
				return {
					pathname: `/app/ideas/manageteam/${notif.ideaId}`,
					state: { idea_id: notif.idea },
				};
			}
			case "cancel_request": {
				return {
					pathname: `/app/ideas/manageteam/${notif.ideaId}`,
					state: { idea_id: notif.idea },
				};
			}
			case "edit_role": {
				return {
					pathname: `/app/ideas/manageteam/${notif.ideaId}`,
					state: { idea_id: notif.idea },
				};
			}
			case "edit_position": {
				return {
					pathname: `/app/ideas/manageteam/${notif.ideaId}`,
					state: { idea_id: notif.idea },
				};
			}
			default: {
				return "/app";
			}
		}
	},
};

export default NotifyMethods;
