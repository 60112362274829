import socketIOClient from "socket.io-client";
import config from '../Helpers/config'

export const socket = socketIOClient(config.socketLink, {
  // withCredentials: true,
  extraHeaders: {
    Authorization: "Bearer " + localStorage.getItem("AyHc7zSW7n6rI")
  },
  path: "/socket",
});
