import Ideas from "../../../services/Ideas";
import IdeasMethods from "../../../Helpers/IdeaHelpers";
import api from "../../../services/Api";

// Idea Section.

// Add new idea.
export const AddIdea = (values) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: "ADD_LOADING",
				loading: true,
			});
			// eslint-disable-next-line
			let data = [];
			IdeasMethods.fillAddIdeaData(values).then((res) => {
				data = res;
				IdeasMethods.CreateData(res, values.files).then((re) => {
					Ideas.AddIdea(re)
						.then((result) => {
							dispatch({
								type: "ADD_LOADING",
								loading: false,
							});
							resolve(result);
						})
						.catch((err) => {
							if (err.response && err.response.data) {
								const result = err.response.data;
								if (result.status && result.status === "failure")
									resolve(result.msg);
								else reject(err);
							} else reject(err);
						});
				});
			});
		});
	};
};
// get idea infos.
export const getIdeaInfo = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Ideas.getIdeaInfo(id)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "Not Found") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
// Edit idea infos.
export const EditIdeaInfo = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Ideas.EditIdea(data)
				.then((res) => {
					if (res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 400) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
// List all my ideas.
export const getMyIdeas = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Ideas.getMyIdeas()
				.then((res) => {
					if (res.status === "success") {
						dispatch({
							type: "MY_IDEAS",
							MyIdeas: res.data.result.Ideas,
						});
					}
					resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "Not Found") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
// List all public ideas.
export const getAllIdeas = (filters) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Ideas.getAllIdeas(filters)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "Not Found") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/* List all public ideas with filters */
export const getAllIdeasWithFilters = (filters) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Ideas.getAllIdeasWithFilters(filters)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	};
};
/* get  public ideas with user Id */
export const getAllIdeasWithUserId = (userId) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Ideas.getAllIdeasWithUserId(userId)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "Not Found") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};

/* Delete Image from Idea Images */
export const deleteIdeaImage = (img, ideaId) => {
	return (dispatch) => {
		return new Promise((resolve, rejects) => {
			try {
				api()
					.delete(`/ideas/${ideaId}/picture`, {
						data: { filename: img },
					})
					.then((res) => {
						if (res.status === 204)
							rejects({ status: "failure", msg: "No image found" });
						else if (res.data.status === "success") {
							resolve(res.data);
						} else rejects(res.data);
					})
					.catch((err) => {
						if (err.request.status === 401) {
							rejects(err.response.data.msg);
							dispatch({ type: "LOGOUT" });
							window.location.href = `${document.location.origin}/login`;
						} else if (err.request.status === 400) rejects(err.response.data);
						else rejects({ msg: "error_1" });
					});
			} catch (e) {
				rejects({ msg: "error_1" });
			}
		});
	};
};
/* Add Image To Idea Images */
export const addIdeaImage = (data, ideaId) => {
	return (dispatch) => {
		return new Promise((resolve, rejects) => {
			try {
				api()
					.put(`/ideas/${ideaId}/add/picture`, data)
					.then((res) => {
						if (res.data.status === "success") {
							resolve(res.data);
						} else rejects(res.data);
					})
					.catch((err) => {
						if (err.request.status === 401) {
							rejects(err.response.data.msg);
							dispatch({ type: "LOGOUT" });
							window.location.href = `${document.location.origin}/login`;
						} else if (err.request.status === 400) rejects(err.response.data);
						else rejects({ msg: "error_1" });
					});
			} catch (e) {
				rejects({ msg: "error_1" });
			}
		});
	};
};

/* Search query of All Publics ideas */
export const searchIdeas = (data) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			Ideas.searchIdeas(data)
				.then(res => {
					if (res.status === "success") resolve(res)
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "Not Found") resolve(res);
						else reject(err);
					} else reject(err);
				});
		})
	}
}
