import Auth from "../../../services/auth";
import User from "../../../services/user";
import UserMethods from "../../../Helpers/UserHelpers";

export const loginWithEmailOrUsername = (user) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			const data = {
				Email_username: user.email,
				password: user.password,
			};
			Auth.loginUser(data)
				.then((res) => {
					localStorage.setItem("AyHc7zSW7n6rI", res.token);
					dispatch({
						type: "LOGIN_USER",
					});
					resolve();
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res.msg);
						else reject(err);
					} else reject(err);
				});
		});
	};
};

export const facebookLogin = (userID, accessToken) => (dispatch) =>
	new Promise((resolve, reject) => {
		Auth.facebookLogin(userID, accessToken)
			.then((res) => {
				localStorage.setItem("AyHc7zSW7n6rI", res.token);
				dispatch({
					type: "LOGIN_USER",
				});
				resolve();
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					const res = err.response.data;
					if (res.status && res.status === "failure") resolve(res.msg);
					else reject(err);
				} else reject(err);
			});
	});

export const googleLogin = (tokenId) => (dispatch) =>
	new Promise((resolve, reject) => {
		Auth.googleLogin(tokenId)
			.then((res) => {
				localStorage.setItem("AyHc7zSW7n6rI", res.token);
				dispatch({
					type: "LOGIN_USER",
				});
				resolve();
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					const res = err.response.data;
					if (res.status && res.status === "failure") resolve(res.msg);
					else reject(err);
				} else reject(err);
			});
	});

export const linkedin = (code, state) => (dispatch) =>
	new Promise((resolve, reject) => {
		Auth.linkedin(code, state)
			.then((res) => {
				localStorage.setItem("AyHc7zSW7n6rI", res.token);
				dispatch({
					type: "LOGIN_USER",
				});
				resolve();
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					const res = err.response.data;
					if (res.status && res.status === "failure") resolve(res.msg);
					else reject(err);
				} else reject(err);
			});
	});

export const logout = () => (dispatch) =>
	dispatch({
		type: "LOGOUT_USER",
	});

export const getUser = () => (dispatch) =>
	new Promise((resolve, reject) => {
		User.get()
			.then((res) => {
				resolve(
					dispatch({
						type: "GET_USER",
						user: res.user,
					}),
				);
			})
			.catch((err) => {
				dispatch({
					type: "LOGOUT_USER",
				});
				reject(err);
			});
	});

export const completeProfile = (data) => (dispatch) =>
	new Promise((resolve, reject) => {
		UserMethods.getQuestions(data).then((res) => {
			data.questions = res;
			User.completeProfile(data)
				.then((res) => {
					if (res.status === "success") {
						getUser();
						resolve(res);
					}
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	});

export const editProfile = (data, oldData) => (dispatch) =>
	new Promise((resolve, reject) => {
		if (data.questions.length < 1) {
			data.questions = oldData.questions;
			User.editProfile(data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		} else {
			UserMethods.getQuestions(data).then((res) => {
				data.questions = res;
				User.editProfile(data)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						if (err.response && err.response.data) {
							const res = err.response.data;
							if (res.status && res.status === "failure") resolve(res);
							else reject(err);
						} else reject(err);
					});
			});
		}
	});

export const changePassword = (data) => (dispatch) =>
	new Promise((resolve, reject) => {
		User.changePassword(data)
			.then((res) => {
				resolve();
			})
			.catch((err) => {
				if (err.response && err.response.data) {
					const res = err.response.data;
					if (res.status && res.status === "failure") resolve(res.msg);
					else reject(err);
				} else reject(err);
			});
	});


export const reportUser = (data) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			User.reportUser(data)
				.then(res => {
					if (res.status === "success") resolve(res)
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		})
	}
}