import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import { IntlProviderWrapper } from "./context/internationalization";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
	<React.Fragment>
		<Provider store={store}>
			<IntlProviderWrapper>
				<App />
				<ToastContainer style={{ display: "none" }} />
			</IntlProviderWrapper>
		</Provider>
	</React.Fragment>,
	document.getElementById("root")
);

