import ManageTeam from "../../../services/Teams";
/*
 ** Manage Team Section
 */

/*
 ** Get requests sended
 */
export const getRequests = (ideaID) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.getRequests(ideaID)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 401) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.result.Invites.length <= 0
						)
							resolve("Not Found");
						else if (res.msg === "error_73") resolve("Not Found");
						else reject(err);
					} else reject(err);
				});
		});
	};
};

/*
 ** Get MY requests
 */
export const getMyRequests = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.getMyRequests()
				.then((res) => {
					if (res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.result.Invites.length <= 0
						)
							resolve("Not Found");
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Accept Request
 */
export const acceptRequests = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.acceptRequests(data)
				.then((res) => {
					if (res.data.status === "success") {
						resolve(res.data);
					}
				})
				.catch((err) => {
					if (err.response.status === 400 || err.response.status === 404) {
						const res = err.response.data;
						if (
							(res.status && res.status === "failure") ||
							res.status === "success"
						) {
							resolve(res);
						} else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Reject Request
 */
export const rejectRequests = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.rejectRequests(data)
				.then((res) => {
					if (res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 400 || err.response.status === 404) {
						const res = err.response.data;
						if (
							(res.status && res.status === "failure") ||
							res.status === "success"
						)
							resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Get Team Members
 */
export const getTeamMembers = (ideaID) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.getTeamMembers(ideaID)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.result.Invites.length <= 0
						)
							resolve("Not Found");
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Get Users for search
 */
export const getUsers = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.getUsers()
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res.msg);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Get Users with name
 */
export const getUsersbyName = (name) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.getUsersbyName(name)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.results.Users.length <= 0
						)
							resolve("Not Found");
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Send request to user
 */
export const sendRequest = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.sendRequest(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (
						err.response &&
						err.response.data &&
						(err.response.status === 401 || err.response.status === 400)
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else resolve(err.response.data);
					} else resolve(err.response.data);
				});
		});
	};
};
/*
 ** Delete Or cancel Request sended
 */
export const deleteRequest = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.deleteRequest(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res.msg);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Edit Team Member Position
 */
export const editPosition = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.editPosition(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Edit Team Member Role
 */
export const editRole = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.editRole(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
/*
 ** Delete Team Member from Idea.
 */
export const deleteMember = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.deleteMember(data)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response && err.response.data) {
						const res = err.response.data;
						if (res.status && res.status === "failure") resolve(res);
						else reject(err);
					} else reject(err);
				});
		});
	};
};
