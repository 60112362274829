import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Container } from "reactstrap";
import "../../../sass/Incubator/index.scss";
import { BulbOutlined } from "@ant-design/icons";
import { Avatar, notification } from "antd";
import UserService from "../../../services/user";
import { Calendar, ChevronRight, Users } from "react-feather";
import { Stage, Steps } from "../../../components/Incubator";
import { getMyIdeas } from "../../../redux/actions/Ideas/IdeasActions";
import { getPath, getStageinfo, EditStep } from "../../../redux/actions/Path/PathActions";
import { ReactComponent as MainSVG } from "../../../assets/About/MainSVG.svg";
import _ from "lodash";
import moment from "moment";

export const Incubation = (props) => {
	const { intl } = props;
	const timezone = moment.tz.guess();
	const history = useHistory();
	const { ideaid } = useParams();
	const { user } = props;
	const [Paths, setPaths] = useState([]);
	const [selectedStage, setselectedStage] = useState([]);
	const [loadData, setloadData] = useState(false);
	const [steps, setsteps] = useState([]);
	const [loadSteps, setloadSteps] = useState(false);
	const [nextbutton, setNextbutton] = useState(true);

	useEffect(() => {
		document.title = intl.formatMessage({ id: "incubator" });
		if (user.account_type !== "idea_holder") history.push("/404");
		getPathByIdea();
		// eslint-disable-next-line
	}, []);

	//get Path for selected Idea
	const getPathByIdea = () => {
		props.getPath(ideaid).then((res) => {
			if (res.status === "success" && res.result !== null) {
				setPaths(res.result);
				setloadData(true);
			} else {
				history.push("/app");
			}
		});
	};

	useEffect(() => {
		selectedStage &&
			getsSteps();
		// eslint-disable-next-line
	}, [selectedStage]);
	const getsSteps = () => {
		const data = {
			ideaId: ideaid,
			stageId: selectedStage._id,
		};
		setloadSteps(false);
		props.getStageinfo(data).then((res) => {
			if (res.status === "success") {
				setsteps(res.result);
				setloadSteps(true);
			} else {
				setsteps([]);
			}
		});
	};

	return (
		<div className="incubator-page">
			<section className="section__main">
				<Container>
					<h1 className="section__main--title heading-1">
						<FormattedMessage id="Incubatorspace" />
					</h1>
					<MainSVG className="section__main--main-svg" />
				</Container>
			</section>
			<Container>
				{loadData ? (
					<>
						<Row style={{ margin: "20px 0" }}>
							<Col md={3} style={{ marginBottom: 10 }}>
								<div
									className="carta carta-nav"
									onClick={() =>
										history.push({
											pathname: "/app/chat/teams",
											state: { idea_id: Paths.idea_info._id },
										})
									}
								>
									<FormattedMessage id="teamRoom" />
								</div>
							</Col>
							<Col md={3} style={{ marginBottom: 10 }}>
								<div
									className="carta carta-nav"
									onClick={() =>
										history.push({
											pathname: `/app/ideas/manageteam/${Paths.idea_info.ideaId}`,
											state: { idea_id: Paths.idea_info._id },
										})
									}
								>
									<FormattedMessage id="manageTeam" />
								</div>
							</Col>
							<Col md={3} style={{ marginBottom: 10 }}>
								<div className="carta carta-nav carta-nav-disabled">
									<FormattedMessage id="getCertificate" />
								</div>
							</Col>
							<Col md={3} style={{ marginBottom: 10 }}>
								<div className="carta carta-nav carta-nav-disabled">
									<FormattedMessage id="launchAsStartup" />
								</div>
							</Col>
						</Row>
						<div className="carta">
							<Row>
								<Col md={3} style={{ marginBottom: 20 }}>
									<div className="idea-selector">
										<BulbOutlined style={{ margin: "0 5px" }} size={30} />
										{Paths.idea_info.title}
									</div>
								</Col>
								<Col md={3} style={{ marginBottom: 20 }}>
									<div className="idea-selector">
										<Avatar
											src={UserService.avatarLink({
												avatar: Paths.idea_info.user.avatar,
												userId: Paths.idea_info.user.userId,
											})}
											style={{
												margin: "0 5px",
											}}
										/>
										<div className="team-leader">
											<div className="name">
												{`${Paths.idea_info.user.firstname} ${Paths.idea_info.user.lastname}`}
											</div>
											<div
												className="all-members"
												onClick={() =>
													history.push({
														pathname: `/app/ideas/manageteam/${Paths.idea_info.ideaId
															? Paths.idea_info.ideaId
															: ""
															}`,
														state: {
															idea_id: Paths.idea_info._id
																? Paths.idea_info._id
																: "",
														},
													})
												}
											>
												<FormattedMessage id="seeAllMembers" />
												<ChevronRight size={12} />
											</div>
										</div>
									</div>
								</Col>
								<Col md={3} style={{ marginBottom: 20 }}>
									<div className="idea-selector">
										<Users
											color="#999999"
											style={{
												margin: "0 10px",
											}}
											size={30}
										/>
										<div className="team-leader">
											<div className="name">
												<FormattedMessage id="suggestedMentors" />
											</div>
											<div
												className="all-members"
												onClick={() => history.push("/app/explore/mentor")}
											>
												<FormattedMessage id="seeSuggestions" />
												<ChevronRight size={12} />
											</div>
										</div>
									</div>
								</Col>
								<Col md={{ size: 2, offset: 1 }} style={{ marginBottom: 20 }}>
									<div className="idea-selector idea-selector-start">
										<Calendar size={12} style={{ margin: "0 5px" }} />
										{moment(Paths.idea_info.created_date)
											.clone()
											.tz(timezone)
											.fromNow()}
									</div>
								</Col>
							</Row>
							<div className="heading">
								<p>
									<FormattedMessage id="stagesAndSteps" />
								</p>
								<hr />
							</div>
							<Row md={4} sm={2} xs={1} style={{ justifyContent: "center" }}>
								{_.isArray(Paths.stages)
									? Paths.stages.map((stage, key) => (
										<Col
											key={stage._id}
											style={{
												justifyContent: "center",
												alignItems: "center",
												marginBottom: 20,
											}}
										>
											<Stage
												key={stage._id}
												setselectedStage={setselectedStage}
												stages={stage}
												size={120}
											/>
										</Col>
									))
									: notification["error"]({
										message: intl.formatMessage({ id: "error_text" }),
										description: intl.formatMessage({ id: "error_1" }),
									})}
							</Row>
							<Row>
								{loadSteps ? (
									<Steps
										steps={{
											getPathByIdea: getPathByIdea,
											getsSteps: getsSteps,
											process_info: Paths.process_info,
											idea: Paths.idea_info,
											allSteps: steps,
											stage: selectedStage,
											total: selectedStage.total_steps,
											finished: selectedStage.steps_finished,
											stages: Paths.stages,
											path_id: Paths.path_id,
											setselectedStage: setselectedStage,
											nextbutton: nextbutton,
											setNextbutton: setNextbutton,
										}}
									/>
								) : (
									""
								)}
							</Row>
						</div>
					</>
				) : (
					""
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = { getPath, getMyIdeas, getStageinfo, EditStep };

export default injectIntl(
	connect(mapStateToProps, mapDispatchToProps)(Incubation)
);
