import Api from "./Api";

const Path = {
	/*
	 **  Show current user chosen paths for their ideas
	 */
	getPath(ideaId) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/paths/${ideaId}/progress`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 **  Show Stages info
	 */
	getStageinfo(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/paths/${data.ideaId}/${data.stageId}/progress`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 **  Show Step info
	 */
	getStepinfo(data) {
		return new Promise((resolve, reject) => {
			Api()
				.get(`/paths/${data.ideaId}/step/${data.stepId}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	/*
	 **  Opens a Path Step in a given Stage, previous steps or stages must be completed
	 */
	openStep(data) {
		return new Promise((resolve, reject) => {
			Api()
				.patch(`/paths/${data.pathId}/open/${data.stepId}/in/${data.stageId}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},

	/*
	 ** document links accepts [array of url strings]
	 */

	finishStep(data) {
		return new Promise((resolve, reject) => {
			Api()
				.patch(`/paths/${data.pathId}/finish/${data.stepId}/in/${data.stageId}`, data.body)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},

	/*
	 **  List Available Processes
	 */
	getAvailableProcess() {
		return new Promise((resolve, reject) => {
			Api()
				.get("/paths/list/processes")
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		});
	},
	helpMeImStack(data) {
		return new Promise((resolve, reject) => {
			Api()
				.post("/contacts/private", data)
				.then(res => {
					resolve(res.data)
				})
				.catch(err => reject(err))
		})
	},
	EditStep(data) {
		return new Promise((resolve, reject) => {
			Api().patch(`/paths/${data.pathId}/edit/${data.stepId}/in/${data.stageId}`, data.body)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		})
	},
	getIncubationTimeline(idea) {
		return new Promise((resolve, reject) => {
			Api().get(`/ideas/${idea}/timeline`)
				.then(res => {
					resolve(res.data)
				})
				.catch(err => reject(err))
		})
	},
};

export default Path;

