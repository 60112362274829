import ZoomMeet from '../../Zoom/Zoom';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import "../../sass/ZoomMeeting.scss"
import { useLocation } from 'react-router-dom';
import QueryString from "query-string";

const ZoomMeetHome = (props) => {
    const [params, setParams] = useState([])
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        setParams(QueryString.parse(location.search))
    }, [location.search])

    return (
        <div className="kor">
            {params.joinMeeting === "true" ? <ZoomMeet meeting={params} /> : history.goBack()}
        </div>
    );

}

export default ZoomMeetHome;