import api from "./Api";

// Idea Section.

const Ideas = {
	// Add new idea.
	AddIdea(data) {
		return new Promise((resolve, reject) => {
			api()
				.post("/ideas/", data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	// get idea infos.
	getIdeaInfo(id) {
		return new Promise((resolve, reject) => {
			api()
				.get(`/ideas/${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	// Edit idea infos.
	EditIdea(data, id) {
		return new Promise((resolve, reject) => {
			api()
				.post(`/ideas/${data.ideaId}/edit`, data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	// List all my ideas.
	getMyIdeas() {
		return new Promise((resolve, reject) => {
			api()
				.get("/ideas/me/?limit=50&sort=begin")
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	// List all public ideas.
	getAllIdeas(filters) {
		return new Promise((resolve, reject) => {
			api()
				.get(
					`/ideas?sort=begin&page=${filters.page ? filters.page : 1}&limit=6${filters.url ? filters.url : ""
					}`
				)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	// get public ideas with User Id.
	getAllIdeasWithUserId(userId) {
		return new Promise((resolve, reject) => {
			api()
				.get(`/ideas?sort=begin&user_id=${userId}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	},
	searchIdeas(data) {
		return new Promise((resolve, reject) => {
			api().get(`/ideas?sort=begin&page=${data.page ? data.page : 1}&limit=10&search_query=${data.search}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => reject(err));
		})
	}
};

export default Ideas;
