import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Empty } from "antd";
import { ReactComponent as Vec } from "../../assets/icons/IdeaInfo/Vector.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/IdeaInfo/ArrowDown.svg";
import EditPosition from "./EditPosition";
import DeleteMember from "./DeleteMember";
import { connect } from "react-redux";
import { getTeamMembers } from "../../redux/actions/Manageteam/TeamActions";
import { FormattedMessage, injectIntl } from "react-intl";
import { socket } from "../../hooks/Socket";
import UserMethods from "../../Helpers/UserHelpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Teams = (props) => {
	const history = useHistory()
	const [EditPos, setEditPos] = useState(false);
	const [deleteMember, setDeleteMember] = useState(false);
	const [Role, setRole] = useState(false);
	const [Pose, setPose] = useState(false);
	const [currentUser, setCurrentUser] = useState("");
	const [admin, setadmin] = useState(false);
	//Loaders
	const [loadRequest, setloadRequest] = useState(true);
	//Data
	const [result, setResult] = useState([]);

	useEffect(() => {
		getTeamMembers();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		socket.on("new_notification", (data) => {
			if (
				data &&
				data.received_by &&
				Array.isArray(data.received_by) &&
				data.received_by.some((one) => one === props.me._id)
			) {
				getTeamMembers();
			}
		});
		return () => { socket.off("new_notification"); };
		// eslint-disable-next-line
	}, []);

	const getTeamMembers = () => {
		setadmin(false);
		props.getTeamMembers(props.id).then((res) => {
			if (res && res.status === "success") {
				setResult(res.Members);
				setloadRequest(true);
				if (
					res.Members.some(
						(one) => one.admin && props.me.userId === one.user.userId
					)
				)
					setadmin(true);
			} else if (res === "Not Found") setloadRequest(false);
		}).catch((err) => { history.push("/500") });
	};
	const menu3 = (userID) => (
		<Menu>
			<Menu.Item
				key={1}
				onClick={() => {
					setPose(false);
					setRole(true);
					setEditPos(true);
					setDeleteMember(false);
					setCurrentUser(userID);
				}}
			>
				<FormattedMessage id="editrole" />
			</Menu.Item>
			<Menu.Item
				key={2}
				onClick={() => {
					setPose(true);
					setRole(false);
					setDeleteMember(false);
					setEditPos(true);
					setCurrentUser(userID);
				}}
			>
				<FormattedMessage id="editposition" />
			</Menu.Item>
			<Menu.Item key={3}>Send message</Menu.Item>
			<Menu.Item
				key={4}
				style={{ color: "red" }}
				onClick={() => {
					setDeleteMember(true);
					setEditPos(false);
					setCurrentUser(userID);
				}}
			>
				<FormattedMessage id="removemember" />
			</Menu.Item>
		</Menu>
	);
	return (
		<div className="Teams">
			<div className="teamMembers">
				{loadRequest && result.length > 0 ? (
					result.map((item, i) => {
						return (
							<div className="editMembers" key={i}>
								<div className="user">
									<div className="useruser">
										<div
											className="userImage"
											style={{
												backgroundImage: `url('${UserMethods.getUserImage(item.user.userId, item.user.avatar)}')`,
											}}
										></div>
										<div className="userInfos">
											<div className="username">
												{item.user.firstname} {item.user.lastname}
											</div>
											<div className="position">
												<FormattedMessage id={item.position} />
											</div>
											<div className="member">
												{item.admin ? "Admin" : "Member"}
											</div>
										</div>
									</div>

									{admin && item.user.userId !== props.me.userId ? (
										<div className="menu" key={i}>
											<Dropdown overlay={menu3(item.user.userId)}>
												{/* eslint-disable-next-line */}
												<a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
													<Vec /> <ArrowDown />
												</a>
											</Dropdown>
										</div>
									) : (
										""
									)}
								</div>
								{EditPos && !item.admin && item.user.userId === currentUser ? (
									<div className="Edit" key={i}>
										<EditPosition
											idea={props.id}
											idea_id={props.idea_id}
											data={item}
											getTeamMembers={getTeamMembers}
											edit={Role ? "role" : Pose ? "pose" : ""}
											onClose={() => setEditPos(false)}
										/>
									</div>
								) : deleteMember &&
									!item.admin &&
									item.user.userId === currentUser ? (
									<div className="Edit">
										<DeleteMember
											getTeamMembers={getTeamMembers}
											onClose={() => setDeleteMember(false)}
											idea={props.id}
											data={item}
										/>
									</div>
								) : (
									""
								)}
							</div>
						);
					})
				) : (
					<Empty description="noData" />
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	me: state.auth.login.user,
});

export default injectIntl(connect(mapStateToProps, { getTeamMembers })(Teams));
