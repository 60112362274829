import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../sass/Profile/Profiles.scss";
import {
	Col,
	Container,
	Row,
} from "reactstrap";
import {
	Button,
	Form,
	Input,
	Select,
	DatePicker,
	Empty,
	notification
} from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import Heading from "../../../components/Heading";
import ProfileCard from "../../../components/Profiles/ProfileCard";
import {
	getProfiles,
	getProfilesByName,
} from "../../../redux/actions/Profiles/ProfilesActions";
import ProfilesMethods from "../../../Helpers/Profiles";
import { useParams, useHistory } from "react-router-dom";
import PaginationComponent from "react-reactstrap-pagination";
import { PulseLoader } from "react-spinners";
import "bootstrap/dist/css/bootstrap.min.css";

const Profiles = (props) => {
	const { getProfiles, getProfilesByName, intl } = props;
	const { type } = useParams();
	const history = useHistory();
	const [Users, setUsers] = useState([]);
	const [result, setResult] = useState([]);
	const [name, setName] = useState("");
	const [Url, setUrl] = useState("");
	const [Disableav, setDisableav] = useState(true);
	const [Filters] = Form.useForm();
	const [loading, isLoading] = useState(false)

	useEffect(() => {
		document.title = intl.formatMessage({ id: type });
		if (type === "all") setUrl("");
		else setUrl(`&account=${type}`);
		if (type !== "all") {
			getAllProfiles(`&account=${type}`)
				.then(resp => {
					Filters.setFieldsValue({ accountType: type });
					setUrl(`&account=${Filters.getFieldValue("accountType")}`);
					Filters.getFieldValue("accountType") === "idea_holder" ||
						!Filters.getFieldValue("accountType")
						? setDisableav(true)
						: setDisableav(false);
				})
		} else {
			getAllProfiles("").then(resp => {
				Filters.setFieldsValue({ accountType: null });
				Filters.getFieldValue("accountType") === "idea_holder" ||
					!Filters.getFieldValue("accountType")
					? setDisableav(true)
					: setDisableav(false);
			})

		}
		// eslint-disable-next-line 
	}, [type]);

	useEffect(() => {
		Filters.accountType === "idea-holder" || !Filters.accountType
			? setDisableav(true)
			: setDisableav(false);
	}, [Filters]);

	useEffect(() => {

		if (type !== "all" || type !== "idea_holder") setDisableav(false);
		if (type === "all") setUrl("");
		else setUrl(`&account=${Filters.getFieldValue("accountType")}`);
		// eslint-disable-next-line 
	}, []);

	/*
	 **	get All Profiles
	 */
	const getAllProfiles = (url) => {
		return new Promise((resolve, rej) => getProfiles(url).then((res) => {
			if (res.status === "success") {
				setResult(res.results);
				setUsers(res.results.Users);
				isLoading(false)
				resolve('Done')
			} else if (res === "Not Found") {
				setUsers([]);
				setResult([]);
				isLoading(false)
				resolve('Done')
			}
		}))
	};
	/*
	 **	get Profiles by filter searsh
	 */
	const getFilters = () => {
		if (
			!Filters.getFieldValue("accountType") ||
			Filters.getFieldValue("accountType") === "idea_holder"
		)
			Filters.setFieldsValue({ availability: "" });
		if (!Filters.getFieldValue("accountType")) history.push("/app/explore/all");
		else history.push(`/app/explore/${Filters.getFieldValue("accountType")}`);
		Filters.getFieldValue("accountType") === "idea_holder" ||
			!Filters.getFieldValue("accountType")
			? setDisableav(true)
			: setDisableav(false);
		ProfilesMethods.FilterUser(Filters.getFieldsValue())
			.then((res) => {
				setUrl(res);
				getAllProfiles(res);
			})
			.catch((err) =>
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: err }),
				})
			);
	};
	/*
	 **	Searsh and get Profiles by name
	 */
	const handlesearshClick = () => {
		getProfilesByName(name).then((res) => {
			if (res.status === "success") setUsers(res.results.Users);
			else setUsers([]);
		});
	};
	/*
	 **	Get Profile By pagination
	 */
	const getProfilebyPage = (pagenb) => {
		getAllProfiles(Url + `&page=${pagenb}`);
	};
	return (
		<div className="profiles-page">
			{
				loading ? <PulseLoader color="#458ff6" size={8} /> :
					<Container>
						<Row>
							{/* HAD ROW DIAL CARDS + FILTER */}
							<Col lg={9} md={8} sm={12}>
								<Row style={{ marginBottom: 30 }}>
									{/* HAD ROW DIAL SEARCH */}
									<Col>
										<div className="carta search">
											<Input
												size="large"
												value={name}
												onChange={(e) => setName(e.target.value)}
											/>
											<Button type="primary" onClick={() => handlesearshClick()}>
												<FormattedMessage id="search" />
											</Button>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<Row lg={3} md={2} sm={2} xs={1}>
											{Users.length ? (
												Users.map((user, i) => {
													return (
														<Col key={user._id} style={{ marginBottom: 20 }}>
															<ProfileCard user={user} />
														</Col>
													);
												})
											) : (
												<Empty />
											)}
										</Row>
									</Col>
								</Row>
							</Col>
							<Col>
								<div className="carta">
									<Heading
										text={<FormattedMessage id="filter" />}
										color="#458ff6"
									/>
									<Form
										layout="vertical"
										size="large"
										onValuesChange={getFilters}
										form={Filters}
									>
										<Form.Item
											name="gender"
											label={<FormattedMessage id="gender" />}
										>
											<Select placeholder="Gender" size="large" allowClear>
												<Select.Option value="male">
													<FormattedMessage id="male" />
												</Select.Option>
												<Select.Option value="female" >
													<FormattedMessage id="female" />
												</Select.Option>
												<Select.Option value="other" >
													<FormattedMessage id="other" />
												</Select.Option>
											</Select>
										</Form.Item>
										<Form.Item
											value={Filters.accountType}
											name="accountType"
											label={<FormattedMessage id="accountType" />}
										>
											<Select placeholder="Account type" allowClear>
												{/* /Mentor/Expert/coach/instruter */}
												<Select.Option value="idea_holder">
													<FormattedMessage id="ideaHolder" />
												</Select.Option>
												<Select.Option value="instructor">
													<FormattedMessage id="instructor" />
												</Select.Option>
											</Select>
										</Form.Item>

										<Form.Item
											name="availability"
											label={<FormattedMessage id="availability" />}
										>
											<DatePicker.RangePicker
												disabled={Disableav}
												format="DD-MM-YYYY"
												allowClear
											/>
										</Form.Item>
									</Form>
								</div>
							</Col>
						</Row>
						<div
							className="pagination"
							style={{ marginTop: "30px", marginLeft: "15px" }}
						>
							<Row>
								<PaginationComponent
									totalItems={result.totalPages ? result.totalPages : 0}
									pageSize={6}
									onSelect={getProfilebyPage}
									maxPaginationNumbers={9}
								/>
							</Row>
						</div>
					</Container>
			}

		</div>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { getProfiles, getProfilesByName };

export default injectIntl(
	connect(mapStateToProps, mapDispatchToProps)(Profiles)
);
