import ManageTeam from "../../../services/Teams";


/*
** get All profiles
** You can use filters to get profiles
*/
export const getProfiles = (url) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.getUsers(url)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.results.Users.length <= 0
						)
							resolve("Not Found");
						else reject(err);
					} else reject(err);
				});
		});
	};
};

/*
**	searsh and get profiles by User name
*/

export const getProfilesByName = (name) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ManageTeam.getUsersbyName(name)
				.then((res) => {
					if (res.data && res.data.status === "success") resolve(res.data);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						const res = err.response.data;
						if (
							res.status &&
							res.status === "success" &&
							res.results.Users.length <= 0
						)
							resolve("Not Found");
						else reject(err);
					} else reject(err);
				});
		})
	}
}