import React from "react";
import "../../sass/ManageTeam/DeleteMember.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import { deleteMember } from "../../redux/actions/Manageteam/TeamActions";
import { connect } from "react-redux";
import { notification } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DeleteMember = (props) => {
	const { intl } = props;
	const history = useHistory()

	const delMember = () => {
		const data = {
			ideaId: props.idea,
			userId: props.data.user.userId,
		};
		props.deleteMember(data).then((res) => {
			if (res.status === "success") {
				notification["success"]({
					message: intl.formatMessage({ id: "success" }),
					// description: intl.formatMessage({ id: res.msg }),
				});
				props.getTeamMembers();
			} else if (res.status === "failure")
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: res.msg }),
				});
		}).catch((err) => { history.push("/500") });
	};
	return (
		<div className="Delete">
			<h6>
				<FormattedMessage id="removeMember" />
			</h6>
			<p>
				<FormattedMessage id="removeMemberdesc" />
			</p>
			<div className="Buttons">
				<div className="cancelButton" onClick={props.onClose}>
					Cancel
				</div>
				<div className="deleteButton" onClick={() => delMember()}>
					<FormattedMessage id="DeleteBtn" />
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({});
export default injectIntl(
	connect(mapStateToProps, { deleteMember })(DeleteMember)
);
