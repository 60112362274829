import React, { useContext } from "react";
import "../../sass/Ideas/ideaCard.scss";
import { ArrowRight, ArrowLeft } from "react-feather";
import "../../sass/Avatar.scss";
import "../../sass/Ideas/ideaCard.scss";
import { IntlContext } from "../../context/internationalization";
import { useHistory } from "react-router-dom";
import ProcessMethods from "../../Helpers/ProcessInfoHelpers"
import { FormattedMessage } from "react-intl";

const ProcessCard = (props) => {
	const context = useContext(IntlContext);
	const history = useHistory();
	const style = props.style ? { ...props.style } : {};


	const getDescrp = (desc) => {
		return desc.substring(0, 150) 
	}

	return (
		<div>
			<div
				className="card"
				style={{ ...style, cursor: "pointer", margin: "auto" }}
				onClick={() => history.push(`/app/process/${props.pro.process_id}`)}
			>
				<div className="thumb">
					<img
						src={ProcessMethods.getProcessImage(props.pro.process_id, props.pro.image)}
						alt="bigola"
					/>
				</div>
				<div className="ideaTitle">{
					localStorage.getItem("locale") === "ar" ? props.pro.name_ar : localStorage.getItem("locale") === "fr" ? props.pro.name_fr : props.pro.name_en

				}</div>
				<div className="ideadesc">
					{
						localStorage.getItem("locale") === "ar" ? getDescrp(props.pro.description_ar) : localStorage.getItem("locale") === "fr" ? getDescrp(props.pro.description_fr) :  getDescrp(props.pro.description_en)
					}
				</div>
				<div className="ideaaction">
					<div className="ideadatesection">
					</div>
					<div className="more">
						<div className="seemore" style={{ cursor: "pointer" }} onClick={() => history.push(`/app/process/${props.pro.process_id}`)}>
							<FormattedMessage id="seemore" />{" "}
							<div style={{ marginLeft: "2px" }}>
								{context.state.direction === "rtl" ? (
									<ArrowLeft size={12} />
								) : (
									<ArrowRight size={12} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProcessCard;
