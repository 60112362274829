import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
// import { PulseLoader } from "react-spinners";
import { Col, Container, Row } from "reactstrap";
import userService from "../../../services/user";
import "../../../sass/Sessions/Book.scss";
import { Calendar, notification, TimePicker } from "antd";
import moment from "moment-timezone";
import { PlusCircle, XCircle } from "react-feather";
import { FormattedMessage, injectIntl } from "react-intl";
import {
	createSession,
	getSession,
	deleteSession,
	getSlots,
	GetAllMonthSlots,
} from "../../../redux/actions/Sessions/SessionsActions";
import SessionsMethods from "../../../Helpers/SessionsHelpers";
import classNames from "classnames";

export const Create = (props) => {
	const history = useHistory();
	const { intl } = props;
	const [selectedDate, setSelectedDate] = useState(moment());
	const [slots, setSlots] = useState([]);
	const [addedSlot, setAddedSlot] = useState(null);
	const [resultat, setresultat] = useState({});

	useEffect(() => {
		if (props.user.account_type === "idea_holder") history.push("/404");
	}, [history, props.user]);
	useEffect(() => {
		getSlots(selectedDate);
		getSlotsbyRange();
		// eslint-disable-next-line
	}, [selectedDate]);

	const CreateNewSlot = () => {
		SessionsMethods.MergeDateAndTime(selectedDate, addedSlot).then((res) => {
			props.createSession(res).then((result) => {
				if (result.status === "success") {
					notification["success"]({
						message: intl.formatMessage({ id: "success" }),
						description: intl.formatMessage({ id: "slotCreated" }),
					});
					setSlots((slots) => [...slots, moment(addedSlot)]);
					getSlotsbyRange();
				} else if (result.status === "failure")
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: result.msg }),
					});
			});
		});
	};
	const getSlots = (date) => {
		SessionsMethods.getSlotsByDate(date).then((res) => {
			Object.assign(res, { owner: props.user._id, limit: 24 });
			props.getSession(res).then((result) => {
				if (result.status === "success" && result.result.Sessions) {
					setSlots(result.result.Sessions);
				} else setSlots([]);
			});
		});
	};
	const deleteSlot = (slotId, k) => {
		props.deleteSession(slotId).then((res) => {
			if (res.status === "success") {
				notification["success"]({
					message: intl.formatMessage({ id: "success" }),
					description: intl.formatMessage({ id: "slotDeleted" }),
				});
				setSlots((slots) => slots.filter((s, i) => i !== k));
			} else if (res.status === "failure")
				notification["error"]({
					message: intl.formatMessage({ id: "error_text" }),
					description: intl.formatMessage({ id: res.msg }),
				});
		});
	};
	// get Mentor Available Slots by Range
	const getSlotsbyRange = () => {
		const begin = moment(selectedDate).format("YYYY-MM-01");
		const data = {
			begin,
			end: moment(begin).add(31, "days"),
		};
		SessionsMethods.getSlotsByRange(data).then((res) => {
			Object.assign(res, { owner: props.user._id });
			props.GetAllMonthSlots(res).then((result) => {
				if (result.status === "success") {
					setresultat(result.result);
				} else setresultat([]);
			});
		});
	};
	return (
		<div className="book-page">
			<Container>
				<Row style={{ justifyContent: "center" }}>
					<Col md={10}>
						<div className="carta title"><FormattedMessage id="manageSlots" /></div>
						<div className="carta">
							<Container>
								<Row>
									<Col md={6} style={{ paddingTop: 50 }}>
										<div className="calendar-wrapper">
											<div
												className="picture"
												style={{
													backgroundImage: `url('${userService.avatarLink({
														avatar: props.user.avatar,
														userId: props.user.userId,
													})}')`,
												}}
											></div>
											<div className="header"><FormattedMessage id="availableday" /> </div>
											<Calendar
												fullscreen={false}
												value={selectedDate}
												onSelect={(date) => {
													setSelectedDate(date);
													getSlots(date);
												}}
												dateFullCellRender={(date) => {
													return (
														<div
															className={classNames("cell", {
																"cell-selected": date.diff(selectedDate) === 0,
																"cell-stroke":
																	resultat[date.format("YYYY-MM-DD")] > 0,
															})}
														>
															{date.date()}
														</div>
													);
												}}
											/>
										</div>
									</Col>
									<Col md={6} style={{ paddingTop: 30 }}>
										<div className="booker-wrapper">
											<div className="header"><FormattedMessage id="openyourhourslot" /> </div>
											<div className="slots">
												{slots.map((m, k) => (
													<div className="slot2" key={k}>
														{m.begin_at ? (
															<div className="text">
																{`from ${moment(m.begin_at).format(
																	"HH"
																)}:00 to ${moment(m.end_at).format("HH")}:00`}
															</div>
														) : (
															<div className="text">
																{`from ${m.format("HH")}:00 to ${moment(m)
																	.add(1, "h")
																	.format("HH")}:00`}
															</div>
														)}

														<XCircle
															color="#FF0000"
															strokeWidth={1}
															style={{
																margin: "0 10px",
																cursor: "pointer",
															}}
															onClick={() => {
																deleteSlot(m.sessionId, k);
															}}
														/>
													</div>
												))}
											</div>
											<div className="add-slot">
												<TimePicker
													showNow={false}
													size="large"
													format="HH:00:00"
													value={addedSlot}
													onChange={(value) => setAddedSlot(value)}
													disabledHours={() => slots}
												/>
												<PlusCircle
													className="add-button"
													color="#28a745"
													strokeWidth={1}
													style={{
														margin: "0 10px",
														cursor: addedSlot ? "pointer" : "unset",
														opacity: addedSlot ? 1 : 0.5,
													}}
													onClick={() => {
														setAddedSlot(null);
														CreateNewSlot(addedSlot);
													}}
												/>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = {
	createSession,
	getSession,
	deleteSession,
	getSlots,
	GetAllMonthSlots,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Create));
