import React from "react";
import { connect } from "react-redux";
import { logout } from "../redux/actions/auth/loginActions";

const Logout = ({ children, logout }) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={logout}>
      {children}
    </div>
  );
};

export default connect(null, {
  logout,
})(Logout);
