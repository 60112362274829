import React, { useEffect } from "react";
import QueryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import config from '../../Helpers/config'

const LoginOK = () => {
	const history = useHistory();
	const location = useLocation()
	useEffect(() => {
		const parsed = QueryString.parse(location.search);
		if (parsed.tk) {
			localStorage.setItem("AyHc7zSW7n6rI", parsed.tk);
			window.location.href = config.zoomLink;
		}
		else history.push("/404");
	}, [location.search, history])

	return (<></>);
};


export default LoginOK;
