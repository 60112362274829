const initialState = {
  successAlert: false,
};

export const register = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTER_USER": {
      return {
        ...state,
        successAlert: action.successAlert,
      };
    }
    case "SUCCESS_ALERT": {
      return {
        ...state,
        successAlert: action.successAlert,
      };
    }
    default: {
      return state;
    }
  }
};
