import React, { useEffect } from "react";
import { notification } from "antd";
import { FormattedMessage } from "react-intl";
import useSound from "use-sound";
import boop from "../assets/Sound/notificationSound3.mp3";

const NotificationAlert = (props) => {
	const [play] = useSound(boop, { volume: 1.25 });
	const Context = React.createContext({ name: "Default" });
	const [api, contextHolder] = notification.useNotification();
	useEffect(() => {
		play();
	}, [play]);

	useEffect(() => {
		openNotification("bottomLeft");
		// eslint-disable-next-line
	}, []);

	const openNotification = (placement) => {
		play();
		api.info({
			message: `${props.user.firstname.toUpperCase()} ${props.user.lastname.toUpperCase()} `,
			description: <FormattedMessage id={props.msg} />,
			placement,
		});
	};

	return <Context.Provider value="Ant">{contextHolder}</Context.Provider>;
};

export default NotificationAlert;
