const config = {
	userLink: 'https://leafunder.ofppt.ma',
	serverLink: 'https://leafunder.ofppt.ma',
	socketLink: 'https://leafunder.ofppt.ma',
	zoomLink: "https://leafunder.ofppt.ma"
}

// dev
// const config = {
// 	userLink: 'http://localhost:1337',
// 	serverLink: 'http://localhost:1337',
// 	socketLink: 'http://localhost:8081',
// 	zoomLink: "https://localhost:3000"
// }

export default config;