import Communications from "../../../services/Socket/Communications";

export const CreateUserConversation = (user_id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.CreateUserConversation(user_id)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const CreateTeamConversation = (idea_id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.CreateTeamConversation(idea_id)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const CreateSessionConversation = (session_id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.CreateSessionConversation(session_id)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};

export const getMySessionsConversations = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.getMySessionsConversations()
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const getMyDMsConversations = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.getMyDMsConversations()
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const getMyTeamsConversations = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.getMyTeamsConversations()
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const getMyTeamsConversationsByIdea = (idea) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.getMyTeamsConversationsByIdea(idea)
				.then((res) => {
				})
				.catch((err) => reject(err));
		});
	};
};

export const CreateMessage = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.CreateMessage(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};

export const getMessages = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.getMessages(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {

					if (err.response.status === 401) {
						const res = err.response.data
						if (res.status === "failure") resolve(res)
					}
				});
		});
	};
};
export const getMessagesWithPage = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.getMessagesWithPage(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 401) {

					}
				});
		});
	};
};

export const ReadAllMessages = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.ReadAllMessages(id)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};

export const getUnreadConvsCount = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.getUnreadConvsCount()
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};

export const UploadFileToConversation = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.UploadFileToConversation(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const UploadImageToConversation = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.UploadImageToConversation(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const ShowMessagesLinkedToAConversation = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.ShowMessagesLinkedToAConversation(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};

export const SearchInMessages = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.SearchInMessages(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const getImageConvs = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.getImageConvs(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const getDocsConvs = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.getDocsConvs(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => reject(err));
		});
	};
};
export const createTask = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.createTask(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (
						err.response.status === 404 ||
						err.response.status === 400 ||
						err.response.status === 401
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") {
							resolve(res);
						} else reject(err);
					} else reject(err);
				});
		});
	};
};

export const sendTask = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.sendTask(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (
						err.response.status === 404 ||
						err.response.status === 400 ||
						err.response.status === 401
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") {
							resolve(res);
						} else reject(err);
					} else reject(err);
				});
		});
	};
};
export const finishTask = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.finishTask(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (
						err.response.status === 404 ||
						err.response.status === 400 ||
						err.response.status === 401
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") {
							resolve(res);
						} else reject(err);
					} else reject(err);
				});
		});
	};
};

export const getTaskConvs = (data) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			Communications.getTaskConvs(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (
						err.response.status === 404 ||
						err.response.status === 400 ||
						err.response.status === 401
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") {
							resolve(res);
						} else reject(err);
					} else reject(err);
				});
		})
	}
}

export const CreateZoomMeeting = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.CreateZoomMeeting(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (
						err.response.status === 404 ||
						err.response.status === 400 ||
						err.response.status === 401
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") {
							resolve(res);
						} else reject(err);
					} else reject(err);
				})
		})
	}
}
export const sendZoomMeeting = (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			Communications.sendZoomMeeting(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (
						err.response.status === 404 ||
						err.response.status === 400 ||
						err.response.status === 401
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") {
							resolve(res);
						} else reject(err);
					} else reject(err);
				})
		});
	};
};

export const getSignature = (data) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			Communications.getSignature(data)
				.then((res) => {
					if (res.status === "success") resolve(res);
				})
				.catch((err) => {
					if (
						err.response.status === 404 ||
						err.response.status === 400 ||
						err.response.status === 401
					) {
						const res = err.response.data;
						if (res.status && res.status === "failure") {
							resolve(res);
						} else reject(err);
					} else reject(err);
				})
		})
	}
}