import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as MainSVG } from "../../../assets/About/MainSVG.svg";
import { Row, Col, Container } from "reactstrap";
import "../../../sass/Incubator/index.scss";
import { Empty } from "antd";
import { getPath } from "../../../redux/actions/Path/PathActions";
import { getMyIdeas } from "../../../redux/actions/Ideas/IdeasActions";
import IdeaCard from "../../../components/Ideas/IdeaCard";

export const Incubation = (props) => {
	const { intl } = props;
	const history = useHistory();
	const { user } = props;
	const [myIdeas, setmyIdeas] = useState([]);

	const [noResult, setNoResult] = useState(false);

	useEffect(() => {
		document.title = intl.formatMessage({ id: "incubator" });
		if (user.account_type !== "idea_holder") history.push("/404");
		getIdeas();
		// eslint-disable-next-line
	}, []);

	//get My Ideas
	const getIdeas = () => {
		props.getMyIdeas().then((res) => {
			if (res.status === "success") {
				setmyIdeas(res.result.Ideas);
			}
			else {
				setNoResult(true)
			}
		});
	};

	return (
		<div className="incubator-page">
			<section className="section__main">
				<Container>
					<h1 className="section__main--title heading-1">
						<FormattedMessage id="Incubatorspace" />
					</h1>
					<MainSVG className="section__main--main-svg" />
				</Container>
			</section>
			<Container>
				<div className="" style={{ marginTop: "50px" }}>
					<Row style={{ justifyContent: "center" }} md={2} lg={3} sm={1} xs={1}>
						{myIdeas.length > 0 ? (
							myIdeas.map((idea, i) => {
								return (
									<Col style={{ marginTop: 20 }} key={i}>
										<IdeaCard idea={idea} user={props.user} incubation="true" />
									</Col>
								);
							})
						) : noResult ? (
							<Empty description={<FormattedMessage id="error_73" />} />
							
						) : (
							<Empty description={<FormattedMessage id="error_73" />} />
						)}
					</Row>
				</div>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

const mapDispatchToProps = { getPath, getMyIdeas };

export default injectIntl(
	connect(mapStateToProps, mapDispatchToProps)(Incubation)
);
