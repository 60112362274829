export const Path = (state = { process_info: null }, action) => {
	switch (action.type) {
		case "GET_PROCESS": {
			return { ...state, process_info: action.process_info };
		}
		default: {
			return state;
		}
	}
};
