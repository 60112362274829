import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
	Container,
	Row,
	Col,
	Alert,
	CardBody,
	CardImg,
	Card,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Form, Input, Select, Spin, notification } from "antd";
import { X, Youtube } from "react-feather";
import Uppy from "@uppy/core";
import { DragDrop } from "@uppy/react";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { IntlContext } from "../../../context/internationalization";
import {
	getIdeaInfo,
	EditIdeaInfo,
	deleteIdeaImage,
	addIdeaImage,
} from "../../../redux/actions/Ideas/IdeasActions";
import Heading from "../../../components/Heading";
import BraftEditor from "braft-editor";
import categories from "../../../json/categories.json";
import IdeasMethods from "../../../Helpers/IdeaHelpers";
import "../../../sass/Ideas/EditIdea.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { DeleteOutlined, AppstoreAddOutlined } from "@ant-design/icons";
import TopBar from "../../../components/Ideas/TopBar";

const tagRender = (props) => (
	<div className="langTag">
		{props.value}
		<X color="white" className="close" onClick={props.onClose} />
	</div>
);

const tagRenderFormatted = (props) => (
	<div className="langTag">
		<FormattedMessage id={props.value} />
		<X color="white" className="close" onClick={props.onClose} />
	</div>
);
// const MySwal = withReactContent(Swal)
const targetCustomerList = [
	"potential",
	"loyal",
	"new",
	"discount",
	"former",
	"internal",
	"external",
	"intermediate",
];

const ideaPhaseList = [
	"generation",
	"selection",
	"implementation",
	"sustaining",
	"diffusion",
	"other",
];

const outcomeList = [
	"businessmodel",
	"design",
	"desktopsoftware",
	"engineeredproduct",
	"mobileapp",
	"processmethod",
	"webcontentmultimediaaudiovideo",
	"websiteservice",
	"other",
];

const skillsNeededList = [
	"communicationSkills",
	"leadership",
	"interpersonalSkills",
	"publicSpeaking",
	"conflictManagement",
	"decisionMaking",
	"timeManagement",
	"stressManagement",
	"customerServiceSkills",
	"creativeThinking",
	"networking",
	"flexibility",
	"negotiation",
	"mentoring",
	"activeListener",
	"accounting",
	"engineering",
	"banking",
	"healthcare",
	"projectManagement",
	"marketing",
	"foreignLanguageProficiency",
	"programingSkills",
	"MobileDevelopment",
	"database",
	"businessAnalytics",
	"dataScience",
	"dataMining",
	"networkSecurity",
	"uiUxDesign",
	"programmingLanguages",
];
const EditIdea = (props) => {
	//Forms && States
	const { intl } = props;
	const [borderColor, setBorderColor] = useState("rgb(213, 213, 213)");
	const [editorState, setEditorState] = useState(() =>
		BraftEditor.createEditorState(null)
	);
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const [form3] = Form.useForm();
	const [form1Error, setform1Error] = useState("");
	const [form2Error, setform2Error] = useState("");
	const [form3Error, setform3Error] = useState("");
	const [form1Success, setform1Success] = useState("");
	const [form2Success, setform2Success] = useState("");
	const [form3Success, setform3Success] = useState("");
	const [LoadData, setLoadData] = useState(true);
	const [Loading, setLoading] = useState({
		load1: false,
		load2: false,
		load3: false,
	});
	const [Ideainfo, setIdeaInfo] = useState([]);
	const [IdeaID, setIdeaID] = useState("");
	const context = useContext(IntlContext);
	const [img, setImg] = useState(null);
	const [currentImg, setCurrentImg] = useState("");
	const [addLead, setAddLead] = useState(false);
	const [delLead, setDelLead] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	//Uppy Initialization
	const uppy = new Uppy({
		meta: { type: "avatar" },
		restrictions: { maxNumberOfFiles: 1 },
		autoProceed: true,
	});

	uppy.use(thumbnailGenerator);

	uppy.on("thumbnail:generated", (file, preview) => {
		setImg(file);
	});

	// Layout
	const layout = {
		layout: "vertical",
		size: "large",
	};

	//get all Idea Info
	useEffect(() => {
		document.title = intl.formatMessage({ id: "Editidea" });
		const id = props.match.params.id;
		setIdeaID(id);
		getIdeainfo(id);
		// eslint-disable-next-line
	}, []);

	const getIdeainfo = (id) => {
		props.getIdeaInfo(id).then((res) => {
			if (res.status === "success" && res.idea) {
				setLoadData(false);
				setIdeaInfo(res.idea);
				form1.setFieldsValue({
					title: res.idea.title,
					shortDescription: res.idea.shortDescription,
					longDescription: BraftEditor.createEditorState(
						res.idea.longDescription
					),
				});
				form2.setFieldsValue({ youtubeVideo: res.idea.youtubeVideo });
				form3.setFieldsValue({
					category: res.idea.category[0].value,
					phase: res.idea.phase,
					tags: res.idea.tags ? res.idea.tags : [],
					targetCustomer: res.idea.targetCustomer,
					skillsNeeded: res.idea.skillsNeeded[0] !== false ? res.idea.skillsNeeded : [],
					solutions: res.idea.solutions,
					visibility: res.idea.visibility,
					outcome: res.idea.outcome,
					ideaPhase: res.idea.phase,
				});
			}
		});
	};
	//get "Idea title and description" values
	const form1Values = (values) => {
		setform1Success("");
		setform1Error("");
		setLoading({ load1: true });
		IdeasMethods.ChekcFieldsForm1(Ideainfo, values).then((res) => {
			if (Object.keys(res).length) {
				Object.assign(res, { ideaId: IdeaID });
				props.EditIdeaInfo(res).then((result) => {
					setLoading({ load1: false, load2: false, load3: false });
					if (result.status === "success")
						setform1Success("success_16");
					if (result.status === "failure") setform1Error(result.msg);
				});
			}
			else {
				setLoading({ load1: false, load2: false, load3: false });
				setform1Error("error_1");
			}
		});
	};
	//get "Media" values
	const form2Values = (values) => {
		setform2Success("");
		setform2Error("");
		setLoading({ load2: true });
		IdeasMethods.ChekcFieldsForm2(Ideainfo, values).then((res) => {
			if (Object.keys(res).length) {
				Object.assign(res, { ideaId: IdeaID });
				props.EditIdeaInfo(res).then((result) => {
					setLoading({ load1: false, load2: false, load3: false });
					if (result.status === "success")
						setform2Success("success_16");
					if (result.status === "failure") setform2Error(result.msg);
				});
			}
			else {
				setLoading({ load1: false, load2: false, load3: false });
				setform2Error("error_1");
			}
		});
	};
	//get "Media" values
	const form3Values = (values) => {
		setform3Success("");
		setform3Error("");
		setLoading({ load3: true });
		IdeasMethods.ChekcFieldsForm3(Ideainfo, values).then((res) => {
			if (Object.keys(res).length) {
				Object.assign(res, { ideaId: IdeaID });
				props.EditIdeaInfo(res).then((result) => {
					setLoading({ load1: false, load2: false, load3: false });
					if (result.status === "success")
						setform3Success("success_16");
					if (result.status === "failure") setform3Error(result.msg);
				});
			}
			else {
				setLoading({ load1: false, load2: false, load3: false });
				setform3Error("error_1");
			}
		});
	};
	// Delete Image from Images array
	const deleteImageHandle = () => {
		setIsOpen(false);
		setDelLead(true);
		if (currentImg) {
			props
				.deleteIdeaImage(currentImg, IdeaID)
				.then((res) => {
					notification["success"]({
						message: intl.formatMessage({ id: "success" }),
						description: intl.formatMessage({ id: "deleteimageSuccess" }),
					});
					const newImages = Ideainfo.images.filter(
						(image) => image !== currentImg
					);
					Ideainfo.images = newImages;
					setDelLead(false);
					setCurrentImg("");
				})
				.catch((err) => {
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: err.msg }),
					});
					setDelLead(false);
					setCurrentImg("");
				});
		}
	};

	const deleteConfirm = (imgId) => {
		setCurrentImg(imgId);
		setIsOpen(!isOpen);
	};
	// Add New Image to images Array
	const addImageHandle = () => {
		setAddLead(true);
		if (img) {
			const formData = new FormData();
			formData.append("image", img.data);
			props
				.addIdeaImage(formData, IdeaID)
				.then((res) => {
					setTimeout(() => {
						notification["success"]({
							message: intl.formatMessage({ id: "success" }),
							description: intl.formatMessage({ id: "addimageSuccess" }),
						});
						const newImages = [...Ideainfo.images, res.image];
						Ideainfo.images = newImages;
						setAddLead(false);
						setImg(null);
					}, 1000);
				})
				.catch((err) => {
					notification["error"]({
						message: intl.formatMessage({ id: "error_text" }),
						description: intl.formatMessage({ id: err.status }),
					});
					setAddLead(false);
				});
		}
	};

	// Render New Image Added
	const renderImage = () => {
		return (
			<div
				className="mt-2 position-relative upload-img"
				style={{ backgroundImage: `url(${img.preview})` }}
			>
				<Button
					onClick={addImageHandle}
					type="primary"
					loading={addLead}
					icon={<AppstoreAddOutlined />}
					className="img-btn-upload"
				>
					Add Image
				</Button>
			</div>
		);
	};

	return (
		<div className="add-idea-page ">
			{LoadData ? (
				<Spin tip="Loading..." spinning={Loading.load1}></Spin>
			) : (
				<Container>
					<Heading text={<FormattedMessage id="Editidea" />} />
					<TopBar idea={IdeaID} ideaID={Ideainfo._id} />
					<Spin tip="Loading..." spinning={Loading.load1}>
						<div className="carta">
							<Container>
								<Heading
									text={<FormattedMessage id="IdeaTitleandDesc" />}
									color="#458ff6"
								/>
								<Row style={{ justifyContent: "center" }}>
									<Col md={10}>
										<Form {...layout} form={form1} onFinish={form1Values}>
											<Form.Item
												name="title"
												label={<FormattedMessage id="IdeaTitle" />}
												rules={[
													{
														required: true,
														message: <FormattedMessage id="EmptyError" />,
													},
												]}
											>
												<Input />
											</Form.Item>
											<Form.Item
												name="shortDescription"
												label={<FormattedMessage id="IdeaShortDisc" />}
												rules={[
													{
														required: true,
														message: <FormattedMessage id="EmptyError" />,
													},
												]}
											>
												<Input />
											</Form.Item>
											<Form.Item
												name="longDescription"
												label={<FormattedMessage id="IdeaLongDisc" />}
												rules={[
													{
														required: true,
														message: <FormattedMessage id="EmptyError" />,
													},
													() => ({
														validator(_, value) {
															const html = value ? value.toHTML() : "";
															if (html !== "" && html !== "<p></p>") {
																setBorderColor("rgb(213, 213, 213)");
																return Promise.resolve();
															}
															setBorderColor("red");
															return Promise.reject("");
														},
													}),
												]}
											>
												<BraftEditor
													value={editorState}
													language="en"
													controlBarClassName="editor-control-bar"
													className="editor"
													style={{ borderColor: borderColor }}
													excludeControls={[
														"emoji",
														"media",
														"list-ul",
														"list-ol",
													]}
													onChange={(state) => setEditorState(state)}
												/>
											</Form.Item>
											{form1Error ? (
												<Alert color="danger">
													<h6 className="alert-heading">Error</h6>
													<div className="alert-body"><FormattedMessage id={form1Error} /></div>
												</Alert>
											) : form1Success ? (
												<Alert color="success">
													<h4 className="alert-heading">Success</h4>
													<div className="alert-body">
														{" "}
														<FormattedMessage id={form1Success} />
													</div>
												</Alert>
											) : (
												""
											)}
											<div className="center-buttons">
												<Button type="primary" htmlType="submit">
													<FormattedMessage id="save" />
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
							</Container>
						</div>
					</Spin>
					<Spin tip="Loading..." spinning={Loading.load2}>
						<div className="carta">
							<Container>
								<Heading
									text={<FormattedMessage id="IdeaMedia" />}
									color="#458ff6"
								/>
								<Row style={{ display: "flex", justifyContent: "center" }}>
									<Col md={10}>
										<Form {...layout} form={form2} onFinish={form2Values}>
											<Form.Item
												name="youtubeVideo"
												rules={[
													() => ({
														validator(_, value) {
															if (!value) return Promise.resolve();
															const ytRegex = new RegExp(
																/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=([^&\n]+)|youtu\.be\/([a-zA-Z\d]+))$/
															);
															if (value.length > 3000) {
																return Promise.reject(
																	<FormattedMessage id="fieldTooLong" />
																);
															} else if (!ytRegex.test(value)) {
																return Promise.reject(
																	<FormattedMessage id="unvalidYoutube" />
																);
															} else {
																return Promise.resolve();
															}
														},
													}),
												]}
												label={<FormattedMessage id="youtubeVideo" />}
											>
												<Input
													prefix={
														<Youtube color="red" style={{ marginRight: 5 }} />
													}
													placeholder="https://www.youtube.com/watch?v=XXXXXXXXX"
												/>
											</Form.Item>
											<Form.Item
												label={<FormattedMessage id="IdeaImages" />}
											>
												<DragDrop uppy={uppy} />
												{img !== null ? renderImage() : null}
												<Row className="p-2 imgslist">
													{Ideainfo.images
														? Ideainfo.images.map((image, idx) => (
															<Col lg="3" md="4" sm="6" key={idx}>
																<Card className="card">
																	<CardImg
																		top
																		src={image ? IdeasMethods.getIdeaImage(
																			IdeaID,
																			image
																		) : IdeasMethods.getIdeaAvatar()}
																		alt="Card cap"
																		className="imgCard"
																	/>
																	<CardBody className="text-center py-1 cardbody">
																		<Button
																			key={idx}
																			danger
																			type="link"
																			icon={<DeleteOutlined />}
																			loading={delLead}
																			size="large"
																			onClick={() => deleteConfirm(image)}
																		>
																			Delete
																		</Button>
																	</CardBody>
																</Card>
															</Col>
														))
														: ""}
												</Row>
											</Form.Item>
											{form2Error ? (
												<Alert color="danger">
													<h6 className="alert-heading">Error</h6>
													<div className="alert-body"> <FormattedMessage id={form2Error} /></div>
												</Alert>
											) : form2Success ? (
												<Alert color="success">
													<h4 className="alert-heading">Success</h4>
													<div className="alert-body">
														{" "}
														<FormattedMessage id={form2Success} />
													</div>
												</Alert>
											) : (
												""
											)}
											<div className="center-buttons">
												<Button type="primary" htmlType="submit">
													<FormattedMessage id="save" />
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
							</Container>
						</div>
					</Spin>
					<Spin tip="Loading..." spinning={Loading.load3}>
						<div className="carta">
							<Container>
								<Heading
									text={<FormattedMessage id="ideaClassification" />}
									color="#458ff6"
								/>
								<Row style={{ justifyContent: "center" }}>
									<Col md={10}>
										<Form {...layout} form={form3} onFinish={form3Values}>
											<Form.Item
												name="category"
												label={<FormattedMessage id="IdeaCategory" />}
												rules={[
													{
														required: true,
														message: <FormattedMessage id="EmptyError" />,
													},
												]}
											>
												<Select>
													{categories.map((category, key) => (
														<Select.Option key={key} value={category.value}>
															{context.state.locale === "en"
																? category.label
																: context.state.locale === "fr"
																	? category.label_fr
																	: category.label_ar}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
											<Form.Item
												name="tags"
												label={<FormattedMessage id="IdeaTags" />}
											// rules={[
											//   {
											//     required: true,
											//     message: <FormattedMessage id="EmptyError" />,
											//   },
											// ]}
											>
												<Select mode="tags" tagRender={tagRender}></Select>
											</Form.Item>
											<Form.Item
												name="targetCustomer"
												label={<FormattedMessage id="IdeaTarget" />}
												rules={[
													{
														required: true,
														message: <FormattedMessage id="EmptyError" />,
													},
												]}
											>
												<Select mode="tags" tagRender={tagRenderFormatted}>
													{targetCustomerList.map((item, key) => (
														<Select.Option key={key} value={item}>
															<FormattedMessage id={item} />
														</Select.Option>
													))}
												</Select>
											</Form.Item>
											<Form.Item
												name="ideaPhase"
												label={<FormattedMessage id="Ideaphase" />}
												rules={[
													{
														required: true,
														message: <FormattedMessage id="EmptyError" />,
													},
												]}
											>
												<Select>
													{ideaPhaseList.map((item, key) => (
														<Select.Option key={key} value={item}>
															<FormattedMessage id={item} />
														</Select.Option>
													))}
												</Select>
											</Form.Item>
											<Form.Item
												name="outcome"
												label={<FormattedMessage id="IdeaProgram" />}
												rules={[
													{
														required: true,
														message: <FormattedMessage id="EmptyError" />,
													},
												]}
											>
												<Select>
													{outcomeList.map((item, key) => (
														<Select.Option key={key} value={item}>
															<FormattedMessage id={item} />
														</Select.Option>
													))}
												</Select>
											</Form.Item>
											<Form.Item
												name="skillsNeeded"
												label={<FormattedMessage id="IdeaNeededskills" />}
											// rules={[
											//   {
											//     required: true,
											//     message: <FormattedMessage id="EmptyError" />,
											//   },
											// ]}
											>
												<Select mode="tags" tagRender={tagRenderFormatted}>
													{skillsNeededList.map((item, key) => (
														<Select.Option key={key} value={item}>
															<FormattedMessage id={item} />
														</Select.Option>
													))}
												</Select>
											</Form.Item>
											<Form.Item
												name="solutions"
												label={<FormattedMessage id="Ideasolution" />}
												rules={[
													{
														required: true,
														message: <FormattedMessage id="EmptyError" />,
													},
												]}
											>
												<Select>
													<Select.Option value="product">
														<FormattedMessage id="Product" />
													</Select.Option>
													<Select.Option value="service">
														<FormattedMessage id="Service" />
													</Select.Option>
												</Select>
											</Form.Item>
											<Form.Item
												name="visibility"
												label={<FormattedMessage id="Ideavisibility" />}
												rules={[
													{
														required: true,
														message: <FormattedMessage id="EmptyError" />,
													},
												]}
											>
												<Select>
													<Select.Option value="public">
														<FormattedMessage id="public" />
													</Select.Option>
													<Select.Option value="private">
														<FormattedMessage id="private" />
													</Select.Option>
												</Select>
											</Form.Item>
											{form3Error ? (
												<Alert color="danger">
													<h6 className="alert-heading">Error</h6>
													<div className="alert-body"><FormattedMessage id={form3Error} /></div>
												</Alert>
											) : form3Success ? (
												<Alert color="success">
													<h4 className="alert-heading">Success</h4>
													<div className="alert-body">
														{" "}
														<FormattedMessage id={form3Success} />
													</div>
												</Alert>
											) : (
												""
											)}
											<div className="center-buttons">
												<Button type="primary" htmlType="submit">
													<FormattedMessage id="save" />
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
							</Container>
						</div>
					</Spin>
					<Modal
						cancelText={<FormattedMessage id="cancel" />}
						okText={<FormattedMessage id="ok" />}
						isOpen={isOpen}
						toggle={() => setIsOpen(!isOpen)}
						className="modal-dialog-centered"
						modalClassName="modal-danger"
					>
						<ModalHeader toggle={() => setIsOpen(!isOpen)}>
							Confirmation
						</ModalHeader>
						<ModalBody>Are you sure you want to delete this image?</ModalBody>
						<ModalFooter>
							<Button danger onClick={deleteImageHandle}>
								Confirm
							</Button>
						</ModalFooter>
					</Modal>
				</Container>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({});

export default injectIntl(
	connect(mapStateToProps, {
		getIdeaInfo,
		EditIdeaInfo,
		deleteIdeaImage,
		addIdeaImage,
	})(EditIdea)
);
