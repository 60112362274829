import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import NotFound from "../views/404";
import Login from "../views/auth/Login";
import LoginOK from "../views/auth/LoginOk";
import App from "../views/app";
import ServerDown from "../views/500";
import Terms from "../views/Terms";
import Privacy from "../views/Privacy";
import Support from "../views/Support";

const mapStateToProps = (state) => {
	return {
		isAuth: state.auth.login.isAuth,
	};
};

const PrivateRoute = connect(mapStateToProps)(({ isAuth, children, ...rest }) =>
	isAuth ? (
		<Route {...rest}>{children}</Route>
	) : (
		<Redirect
			to={{
				pathname: "/login",
				state: { from: rest.location },
			}}
		/>
	)
);

const ForLoggedOutRoute = connect(
	mapStateToProps
)(({ isAuth, children, ...rest }) =>
	isAuth ? <Redirect to="/app" /> : <Route {...rest}>{children}</Route>
);

function Router() {
	return (
		<Switch>
			<ForLoggedOutRoute exact path="/login">
				<Login />
			</ForLoggedOutRoute>
			<ForLoggedOutRoute exact path="/loginOK">
				<LoginOK />
			</ForLoggedOutRoute>
			<ForLoggedOutRoute exact path="/">
				<Redirect to="/app" />
			</ForLoggedOutRoute>
			<PrivateRoute path="/app">
				<App />
			</PrivateRoute>
			<Route exact path="/404">
				<NotFound />
			</Route>
			<Route exact path="/privacy">
				<Privacy />
			</Route>
			<Route exact path="/terms">
				<Terms />
			</Route>
			<Route exact path="/support">
				<Support />
			</Route>
			<Route exact path="/500">
				<ServerDown />
			</Route>
			<Redirect to="/404" />
		</Switch>
	);
}

export default connect(mapStateToProps)(Router);
