import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Avatar from "antd/lib/avatar/avatar";
import UserService from "../../services/user";
import { useContext } from "react";
import { IntlContext } from "../../context/internationalization";
import "../../sass/ChatApp/Conversation.scss";
import { FormattedMessage } from "react-intl";
import IdeasMethods from "../../Helpers/IdeaHelpers";
import { connect } from "react-redux";
import _ from "lodash";
import { Image, Paperclip, CheckSquare } from "react-feather";

const Conversation = ({

	className,
	onClick,
	type,
	conv,
	me,
	setUserdmprops,
}) => {
	const context = useContext(IntlContext);
	const { state } = context;
	const lastMessage =
		conv?.last_message?.type === "file" ? (
			<>
				<Paperclip size={10} style={{ margin: "0 3px" }} />
				<FormattedMessage id="file" />
			</>
		) : conv?.last_message?.type === "image" ? (
			<>
				<Image size={10} style={{ margin: "0 3px" }} />
				<FormattedMessage id="image" />
			</>
		) : conv?.last_message?.type === "task" ? (
			<>
				<CheckSquare size={10} style={{ margin: "0 3px" }} />
				<FormattedMessage id="task" />
			</>
		) : (
			conv?.last_message?.body
		);
	const unread = conv.unread_count;
	const [userdm, setUserdm] = useState(null);

	useEffect(() => {
		if (type === "dm" && conv)
			conv?.dm_members?.forEach((user, i) => {
				if (user._id !== me._id) {
					setUserdm(user);
				}
			});
		// eslint-disable-next-line
	}, []);
	return (
		<div className={classnames("convs-conv", className)} onClick={onClick}>
			<div className="flex justify-center items-center">
				{type === "teams" ? (
					<Avatar
						size="large"
						src={
							conv.idea && conv.idea.ideaId && conv.idea.images[0]
								? IdeasMethods.getIdeaImage(
									conv.idea.ideaId,
									conv.idea.images[0]
								)
								: IdeasMethods.getIdeaAvatar()
						}
						style={{
							marginRight: state.direction === "ltr" ? 10 : 0,
							marginLeft: state.direction === "rtl" ? 10 : 0,
						}}
					>
						T
					</Avatar>
				) : type === "sessions" ? (
					<Avatar
						size="large"
						src={
							conv.idea && conv.idea.ideaId && conv.idea.images[0]
								? IdeasMethods.getIdeaImage(
									conv.idea.ideaId,
									conv.idea.images[0]
								)
								: IdeasMethods.getIdeaAvatar()
						}
						style={{
							marginRight: state.direction === "ltr" ? 10 : 0,
							marginLeft: state.direction === "rtl" ? 10 : 0,
						}}
					>
						S
					</Avatar>
				) : (
					<Avatar
						size="large"
						src={UserService.avatarLink({
							avatar: userdm?.avatar ? userdm.avatar : "",
							userId: userdm?.userId ? userdm.userId : "",
						})}
						style={{
							marginRight: state.direction === "ltr" ? 10 : 0,
							marginLeft: state.direction === "rtl" ? 10 : 0,
						}}
					/>
				)}
				<div className="name-last_message">
					<div className="name" style={{ textTransform: "capitalize" }}>
						{type === "teams" ? (
							<>
								<FormattedMessage id="teamOf" />{" "}
								<span>
									{conv.idea && conv.idea.title ? conv.idea.title : ""}
								</span>
							</>
						) : type === "sessions" ? (
							<>
								<span>
									[
									{conv.session_owner && conv.session_owner?.firstname
										? conv.session_owner?.firstname +
										" " +
										conv.session_owner.lastname
										: ""}
									] {conv.idea && conv.idea.title ? conv.idea.title : ""}
								</span>
							</>
						) : userdm ? (
							`${userdm?.firstname} ${userdm?.lastname}`
						) : (
							""
						)}
					</div>
					<div
						className={classnames("last_message", {
							"last_message-unread": unread > 0,
						})}
					>
						{_.isString(lastMessage)
							? lastMessage.length > 20
								? lastMessage.substr(0, 20) + "..."
								: lastMessage
							: lastMessage}
					</div>
				</div>
			</div>
			<div className="unread">
				{unread > 0 ? (
					<div className="unread-circle">{unread > 9 ? "+9" : unread}</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

Conversation.defaultProps = {
	unread: 0,
	className: "",
	type: "dm",
	onClick: () => { },
};

const mapStateToProps = (state) => ({
	me: state.auth.login.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
