import React, { useContext } from "react";
import "../../sass/Ideas/ideaCard.scss";
import { Calendar, ArrowRight, ArrowLeft } from "react-feather";
import "../../sass/Avatar.scss";
import "../../sass/Ideas/ideaCard.scss";
import moment from "moment";
import { IntlContext } from "../../context/internationalization";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import IdeasMethods from "../../Helpers/IdeaHelpers";
import UserMethods from "../../Helpers/UserHelpers";
import { FormattedMessage } from "react-intl";

const IdeaCard = (props) => {
	const context = useContext(IntlContext);
	const history = useHistory();
	const style = props.style ? { ...props.style } : {};
	return (
		<div>
			<div
				className="card"
				style={{ ...style, cursor: "pointer", margin: "auto" }}
				onClick={() => {
					props.incubation === "true"
						? history.push(`/app/incubator/${props.idea._id}`)
						: history.push(`/app/ideas/${props.idea.ideaId}`);
				}}
			>
				<div className="thumb">
					<img
						src={IdeasMethods.getIdeaImage(props.idea.ideaId, props.idea.images[0])}
						alt="bigola"
					/>{" "}
					<span className="state">IN PROGRESS</span>
				</div>

				{props.idea.user ? <Row className="infos" style={{ justifyContent: "center" }}>
					<Col
						className="user"
						md={6}
						sm={6}
						xs={12}
						style={{ marginBottom: 10 }}
					>
						<p
							className="Ellipse_224"
							style={props.idea.user ? {
								backgroundImage: `url('${UserMethods.getUserImage(props.idea.user.userId, props.idea.user.avatar)}')`,
							} : {}}
						></p>
						<div className="user-infos">
							<div className="name">
								{props.idea.user.lastname + " " + props.idea.user.firstname}
							</div>
							<div className="position">Team leader</div>
						</div>
					</Col>
					<Col
						className="socialidea"
						md={6}
						sm={6}
						xs={12}
						style={{ marginBottom: 10 }}
					>
						<p className="socialideaimg"></p>
						<div className="ideas-infos">
							<div className="name">Social Idea</div>
							<div className="position">Solution</div>
						</div>
					</Col>
				</Row>
					: ""}
				<div className="ideaTitle">{props.idea.title}</div>
				<div className="ideadesc">{props.idea.shortDescription}</div>
				<div className="ideaaction">
					<div className="ideadatesection">
						<Calendar size={14} />
						<div className="ideadate">
							{moment(props.idea.created_date).format("MMM Do YYYY")}
						</div>
					</div>
					<div className="more">
						<div
							className="seemore"
							style={{ cursor: "pointer" }}
							onClick={() =>
								props.incubation === "true"
									? history.push(`/app/incubator/${props.idea._id}`)
									: history.push(`/app/ideas/${props.idea.ideaId}`)
							}
						>
							<FormattedMessage id="seemore" />{" "}
							<div style={{ marginLeft: "2px" }}>
								{context.state.direction === "rtl" ? (
									<ArrowLeft size={12} />
								) : (
									<ArrowRight size={12} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IdeaCard;
