import ProcessInfo from "../../../services/ProcessInfo"

export const getALLProcess = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ProcessInfo.getALLProcess()
				.then(res => {
					if (res.status === "success") resolve(res)
				})
				.catch(err => reject(err))
		});
	};
};

export const getProccesById = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			ProcessInfo.getProcessById(id)
				.then(res => {
					if (res.status === "success") resolve(res)
				})
				.catch(err => reject(err))
		});
	};
};

export const getStageById = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => { });
	};
};
