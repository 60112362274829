import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IntlContext } from "../../context/internationalization";
import "../../sass/CompleteProfile.scss";
import countryCodes from "../../json/countryCodes.json";
import countries from "../../json/countries.json";
import cities from "../../json/cities.json";
import langs from "../../json/languages.json";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import Heading from "../../components/Heading";
import Languages from "../../components/Languages";
import Logout from "../../components/Logout";
import { completeProfile } from "../../redux/actions/auth/loginActions";
import { getUser } from "../../redux/actions/auth/loginActions";
import {
	Form,
	Select,
	Steps,
	Input,
	Button,
	DatePicker,
	Upload,
	Modal,
	Switch,
	Rate,
	Divider,
} from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactCountryFlag from "react-country-flag";
import {
	Facebook,
	Globe,
	Instagram,
	Linkedin,
	Lock,
	LogOut,
	Plus,
	Twitter,
	Unlock,
	UploadCloud,
	X,
	Youtube,
} from "react-feather";
const { Step } = Steps;
const { Dragger } = Upload;

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

export const CompleteProfile = ({ user, completeProfile, intl }) => {
	const history = useHistory();

	const context = useContext(IntlContext);
	const [current, setCurrent] = useState(0);
	const [percent, setPercent] = useState(0);
	const [fileList, setFileList] = useState([]);
	const [skills, setSkills] = useState([]);
	const [experiences, setExperiences] = useState([]);
	const [expForm] = Form.useForm();
	const [eduForm] = Form.useForm();
	const [stateCities, setStateCities] = useState([]);
	const [educations, setEducations] = useState([]);
	const [untilNow1, setUntilNow1] = useState(false);
	const [untilNow2, setUntilNow2] = useState(false);
	const [isVisible1, setIsVisible1] = useState(false);
	const [isVisible2, setIsVisible2] = useState(false);
	const [sending, setSending] = useState(false);
	const [pdpPreview, setPdpPreview] = useState({
		previewVisible: false,
		previewImage: "user_avatar.jpg",
		previewTitle: "",
	});
	const [form, setForm] = useState({
		account_type: "",
		phoneNumber: {
			number: "",
			privacy: "",
		},
		gender: "",
		address: {
			country: {},
			city: {},
			address: "",
		},
		languages: [],
		questions: [],
		birthdate: {
			date: "",
			privacy: "",
		},
		firstname: "srsfsdvsd",
		lastname: "",
		avatar: user.avatar,
		biography: "",
		socialMedia: {
			website: "",
			linkedin: "",
			twitter: "",
			facebook: "",
			instagram: "",
			youtube: "",
		},
		skills: [],
		experiences: [
			{
				postTitle: "",
				organization: "",
				orgImage: "",
				startDate: "",
				endDate: "",
			},
		],
		education: [
			{
				fieldStudy: "",
				school: "",
				schoolImage: "",
				startDate: "",
				endDate: "",
			},
		],
	});
	useEffect(() => {
		document.title = intl.formatMessage({ id: "completeProfile" })
		// eslint-disable-next-line 
	}, [])
	const sendData = (questions) => {
		let data = form
		if (questions) data.questions = questions
		setSending(true);
		completeProfile(form)
			.then((res) => {
				if (res.status === "success") {
					history.push("/app")
					setSending(false);
				} else {
					setSending(false);
					history.push("/app");
				}
			})
			.catch((err) => {
				getUser()
				setSending(false);
			});
	};

	const questions = {
		idea_holder: [
			<FormattedMessage id="ihQte_1" />,
			<FormattedMessage id="ihQte_2" />,
			<FormattedMessage id="ihQte_3" />,
			<FormattedMessage id="ihQte_4" />,
		],
		expert: [
			<FormattedMessage id="meciQte_1" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_4" />,
			<FormattedMessage id="meciQte_5" />,
			<FormattedMessage id="meciQte_6" />,
		],
		mentor: [
			<FormattedMessage id="meciQte_1" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_4" />,
			<FormattedMessage id="meciQte_5" />,
			<FormattedMessage id="meciQte_6" />,
		],
		coach: [
			<FormattedMessage id="meciQte_1" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_4" />,
			<FormattedMessage id="meciQte_5" />,
			<FormattedMessage id="meciQte_6" />,
		],
		instructor: [
			<FormattedMessage id="meciQte_1" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_2" />,
			<FormattedMessage id="meciQte_4" />,
			<FormattedMessage id="meciQte_5" />,
			<FormattedMessage id="meciQte_6" />,
		],
	};



	const nextStep = () => {
		setCurrent(current + 1);
		setPercent(0);
	};

	const draggerProps = {
		multiple: false,
		onRemove: (file) => {
			setFileList([file]);
		},
		beforeUpload: async (file) => {
			const preview = await getBase64(file);
			setPdpPreview({
				previewImage: preview,
				previewVisible: false,
				previewTitle:
					file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
			});
			setFileList([file]);
			return true;
		},
		showUploadList: false,
		listType: "picture-card",
		fileList,
	};

	const formes = [
		<Row style={{ justifyContent: "center" }}>
			<Col md="6">
				<Form
					size="large"
					onFinish={({ account_type }) => {
						setForm((form) => ({ ...form, account_type: account_type }));
						nextStep();
					}}
					initialValues={{
						account_type: user.account_type
					}}
				>
					<Form.Item
						name="account_type"
						rules={[
							{ required: true, message: <FormattedMessage id="EmptyError" /> },
						]}
						label={<FormattedMessage id="selectAccountType" />}
						required
					>
						<Select placeholder={<FormattedMessage id="accountType" />} disabled>
							<Select.Option value="idea_holder">
								<FormattedMessage id="ideaHolder" />
							</Select.Option>
							<Select.Option value="instructor">
								<FormattedMessage id="instructor" />
							</Select.Option>
						</Select>
					</Form.Item>
					<div style={{ width: "100%", textAlign: "center" }}>
						<Button type="primary" htmlType="submit">
							<FormattedMessage id="save" />
						</Button>
					</div>
				</Form>
			</Col>
		</Row>,
		<Form
			onFinish={({
				birthdate,
				countrycode,
				firstName,
				lastName,
				gender,
				languages,
				phone,
				phonePrivate,
				birthdatePrivate
			}) => {
				setForm((form) => ({
					...form,
					phoneNumber: {
						number: countrycode + phone,
						privacy: phonePrivate ? "private" : "public",
					},
					gender,
					languages,
					birthdate: {
						date: new Date(birthdate),
						privacy: birthdatePrivate ? "private" : "public",
					},
					firstname: firstName,
					lastname: lastName,
					avatar:
						pdpPreview.previewImage === "user_avatar.jpg"
							? pdpPreview.previewImage
							: pdpPreview.previewImage.split(",")[1],
				}));
				setCurrent((current) => current + 1);
			}}
			size="large"
			initialValues={{ countrycode: "+212", firstName: user.firstname, lastName: user.lastname }}
			layout="vertical"
		>
			<Row>
				<Col md="6">
					<Form.Item
						name="firstName"
						label={<FormattedMessage id="firstName" />}
						required
						rules={[
							{
								required: true,
								message: <FormattedMessage id="EmptyError" />,
							},
							() => ({
								validator(_, value) {
									let nameRegex = new RegExp(
										/\b[a-zA-Z\u0621-\u064AÀ-ÿ][-,a-zA-Z\u0621-\u064AÀ-ÿ. ']{2,30}$/
									);
									if (!value || nameRegex.test(value)) {
										return Promise.resolve();
									}
									return Promise.reject("This name is invalid");
								},
							}),
						]}
					>
						<Input placeholder={intl?.formatMessage({ id: "firstName" })} />
					</Form.Item>

					<Form.Item
						name="lastName"
						label={<FormattedMessage id="lastName" />}
						required
						rules={[
							{
								required: true,
								message: <FormattedMessage id="EmptyError" />,
							},
							() => ({
								validator(_, value) {
									let nameRegex = new RegExp(
										/\b[a-zA-Z\u0621-\u064AÀ-ÿ][-,a-zA-Z\u0621-\u064AÀ-ÿ. ']{2,30}$/
									);
									if (!value || nameRegex.test(value)) {
										return Promise.resolve();
									}
									return Promise.reject("This name is invalid");
								},
							}),
						]}
					>
						<Input placeholder={intl?.formatMessage({ id: "lastName" })} />
					</Form.Item>

					<Form.Item
						name="birthdate"
						required
						label={<FormattedMessage id="birthdate" />}
						rules={[
							{
								required: true,
								message: <FormattedMessage id="EmptyError" />,
							},
						]}
					>
						<DatePicker
							allowClear
							format="DD-MM-YYYY"
							placeholder="DD-MM-YYYY"
							disabledDate={(date) => {
								const now = new Date();
								const minDate = new Date(
									now.getFullYear() - 130,
									now.getMonth(),
									now.getDay()
								);
								const maxDate = new Date(
									now.getFullYear() - 13,
									now.getMonth(),
									now.getDay()
								);
								return date > maxDate || date < minDate;
							}}
							showToday={false}
							renderExtraFooter={() =>
								<Form.Item name="birthdatePrivate" valuePropName="checked" noStyle>
									<Switch
										checkedChildren={<Lock size={13} />}
										unCheckedChildren={<Unlock size={13} />}
										size="default"
									/>
								</Form.Item>
							}
						// style={{ width: "100%" }}

						/>

					</Form.Item>

					<Form.Item
						label={<FormattedMessage id="phoneNumber" />}
						required
						name="phone"
						rules={[
							{
								required: true,
								message: <FormattedMessage id="EmptyError" />,
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									const phonenumber = getFieldValue("countrycode") + value;
									// eslint-disable-next-line
									const phoneRegex = new RegExp(/^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}/
									);
									if (!value || phoneRegex.test(phonenumber))
										return Promise.resolve();
									else return Promise.reject("phone invalid");
								},
							}),
						]}
					>
						<Input
							placeholder="XXX-XXXXXX"
							addonBefore={
								<Form.Item name="countrycode" noStyle>
									<Select showSearch style={{ width: 100 }}>
										{countryCodes.map(({ dial_code, code }) => (
											<Select.Option value={dial_code} key={code}>
												<div className="dial-select">
													<div className="flag">
														<ReactCountryFlag countryCode={code} svg />
													</div>
													<div className="code">{dial_code}</div>
												</div>
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							}
							addonAfter={
								<Form.Item name="phonePrivate" valuePropName="checked" noStyle>
									<Switch
										checkedChildren={<Lock size={13} />}
										unCheckedChildren={<Unlock size={13} />}
										size="default"
									/>
								</Form.Item>
							}
							style={{ width: "100%" }}
						/>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						label={<FormattedMessage id="gender" />}
						name="gender"
						required
						rules={[
							{
								required: true,
								message: <FormattedMessage id="EmptyError" />,
							},
						]}
					>
						<Select placeholder={<FormattedMessage id="selGender" />}>
							<Select.Option value="male"><FormattedMessage id="male" /></Select.Option>
							<Select.Option value="female"><FormattedMessage id="female" /></Select.Option>
							<Select.Option value="other"><FormattedMessage id="other" /></Select.Option>
						</Select>
					</Form.Item>
					<Form.Item label={<FormattedMessage id="ProfilePic" />}>
						<div>
							{fileList[0] ? (
								<div className="preview-container">
									<div
										className="preview-image"
										style={{
											backgroundImage: "url('" + pdpPreview.previewImage + "')",
											backgroundPosition: "center",
											backgroundSize: "cover",
											backgroundRepeat: "no-repeat",
										}}
										onClick={(e) => {
											e.stopPropagation();
											setPdpPreview((x) => ({ ...x, previewVisible: true }));
										}}
									>
										<X
											color="red"
											className="preview-remove"
											onClick={(e) => {
												e.stopPropagation();
												setFileList([]);
												setPdpPreview((x) => ({
													...x,
													previewImage: "user_avatar.jpg",
												}));
											}}
										/>
									</div>
								</div>
							) : (
								<Dragger {...draggerProps}>
									<p>
										<UploadCloud size={30} />
									</p>
									<p>Click or drag image to this area to upload</p>
								</Dragger>
							)}
						</div>
						<Modal
							cancelText={<FormattedMessage id="cancel" />}
							okText={<FormattedMessage id="ok" />}
							visible={pdpPreview.previewVisible}
							title={pdpPreview.previewTitle}
							footer={null}
							onCancel={() => {
								setPdpPreview((x) => ({
									...x,
									previewVisible: false,
								}));
							}}
						>
							<img
								alt="example"
								style={{ width: "100%" }}
								src={pdpPreview.previewImage}
							/>
						</Modal>
					</Form.Item>
					<Form.Item
						label={<FormattedMessage id="Languages" />}
						name="languages"
						rules={[
							{
								required: true,
								message: <FormattedMessage id="EmptyError" />,
							},
						]}
					>
						<Select
							mode="multiple"
							tagRender={(props) => {
								return (
									<div className="langTag">
										{props.value}
										<X
											color="white"
											className="close"
											onClick={props.onClose}
										/>
									</div>
								);
							}}

							showSearch
							placeholder={<FormattedMessage id="selLangs" />}
							name="languages"
						>
							{langs.map((i, k) => (
								<Select.Option key={k} value={i.nativeName}>
									{i.nativeName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<div className="navigation-buttons">
				<Button onClick={() => setCurrent(current - 1)}>
					<FormattedMessage id="prev" />
				</Button>
				<Button htmlType="submit" type="primary">
					<FormattedMessage id="next" />
				</Button>
			</div>
		</Form>,
		<Form
			size="large"
			onFinish={({ bio }) => {
				setForm((form) => ({
					...form,
					skills: skills.map((e) => e.name),
					biography: bio,
					experiences,
					education: educations,
				}));
				nextStep();
			}}
			layout="vertical"
		>
			<Row>
				<Col md="6">
					<Form.Item label={<FormattedMessage id="skills" />}>
						<Select
							disabled={skills.length > 30}
							showSearch
							mode="tags"
							value={skills.map((i) => i.name)}
							tagRender={() => { }}
							onSelect={(value) => {
								setSkills((skills) => [
									...skills,
									{
										name: value,
										rating: 0,
									},
								]);
							}}
							onDeselect={(value) => {
								setSkills((skills) =>
									skills.filter((skill) => skill.name !== value)
								);
							}}
							placeholder={<FormattedMessage id="addSkills" />}
						>
							{[
								"Node JS",
								"React JS",
								"Vue JS",
								"C language",
								"Java Language",
							].map((i, k) => (
								<Select.Option key={k} value={i}>
									{i}
								</Select.Option>
							))}
						</Select>
						<div className="skills-container">
							{skills.map((i, k) => (
								<div className="skill" key={k}>
									<div className="name">{i.name}</div>
									<div className="rating">
										<Rate
											allowHalf
											onChange={(value) =>
												setSkills((skills) =>
													skills.map((skill) => {
														if (skill.name === i.name) skill.rating = value;
														return skill;
													})
												)
											}
										/>
									</div>
									<X
										color="red"
										className="close"
										onClick={() => {
											setSkills((skills) =>
												skills.filter((skill) => skill.name !== i.name)
											);
										}}
									/>
								</div>
							))}
						</div>
					</Form.Item>
					<Form.Item
						label={<FormattedMessage id="biography" />}
						name="bio"
						rules={[
							{
								required: true,
								message: <FormattedMessage id="EmptyError" />,
							},
							() => ({
								validator(_, value) {
									if (value && value.length <= 2000) {
										return Promise.resolve();
									}
									return Promise.reject(
										"Your biography should be 2000 characters long at max"
									);
								},
							}),
						]}
					>
						<Input.TextArea rows={6} />
					</Form.Item>
				</Col>
				<Col>
					<Form.Item label={<FormattedMessage id="Experiences" />}>
						<div className="experiences">
							{experiences.length > 0 ? (
								<Container>
									<Row className="experiences-header">
										<Col>{<FormattedMessage id="PostTitle" />}</Col>
										<Col>{<FormattedMessage id="organization" />}</Col>
										<Col md={1}></Col>
									</Row>
									<Divider style={{ margin: "5px 0" }} />
									{experiences.map((e, k) => (
										<Row className="experience" key={k}>
											<Col className="postTitle">{e.postTitle}</Col>
											<Col className="organisation">{e.organization}</Col>
											<Col md={1}>
												<X
													color="red"
													className="close"
													onClick={() => {
														setExperiences((exps) =>
															exps.filter((item, key) => key !== k)
														);
													}}
												/>
											</Col>
										</Row>
									))}
								</Container>
							) : (
								""
							)}
						</div>
						<Button
							type="primary"
							block
							onClick={() => setIsVisible1(true)}
							disabled={experiences.length > 20}
						>
							<Plus />
							<FormattedMessage id="addexperiences" />
						</Button>
						<Modal
							cancelText={<FormattedMessage id="cancel" />}
							okText={<FormattedMessage id="ok" />}
							title={<FormattedMessage id="addexperiences" />}
							visible={isVisible1}
							onCancel={() => setIsVisible1(false)}
							okButtonProps={{ style: { display: "none" } }}
							cancelButtonProps={{ style: { display: "none" } }}
						>
							<Form
								form={expForm}
								layout="vertical"
								size="large"
								onFinish={({ postTitle, organisation, duration }) => {
									setExperiences((exps) => [
										...exps,
										{
											postTitle,
											organization: organisation,
											orgImage: "org_avatar.jpg",
											startDate: new Date(duration[0]),
											endDate: untilNow1 ? "until_now" : new Date(duration[1]),
										},
									]);
									setIsVisible1(false);
									expForm.resetFields();
								}}
								preserve={false}
							>
								<Form.Item
									label={intl?.formatMessage({ id: "PostTitle" })}
									name="postTitle"
									rules={[
										{
											required: true,
											message: <FormattedMessage id="EmptyError" />,
										},
										() => ({
											validator(_, value) {
												if (!value || value.length <= 100) {
													return Promise.resolve();
												}
												return Promise.reject(
													"This field should be 100 characters long at max"
												);
											},
										}),
									]}
								>
									<Input placeholder={intl?.formatMessage({ id: "PostTitle" })} />
								</Form.Item>
								<Form.Item
									label={intl?.formatMessage({ id: "organization" })}
									name="organisation"
									rules={[
										{
											required: true,
											message: <FormattedMessage id="EmptyError" />,
										},
										() => ({
											validator(_, value) {
												if (!value || value.length <= 100) {
													return Promise.resolve();
												}
												return Promise.reject(
													"This field should be 100 characters long at max"
												);
											},
										}),
									]}
								>
									<Input placeholder={intl?.formatMessage({ id: "organization" })} />
								</Form.Item>
								<Form.Item
									label={<FormattedMessage id="Duration" />}
									name="duration"
									rules={[
										{
											required: true,
											message: <FormattedMessage id="EmptyError" />,
										},
									]}
								>
									<DatePicker.RangePicker
										placeholder={[intl?.formatMessage({ id: "startDate" }), intl?.formatMessage({ id: "endDate" })]}
										format="DD-MM-YYYY"
										disabled={[false, untilNow1]}
										disabledDate={(date) => date > new Date()}
									/>
								</Form.Item>
								<Form.Item>
									<span><FormattedMessage id="until" /> </span>
									<Switch
										size="default"
										name="untilnow"
										onChange={(checked) => setUntilNow1(checked)}
									/>
								</Form.Item>
								<Button htmlType="submit" type="primary" block>
									<FormattedMessage id="addButton" />
								</Button>
							</Form>
						</Modal>
					</Form.Item>
					<Form.Item label={<FormattedMessage id="Educations" />}>
						<div className="experiences">
							{educations.length > 0 ? (
								<Container>
									<Row className="experiences-header">
										<Col><FormattedMessage id="fieldStudy" /></Col>
										<Col><FormattedMessage id="school" /></Col>
										<Col md={1}></Col>
									</Row>
									<Divider style={{ margin: "5px 0" }} />
									{educations.map((e, k) => (
										<Row className="experience" key={k}>
											<Col className="postTitle">{e.fieldStudy}</Col>
											<Col className="organisation">{e.school}</Col>
											<Col md={1}>
												<X
													color="red"
													className="close"
													onClick={() => {
														setEducations((edus) =>
															edus.filter((item, key) => key !== k)
														);
													}}
												/>
											</Col>
										</Row>
									))}
								</Container>
							) : (
								""
							)}
						</div>
						<Button
							type="primary"
							block
							onClick={() => setIsVisible2(true)}
							disabled={educations.length > 20}
						>
							<Plus />
							<FormattedMessage id="addEducations" />
						</Button>
						<Modal
							cancelText={<FormattedMessage id="cancel" />}
							okText={<FormattedMessage id="ok" />}
							title={<FormattedMessage id="addEducations" />}
							visible={isVisible2}
							onCancel={() => setIsVisible2(false)}
							okButtonProps={{ style: { display: "none" } }}
							cancelButtonProps={{ style: { display: "none" } }}
						>
							<Form
								form={eduForm}
								layout="vertical"
								size="large"
								onFinish={({ fieldStudy, school, duration }) => {
									setEducations((edus) => [
										...edus,
										{
											fieldStudy,
											school,
											schoolImage: "school_avatar.jpg",
											startDate: new Date(duration[0]),
											endDate: untilNow2 ? "until_now" : new Date(duration[1]),
										},
									]);
									setIsVisible2(false);
									eduForm.resetFields();
								}}
								preserve={false}
							>
								<Form.Item
									label={intl?.formatMessage({ id: "fieldStudy" })}
									name="fieldStudy"
									rules={[
										{
											required: true,
											message: <FormattedMessage id="EmptyError" />,
										},
										() => ({
											validator(_, value) {
												if (!value || value.length <= 100) {
													return Promise.resolve();
												}
												return Promise.reject(
													"This field should be 100 characters long at max"
												);
											},
										}),
									]}
								>
									<Input placeholder={intl?.formatMessage({ id: "fieldStudy" })} />
								</Form.Item>
								<Form.Item
									label={intl?.formatMessage({ id: "school" })}
									name="school"
									rules={[
										{
											required: true,
											message: <FormattedMessage id="EmptyError" />,
										},
										() => ({
											validator(_, value) {
												if (!value || value.length <= 100) {
													return Promise.resolve();
												}
												return Promise.reject(
													"This field should be 100 characters long at max"
												);
											},
										}),
									]}
								>
									<Input placeholder={intl?.formatMessage({ id: "school" })} />
								</Form.Item>
								<Form.Item
									label={<FormattedMessage id="Duration" />}
									name="duration"
									rules={[
										{
											required: true,
											message: <FormattedMessage id="EmptyError" />,
										},
									]}
								>
									<DatePicker.RangePicker
										placeholder={[intl?.formatMessage({ id: "startDate" }), intl?.formatMessage({ id: "endDate" })]}
										format="DD-MM-YYYY"
										disabled={[false, untilNow2]}
										disabledDate={(date) => date > new Date()}
									/>
								</Form.Item>
								<Form.Item>
									<span><FormattedMessage id="until" /> </span>
									<Switch
										size="default"
										name="untilnow"
										onChange={(checked) => setUntilNow2(checked)}
									/>
								</Form.Item>
								<Button htmlType="submit" type="primary" block>
									<FormattedMessage id="addButton" />
								</Button>
							</Form>
						</Modal>
					</Form.Item>
				</Col>
			</Row>
			<div className="navigation-buttons">
				<Button onClick={() => setCurrent(current - 1)}>
					<FormattedMessage id="prev" />
				</Button>
				<Button htmlType="submit" type="primary">
					<FormattedMessage id="next" />
				</Button>
			</div>
		</Form>,
		<Form
			onFinish={({
				country,
				city,
				address,
				website,
				linkedin,
				twitter,
				facebook,
				instagram,
				youtube,
			}) => {
				const countryObj = countries.find((obj) => {
					if (!country) return false;
					const locale = context.state.locale;
					if (locale === "en") return obj.label === country;
					else if (locale === "fr") return obj.label_fr === country;
					return obj.label_ar === country;
				});
				const countryCities = cities.find((obj) => {
					if (!country || !city) return false;
					return obj.country === countryObj.label;
				});
				const cityObj = countryCities
					? countryCities.cities.find((obj) => {
						const locale = context.state.locale;
						if (locale === "en") return obj.label === city;
						else if (locale === "fr") return obj.label_fr === city;
						return obj.label_ar === city;
					})
					: undefined;
				setForm((form) => ({
					...form,
					address: {
						country: countryObj ? countryObj : {},
						city: cityObj ? cityObj : {},
						address: address ? address : "",
					},
					socialMedia: {
						...form.socialMedia,
						linkedin: linkedin ? linkedin : "",
						twitter: twitter ? twitter : "",
						website: website ? website : "",
						youtube: youtube ? youtube : "",
						facebook: facebook ? facebook : "",
						instagram: instagram ? instagram : "",
					},
				}));
				nextStep();
			}}
			layout="vertical"
			size="large"
		>
			<Row>
				<Col md={6}>
					<Form.Item label={<FormattedMessage id="country" />} name="country">
						<Select
							placeholder={<FormattedMessage id="country" />}
							onChange={(value) => {
								const cntr = countries.find((obj) => (obj?.label_fr === value || obj?.label_ar === value))
								const arr = cities.find((obj) => obj.country === cntr?.label);
								setStateCities(arr ? arr.cities : []);
							}}
							showSearch
							allowClear
						>
							{countries.map((i) => (
								<Select.Option
									value={context.state.locale === "ar"
										? i.label_ar
										: context.state.locale === "fr"
											? i.label_fr
											: i.label}
									key={i.value}
								>
									{context.state.locale === "ar"
										? i.label_ar
										: context.state.locale === "fr"
											? i.label_fr
											: i.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label={<FormattedMessage id="city" />} name="city">
						<Select placeholder={<FormattedMessage id="city" />} showSearch>
							{stateCities.map((i) => (
								<Select.Option
									key={i.value}
									value={
										context.state.locale === "ar"
											? i.label_ar
											: context.state.locale === "fr"
												? i.label_fr
												: i.label
									}
								>
									{context.state.locale === "ar"
										? i.label_ar
										: context.state.locale === "fr"
											? i.label_fr
											: i.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label={<FormattedMessage id="address" />} name="address">
						<Input placeholder={intl?.formatMessage({ id: "address" })} />
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="website"
						label={<FormattedMessage id="webSite" />}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) return Promise.resolve();
									const urlRegex = new RegExp(
										/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
									);
									if (value.length > 3000) {
										return Promise.reject("This link is too long");
									} else if (!urlRegex.test(value)) {
										return Promise.reject("Unvalid URL");
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<Input
							prefix={<Globe color="#458ff6" />}
							placeholder={intl?.formatMessage({ id: "webSite" })}
						/>
					</Form.Item>
					<Form.Item
						name="linkedin"
						label={<FormattedMessage id="linkedin" />}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) return Promise.resolve();
									const ldRegex = new RegExp(
										/(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
									);
									if (value.length > 3000) {
										return Promise.reject("This link is too long");
									} else if (!ldRegex.test(value)) {
										return Promise.reject("Unvalid linkedin link");
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<Input
							prefix={<Linkedin color="#458ff6" />}
							placeholder={intl?.formatMessage({ id: "linkedin" })}
						/>
					</Form.Item>
					<Form.Item
						name="twitter"
						label={<FormattedMessage id="Twitter" />}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) return Promise.resolve();
									const twRegex = new RegExp(
										/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
									);
									if (value.length > 3000) {
										return Promise.reject("This link is too long");
									} else if (!twRegex.test(value)) {
										return Promise.reject("Unvalid twitter link");
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<Input
							prefix={<Twitter color="#458ff6" />}
							placeholder={intl?.formatMessage({ id: "Twitter" })}
						/>
					</Form.Item>
					<Form.Item
						name="facebook"
						label={<FormattedMessage id="facebook" />}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) return Promise.resolve();
									const fbRegex = new RegExp(
										/(?:https?:)?\/\/(?:www\.)?(?:facebook|fb)\.com\/((?![A-z]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)[A-z0-9_\-.]+)\/?/
									);
									if (value.length > 3000) {
										return Promise.reject("This link is too long");
									} else if (!fbRegex.test(value)) {
										return Promise.reject("Unvalid facebook link");
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<Input
							prefix={<Facebook color="#458ff6" />}
							placeholder={intl?.formatMessage({ id: "facebook" })}
						/>
					</Form.Item>
					<Form.Item
						name="instagram"
						label={<FormattedMessage id="instagram" />}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) return Promise.resolve();
									const igRegex = new RegExp(
										/http(?:s)?:\/\/(?:www\.)?instagram\.com\/([a-zA-Z0-9_]+)/
									);
									if (value.length > 3000) {
										return Promise.reject("This link is too long");
									} else if (!igRegex.test(value)) {
										return Promise.reject("Unvalid instagram link");
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<Input
							prefix={<Instagram color="#458ff6" />}
							placeholder={intl?.formatMessage({ id: "instagram" })}
						/>
					</Form.Item>
					<Form.Item
						name="youtube"
						label={<FormattedMessage id="Youtube" />}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) return Promise.resolve();
									const ytRegex = new RegExp(
										/(?:https?:)?\/\/(?:[A-z]+\.)?youtube.com\/(channel|c|user)\/([A-z0-9-_]+)\/?/
									);
									if (value.length > 3000) {
										return Promise.reject("This link is too long");
									} else if (!ytRegex.test(value)) {
										return Promise.reject("Unvalid youtube link");
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<Input
							prefix={<Youtube color="#458ff6" />}
							placeholder={intl?.formatMessage({ id: "Youtube" })}
						/>
					</Form.Item>
				</Col>
			</Row>
			<div className="navigation-buttons">
				<Button onClick={() => setCurrent(current - 1)}>
					{" "}
					<FormattedMessage id="prev" />
				</Button>
				<Button htmlType="submit" type="primary">
					<FormattedMessage id="next" />
				</Button>
			</div>
		</Form>,
		<Form
			size="large"
			layout="vertical"
			onFinish={(values) => {
				if (values) {
					// setForm({ ...form, questions: values })
					sendData(values)
				}
				else {
					sendData()
				}
			}}
		>
			{
				questions[form.account_type]
					? questions[form.account_type].map((i, k) => (
						<Form.Item label={i} key={k} name={"qte" + k}>
							<Input.TextArea rows={6} />
						</Form.Item>
					))
					: ""}
			< div className="navigation-buttons" >
				<Button onClick={() => setCurrent(current - 1)}>
					<FormattedMessage id="prev" />
				</Button>
				<Button
					htmlType="submit"
					type="primary"
					loading={sending}
				>
					Submit
        </Button>
			</div >
		</Form >,
	];
	return (
		<div className="complete-profile">
			<Container>
				<div className="complete-profile-header">
					<div className="complete-profile-header-item">
						<Languages />
					</div>
					<div className="complete-profile-header-item">
						<Logout>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<LogOut size={15} style={{ margin: "0 5px" }} />
								<span>
									<FormattedMessage id="logout" />
								</span>
							</div>
						</Logout>
					</div>
				</div>
				<Heading text={<FormattedMessage id="completeProfile" />} />
				<div className="complete-profile__card">
					<Container>
						<Steps current={current} percent={percent}>
							<Step title={<FormattedMessage id="accountType" />} />
							<Step
								title={
									<>
										<FormattedMessage id="basicInformations" /> - 1{" "}
									</>
								}
							/>
							<Step
								title={
									<>
										<FormattedMessage id="basicInformations" /> - 2{" "}
									</>
								}
							/>
							<Step
								title={
									<>
										<FormattedMessage id="basicInformations" /> - 3{" "}
									</>
								}
							/>
							<Step title={<FormattedMessage id="questions" />} />
						</Steps>
					</Container>
				</div>
				<div className="complete-profile__card form-card">
					<Container>
						<div className="form-container ">
							<Container>{formes[current]}</Container>
						</div>
					</Container>
				</div>
			</Container>
		</div>
	);
};
const mapStateToProps = (state) => ({
	isAuth: state.auth.login.isAuth,
	user: state.auth.login.user,
});

const mapDispatchToProps = {
	completeProfile,
	getUser
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CompleteProfile));
