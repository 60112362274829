import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import User from "../../../services/user";
import toggler from "../../../assets/icons/nav-toggler.png";
import logo from "../../../assets/logo.png";
import togglerFocused from "../../../assets/icons/nav-toggler-focus.png";
import { ReactComponent as Main } from "../../../assets/icons/MainApp/Layout/main.svg";
import { ReactComponent as Incubator } from "../../../assets/icons/MainApp/Layout/incubator.svg";
import { ReactComponent as Ideas } from "../../../assets/icons/MainApp/Layout/ideas.svg";
import { ReactComponent as ExploreProfile } from "../../../assets/icons/MainApp/Layout/exploreProfile.svg";
import { ReactComponent as Sessions } from "../../../assets/icons/MainApp/Layout/sessions.svg";
import { ReactComponent as EcosystemDataset } from "../../../assets/icons/MainApp/Layout/ecosystemDataset.svg";
import MentorsLayout from "./MentorsLayout";
import "../../../sass/MainAppLayout.scss";
import { connect } from "react-redux";
import {
	Grid,
	Mail,
	Bell,
	ChevronDown,
	LogOut,
	User as UserIcon,
	Settings,
	Heart,
	Facebook,
	Youtube,
	Linkedin,
	Instagram,
	Twitter
} from "react-feather";
import {
	Layout,
	Menu,
	Popover,
	Divider,
	Tooltip,
	Badge,
	notification,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { Col, Container } from "reactstrap";
import Logout from "../../Logout";
import { IntlContext } from "../../../context/internationalization";
import { FormattedMessage, useIntl } from "react-intl";
import { Content, Footer } from "antd/lib/layout/layout";
import { socket } from "../../../hooks/Socket";
import NotifPopover from "../../Notifications/NotifPopover";
import HelpMenu from "../../HelpMenu/HelpMenu"
import { getNotifications } from "../../../redux/actions/Notifications/Notifications";
import { getUnreadConvsCount } from "../../../redux/actions/Communications/CommActions";
import Languages from "../../Languages";
const { Header, Sider } = Layout;
const { SubMenu } = Menu;


const MainAppLayout = ({
	user,
	children,
	getNotifications,
	getUnreadConvsCount,
}) => {
	const [selectedKey, setSelectedKey] = useState(["main"]);
	const [collapsed, setCollapsed] = useState(false);
	const [logos, setlogos] = useState(logo);
	const [isParentVisible, setIsParentVisible] = useState(false);
	const [isHelpMenu, setIsHelpMenu] = useState(false);
	const [NotifCount, setNotifCount] = useState(0);
	const [msgCount, setmsgCount] = useState(0);
	const context = useContext(IntlContext);
	const location = useLocation();
	const history = useHistory();
	const intl = useIntl();
	const bp = useBreakpoint();
	const { pathname } = location;

	useEffect(() => {
		const arr = [
			"main",
			"ideas",
			"incubator",
			"explore",
			"sessions",
			"help",
			"opportunities",
			"training",
			"programs",
			"startups",
			"events",
			"workspaces",
			"ecosystem",
			"resources",
			"blogs",
			"other",
			"invites",
			"notifications",
			"process"
		];
		const x = pathname.split("/");

		let key = "404";
		if (x[1] === "app") {
			key = x[2] ? x[2] : "";
		}

		if (arr.indexOf(key) !== -1) setSelectedKey([key]);
		else if (key === "") setSelectedKey(["main"]);
		else setSelectedKey([]);
	}, [pathname]);
	useEffect(() => {
		getNotificationcount();
		getmsgCount();
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (
			location.pathname === "/app/chat/teams" ||
			location.pathname === "/app/chat/dm" ||
			location.pathname === "/app/chat/sessions"
		)
			setmsgCount(0);
	}, [location]);
	const getNotificationcount = () => {
		getNotifications().then((res) => {
			if (res.status === "success") {
				let count = 0;
				res.result.Notifications.forEach((not, i) => {
					// eslint-disable-next-line
					if (not.read_by.some((one) => one != user._id) || !not.read_by.length) {
						count++;
					} else {
						count = 0;
					}
				});
				setNotifCount(count);
			}
		})
			.catch((err) => { history.push("/500") });
	};

	const getmsgCount = () => {
		getUnreadConvsCount().then((res) => {
			if (res.status === "success") setmsgCount(res.result);
		}).catch((err) => { history.push("/500") });
	};
	useEffect(() => {
		socket.on("new_notification", (data) => {
			if (
				data &&
				data.received_by &&
				Array.isArray(data.received_by) &&
				data.received_by.some((one) => one === user._id)
			) {
				setNotifCount(NotifCount + 1);
			}
		});
		return () => { socket.off("new_notification"); };
		// eslint-disable-next-line
	}, [NotifCount]);
	const CleanNotificationCount = () => {
		setNotifCount(0);
	};
	const CleanmsgCount = () => {
		setmsgCount(0);
		history.push("/app/chat/dm");
	};

	useEffect(() => {
		socket.on("message_notification", (data) => {
			if (
				data.received_by.some(
					(mem) => mem === user._id && mem !== data.sent_by._id
				)
			) {
				if (
					location.pathname === "/app/chat/teams" ||
					location.pathname === "/app/chat/dm" ||
					location.pathname === "/app/chat/sessions"
				)
					setmsgCount(0);
				else {
					notification["success"]({
						message:
							intl.formatMessage({ id: "message" }) + data.sent_by.firstname,
						description: intl.formatMessage({ id: "newmsg" }),
						placement: "bottomLeft",
					});
					setmsgCount(msgCount + 1);
				}
			}
		});
		return () => {
			socket.off('message_notification');
		}
		// eslint-disable-next-line
	}, [location]);
	useEffect(() => {
		setTimeout(() => {
			setlogos(logo)
		}, 5000);
	}, [logos])
	return (
		<Layout className="main-app-layout">
			<Header
				style={{
					background: "white",
					padding: "0 20px",
					borderBottom: "2px solid #458ff6",
					position: "fixed",
					zIndex: 9999,
					width: "100%",
					height: 60,
				}}
			>
				<div className="main-app-navbar">
					<div className="main-app-navbar-left">
						<div className="main-app-navbar-item">
							<img
								src={!collapsed ? togglerFocused : toggler}
								width={20}
								height={20}
								alt=""
								className="collapse-menu"
								onClick={() => setCollapsed(!collapsed)}
							/>
						</div>
						<div className="main-app-navbar-item">
							<img
								src={logos}
								alt=""
								onClick={() => history.push("/app")}
								style={{ height: 30, transition: "opacity 1s ease-in-out", animationDelay: "2s" }}
							/>
						</div>
						<div className="main-app-navbar-item">

						</div>
					</div>
					<div className="main-app-navbar-right">
						{bp !== "sm" && bp !== "xs" ? (
							<>
								<div className="main-app-navbar-item">
									<Languages className="nv-header-nav" />
									<Popover
										trigger="click"
										style={{
											padding: 0,
										}}
										onVisibleChange={(visible) => setIsHelpMenu(visible)}
										visible={isHelpMenu}
										content={
											<HelpMenu />
										}
										placement={
											context.state.direction === "ltr"
												? "bottomRight"
												: "bottomLeft"
										}
									>
										<Tooltip title={<FormattedMessage id="menu" />}>
											<Grid />
										</Tooltip>
									</Popover>


								</div>
								<div className="main-app-navbar-item">
									<Badge count={msgCount}>
										<Tooltip title={<FormattedMessage id="messages" />}>
											<Mail
												onClick={() => {
													CleanmsgCount();
												}}
											/>
										</Tooltip>
									</Badge>
								</div>
								<div className="main-app-navbar-item">
									<Badge count={NotifCount}>
										<Popover
											trigger="click"
											style={{
												padding: 0,
											}}
											onVisibleChange={(visible) => setIsParentVisible(visible)}
											visible={isParentVisible}
											content={
												<NotifPopover
													CleanNotificationCount={CleanNotificationCount}
													parentVisible={isParentVisible}
													setParentVisible={setIsParentVisible}
												/>
											}
											placement={
												context.state.direction === "ltr"
													? "bottomRight"
													: "bottomLeft"
											}
										>
											<Tooltip title={<FormattedMessage id="notifications" />}>
												<Bell />
											</Tooltip>
										</Popover>

									</Badge>
								</div>
							</>
						) : (
							""
						)}
						<div className="">
							<Popover
								trigger="click"
								placement={
									context.state.direction === "ltr"
										? "bottomRight"
										: "bottomLeft"
								}
								content={
									<Container
										className="popover-content"
										style={{ margin: " 20px 0" }}
									>
										<Col style={{ margin: "auto", textAlign: "center" }}>
											<Avatar src={User.avatarLink(user)} size="large" />
											<div style={{ margin: "10px 0" }}>
												{`${user.firstname} ${user.lastname}`}
											</div>
										</Col>
										<Divider style={{ margin: "10px 0" }} />
										{(bp !== "sm" || bp !== "xs") && (
											<>
												<div className="popover-item" onClick={() => history.push("/app/chat/dm")}>
													<span>
														{" "}
														<FormattedMessage id="messages" />{" "}
													</span>
													<Mail
														size={15}
													/>
												</div>
												<div className="popover-item" onClick={() => {
													CleanNotificationCount()
													history.push("/app/notifications")
												}}>
													<span>
														{" "}
														<FormattedMessage id="notifications" />{" "}
													</span>
													<Badge count={NotifCount}>
														<Bell size={15} />
													</Badge>
												</div>
											</>
										)}
										<div
											className="popover-item"
											onClick={() => {
												history.push("/app/profile/me");
											}}
										>
											<span>
												{" "}
												<FormattedMessage id="profile" />{" "}
											</span>
											<UserIcon size={15} />
										</div>
										<div className="popover-item"
											onClick={() => {
												history.push("/app/profile/edit");
											}}
										>
											<span>
												{" "}
												<FormattedMessage id="settings" />{" "}
											</span>
											<Settings size={15} />
										</div>
										<Logout>
											<div className="popover-item">
												<span>
													{" "}
													<FormattedMessage id="logout" />{" "}
												</span>
												<LogOut size={15} />
											</div>
										</Logout>
									</Container>
								}
							>
								<Avatar src={User.avatarLink(user)} />
								<ChevronDown size={15} className="avatar-chevron" />
							</Popover>
						</div>
					</div>
				</div>
			</Header>
			<Layout
				hasSider={true}
				style={{ paddingTop: 60, minHeight: "100vh" }}
				className="inner-layout"
			>
				{user.account_type === "idea_holder" ? (
					<Sider
						breakpoint="md"
						collapsedWidth={bp === "sm" || bp === "md" ? 0 : 80}
						collapsible
						collapsed={collapsed}
						onCollapse={() => setCollapsed(!collapsed)}
						theme="light"
						trigger={null}
						style={{
							overflow: "auto",
							height: "100vh",
							position: "fixed",
							left: 0,
							zIndex: 9999,
						}}
						className="sider"
					>
						<Menu
							mode="inline"
							className="sider-menu"
							defaultselectedkey={["main"]}
							selectedKeys={selectedKey}
						>
							<Menu.Item
								key="main"
								icon={<Main />}
								onClick={() => history.push("/app")}
							>
								<FormattedMessage id="Main" />
							</Menu.Item>
							<Menu.Item
								key="incubator"
								icon={<Incubator />}
								onClick={() => history.push("/app/incubator")}
							>
								<FormattedMessage id="incubator" />
							</Menu.Item>
							<SubMenu
								key="ideas"
								icon={<Ideas />}
								title={
									<span
										className="ideas-text"
										style={{
											opacity: collapsed ? 0 : 1,
											transition: "opacity .2s ease",
										}}
									>
										<FormattedMessage id="Ideas" />
									</span>
								}
							>
								<Menu.Item
									key="Addnewidea"
									onClick={() => history.push("/app/ideas/new")}
								>
									<FormattedMessage id="Addnewidea" />
								</Menu.Item>
								<Menu.Item
									key="MyIdeas"
									onClick={() => history.push("/app/ideas/me")}
								>
									{/* {withdSize} */}
									<FormattedMessage id="MyIdeas" />
								</Menu.Item>
								<Menu.Item
									key="allIdeas"
									onClick={() => history.push("/app/ideas")}
								>
									<FormattedMessage id="allIdeas" />
								</Menu.Item>

							</SubMenu>
							<SubMenu
								key="profile"
								icon={<ExploreProfile />}
								title={
									<span
										className="ideas-text"
										style={{
											opacity: collapsed ? 0 : 1,
											transition: "opacity .2s ease",
										}}
									>
										<FormattedMessage id="exploreProfile" />
									</span>
								}
							>
								<Menu.Item
									key="all"
									onClick={() => history.push("/app/explore/all")}
								>
									<FormattedMessage id="allprofiles" />
								</Menu.Item>
								<Menu.Item
									key="idea_holder"
									onClick={() => history.push("/app/explore/idea_holder")}
								>
									<FormattedMessage id="ideaHolder" />
								</Menu.Item>
								<Menu.Item
									key="instructor"
									onClick={() => history.push("/app/explore/instructor")}
								>
									<FormattedMessage id="instructor" />
								</Menu.Item>
							</SubMenu>
							<Menu.Item
								key="sessions"
								icon={<Sessions />}
								onClick={() => history.push("/app/sessions")}
							>
								<FormattedMessage id="sessions" />
							</Menu.Item>
							<Menu.Item
								key="invites"
								icon={
									<UsergroupAddOutlined
										style={{ fontSize: "20px", color: "#458ff6" }}
									/>
								}
								onClick={() => history.push("/app/invites")}
							>
								<FormattedMessage id="invites" />
							</Menu.Item>
							<Menu.Item
								key="process"
								icon={<EcosystemDataset />}
								onClick={() => history.push("/app/process")}
							>
								<FormattedMessage id="ProcessInfo" />
							</Menu.Item>


						</Menu>
					</Sider>
				) : (
					<MentorsLayout collapsed={collapsed} setCollapsed={setCollapsed} />
				)}

				<Content
					style={{
						paddingLeft:
							context.state.direction === "ltr"
								? collapsed
									? bp !== "md" && bp !== "sm"
										? 80
										: 0
									: bp !== "md" && bp !== "sm"
										? 260
										: 0
								: 0,
						paddingRight:
							context.state.direction === "rtl"
								? collapsed
									? bp !== "md" && bp !== "sm"
										? 80
										: 0
									: bp !== "md" && bp !== "sm"
										? 260
										: 0
								: 0,
						transition: "padding .2s ease",
						backgroundColor: "white"
					}}
				>
					{children}
				</Content>

			</Layout>
			<Footer
				style={{ textAlign: 'end', backgroundColor: "white" }}
			>
				<p>
					<div className="footer_">
						<div className="socials">
							<a href="https://www.facebook.com/ofppt.page.officielle" rel="noreferrer" target="__blank">
								<Facebook className="social-icon" />
							</a>
							<a href="https://www.youtube.com/c/OFPPTchaineofficielle" rel="noreferrer" target="__blank">
								<Youtube className="social-icon" />
							</a>
							<a href="https://www.linkedin.com/company/ofpptpageofficielle" rel="noreferrer" target="__blank">
								<Linkedin className="social-icon" />
							</a>
							<a href="https://www.instagram.com/ofppt.officiel/" rel="noreferrer" target="__blank">
								<Instagram className="social-icon" />
							</a>
							<a href="https://twitter.com/OFPPT_Officiel" rel="noreferrer" target="__blank">
								<Twitter className="social-icon" />
							</a>
						</div>
						<span>
							<span> <FormattedMessage id="hand-craft" /></span>
							<Heart className="text-danger" size={15} />
						</span>
					</div>
				</p>
			</Footer>
		</Layout >
	);
};

const mapStateToProps = (state) => ({
	Uservalue: state.auth.login.user,
});


export default connect(mapStateToProps, {
	getNotifications,
	getUnreadConvsCount,
})(MainAppLayout);
