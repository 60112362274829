import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../sass/Notifications/index.scss";
import "../../sass/Notifications/NotifPopover.scss";
import { Container } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Heading from "../../components/Heading";
import {
	getNotifications,
	ReadAllNotifications,
	ReadNotificationsById,
} from "../../redux/actions/Notifications/Notifications";
import _ from "lodash";
import { NotifEntry } from "../../components/Notifications/NotifPopover";
import { PulseLoader } from "react-spinners";
import { Empty } from "antd";
import UserMethods from "../../Helpers/UserHelpers";
import NotifyMethods from "../../Helpers/Notifications";
import InfiniteScroll from "react-infinite-scroll-component";
import { Check } from "react-feather";

export const Notifications = (props) => {
	const [Notifs, setNotifs] = useState([]);
	const [results, setResults] = useState([]);
	const [page, setPage] = useState(1);
	const [hasMore, setHasMore] = useState(false);

	useEffect(() => {
		getNotifications();
		// eslint-disable-next-line
	}, []);
	const getNotifications = () => {
		props.getNotifications(1).then((res) => {
			if (res && res.status === "success") {
				if (res.result.hasNextPage) {
					setHasMore(true);
					setPage(res.result.nextPage);
				}
				setNotifs(res.result.Notifications);
				setResults(res.result);
			}
		});
	};
	const getNotificationsbyPage = () => {
		if (results.hasNextPage) {
			props.getNotifications(page).then((res) => {
				if (res && res.status === "success") {
					setNotifs((Notifs) => [...Notifs, ...res.result.Notifications]);
					setResults(res.result);
				}
			});
		} else setHasMore(false);
	};
	// Read Just One notification
	const ReadNotif = (id) => {
		props.ReadNotificationsById(id).then((res) => {
			if (res && res.status === "success" && res.updated === 1) {
				getNotifications();
			}
		});
	};
	const ReadAllNotif = () => {
		props.ReadAllNotifications().then((res) => {
			if (res && res.status === "success") {
				getNotifications();
			}
		});
	};

	return (
		<div className="notif-page">
			<Container>
				<div className="carta">
					<Heading
						text={<FormattedMessage id="notifications" />}
						color="primary"
					/>
					<div className="w-100 flex justify-end">
						<div className="mark-all-as-read" onClick={() => ReadAllNotif()}>
							<Check size={15} /> <FormattedMessage id="markAllAsRead" />
						</div>
					</div>
					<div className="notifs-wrapper" id="notifsWrapper">
						<InfiniteScroll
							dataLength={Notifs.length}
							next={getNotificationsbyPage}
							loader={<PulseLoader color="#458ff6" size={8} />}
							scrollableTarget="notifsWrapper"
							hasMore={hasMore}
							scrollThreshold={1}
						>
							{_.isArray(Notifs) && Notifs.length ? (
								Notifs.map((notif, i) => {
									return (
										<NotifEntry
											key={i}
											readNotif={ReadNotif}
											notif={notif}
											title={
												notif.sent_by.firstname
													? notif.sent_by.firstname.toUpperCase() +
													" " +
													notif.sent_by.lastname.toUpperCase()
													: ""
											}
											unread={
												notif.read_by.some((one) => one === props.user._id)
													? false
													: true
											}
											content={
												<div>
													<FormattedMessage id={notif.type} />
												</div>
											}
											link={NotifyMethods.getLinkbyType(notif)}
											imgSrc={notif.sent_by.avatar ? UserMethods.getUserImage(
												notif.sent_by.userId,
												notif.sent_by.avatar
											) : UserMethods.getUserAvatar()}
										/>
									);
								})
							) : (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={<FormattedMessage id="noNotifications" />}
								/>
							)}
						</InfiniteScroll>
					</div>
				</div>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.login.user,
});

export default connect(mapStateToProps, {
	getNotifications,
	ReadAllNotifications,
	ReadNotificationsById,
})(Notifications);
